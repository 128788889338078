import { useTranslation } from "react-i18next"
import useTopics from "../../context/useTopics"
import LoadingSpinner from "../helpers/LoadingSpinner"
import TopicListEntry from "./TopicListEntry"

const TopicList = ({ needRefetch, setNeedRefetch }) => {
  const { t } = useTranslation("data")
  const { topics, loading, switchEditMode } = useTopics(
    needRefetch,
    setNeedRefetch
  )

  const topicsTable = Object.values(topics).length ? (
    <div className="table-container">
      <table className="table is-fullwidth">
        <thead>
          <tr>
            <th>{t("course.titleRu")}</th>
            <th>{t("course.titleEn")}</th>
            <th>{t("course.created")}</th>
            <th className="has-text-centered">{t("course.action")}</th>
          </tr>
        </thead>
        <tbody>
          {Object.values(topics).map(topic => (
            <TopicListEntry
              key={topic.id}
              topic={topic}
              switchEditMode={switchEditMode}
              setNeedRefetch={setNeedRefetch}
            />
          ))}
        </tbody>
      </table>
    </div>
  ) : (
    <p className="has-text-grey">{t("emptyList")}</p>
  )

  return (
    <div className="box">
      <p className="title is-5">{t("topic.listTitle")}</p>
      {loading ? (
        <LoadingSpinner text={t("loading.topicsList")} />
      ) : (
        topicsTable
      )}
    </div>
  )
}

export default TopicList
