import { useTranslation } from "react-i18next"
import useStudentAsgn from "../../../context/useStudentAsgn"

const StudentHomeGrade = () => {
  const { t } = useTranslation("data")
  const { loading, hasAsgn, asgn } = useStudentAsgn()

  if (loading || !hasAsgn || asgn?.status !== "finished") return null

  return (
    <div className="box has-text-centered">
      {t("group.asgnGradeByLeader")}:&nbsp;<strong>{asgn?.grade}</strong>
    </div>
  )
}

export default StudentHomeGrade
