import axios from "axios"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import { API_URL, messageToToasts } from "../utils"

const useUsers = userType => {
  const { i18n } = useTranslation()
  const [loading, setLoading] = useState(true)
  const [users, setUsers] = useState([])
  const [usersMap, setUsersMap] = useState({})
  const [needRefetch, setNeedRefetch] = useState(true)

  useEffect(
    () => {
      const fetchUsers = async () => {
        try {
          const requestURL = `${API_URL}/user/${userType}/list`
          const resp = await axios.get(requestURL)
          const { status, message, users: usersRaw } = resp.data

          if (status === "failed")
            return messageToToasts(message, i18n.language)

          setUsersMap(
            usersRaw.reduce(
              (obj, user) =>
                Object.assign(obj, {
                  [user.id]: user,
                }),
              {}
            )
          )
          setUsers(usersRaw)
          setLoading(false)
        } catch (error) {
          toast.error(
            "Server error. Try to reload page or contact administrator"
          )
        }
      }

      if (needRefetch) {
        setLoading(true)
        fetchUsers()
        setNeedRefetch(false)
      }
    },
    // eslint-disable-next-line
    [userType, needRefetch]
  )

  return { loading, users, usersMap, setNeedRefetch }
}

export default useUsers
