import axios from "axios"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router"
import { toast } from "react-toastify"
import { API_URL, messageToToasts } from "../../utils"
import FormEntry from "../helpers/FormEntry"
import Submitting from "../helpers/Submitting"

const ForgotPasswordForm = ({ userType }) => {
  const history = useHistory()
  const { t, i18n } = useTranslation("auth")
  const [userEmail, setUserEmail] = useState("")
  const [submitting, setSubmitting] = useState(false)

  const requestReset = async e => {
    e.preventDefault()
    setSubmitting(true)

    try {
      const data = {
        userEmail,
        userType,
      }

      const resp = await axios.post(`${API_URL}/user/forgotPassword`, data)
      const { status, message } = resp.data

      setSubmitting(false)
      if (status === "failed") {
        return messageToToasts(message, i18n.language)
      }

      toast.success(t("requestResetSuccessMessage"))
      history.push("../signIn")
    } catch (error) {
      toast.error("Server error. Try to reload page or contact administrator")
    }
  }

  return (
    <>
      <h4 className="subtitle has-text-centered capitalized">
        {t("forgotPassword")}
      </h4>
      <form>
        <FormEntry labelText="Email">
          <input
            className="input"
            value={userEmail}
            onChange={e => setUserEmail(e.target.value)}
            type="email"
            placeholder={`${userType.toLowerCase()}@${
              userType === "student" ? "edu." : ""
            }hse.ru`}
          />
        </FormEntry>

        <FormEntry>
          <Submitting submitting={submitting}>
            <p className="control">
              <button
                className="button is-link is-outlined is-fullwidth"
                type="submit"
                onClick={requestReset}
              >
                {t("requestReset")}
              </button>
            </p>
          </Submitting>
        </FormEntry>
      </form>
    </>
  )
}

export default ForgotPasswordForm
