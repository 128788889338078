import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import dayjs from "dayjs"
import i18next from "i18next"
import { useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import { useBlogPostContext } from "../../../context/BlogPostContext"
import { UserContext } from "../../../context/UserContext"
import LoadingSpinner from "../../helpers/LoadingSpinner"
import MeetingPostNew from "./MeetingPostNew"

const LinkHelper = ({ link }) => {
  const { t } = useTranslation("data")

  if ((link || "").toLowerCase().includes("zoom"))
    return (
      <a href={link} target="_blank" rel="noreferrer">
        <FontAwesomeIcon icon="video" /> Zoom
      </a>
    )

  if ((link || "").toLowerCase().includes("discord"))
    return (
      <a href={link} target="_blank" rel="noreferrer">
        <FontAwesomeIcon icon={["fab", "discord"]} /> Discord
      </a>
    )

  if ((link || "").toLowerCase().includes("skype"))
    return (
      <a href={link} target="_blank" rel="noreferrer">
        <FontAwesomeIcon icon={["fab", "skype"]} /> Skype
      </a>
    )

  if ((link || "").toLowerCase().includes("teams"))
    return (
      <a href={link} target="_blank" rel="noreferrer">
        <FontAwesomeIcon icon={["fab", "microsoft"]} /> MS Teams
      </a>
    )

  return (
    <a href={link} target="_blank" rel="noreferrer">
      <FontAwesomeIcon icon="globe" /> {t("goto")}
    </a>
  )
}

const MeetingPostList = () => {
  const { t } = useTranslation("data")
  const {
    user: { userType },
  } = useContext(UserContext)
  const { meetings, meetingLoading } = useBlogPostContext()

  const [editMode, setEditMode] = useState(false)
  const [showHistory, setShowHistory] = useState(false)

  if (meetingLoading)
    return (
      <div className="box">
        <LoadingSpinner />
      </div>
    )

  if (meetings?.length === 0) return null

  const actualMeeting = meetings[0]

  if (editMode)
    return (
      <MeetingPostNew
        projectID={actualMeeting?.projectID}
        meeting={{ ...actualMeeting, setEditMode }}
      />
    )

  return (
    <div className="box">
      <p className="title is-5">
        <FontAwesomeIcon icon="thumbtack" />
        &nbsp;{t("blogpost.nextMeeting")}
        {!["admin", "projectLeader"].includes(userType) || (
          <span
            className="is-pulled-right has-text-grey pointer"
            onClick={() => setEditMode(true)}
          >
            <FontAwesomeIcon icon="pen" />
          </span>
        )}
      </p>

      <hr />

      <div className="columns">
        <div className="column is-half">
          <p>
            <strong>{t("blogpost.theme")}:</strong> {actualMeeting?.theme}
          </p>

          <p>
            <strong>{t("blogpost.date")}:</strong>{" "}
            {dayjs(actualMeeting?.meetingDate)
              .locale(i18next.language)
              .format("DD/MM/YYYY HH:mm (dddd)")}
          </p>
        </div>

        <div className="column is-half">
          {actualMeeting?.auditorium?.length === 0 || (
            <p>
              <strong>{t("blogpost.auditorium")}:</strong>{" "}
              {actualMeeting?.auditorium}
            </p>
          )}

          {actualMeeting?.link?.length === 0 || (
            <p>
              <strong>{t("blogpost.link")}:</strong>{" "}
              <LinkHelper link={actualMeeting?.link} />
            </p>
          )}
        </div>
      </div>

      {!actualMeeting?.text?.length > 0 || (
        <div className="content" style={{ whiteSpace: "pre-wrap" }}>
          {actualMeeting?.text}
        </div>
      )}

      {(meetings || []).length <= 1 || (
        <>
          <hr />
          {showHistory ? (
            <div>
              <p className="has-text-centered has-text-grey">
                <span className="pointer" onClick={() => setShowHistory(false)}>
                  {t("blogpost.hideHistory")}
                </span>
              </p>

              <hr />

              {meetings.slice(1).map(meeting => (
                <>
                  <div className="columns">
                    <div className="column is-half">
                      <p>
                        <strong>{t("blogpost.theme")}:</strong> {meeting?.theme}
                      </p>

                      <p>
                        <strong>{t("blogpost.date")}:</strong>{" "}
                        {dayjs(meeting?.meetingDate)
                          .locale(i18next.language)
                          .format("DD/MM/YYYY HH:mm (dddd)")}
                      </p>
                    </div>

                    <div className="column is-half">
                      {meeting?.auditorium?.length === 0 || (
                        <p>
                          <strong>{t("blogpost.auditorium")}:</strong>{" "}
                          {meeting?.auditorium}
                        </p>
                      )}

                      {meeting?.link?.length === 0 || (
                        <p>
                          <strong>{t("blogpost.link")}:</strong>{" "}
                          <LinkHelper link={meeting?.link} />
                        </p>
                      )}
                    </div>
                  </div>

                  {!meeting?.text?.length > 0 || (
                    <div className="content" style={{ whiteSpace: "pre-wrap" }}>
                      {meeting?.text}
                    </div>
                  )}

                  <hr />
                </>
              ))}

              <p className="has-text-centered has-text-grey">
                <span className="pointer" onClick={() => setShowHistory(false)}>
                  {t("blogpost.hideHistory")}
                </span>
              </p>
            </div>
          ) : (
            <p className="has-text-centered has-text-grey">
              <span className="pointer" onClick={() => setShowHistory(true)}>
                {t("blogpost.history")}
              </span>
            </p>
          )}
        </>
      )}
    </div>
  )
}

export default MeetingPostList
