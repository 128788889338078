import axios from "axios"
import { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { API_URL, messageToToasts } from "../../../utils"
import { UserContext } from "../../../context/UserContext"
import { toast } from "react-toastify"
import PracticeCard from "./PracticeCard"
import PracticePersonDataForm from "./PracticePersonDataForm"

const PracticeNew = () => {
  const { t, i18n } = useTranslation("data")
  const {
    user: { userID },
  } = useContext(UserContext)

  const [nr, setNR] = useState(true)
  const [hasPractice, setHasPractice] = useState(true)
  const [practice, setPractice] = useState(null)
  const [orgType, setOrgType] = useState("hse")
  const [hseDepartment, setHseDepartment] = useState("")
  const [orgLocation, setOrgLocation] = useState("rf")
  const [orgAddress, setOrgAddress] = useState("")
  const [orgTitle, setOrgTitle] = useState("")
  const [inn, setInn] = useState("")
  const [country, setCountry] = useState("")
  const [personsForm, setPersonsForm] = useState({
    leaderFullName: "",
    leaderPosition: "",
    leaderEmail: "",
    leaderPhone: "",
    acceptorFullName: "",
    acceptorPosition: "",
    acceptorEmail: "",
    acceptorPhone: "",
    contactFullName: "",
    contactPosition: "",
    contactEmail: "",
    contactPhone: "",
  })

  useEffect(() => {
    const getPractice = async () => {
      const resp = await axios.get(
        `${API_URL}/practice/get?studentID=${userID}`
      )

      const { hasPractice, practice } = resp.data
      setHasPractice(hasPractice)
      setPractice(practice)
    }

    if (nr) {
      getPractice()
      setNR(false)
    }
  }, [userID, nr])

  const sendForm = async () => {
    try {
      const data = {
        studentID: userID,
        isInternal: orgType === "hse",
        hseDepartment,
        orgTitle,
        orgLocation,
        orgAddress,
        inn,
        country,
        format: "offline",
        ...personsForm,
      }

      console.log(data)

      const resp = await axios.post(`${API_URL}/practice/add`, data)
      const { status, message } = resp.data

      if (status === "failed") {
        return messageToToasts(message, i18n.language)
      }

      setNR(true)
    } catch (_) {
      toast.error(
        "System error (or internet connection missing)! Try to reload page"
      )
    }
  }

  return (
    <>
      <div className="box has-text-right title is-4">{t("practice.title")}</div>

      {!hasPractice || <PracticeCard practice={practice} />}

      {hasPractice || (
        <div className="box">
          <div className="columns">
            <div className="column">
              <label className="label">{t("practice.orgType")}</label>
              <div className="field has-addons">
                <div className="control is-expanded">
                  <button
                    onClick={() => setOrgType("hse")}
                    className={
                      "button is-fullwidth " +
                      (orgType === "hse" ? "is-link" : "")
                    }
                  >
                    {t("practice.hse")}
                  </button>
                </div>
                <div className="control is-expanded">
                  <button
                    onClick={() => setOrgType("external")}
                    className={
                      "button is-fullwidth " +
                      (orgType === "external" ? "is-link" : "")
                    }
                  >
                    {t("practice.external")}
                  </button>
                </div>
              </div>
            </div>

            <div className="column">
              {orgType === "hse" || (
                <>
                  <label className="label">{t("practice.orgLocation")}</label>
                  <div className="field has-addons">
                    <div className="control is-expanded">
                      <button
                        onClick={() => setOrgLocation("rf")}
                        className={
                          "button is-fullwidth " +
                          (orgLocation === "rf" ? "is-link" : "")
                        }
                      >
                        {t("practice.rf")}
                      </button>
                    </div>
                    <div className="control is-expanded">
                      <button
                        onClick={() => setOrgLocation("non-rf")}
                        className={
                          "button is-fullwidth " +
                          (orgLocation === "non-rf" ? "is-link" : "")
                        }
                      >
                        {t("practice.non-rf")}
                      </button>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>

          <hr />

          {orgType === "hse" ? (
            <div className="field">
              <div className="control">
                <label className="label">{t("practice.hseDepartment")}</label>
                <input
                  value={hseDepartment}
                  onChange={e => setHseDepartment(e.target.value)}
                  className="input"
                  type="text"
                />
                <p className="help">{t("required")}</p>
              </div>
            </div>
          ) : (
            <>
              <div className="columns">
                <div className="column">
                  <div className="field">
                    <div className="control">
                      <label className="label">{t("practice.orgTitle")}</label>
                      <input
                        value={orgTitle}
                        onChange={e => setOrgTitle(e.target.value)}
                        className="input"
                        type="text"
                      />
                      <p className="help">
                        {t("required") + ", " + t("practice.orgTitleHelp")}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="column">
                  <div className="field">
                    <div className="control">
                      <label className="label">
                        {t(
                          `practice.${orgLocation === "rf" ? "inn" : "country"}`
                        )}
                      </label>
                      <input
                        value={orgLocation === "rf" ? inn : country}
                        onChange={e =>
                          orgLocation === "rf"
                            ? setInn(e.target.value)
                            : setCountry(e.target.value)
                        }
                        className="input"
                        type="text"
                      />
                      <p className="help">{t("required")}</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="columns">
                <div className="column">
                  <div className="field">
                    <div className="control">
                      <label className="label">
                        {t("practice.orgAddress")}
                      </label>
                      <input
                        value={orgAddress}
                        onChange={e => setOrgAddress(e.target.value)}
                        className="input"
                        type="text"
                      />
                      <p className="help">{t("required")}</p>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

          {orgType !== "hse" || (
            <div className="columns">
              <div className="column">
                <div className="field">
                  <div className="control">
                    <label className="label">
                      {orgType === "hse"
                        ? t("practice.leaderFullName")
                            .replace("от организации", "")
                            .replace("from the organization", "")
                        : t("practice.leaderFullName")}
                    </label>
                    <input
                      value={personsForm.leaderFullName}
                      onChange={e =>
                        setPersonsForm({
                          ...personsForm,
                          leaderFullName: e.target.value,
                        })
                      }
                      type="text"
                      className="input"
                    />
                    <p className="help">{t("required")}</p>
                  </div>
                </div>
              </div>

              <div className="column">
                <div className="field">
                  <div className="control">
                    <label className="label">
                      {orgType === "hse"
                        ? t("practice.leaderPosition")
                            .replace("от организации", "в подразделении")
                            .replace(
                              "from the organization",
                              "in the department"
                            )
                        : t("practice.leaderPosition")}
                    </label>
                    <input
                      value={personsForm.leaderPosition}
                      onChange={e =>
                        setPersonsForm({
                          ...personsForm,
                          leaderPosition: e.target.value,
                        })
                      }
                      type="text"
                      className="input"
                    />
                    <p className="help">{t("required")}</p>
                  </div>
                </div>
              </div>
            </div>
          )}

          {orgType !== "external" || (
            <>
              <PracticePersonDataForm
                personType="leader"
                form={personsForm}
                setForm={setPersonsForm}
              />

              <PracticePersonDataForm
                personType="acceptor"
                form={personsForm}
                setForm={setPersonsForm}
              />

              <PracticePersonDataForm
                personType="contact"
                form={personsForm}
                setForm={setPersonsForm}
              />
            </>
          )}

          <div className="field is-grouped is-grouped-right">
            <div className="control">
              <button onClick={sendForm} className="button is-link">
                {t("practice.send")}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default PracticeNew
