import i18next from "i18next"
import { useContext } from "react"
import { useTranslation } from "react-i18next"
import { CommonDataContext } from "../../../context/CommonDataContext"
import useProjects from "../../../context/useProjects"
import LoadingSpinner from "../../helpers/LoadingSpinner"
import {
  initialFormatOptions,
  langOptions,
  startDatesRaw,
} from "../ProposalNew"

const FillFromProject = ({
  setTitle,
  setTitleEN,
  setSelectedLang,
  setSelectedDivision,
  setSummary,
  setDescription,
  setStartDate,
  setIsOpenToExternal,
  setVideoLink,
  setStudentsMax,
  setSelectedFormat,
  setTopicStrings,
  setRecomendedCoursesStrings,
  setExistingColeaders,
}) => {
  const { t } = useTranslation("data")
  const {
    // projectLeaders: { dict: pls },
    divisions: { options: divisionOptions },
  } = useContext(CommonDataContext)
  const { projects, loading } = useProjects("projectLeader")

  const startDates = startDatesRaw(t)
  const formatOptions = [
    { label: "Online", value: "online" },
    { label: "Offline", value: "offline" },
    ...initialFormatOptions[i18next.language],
  ]
  const currentYear = new Date().getFullYear()
  const nextYear = currentYear - 1999

  if (loading)
    return (
      <div className="box">
        <LoadingSpinner />
      </div>
    )

  if (!projects?.length) return null

  const fillFromProject = pr => {
    setTitle(pr?.title)
    setTitleEN(pr?.titleEN)
    setSelectedLang(langOptions.find(opt => opt.value === pr?.lang))
    setSelectedDivision(
      divisionOptions[i18next.language].find(
        opt => opt.value === pr?.divisionID
      )
    )
    setSelectedFormat(formatOptions.find(fo => fo.value === pr?.format))
    setSummary(pr?.summary)
    setDescription(pr?.description)
    setStartDate(startDates.find(opt => opt.value === pr?.startDate))
    setIsOpenToExternal(pr?.isOpenToExternal)
    setVideoLink(pr?.videoLink)
    setStudentsMax(pr?.maxStudents)

    setTopicStrings(pr?.topicsID || [])
    setRecomendedCoursesStrings(pr?.recomendedCoursesID || [])

    // setExistingColeaders(
    //   (pr?.projectColeadersID || []).map(cl =>
    //     pls[cl]?.email ? { email: pls[cl]?.email } : { email: "INVITE PENDING" }
    //   )
    // )
  }

  return (
    <div className="box">
      <p className="title is-5">{t("proposal.exisitingProjects")}</p>

      <hr />

      <div className="table-container">
        <table className="table is-fullwidth">
          <tbody>
            {(projects || []).map(pr => (
              <tr key={pr?.id}>
                <th>
                  {pr?.projectYear}-{pr?.num} - {pr?.title}
                </th>
                <td className="has-text-right">
                  <button
                    className="button is-small is-link"
                    onClick={() => fillFromProject(pr)}
                  >
                    {t("proposal.fillFromExisting")}&nbsp;
                    {currentYear}/{nextYear}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <hr />

        <p className="help">{t("proposal.existingProjectHint")}</p>
      </div>
    </div>
  )
}

export default FillFromProject
