import axios from "axios"
import Select from "react-select"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import FormEntry from "../../helpers/FormEntry"
import { API_URL, messageToToasts } from "../../../utils"
import { useContext, useState } from "react"
import { SettingsContext } from "../../../context/SettingsContext"
import StudentInfoNew from "../../data/StudentInfoNew"
import { UserContext } from "../../../context/UserContext"
import SettingsBlockedProgrammes from "./SettingsBlockedProgrammes"

const allowedOptions = [
  { label: "разрешено", value: "allowed" },
  { label: "запрещено", value: "notAllowed" },
]

const loadingOption = { label: "loading", value: "" }

const SettingsView = () => {
  const [submitting, setSubmitting] = useState(false)
  const { t, i18n } = useTranslation("data")
  const {
    user: { role },
  } = useContext(UserContext)
  const { settings, refetch, updateSetting } = useContext(SettingsContext)
  const [newLimit, setNewLimit] = useState(0)

  const resetSettings = async () => {
    setSubmitting(true)

    try {
      const resp = await axios.post(`${API_URL}/db/initBadger`)

      const { status, message } = resp.data
      setSubmitting(false)

      if (status === "failed") return messageToToasts(message, i18n.language)

      refetch()
      toast.success(t("settings.initBadgerSuccess"))
    } catch (error) {
      setSubmitting(false)
      toast.error("Server error!")
    }
  }

  const initDeclineLimit = async () => {
    setSubmitting(true)

    try {
      const resp = await axios.post(`${API_URL}/initDeclineLimit`, {
        newLimit: Number(newLimit),
      })
      setSubmitting(false)

      const { status, message } = resp.data
      if (status === "failed") return messageToToasts(message, i18n.language)

      refetch()
      setNewLimit(0)
      toast.success(t("settings.initDeclineLimitSuccess"))
    } catch (error) {
      setSubmitting(false)
      toast.error("Server error")
    }
  }

  const isModerator = role === "moderator"

  return (
    <>
      <div className="box title is-4 has-text-right">{t("settings.title")}</div>

      {!isModerator ? <StudentInfoNew /> : null}

      {/* General Settings */}
      <div className="box">
        <p className="title is-5">{t("settings.title")}</p>
        <hr />
        <div>
          <table className="table is-fullwidth">
            <tbody>
              <tr>
                <th>{t("settings.signupYear")}</th>
                <td className="has-text-right">
                  <input
                    type="number"
                    className="input"
                    value={settings?.signupYear}
                    onChange={e => updateSetting("signupYear", e.target.value)}
                    disabled={isModerator}
                  />
                </td>
              </tr>

              <tr>
                <th>{t("settings.currentYear")}</th>
                <td className="has-text-right">
                  <input
                    type="number"
                    className="input"
                    value={settings?.currentYear}
                    onChange={e => updateSetting("currentYear", e.target.value)}
                    disabled={isModerator}
                  />
                </td>
              </tr>

              <tr>
                <th>Год обучения (техническое для нагрузок)</th>
                <td className="has-text-right">
                  <input
                    type="number"
                    className="input"
                    value={settings?.studyYear}
                    onChange={e => updateSetting("studyYear", e.target.value)}
                    disabled={isModerator}
                  />
                </td>
              </tr>

              <tr>
                <th>Год заявок (техническое для нагрузок)</th>
                <td className="has-text-right">
                  <input
                    type="number"
                    className="input"
                    value={settings?.applyYear}
                    onChange={e => updateSetting("applyYear", e.target.value)}
                    disabled={isModerator}
                  />
                </td>
              </tr>

              <tr>
                <th>{t("settings.listProjectsStudent")}</th>
                <td className="has-text-right">
                  <Select
                    options={allowedOptions}
                    value={
                      settings?.listProjectsStudent
                        ? allowedOptions.find(
                            el => el.value === settings?.listProjectsStudent
                          )
                        : loadingOption
                    }
                    onChange={option =>
                      updateSetting("listProjectsStudent", option.value)
                    }
                    isDisabled={isModerator}
                  />
                </td>
              </tr>

              <tr>
                <th>{t("settings.requestTransferStudent")}</th>
                <td className="has-text-right">
                  <Select
                    options={allowedOptions}
                    value={
                      settings?.requestTransferStudent
                        ? allowedOptions.find(
                            el => el.value === settings?.requestTransferStudent
                          )
                        : loadingOption
                    }
                    onChange={option =>
                      updateSetting("requestTransferStudent", option.value)
                    }
                    isDisabled={isModerator}
                  />
                </td>
              </tr>

              <tr>
                <th>{t("settings.nonFreePlacesStudent")}</th>
                <td className="has-text-right">
                  <Select
                    options={allowedOptions}
                    value={
                      settings?.nonFreePlacesStudent
                        ? allowedOptions.find(
                            el => el.value === settings?.nonFreePlacesStudent
                          )
                        : loadingOption
                    }
                    onChange={option =>
                      updateSetting("nonFreePlacesStudent", option.value)
                    }
                    isDisabled={isModerator}
                  />
                </td>
              </tr>

              <tr>
                <th>{t("settings.sendApplications")}</th>
                <td className="has-text-right">
                  <Select
                    options={allowedOptions}
                    value={
                      settings?.sendApplications
                        ? allowedOptions.find(
                            el => el.value === settings?.sendApplications
                          )
                        : loadingOption
                    }
                    onChange={option =>
                      updateSetting("sendApplications", option.value)
                    }
                    isDisabled={isModerator}
                  />
                </td>
              </tr>

              <tr>
                <th>{t("settings.requireMotivationLetter")}</th>
                <td className="has-text-right">
                  <Select
                    options={allowedOptions}
                    value={
                      settings?.requireMotivationLetter
                        ? allowedOptions.find(
                            el => el.value === settings?.requireMotivationLetter
                          )
                        : loadingOption
                    }
                    onChange={option =>
                      updateSetting("requireMotivationLetter", option.value)
                    }
                    isDisabled={isModerator}
                  />
                </td>
              </tr>

              <tr>
                <th>{t("settings.sendNotRecomended")}</th>
                <td className="has-text-right">
                  <Select
                    options={allowedOptions}
                    value={
                      settings?.sendNotRecomended
                        ? allowedOptions.find(
                            el => el.value === settings?.sendNotRecomended
                          )
                        : loadingOption
                    }
                    onChange={option =>
                      updateSetting("sendNotRecomended", option.value)
                    }
                    isDisabled={isModerator}
                  />
                </td>
              </tr>

              <tr>
                <th>{t("settings.applicationsPerStudent")}</th>
                <td className="has-text-right">
                  <input
                    type="number"
                    className="input"
                    value={settings.applicationsPerStudent}
                    onChange={e =>
                      updateSetting("applicationsPerStudent", e.target.value)
                    }
                    disabled={isModerator}
                  />
                </td>
              </tr>

              <tr>
                <th>{t("settings.leaderAcceptApplication")}</th>
                <td className="has-text-right">
                  <Select
                    options={allowedOptions}
                    value={
                      settings?.leaderAcceptApplication
                        ? allowedOptions.find(
                            el => el.value === settings?.leaderAcceptApplication
                          )
                        : loadingOption
                    }
                    onChange={option =>
                      updateSetting("leaderAcceptApplication", option.value)
                    }
                    isDisabled={isModerator}
                  />
                </td>
              </tr>

              <tr>
                <th>{t("settings.leaderApplicationsList")}</th>
                <td className="has-text-right">
                  <Select
                    options={allowedOptions}
                    value={
                      settings?.leaderApplicationsList
                        ? allowedOptions.find(
                            el => el.value === settings?.leaderApplicationsList
                          )
                        : loadingOption
                    }
                    onChange={option =>
                      updateSetting("leaderApplicationsList", option.value)
                    }
                    isDisabled={isModerator}
                  />
                </td>
              </tr>

              <tr>
                <th>{t("settings.leaderAcceptsFirstPriority")}</th>
                <td className="has-text-right">
                  <Select
                    options={allowedOptions}
                    value={
                      settings?.leaderAcceptsFirstPriority
                        ? allowedOptions.find(
                            el =>
                              el.value === settings?.leaderAcceptsFirstPriority
                          )
                        : loadingOption
                    }
                    onChange={option =>
                      updateSetting("leaderAcceptsFirstPriority", option.value)
                    }
                    isDisabled={isModerator}
                  />
                </td>
              </tr>

              <tr>
                <th>{t("settings.leaderApplicationsActions")}</th>
                <td className="has-text-right">
                  <Select
                    options={allowedOptions}
                    value={
                      settings?.leaderApplicationsActions
                        ? allowedOptions.find(
                            el =>
                              el.value === settings?.leaderApplicationsActions
                          )
                        : loadingOption
                    }
                    onChange={option =>
                      updateSetting("leaderApplicationsActions", option.value)
                    }
                    isDisabled={isModerator}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      {/* Decline limit */}
      <div className="box">
        <p className="title is-5">{t("settings.declineLimit")}</p>
        <hr />

        <div>
          <table className="table is-fullwidth">
            <tbody>
              <tr>
                <th>{t("settings.leaderDeclineWithLimits")}</th>
                <td className="has-text-right">
                  <Select
                    options={allowedOptions}
                    value={
                      settings?.leaderDeclineWithLimits
                        ? allowedOptions.find(
                            el => el.value === settings?.leaderDeclineWithLimits
                          )
                        : loadingOption
                    }
                    onChange={option =>
                      updateSetting("leaderDeclineWithLimits", option.value)
                    }
                    isDisabled={isModerator}
                  />
                </td>
              </tr>

              <tr>
                <th>{t("settings.declineLimit")}</th>
                <td className="has-text-right">
                  <input
                    type="number"
                    className="input"
                    value={settings.declineLimit}
                    disabled={true}
                  />
                </td>
              </tr>
            </tbody>
          </table>

          <hr />

          <div className="field has-addons">
            <p className="control is-expanded">
              <input
                type="number"
                className="input"
                value={newLimit}
                onChange={e => setNewLimit(e.target.value)}
                disabled={isModerator}
              />
            </p>

            <p className="control">
              <button
                className="button is-link"
                onClick={initDeclineLimit}
                disabled={isModerator || submitting}
              >
                {t("settings.setDeclineLimit")}
              </button>
            </p>
          </div>
        </div>
      </div>

      {/* Blocked Programmes settings */}
      <SettingsBlockedProgrammes isModerator={isModerator} />

      {/* Settings reset */}
      <div className="box">
        <p className="title is-5">{t("settings.resetTitle")}</p>
        <hr />

        <FormEntry>
          <button
            className="button is-danger is-outlined is-fullwidth"
            onClick={resetSettings}
            disabled={submitting || isModerator}
          >
            {t("settings.reset")}
          </button>
        </FormEntry>
      </div>
    </>
  )
}

export default SettingsView
