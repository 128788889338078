import { useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import { AcademicProgrammeContext } from "../../../context/AcademicProgrammeContext"
import { CommonDataContext } from "../../../context/CommonDataContext"
import { ProjectsContext } from "../../../context/ProjectsContext"
import { UserContext } from "../../../context/UserContext"
import { messageToToasts, nilObjectID } from "../../../utils.js"
import { toast } from "react-toastify"
import ProjectFreePlaces from "./ProjectFreePlaces"

const ProjectPreview = ({ project }) => {
  const [submitting, setSubmitting] = useState(false)
  const { t, i18n } = useTranslation("data")
  const {
    projectLeaders: { dict: plDict },
    divisions: { dict: divDict },
    programmes: { dict: progDict },
  } = useContext(CommonDataContext)
  const {
    user: { userType },
  } = useContext(UserContext)
  const { currentProgramme } = useContext(AcademicProgrammeContext)
  const { updateProject, unrequest } = useContext(ProjectsContext)

  const projectLeader = plDict[project?.projectLeaderID]

  const coleaders = (project?.projectColeadersID || [])
    .map(clID => ({
      id: clID,
      fullName: plDict[clID]?.fullName,
      workPlace:
        plDict[clID]?.workPlace === "hse"
          ? t("proposal.hse")
          : plDict[clID]?.workPlace,
    }))
    .filter(cl => cl.fullName)

  const recomend = async (projectID, mode) => {
    setSubmitting(true)

    try {
      const { status, message } = await updateProject(
        projectID,
        currentProgramme,
        mode
      )
      setSubmitting(false)
      if (status === "failed") return messageToToasts(message, i18n.language)

      if (mode === "push") toast.success(t("project.recomendSuccess"))
      else toast.success(t("project.unrecomendSuccess"))
    } catch (error) {
      setSubmitting(false)
      toast.error("Server error!")
    }
  }

  const unrequestProgramme = async projectID => {
    setSubmitting(true)

    try {
      const { status, message } = await unrequest(projectID, currentProgramme)
      setSubmitting(false)
      if (status === "failed") return messageToToasts(message, i18n.language)

      toast.success(t("project.unrequestSuccess"))
    } catch (error) {
      setSubmitting(false)
      toast.error("Server error!")
    }
  }

  const visibleProgrammes =
    userType === "projectLeader"
      ? project?.recomendingProgrammesID.filter(rpr => progDict[rpr]?.isVisible)
      : project?.recomendingProgrammesID

  return (
    <div className="box">
      <p className="title is-5">
        <span className="has-text-grey">{project?.projectYear}-</span>
        {project?.num ? (
          <span className="has-text-grey">{project?.num} - </span>
        ) : null}

        <Link to={"./viewProject/" + project.id}>
          {project.title}
          {project.lang === "ru" && project.titleEN !== ""
            ? ` (${project.titleEN})`
            : ""}
        </Link>
        <span className="is-pulled-right has-text-grey">{project.lang}</span>
      </p>

      <hr />

      <p className="subtitle is-6">
        <strong>{t("project.leader")}: </strong>
        <Link
          to={`/r/common/profile?userID=${projectLeader?.id}&userType=projectLeader`}
          target="_blank"
        >
          {projectLeader?.fullName}
        </Link>
      </p>

      {coleaders.length ? (
        <p className="subtitle is-6">
          <strong>{t("project.cle")}: </strong>
          {coleaders.map(cl => (
            <Link
              key={cl.id + Math.random()} // for retards with multiple same coleaders
              to={`/r/common/profile?userID=${cl?.id}&userType=projectLeader`}
              target="_blank"
            >
              {cl.fullName + " (" + cl.workPlace + ") "}&nbsp;
            </Link>
          ))}
        </p>
      ) : null}

      {project.division !== nilObjectID ? (
        <p className="subtitle is-6">
          <strong>{t("project.division")}: </strong>
          {" " + divDict[project.divisionID]?.title[i18n.language]}
        </p>
      ) : null}

      {project?.externalPartner?.length > 0 ? (
        <p className="subtitle is-6">
          <strong>{t("proposal.externalPartner")}: </strong>
          {project?.externalPartner}
        </p>
      ) : null}

      <hr />
      <div className="content">{project.summary}</div>

      {userType === "student" ? <ProjectFreePlaces project={project} /> : null}

      {visibleProgrammes?.length ? (
        <>
          <hr />
          <p className="subtitle is-6">
            <strong>{t("project.recomendingProgrammes")}: </strong>
            {visibleProgrammes
              .map(rpr => progDict[rpr]?.title[i18n.language])
              .join(", ")}
          </p>
        </>
      ) : null}

      {userType === "academicManager" &&
      (project?.recomendingProgrammesID || []).includes(currentProgramme) ? (
        <>
          <hr />
          <div className="columns">
            <div className="column is-8">
              <p className="has-text-left">
                <strong className="has-text-success">
                  {t("project.recomended")}
                </strong>
              </p>
            </div>

            <div className="column is-4">
              <div className="field is-grouped is-grouped-right">
                <p className="control">
                  <button
                    className="button is-small is-danger"
                    onClick={() => recomend(project.id, "pull")}
                    disabled={submitting}
                  >
                    {t("remove")}
                  </button>
                </p>
              </div>
            </div>
          </div>
        </>
      ) : null}

      {userType === "academicManager" &&
      !(project?.recomendingProgrammesID || []).includes(currentProgramme) ? (
        <>
          <hr />
          <div className="columns">
            <div className="column is-8">
              {(project?.requestedProgrammesID || []).includes(
                currentProgramme
              ) ? (
                <strong className="has-text-warning">
                  {t("project.requested")}
                </strong>
              ) : null}
            </div>

            <div className="column is-4">
              <div className="field is-grouped is-grouped-right">
                <p className="control">
                  <button
                    className="button is-small is-success"
                    onClick={() => recomend(project.id, "push")}
                    disabled={submitting}
                  >
                    {t("project.recomend").toLowerCase()}
                  </button>
                </p>
                {(project?.requestedProgrammesID || []).includes(
                  currentProgramme
                ) ? (
                  <p className="control">
                    <button
                      className="button is-small is-danger"
                      onClick={() => unrequestProgramme(project.id)}
                      disabled={submitting}
                    >
                      {t("project.unrequest").toLowerCase()}
                    </button>
                  </p>
                ) : null}
              </div>
            </div>
          </div>
        </>
      ) : null}
    </div>
  )
}

export default ProjectPreview
