import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import axios from "axios"
import fileDownload from "js-file-download"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import { API_URL } from "../../../utils"

const MakeGradeSheet = ({ asgnID, studentEmail = "@", mode = "add" }) => {
  const { t } = useTranslation("data")

  const makeSheet = async () => {
    try {
      const resp = await axios.get(
        API_URL + "/group/makeGradeSheet?asgnID=" + asgnID + "&mode=" + mode,
        {
          headers: {
            "Content-Type":
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          },
          responseType: "arraybuffer",
        }
      )

      const studentShortName = studentEmail.split("@")[0]
      fileDownload(resp.data, `grade_sheet_${studentShortName}.xlsx`)
    } catch (error) {
      toast.error("Server error!")
    }
  }

  return (
    <div className="control">
      <button className="button is-info" onClick={makeSheet}>
        <FontAwesomeIcon icon="file-excel" />
        &nbsp;
        {t("group.gradeSheet")}
      </button>
    </div>
  )
}

export default MakeGradeSheet
