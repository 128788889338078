import axios from "axios"
import i18next from "i18next"
import { useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import { CommonDataContext } from "../../../context/CommonDataContext"
import { UserContext } from "../../../context/UserContext"
import { API_URL, messageToToasts } from "../../../utils"

const BulletinList = ({ bulletins, refetch }) => {
  const { t } = useTranslation("data")
  const {
    user: { userType },
  } = useContext(UserContext)
  const {
    externalClients: { dict: clDict },
    topics: { dict: topicsDict },
  } = useContext(CommonDataContext)

  const [sub, setSub] = useState(false)

  if (!bulletins?.length) return <div className="box">{t("emptyList")}</div>

  const actionBulletin = async (action, bulletinID) => {
    setSub(true)

    try {
      const resp = await axios.post(`${API_URL}/bulletin/${action}`, {
        bulletinID,
      })

      const { status, message } = resp.data
      setSub(false)
      if (status === "failed")
        return toast.error(messageToToasts(message, i18next?.language))

      refetch()
    } catch (err) {
      setSub(false)
      toast.error("Server error")
    }
  }

  return (
    <>
      {bulletins.map(bulletin => (
        <div className="box" key={bulletin?.id}>
          <p className="title is-5">{bulletin?.title}</p>
          <hr />

          <div className="columns">
            <div className="column is-half">
              <div className="field">
                <div className="control">
                  <label className="label">{t("bulletin.description")}</label>
                  <div
                    className="has-background-light is-rounded px-2 py-2"
                    style={{ whiteSpace: "pre-wrap" }}
                  >
                    {bulletin?.description}
                  </div>
                </div>
              </div>

              <div className="field">
                <div className="control">
                  <label className="label">{t("bulletin.organization")}</label>
                  <div
                    className="has-background-light is-rounded px-2 py-2"
                    style={{ whiteSpace: "pre-wrap" }}
                  >
                    {clDict[bulletin?.authorID]?.organization}
                  </div>
                </div>
              </div>

              {!(bulletin?.topicsID || [])?.length || (
                <div className="field">
                  <div className="control">
                    <label className="label">{t("bulletin.topics")}</label>
                    {(bulletin?.topicsID || []).map(topic => (
                      <>
                        <span className="tag" key={topicsDict[topic]?.id}>
                          {topicsDict[topic]?.title[i18next?.language]}
                        </span>
                        &nbsp;
                      </>
                    ))}
                  </div>
                </div>
              )}
            </div>

            <div className="column is-half">
              <div className="field">
                <div className="control">
                  <label className="label">{t("bulletin.studentsReq")}</label>
                  <div
                    className="has-background-light is-rounded px-2 py-2"
                    style={{ whiteSpace: "pre-wrap" }}
                  >
                    {bulletin?.studentRequirements}
                  </div>
                </div>
              </div>

              <div className="field">
                <div className="control">
                  <label className="label">{t("bulletin.contacts")}</label>
                  <div
                    className="has-background-light is-rounded px-2 py-2"
                    style={{ whiteSpace: "pre-wrap" }}
                  >
                    {bulletin?.contacts}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {userType === "projectLeader" || (
            <>
              <hr />
              <div className="columns">
                <div className="column is-4 has-text-grey">
                  {t(`bulletin.${bulletin?.status}`)}
                </div>

                <div className="column is-8">
                  <div className="field is-grouped is-grouped-right">
                    <div className="control">
                      <button
                        className="button is-small is-danger"
                        onClick={() => actionBulletin("remove", bulletin?.id)}
                        disabled={sub}
                      >
                        {t("remove")}
                      </button>
                    </div>

                    {!(
                      userType === "admin" && bulletin?.status === "awaiting"
                    ) || (
                      <div className="control">
                        <button
                          className="button is-small is-success"
                          onClick={() =>
                            actionBulletin("publish", bulletin?.id)
                          }
                          disabled={sub}
                        >
                          {t("bulletin.publish")}
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      ))}
    </>
  )
}

export default BulletinList
