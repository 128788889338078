import { useTranslation } from "react-i18next"
import { useTransferContext } from "../../../context/TransferContext"

const TransferActions = ({ transfer }) => {
  const { t } = useTranslation("data")
  const { mode, declineTransfer, acceptTransfer } = useTransferContext()

  if (transfer?.status !== "awaiting") {
    return null
  }

  const declineButton =
    mode === "from" && !transfer.hasApprovedAsgn ? null : (
      <p className="control">
        &nbsp;
        <button
          className="button is-danger is-small"
          onClick={() => declineTransfer(transfer?.id)}
        >
          {t("application.decline")}
        </button>
      </p>
    )

  const acceptButton =
    ["both", "admin"].includes(mode) ||
    (mode === "to" && !transfer.toAccepted) ||
    (mode === "from" && !transfer.fromAccepted) ? (
      <p className="control">
        &nbsp;
        <button
          className="button is-success is-small"
          onClick={() => acceptTransfer(transfer?.id)}
        >
          {t("application.accept")}
        </button>
      </p>
    ) : null

  return (
    <div className="field is-grouped is-grouped-right">
      {declineButton}
      {mode === "student" || acceptButton}
    </div>
  )
}

export default TransferActions
