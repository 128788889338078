import axios from "axios"
import { useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import Select from "react-select"
import { toast } from "react-toastify"
import { CommonDataContext } from "../../../context/CommonDataContext"
import { ProjectsContext } from "../../../context/ProjectsContext"
import {
  API_URL,
  messageToToasts,
  nilObjectID,
  yearsToText,
} from "../../../utils"
import FormEntry from "../../helpers/FormEntry"

const ApplicationAssignStudent = () => {
  const { t, i18n } = useTranslation("data")
  const { currentProject, refetch } = useContext(ProjectsContext)
  const {
    students: { dict: allStudents, loading: studentsLoading },
    refetch: cdRefetch,
  } = useContext(CommonDataContext)
  const [submitting, setSubmitting] = useState(false)
  const [selectedStudent, setSelectedStudent] = useState(null)

  if (studentsLoading) return null

  const studentsNoProjectOptions = Object.values(allStudents)
    .filter(stud => stud.projectID === nilObjectID)
    .map(stud => ({
      value: stud.id,
      label: `${stud.fullName} (${yearsToText(stud.yearOfStudy)})`,
    }))

  const assignStudent = async () => {
    setSubmitting(true)
    try {
      if (!selectedStudent) return setSubmitting(false)

      const data = {
        projectID: currentProject?.id,
        studentID: selectedStudent.value,
      }
      const resp = await axios.post(
        `${API_URL}/application/assignStudent`,
        data
      )

      setSubmitting(false)
      const { status, message } = resp.data

      if (status === "failed") return messageToToasts(message, i18n.language)

      toast.success(t("application.assignSuccess"))
      setSelectedStudent(null)
      refetch("projects")
      cdRefetch("students")
    } catch (error) {
      setSubmitting(false)
      toast.error("Server error")
    }
  }

  return (
    <div className="box">
      <p className="title is-5">{t("application.assignStudent")}</p>
      <hr />

      <p className="mb-3">{t("application.assignHint")}</p>

      {selectedStudent?.value &&
      !(currentProject?.recomendingProgrammesID || []).includes(
        allStudents[selectedStudent?.value]?.programmeID
      ) ? (
        <article className="message is-warning">
          <div className="message-body">
            Студент обучается на программе, которая не включена в список
            рекомендуемых данному проекту.
          </div>
        </article>
      ) : null}

      <FormEntry>
        <Select
          isSearchable
          isClearable
          value={selectedStudent}
          onChange={setSelectedStudent}
          options={studentsNoProjectOptions}
          placeholder={t("application.assignPlaceholder")}
        />
      </FormEntry>

      <div className="field has-text-right">
        <p className="control">
          <button
            className="button is-link is-outlined"
            onClick={assignStudent}
            disabled={submitting}
          >
            {t("application.assign")}
          </button>
        </p>
      </div>
    </div>
  )
}

export default ApplicationAssignStudent
