import axios from "axios"
import { useState } from "react"
import { toast } from "react-toastify"
import { useTranslation } from "react-i18next"
import { API_URL, messageToToasts } from "../../utils"
import AdminSignUpForm from "./su-forms/AdminSignUpForm"
import ProjectLeaderSignUpForm from "./su-forms/ProjectLeaderSignUpForm"
import CheckInvite from "./CheckInvite"
import Submitting from "../helpers/Submitting"
import { useHistory } from "react-router"
import DivisionManagerSignUpForm from "./su-forms/DivisionManagerSignUpForm"
import AcademicManagerSignUpForm from "./su-forms/AcademicManagerSignUpForm"
import StudentSignUpForm from "./su-forms/StudentSignUpForm"
import ExternalClientSignUpForm from "./su-forms/ExternalClientSignUpForm"

const initialCommonFields = {
  email: "",
  password: "",
  passwordRep: "",
  surname: "",
  name: "",
  secondName: "",
}

const SignUpForm = ({ userType }) => {
  const history = useHistory()
  const { t, i18n } = useTranslation("auth")
  const [submitting, setSubmitting] = useState(false)
  const [fields, setFields] = useState(initialCommonFields)
  const [inviteChecked, setInviteChecked] = useState(false)

  const updateFields = e =>
    setFields({ ...fields, [e.target.name]: e.target.value })

  const userTypeForm = () => {
    switch (userType) {
      case "admin":
        return (
          <AdminSignUpForm
            fields={fields}
            setFields={setFields}
            updateFields={updateFields}
          />
        )
      case "projectLeader":
        return (
          <ProjectLeaderSignUpForm
            fields={fields}
            setFields={setFields}
            updateFields={updateFields}
          />
        )
      case "externalClient":
        return (
          <ExternalClientSignUpForm
            fields={fields}
            setFields={setFields}
            updateFields={updateFields}
          />
        )
      case "divisionManager":
        return (
          <DivisionManagerSignUpForm
            fields={fields}
            setFields={setFields}
            updateFields={updateFields}
          />
        )
      case "academicManager":
        return (
          <AcademicManagerSignUpForm
            fields={fields}
            setFields={setFields}
            updateFields={updateFields}
          />
        )
      case "student":
        return (
          <StudentSignUpForm
            fields={fields}
            setFields={setFields}
            updateFields={updateFields}
          />
        )
      default:
        return <p>Неверный тип пользователя</p>
    }
  }

  const signUp = async e => {
    e.preventDefault()

    if (fields.surname === "") return toast.error(t("message.emptySurname"))

    if (fields.name === "") return toast.error(t("message.emptyName"))

    if (userType === "student" && fields.surnameEn === "")
      return toast.error(t("message.emptySurnameEn"))

    if (userType === "student" && fields.nameEn === "")
      return toast.error(t("message.emptyNameEn"))

    const fullName =
      `${fields.surname.trim()} ${fields.name.trim()} ${fields.secondName.trim()}`.trim()

    const url = `${API_URL}/user/${userType}/add${
      fields.inviteCode ? "" : "Email"
    }`

    const data = {
      ...fields,
      fullName,
    }

    if (userType === "student") {
      const fullNameEn =
        `${fields.surnameEn.trim()} ${fields.nameEn.trim()} ${fields.secondNameEn.trim()}`.trim()
      data.fullNameEn = fullNameEn
    }

    console.log(data)

    setSubmitting(true)
    const resp = await axios.post(url, data)

    const { status, message } = resp.data

    setSubmitting(false)
    if (status === "failed") {
      return messageToToasts(message, i18n.language)
    }

    toast.success(t("signUpSuccessMessage"))
    history.push("./signIn")
  }

  return (
    <>
      <h4 className="subtitle has-text-centered capitalized">{t("signUp")}</h4>
      {!inviteChecked ? (
        <CheckInvite
          fields={fields}
          setFields={setFields}
          userType={userType}
          setInviteChecked={setInviteChecked}
        />
      ) : (
        <form>
          {userTypeForm()}

          <div className="field has-text-centered mt-4">
            <Submitting submitting={submitting}>
              <p className="control">
                <button
                  className="button is-link is-outlined is-fullwidth capitalized"
                  type="submit"
                  onClick={e => signUp(e)}
                >
                  {t("signUp")}
                </button>{" "}
              </p>
            </Submitting>
          </div>
        </form>
      )}
    </>
  )
}

export default SignUpForm
