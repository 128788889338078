import { useContext } from "react"
import { useTranslation } from "react-i18next"
import { ApplicationsStudentContext } from "../../../context/ApplicationsStudentContext"
import ApplicationInfo from "../../data/application/ApplicationInfo"

const StudentApplicationsView = () => {
  const { t } = useTranslation("data")
  const { applications } = useContext(ApplicationsStudentContext)

  if (!Object.values(applications)?.length)
    return (
      <div className="box">
        <p className="has-text-grey">{t("emptyList")}</p>
      </div>
    )

  return Object.values(applications).map(app => (
    <ApplicationInfo application={app} key={app.id} />
  ))
}

export default StudentApplicationsView
