import { useContext } from "react"
import { useTranslation } from "react-i18next"
import ApplicationsStudentContextProvider from "../../../context/ApplicationsStudentContext"
import ProjectsContextProvider from "../../../context/ProjectsContext"
import useProfile from "../../../context/useProfile"
import { UserContext } from "../../../context/UserContext"
import { useQuery } from "../../../utils"
import CuratedDivisions from "../../data/user/CuratedDivisions"
import CuratedProgrammes from "../../data/user/CuratedProgrammes"
import LoadingSpinner from "../../helpers/LoadingSpinner"
import ChangePasswordView from "./ChangePasswordView"
import LeaderProjectsView from "./LeaderProjectsView"
import ProfileInfoView from "./ProfileInfoView"
import RemoveProjectView from "./RemoveProjectView"
import SessionsView from "./SessionsView"
import StudentApplicationsView from "./StudentApplicationsView"
import AlternativeEmailView from "./AlternativeEmailView"
import UpdateInfo from "./UpdateInfo"

const UserProfilePage = () => {
  const query = useQuery()
  const { user } = useContext(UserContext)
  const { t } = useTranslation("ws")
  const { loading, profile, currentProfile, refetch } = useProfile(
    query.get("userType"),
    query.get("userID"),
    user.userID
  )

  if (loading) return <LoadingSpinner />

  return (
    <>
      <div className="box has-text-right title is-4">
        {profile.fullName ? profile.fullName : t("userProfilePage.loading")}
      </div>

      <div className="columns">
        <div
          className={
            "column" +
            (currentProfile || user.userType === "admin" ? " is-8" : " is-12")
          }
        >
          {loading ? (
            <LoadingSpinner text={t("userProfilePage.loading")} />
          ) : (
            <>
              <ProfileInfoView profile={profile} />

              {profile.userType === "divisionManager" ? (
                <CuratedDivisions
                  curatedDivisions={profile.curatedDivisions}
                  managerID={query.get("userID")}
                />
              ) : null}

              {profile.userType === "academicManager" ? (
                <CuratedProgrammes
                  curatedProgrammes={profile.curatedProgrammesID}
                  managerID={query.get("userID")}
                />
              ) : null}

              {currentProfile ? <SessionsView /> : null}
            </>
          )}
        </div>

        {profile?.userType === "student" && (currentProfile || user.userType === "admin") ? (
          <div className="column is-4">
            {user.userType === "admin" ||
              (profile.userType === "student" && currentProfile) ? (
              <UpdateInfo profile={profile} refetchProfile={refetch} />
            ) : null}

            {currentProfile ? (
              <ChangePasswordView
                userID={profile.id}
                userType={profile.userType}
              />
            ) : null}
          </div>
        ) : null}
      </div>

      {profile.userType !== "student" || (
        <AlternativeEmailView
          profile={profile}
          currentProfile={currentProfile}
        />
      )}

      {profile.userType === "student" && user.userType === "admin" ? (
        <ProjectsContextProvider userType="admin">
          <ApplicationsStudentContextProvider
            fetchMode="student"
            studentID={query.get("userID")}
          >
            <RemoveProjectView
              studentID={profile.id}
              projectID={profile.projectID}
              oldProjectID={profile?.oldProjectID}
            />

            <StudentApplicationsView />
          </ApplicationsStudentContextProvider>
        </ProjectsContextProvider>
      ) : null}

      {profile.userType === "projectLeader" ? (
        <LeaderProjectsView leaderID={profile.id} />
      ) : null}
    </>
  )
}

export default UserProfilePage
