import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import axios from "axios"
import fileDownload from "js-file-download"
import { useContext } from "react"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import { CommonDataContext } from "../../../context/CommonDataContext"
import { ProjectsContext } from "../../../context/ProjectsContext"
import { UserContext } from "../../../context/UserContext"
import { API_URL } from "../../../utils"
import ProjectStatsForProgramme from "../../data/project/ProjectStatsForProgramme"
import LoadingSpinner from "../../helpers/LoadingSpinner"

const ProjectsStatsView = () => {
  const { t, i18n } = useTranslation(["ws", "data"])
  const {
    programmes: { dict: progDict },
  } = useContext(CommonDataContext)
  const { projects, refetch, projectsLoading } = useContext(ProjectsContext)
  const {
    user: { role },
  } = useContext(UserContext)

  const isModerator = role === "moderator"

  const totalCount = projects.length
  const recCount = projects.filter(
    pr => pr.recomendingProgrammesID.length
  ).length
  const unrecCount = totalCount - recCount

  const setProjectNums = async () => {
    try {
      const resp = await axios.get(`${API_URL}/project/setNums`)

      const { notSetted } = resp.data

      refetch("projects")
      toast.success(t("data:project.setNumsSuccess", { notSetted }))
    } catch (error) {
      toast.error("Server error")
    }
  }

  const getExportedCSV = async () => {
    try {
      const resp = await axios.get(API_URL + "/project/exportToCsv")

      fileDownload(resp.data, "projects.csv")
    } catch (error) {
      toast.error("Server error")
    }
  }

  const getExportedGradesCSV = async () => {
    try {
      const resp = await axios.get(API_URL + "/group/exportGrades")

      fileDownload(resp.data, "projects.csv")
    } catch (error) {
      toast.error("Server error")
    }
  }

  if (projectsLoading) return <LoadingSpinner />

  return (
    <>
      <div className="box has-text-right">
        <p className="title is-4">{t("proposalsPage.stats")}</p>
      </div>

      <div className="box">
        <nav className="level">
          <div className="level-item has-text-centered">
            <div>
              <p className="heading">{t("projectsPage.totalProjects")}</p>
              <p className="title">{totalCount}</p>
            </div>
          </div>

          <div className="level-item has-text-centered">
            <div>
              <p className="heading">{t("projectsPage.recProjects")}</p>
              <p className="title">{recCount}</p>
            </div>
          </div>

          <div className="level-item has-text-centered">
            <div>
              <p className="heading">{t("projectsPage.unrecProjects")}</p>
              <p className="title">{unrecCount}</p>
            </div>
          </div>

          <div className="level-item has-text-centered">
            <div>
              <p className="heading">{t("projectsPage.refresh")}</p>
              <p className="title">
                <FontAwesomeIcon
                  icon="sync"
                  className="pointer"
                  onClick={() => refetch("projects")}
                />
              </p>
            </div>
          </div>
        </nav>
      </div>

      {!isModerator ? (
        <div className="box">
          <div className="columns">
            <div className="column is-third has-text-centered">
              <button className="button is-link" onClick={getExportedCSV}>
                <FontAwesomeIcon icon="file-csv" size="lg" />
                &nbsp;
                {t("data:project.exportToCSV")}
              </button>
            </div>

            <div className="column is-third has-text-centered">
              <button className="button is-link" onClick={getExportedGradesCSV}>
                <FontAwesomeIcon icon="file-csv" size="lg" />
                &nbsp; Оценки и ТЗ
              </button>
            </div>

            <div className="column is-third has-text-centered">
              <button className="button is-link" onClick={setProjectNums}>
                <FontAwesomeIcon icon="sort-numeric-up" size="lg" />
                &nbsp;
                {t("data:project.setNums")}
              </button>
            </div>
          </div>
        </div>
      ) : null}

      {Object.values(progDict).map(prog => (
        <div className="box" key={prog.id}>
          <p className="title is-5">
            {prog?.title[i18n.language]} ({t(`data:proposal.${prog.mode}`)})
          </p>
          <hr />

          <ProjectStatsForProgramme programmeID={prog.id} />
        </div>
      ))}
    </>
  )
}

export default ProjectsStatsView
