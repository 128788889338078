import { useTranslation } from "react-i18next"
import { Redirect, Route, Switch, useRouteMatch } from "react-router"
import { Link } from "react-router-dom"
import ProposalView from "../projectLeader/ProposalView"
import ProposalListView from "../projectLeader/ProposalListView"
import ProposalStatsView from "./ProposalsStatsView"
import ProjectListView from "./ProjectListView"
import ProjectView from "./ProjectView"
import WishListView from "./WishListView"
import ProjectsStatsView from "./ProjectsStatsView"
import ApplicationStatisticsForProjects from "../../data/application/ApplicationStatisticsForProjects"
import ApplicationList from "../../data/application/ApplicationList"
import ApplicationsStudentContextProvider from "../../../context/ApplicationsStudentContext"
import ApplicationsActionsView from "./ApplicationsActionsView"
import ProjectChangeListView from "./ProjectChangeListView"
import StudentLogList from "../../data/studentlog/StudentLogList"

const ProjectsPageView = () => {
  const { t } = useTranslation("ws")
  const { path, url } = useRouteMatch()

  const proposalsMenu = (
    <div className="box">
      <aside className="menu">
        <p className="menu-label">{t("proposalsPage.proposals")}</p>
        <ul className="menu-list">
          <li>
            <Link to={url + "/stats"}>{t("proposalsPage.stats")}</Link>
          </li>

          <li>
            <Link to={url + "/listMain"}>{t("proposalsPage.mainList")}</Link>
          </li>

          <li>
            <Link to={url + "/listDraft"}>{t("proposalsPage.draftList")}</Link>
          </li>

          <li>
            <Link to={url + "/listArchive"}>
              {t("proposalsPage.archiveList")}
            </Link>
          </li>
        </ul>
      </aside>
    </div>
  )

  const projectsMenu = (
    <div className="box">
      <aside className="menu">
        <p className="menu-label">{t("menu.adminProjects")}</p>
        <ul className="menu-list">
          <li>
            <Link to={url + "/statsProjects"}>{t("projectsPage.stats")}</Link>
          </li>

          <li>
            <Link to={url + "/listProjects"}>{t("projectsPage.list")}</Link>
          </li>

          <li>
            <Link to={url + "/listChanges"}>
              {t("projectsPage.changeList")}
            </Link>
          </li>

          <li>
            <Link to={url + "/listWishes"}>{t("projectsPage.wishList")}</Link>
          </li>
        </ul>
      </aside>
    </div>
  )

  const applicationsMenu = (
    <div className="box">
      <aside className="menu">
        <p className="menu-label">{t("menu.studentApps")}</p>
        <ul className="menu-list">
          <li>
            <Link to={url + "/statsProjectApps"}>
              {t("projectsPage.stats")}
            </Link>
          </li>

          <li>
            <Link to={url + "/listApps"}>{t("projectsPage.listApps")}</Link>
          </li>

          <li>
            <Link to={url + "/actionsApps"}>
              {t("projectsPage.actionsApps")}
            </Link>
          </li>

          <li>
            <Link to={url + "/studentLog"}>Движение студентов</Link>
          </li>
        </ul>
      </aside>
    </div>
  )

  return (
    <div className="columns">
      <div className="column is-3">
        {proposalsMenu} {projectsMenu} {applicationsMenu}
      </div>
      <div className="column is-9">
        <Switch>
          <Route exact path={path}>
            <Redirect to={url + "/stats"} />
          </Route>

          <Route path={path + "/stats"}>
            <ProposalStatsView />
          </Route>

          <Route path={path + "/listMain"}>
            <ProposalListView category="main" />
          </Route>

          <Route path={path + "/listDraft"}>
            <ProposalListView category="draft" />
          </Route>

          <Route path={path + "/listArchive"}>
            <ProposalListView category="archive" />
          </Route>

          <Route path={path + "/view/:proposalID"}>
            <ProposalView />
          </Route>

          <Route path={path + "/statsProjects"}>
            <ProjectsStatsView />
          </Route>

          <Route path={path + "/statsProjectApps"}>
            <ApplicationStatisticsForProjects />
          </Route>

          <Route path={path + "/listApps"}>
            <ApplicationsStudentContextProvider fetchMode="admin">
              <ApplicationList forProject={false} showStudentInfo={false} />
            </ApplicationsStudentContextProvider>
          </Route>

          <Route path={path + "/listProjects"}>
            <ProjectListView />
          </Route>

          <Route path={path + "/listChanges"}>
            <ProjectChangeListView />
          </Route>

          <Route path={path + "/listWishes"}>
            <WishListView />
          </Route>

          <Route path={path + "/actionsApps"}>
            <ApplicationsActionsView />
          </Route>

          <Route path={path + "/studentLog"}>
            <StudentLogList />
          </Route>

          <Route path={path + "/viewProject/:projectID"}>
            <ProjectView />
          </Route>
        </Switch>
      </div>
    </div>
  )
}

export default ProjectsPageView
