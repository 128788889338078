import { useContext, useEffect, useState } from "react"
import Select from "react-select"
import { useTranslation } from "react-i18next"
import { CommonDataContext } from "../../../context/CommonDataContext"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { API_URL, messageToToasts, nilObjectID } from "../../../utils"
import { UserContext } from "../../../context/UserContext"
import axios from "axios"
import i18next from "i18next"
import { toast } from "react-toastify"

const PracticeSelectField = ({ practice, setPractice, field }) => {
  const { t } = useTranslation("data")
  const {
    projectLeaders: { dict: plDict },
  } = useContext(CommonDataContext)
  const {
    user: { userType, email: userEmail },
  } = useContext(UserContext)

  const [value, setValue] = useState(practice?.[field])
  const [selectedValue, setSelectedValue] = useState(practice?.[field])
  const [oldValue, setOldValue] = useState(practice?.[field])
  const [mode, setMode] = useState("show")

  useEffect(() => {
    setValue(practice?.[field])
  }, [practice, field])

  const plOptions = Object.values(plDict).map(pl => ({
    value: pl.id,
    label: pl.fullName,
  }))

  const edit = () => {
    setOldValue(value)
    setMode("edit")
  }

  const cancel = () => {
    setValue(oldValue)
    setMode("show")
  }

  const update = async () => {
    try {
      const resp = await axios.post(`${API_URL}/practice/update`, {
        ...practice,
        [field]: selectedValue.value,
        userEmail,
        changedField: field,
      })

      const { status, message, practice: upd_practice } = resp.data
      if (status === "failed") return messageToToasts(message, i18next.language)

      setPractice(upd_practice)
      setMode("show")
    } catch (error) {
      toast.error(`System error: ${error}`)
    }
  }

  const showView = (
    <span>
      {value !== nilObjectID
        ? plDict[value]?.fullName
        : t("practice.notAssigned")}
      &nbsp;
      {userType !== "admin" || (
        <FontAwesomeIcon icon="pen" className="pointer" onClick={edit} />
      )}
    </span>
  )

  const editView = (
    <div className="field is-fullwidth has-addons">
      <div className="control is-expanded">
        <Select
          options={plOptions}
          value={selectedValue}
          onChange={setSelectedValue}
          placeholder="Выберите руководителя..."
        />
      </div>
      <div className="control">
        <button className="button is-small is-success px-3" onClick={update}>
          <FontAwesomeIcon icon="check" size="lg" />
        </button>
      </div>
      <div className="control">
        <button className="button is-small is-danger px-3" onClick={cancel}>
          <FontAwesomeIcon icon="times" size="lg" />
        </button>
      </div>
    </div>
  )

  return (
    <p>
      <strong>{t(`practice.${field}`)}</strong>
      <br />
      {mode === "show" ? showView : editView}
    </p>
  )
}

export default PracticeSelectField
