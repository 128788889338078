import axios from "axios"
import { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import { toast } from "react-toastify"
import { CommonDataContext } from "../../../context/CommonDataContext"
import { UserContext } from "../../../context/UserContext"
import { API_URL, messageToToasts } from "../../../utils"
import GradingView from "../../data/grading/GradingView"
import LoadingSpinner from "../../helpers/LoadingSpinner"
import MathMarkdown from "../../helpers/MathMarkdown"

const MyProjectView = () => {
  const { t, i18n } = useTranslation("data")
  const [project, setProject] = useState(null)
  const {
    user: { userID },
  } = useContext(UserContext)
  const {
    projectLeaders: { dict: plDict },
    divisions: { dict: divDict },
    courses: { dict: courDict },
    topics: { dict: topicsDict },
  } = useContext(CommonDataContext)

  useEffect(
    () => {
      const fetchProject = async () => {
        try {
          const resp = await axios.get(
            `${API_URL}/project/getForStudent?userID=${userID}`
          )

          const { status, message, project } = resp.data
          if (status === "failed")
            return messageToToasts(message, i18n.language)

          setProject(project)
        } catch (error) {
          toast.error("Server error!")
        }
      }

      fetchProject()
    },
    // eslint-disable-next-line
    [userID]
  )

  if (!project?.lang) return <LoadingSpinner />

  const {
    lang,
    divisionID,
    titleEN,
    format,
    isOpenToExternal,
    description,
    topicsID,
    projectColeadersID,
    recomendedCoursesID,
  } = project

  const projectLeader = plDict[project.projectLeaderID]

  const coleaders = projectColeadersID
    .map(clID => ({
      id: clID,
      fullName: plDict[clID]?.fullName,
      workPlace:
        plDict[clID]?.workPlace === "hse"
          ? t("proposal.hse")
          : plDict[clID]?.workPlace,
    }))
    .filter(cl => cl.fullName)

  const recBachelorCourses = recomendedCoursesID
    .map(rc => courDict[rc])
    .filter(rc => rc?.mode === "bachelor")

  const recMasterCourses = recomendedCoursesID
    .map(rc => courDict[rc])
    .filter(rc => rc?.mode === "master")

  return (
    <>
      <div className="box has-text-right">
        <span className="title is-4">
          {project?.title}
          &nbsp;
          <span className="has-text-grey">
            ({project?.projectYear}-{project?.num})
          </span>
        </span>
      </div>

      <GradingView projectID={project?.id} projectLang={project?.lang} />

      <div className="box">
        <p className="title is-5">{t("proposal.projectInfo")}</p>
        <hr />

        {lang === "ru" && titleEN !== "" ? (
          <p className="subtitle is-6 mt-1">
            <strong>{t("proposal.titleEN")}: </strong>
            {titleEN}
          </p>
        ) : null}

        <p className="subtitle is-6 mt-1">
          <strong>{t("proposal.lang")}: </strong>
          {lang}
        </p>

        <p className="subtitle is-6">
          <strong>{t("project.leader")}: </strong>
          <Link
            to={`/r/common/profile?userID=${projectLeader?.id}&userType=projectLeader`}
            target="_blank"
          >
            {projectLeader?.fullName}
          </Link>
        </p>

        <p className="subtitle is-6">
          <strong>{t("project.division")}: </strong>
          {divDict[divisionID]?.title[i18n.language]}
        </p>

        {coleaders.length ? (
          <p className="subtitle is-6">
            <strong>{t("proposal.cle")}: </strong>
            {coleaders.map(cl => (
              <Link
                key={cl.id}
                to={`/r/common/profile?userID=${cl?.id}&userType=projectLeader`}
                target="_blank"
              >
                {cl.fullName + " (" + cl.workPlace + ") "}&nbsp;
              </Link>
            ))}
          </p>
        ) : null}

        {topicsID.length ? (
          <p className="subtitle is-6">
            <strong>{t("proposal.topics")}: </strong>
            {topicsID
              .map(rc => topicsDict[rc]?.title[i18n.language])
              .join("; ")}
          </p>
        ) : null}

        {recBachelorCourses.length ? (
          <p className="subtitle is-6">
            <strong>{t("proposal.recomendedBachelorCourses")}: </strong>
            {recBachelorCourses.map(rc => rc?.title[i18n.language]).join("; ")}
          </p>
        ) : null}

        {recMasterCourses.length ? (
          <p className="subtitle is-6">
            <strong>{t("proposal.recomendedMasterCourses")}: </strong>
            {recMasterCourses.map(rc => rc?.title[i18n.language]).join("; ")}
          </p>
        ) : null}

        <p className="subtitle is-6">
          <strong>{t("proposal.format")}: </strong>
          {format === "mixed" ? t("proposal.formatMixed") : format}
          {["online", "mixed"].includes(format) && !isOpenToExternal
            ? " (" + t("proposal.externalStudents") + ")"
            : null}
        </p>
      </div>

      <div className="box">
        <p className="title is-5">{t("proposal.description")}</p>
        <hr />
        <MathMarkdown styleClass="content">{description}</MathMarkdown>
      </div>
    </>
  )
}
export default MyProjectView
