import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom"
import ProjectListView from "./ProjectListView"
import ProjectView from "./ProjectView"

const ProjectsPageView = () => {
  const { path, url } = useRouteMatch()

  return (
    <>
      <Switch>
        <Route exact path={path}>
          <Redirect to={url + "/list"} />
        </Route>

        <Route path={path + "/list"}>
          <ProjectListView />
        </Route>

        <Route path={path + "/viewProject/:projectID"}>
          <ProjectView />
        </Route>
      </Switch>
    </>
  )
}

export default ProjectsPageView
