import axios from "axios"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import { API_URL, messageToToasts } from "../utils"

const useSettings = () => {
  const { i18n } = useTranslation()
  const [settings, setSettings] = useState({})
  const [needRefetch, setNeedRefetch] = useState(true)

  useEffect(
    () => {
      const fetchSettings = async () => {
        try {
          const resp = await axios.get(`${API_URL}/settings`)

          const { status, message, settings } = resp.data

          if (status === "failed")
            return messageToToasts(message, i18n.language)

          setSettings(settings)
        } catch (error) {
          toast.error("Server error!")
        }
      }

      if (needRefetch) {
        fetchSettings()
        setNeedRefetch(false)
      }
    },
    //eslint-disable-next-line
    [needRefetch]
  )

  return {
    settings,
    setNeedRefetch,
  }
}

export default useSettings
