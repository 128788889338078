import axios from "axios"
import { useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import { AcademicProgrammeContext } from "../../../context/AcademicProgrammeContext"
import { CommonDataContext } from "../../../context/CommonDataContext"
import { ProjectsContext } from "../../../context/ProjectsContext"
import { API_URL, messageToToasts } from "../../../utils"
import Submitting from "../../helpers/Submitting"

const ProjectRecomend = ({ projectID }) => {
  const { t, i18n } = useTranslation("data")
  const {
    programmes: { dict: progDict },
  } = useContext(CommonDataContext)
  const { refetch, currentProject, unrequest } = useContext(ProjectsContext)
  const { currentProgramme } = useContext(AcademicProgrammeContext)
  const [submitting, setSubmitting] = useState(false)

  const recomendProject = async () => {
    setSubmitting(true)

    try {
      const data = { projectID, programmeID: currentProgramme }
      const resp = await axios.post(`${API_URL}/project/recomend`, data)
      setSubmitting(false)

      const { status, message } = resp.data
      if (status === "failed") return messageToToasts(message, i18n.language)

      toast.success(t("project.recomendSuccess"))
      refetch("projects")
    } catch (error) {
      setSubmitting(false)
      toast.error("Server error!")
    }
  }

  const unrequestProject = async () => {
    setSubmitting(true)

    try {
      const { status, message } = await unrequest(projectID, currentProgramme)

      setSubmitting(false)
      if (status === "failed") return messageToToasts(message, i18n.language)

      toast.success(t("project.unrequestSuccess"))
    } catch (error) {
      setSubmitting(false)
      toast.error("Server error!")
    }
  }

  const requestedByProgramme = (
    currentProject?.requestedProgrammesID || []
  ).includes(currentProgramme)

  return (
    <div className="box">
      <p className="title is-5">{t("project.recomendTitle")}</p>
      <p>
        {t("project.recomendHint")}:
        <strong> {progDict[currentProgramme]?.title[i18n.language]}</strong>?
      </p>
      {!requestedByProgramme ? <p>{t("project.noAction")}</p> : null}
      <Submitting submitting={submitting}>
        <div className="field is-grouped is-grouped-right mt-5">
          <p className="control">
            <button
              className="button is-link is-outlined"
              onClick={recomendProject}
            >
              {t("project.recomend")}
            </button>
          </p>

          {requestedByProgramme ? (
            <p className="control">
              <button
                className="button is-danger is-outlined is-capitalized"
                onClick={unrequestProject}
              >
                {t("project.unrequest")}
              </button>
            </p>
          ) : null}
        </div>
      </Submitting>
    </div>
  )
}

export default ProjectRecomend
