import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import useStudentAsgn from "../../../context/useStudentAsgn"
import LoadingSpinner from "../../helpers/LoadingSpinner"
import AsgnChangeForm from "./AsgnChangeForm"

const AsgnChangeNew = ({ refetch }) => {
  const { t } = useTranslation("data")
  const { loading, hasAsgn, asgn } = useStudentAsgn()

  const [mode, setMode] = useState("view")

  if (loading || !asgn?.theme?.length)
    return (
      <div className="box">
        <LoadingSpinner />
      </div>
    )

  if (!hasAsgn) return <div className="box">{t("group.noAsgn")}</div>

  if (mode === "view")
    return (
      <div className="box has-text-centered">
        <span
          className="is-size-5 pointer has-text-link"
          onClick={() => setMode("add")}
        >
          <FontAwesomeIcon icon="plus" />
          &nbsp;{t("group.asgnRequestChange")}
        </span>
      </div>
    )

  return (
    <div className="box">
      <AsgnChangeForm asgn={asgn} setMode={setMode} refetch={refetch} />
    </div>
  )
}

export default AsgnChangeNew
