import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import axios from "axios"
import i18next from "i18next"
import { useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import Select from "react-select"
import { toast } from "react-toastify"
import { CommonDataContext } from "../../../context/CommonDataContext"
import { UserContext } from "../../../context/UserContext"
import { API_URL, messageToToasts, yearsToText } from "../../../utils"
import AsgnChangeForm from "./AsgnChangeForm"
import AsgnUpdate from "./AsgnUpdate"
import StudentStatus from "./StudentStatus"

const GroupStudentsRow = ({
  asgn,
  leaderOptions,
  isAdminPL,
  refetch,
  studRefetch,
  groupsOptions,
  group,
}) => {
  const { t } = useTranslation("data")
  const {
    projectLeaders: { dict: plDict },
    students: { dict: studDict },
  } = useContext(CommonDataContext)
  const {
    user: { userType },
  } = useContext(UserContext)

  const [submitting, setSubmitting] = useState(false)
  const [mode, setMode] = useState("view")

  const [selectedNewGroup, setSelectedNewGroup] = useState(null)

  const student = studDict[asgn?.studentID]
  const leader = plDict[asgn?.leaderID]

  const studentName =
    i18next.language === "ru" ? student?.fullName : student?.fullNameEn

  const switchLoad = async () => {
    try {
      const resp = await axios.post(`${API_URL}/group/switchLoad`, {
        asgnID: asgn?.id,
      })
      const { status, message } = resp.data

      if (status === "failed") return messageToToasts(message, i18next.language)

      refetch(false)
    } catch (error) {
      toast.error("Server error!")
    }
  }

  const changeLeader = async option => {
    setSubmitting(true)

    try {
      const resp = await axios.post(`${API_URL}/group/changeLeader`, {
        asgnID: asgn?.id,
        newLeaderID: option?.value,
      })
      const { status, message } = resp.data
      setSubmitting(false)

      if (status === "failed") return messageToToasts(message, i18next.language)

      refetch(false)
    } catch (error) {
      setSubmitting(false)
      toast.error("Server error!")
    }
  }

  const removeFromGroup = async () => {
    setSubmitting(true)

    try {
      const resp = await axios.post(`${API_URL}/group/removeStudent`, {
        groupID: asgn?.groupID,
        studentID: asgn?.studentID,
      })
      const { status, message } = resp.data
      setSubmitting(false)

      if (status === "failed") return messageToToasts(message, i18next.language)

      refetch(false)
      studRefetch()
    } catch (error) {
      setSubmitting(false)
      toast.error("Server error!")
    }
  }

  const transferStudent = async () => {
    setSubmitting(true)

    try {
      const resp = await axios.post(`${API_URL}/group/changeStudentGroup`, {
        oldGroupID: asgn?.groupID,
        newGroupID: selectedNewGroup?.value,
        studentID: asgn?.studentID,
      })
      const { status, message } = resp.data
      setSubmitting(false)

      if (status === "failed") return messageToToasts(message, i18next.language)

      refetch(false)
      studRefetch()
    } catch (error) {
      setSubmitting(false)
      toast.error("Server error!")
    }
  }

  if (mode === "remove" && isAdminPL)
    return (
      <>
        <div className="columns">
          <div className="column is-8">
            {t("group.removeHint")} <strong>{studentName}</strong>?
          </div>

          <div className="column is-4">
            <div className="field is-grouped is-grouped-right">
              <div className="control">
                <button
                  className="button is-small is-light"
                  onClick={() => setMode("view")}
                  disabled={submitting}
                >
                  {t("cancel")}
                </button>
              </div>

              <div className="control">
                <button
                  className="button is-small is-danger is-capitalized"
                  onClick={removeFromGroup}
                  disabled={submitting}
                >
                  {t("remove")}
                </button>
              </div>
            </div>
          </div>
        </div>

        <hr className="mt-2" />
      </>
    )

  if (mode === "update" && isAdminPL)
    return (
      <AsgnUpdate
        asgn={asgn}
        student={student}
        group={group}
        setModeView={() => setMode("view")}
        refetch={refetch}
      />
    )

  if (mode === "requestChange" && isAdminPL)
    return (
      <>
        <div className="columns">
          <div className="column is-7">
            <strong>{yearsToText(student?.yearOfStudy)}</strong>&nbsp;
            {studentName}
          </div>

          <div className="column is-5 has-text-right">
            <strong>{t("group.statusHelp")}:</strong>&nbsp;
            <StudentStatus status={asgn?.status}>
              {t(`group.${asgn?.status || "notAssigned"}`)}
            </StudentStatus>
          </div>
        </div>

        <AsgnChangeForm asgn={asgn} setMode={setMode} />
      </>
    )

  if (mode === "transfer" && isAdminPL)
    return (
      <>
        <div className="columns">
          <div className="column is-4">
            <strong>{yearsToText(student?.yearOfStudy)}</strong>&nbsp;
            {studentName}
          </div>

          <div className="column is-4">
            <Select
              options={groupsOptions}
              value={selectedNewGroup}
              onChange={setSelectedNewGroup}
              placeholder={t("group.newGroupPlh")}
              isClearable
            />
          </div>

          <div className="column is-4">
            <div className="field is-grouped is-grouped-right">
              <div className="control">
                <button
                  className="button is-small is-light"
                  onClick={() => setMode("view")}
                  disabled={submitting}
                >
                  {t("cancel")}
                </button>
              </div>

              <div className="control">
                <button
                  className="button is-small is-link is-capitalized"
                  disabled={submitting}
                  onClick={transferStudent}
                >
                  {t("group.transfer")}
                </button>
              </div>
            </div>
          </div>
        </div>

        <hr className="mt-2" />
      </>
    )

  return (
    <>
      <div className="columns mb-0">
        <div className={"column" + (isAdminPL ? " is-4" : " is-6")}>
          <Link
            to={`/r/common/profile?userID=${student.id}&userType=student`}
            target="_blank"
          >
            <FontAwesomeIcon icon="user" size="lg" />
          </Link>
          &nbsp;
          {!isAdminPL || (
            <span className="pointer" onClick={() => setMode("update")}>
              <StudentStatus status={asgn?.status}>
                <FontAwesomeIcon icon="ellipsis-v" size="lg" />
              </StudentStatus>
              &nbsp;
            </span>
          )}
          <strong>{yearsToText(student?.yearOfStudy)}</strong>&nbsp;
          {studentName}
          &nbsp;(
          <a href={"mailto:" + student?.email}>{student?.email}</a>
          {student?.alternativeEmail ? (
            <span>
              ,{" "}
              <a href={"mailto:" + student?.alternativeEmail}>
                {student?.alternativeEmail}
              </a>
            </span>
          ) : (
            ""
          )}
          )
        </div>

        <div className="column is-4">
          {isAdminPL ? (
            <Select
              value={{ value: asgn?.leaderID, label: leader?.fullName }}
              options={leaderOptions}
              onChange={changeLeader}
              isDisabled={submitting}
            />
          ) : (
            leader?.fullName
          )}
        </div>

        <div className="column has-text-right">
          <div className="field">
            <label
              className={
                "switch is-rounded" +
                (userType === "admin" ? "" : " is-disabled")
              }
              disabled={userType !== "admin"}
            >
              <input
                type="checkbox"
                value="false"
                checked={asgn?.isLoad}
                onChange={switchLoad}
                disabled={!isAdminPL}
              />
              <span className="check is-link"></span>
              <span className="control-label">{t("group.load")}</span>
            </label>
          </div>
        </div>

        {!isAdminPL || (
          <div className="column is-2 has-text-right pt-4">
            <span
              className="pointer has-text-link"
              onClick={() => setMode("update")}
            >
              <FontAwesomeIcon
                icon={asgn?.status !== "approved" ? "pen" : "eye"}
                size="lg"
              />
            </span>
            &nbsp;
            {asgn?.status !== "approved" || (
              <>
                <span
                  className="pointer has-text-link"
                  onClick={() => setMode("requestChange")}
                >
                  <FontAwesomeIcon icon="pen" size="lg" />
                </span>
                &nbsp;
              </>
            )}
            <span
              className="pointer has-text-link"
              onClick={() => setMode("transfer")}
            >
              <FontAwesomeIcon icon="exchange-alt" size="lg" />
            </span>
            &nbsp;
            <span
              className="pointer has-text-danger"
              onClick={() => setMode("remove")}
            >
              <FontAwesomeIcon icon="times" size="lg" />
            </span>
          </div>
        )}
      </div>

      <hr className="mt-2" />
    </>
  )
}

export default GroupStudentsRow
