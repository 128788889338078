import { createContext } from "react"
import useApplications from "./useApplications"

export const ApplicationsStudentContext = createContext()

const ApplicationsStudentContextProvider = ({
  children,
  fetchMode,
  studentID = "",
  projectID = "",
}) => {
  const { applications, setNeedRefetch } = useApplications(
    fetchMode,
    studentID,
    projectID
  )

  const refetch = () => {
    setNeedRefetch(true)
  }

  const applicationsList = Object.values(applications)

  const applicationsLength = applicationsList?.length
  const awaitingLength = applicationsList?.filter(
    app => app.status === "awaiting"
  ).length
  const acceptedByLeaderLength = applicationsList?.filter(
    app => app.status === "acceptedByLeader"
  ).length

  const usedProjects = applicationsList.filter(
    app => !["declined", "declinedSelf"].includes(app.status)
  )

  const usedPriorities = usedProjects.map(app => app.priority)
  const availablePriorities = [1, 2, 3, 4, 5].filter(
    el => !usedPriorities.includes(el)
  )

  const projectsID = usedProjects.map(app => app.projectID)

  const value = {
    applications,
    applicationsLength,
    awaitingLength,
    acceptedByLeaderLength,
    availablePriorities,
    projectsID,
    fetchMode,
    refetch,
  }

  return (
    <ApplicationsStudentContext.Provider value={value}>
      {children}
    </ApplicationsStudentContext.Provider>
  )
}

export default ApplicationsStudentContextProvider
