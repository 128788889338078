import { useContext } from "react"
import { CommonDataContext } from "../../../context/CommonDataContext"
import { UserContext } from "../../../context/UserContext"
import dayjs from "dayjs"

const Message = ({ message }) => {
  const {
    academicManagers: { dict: amDict },
    projectLeaders: { dict: plDict },
  } = useContext(CommonDataContext)
  const {
    user: { userID },
  } = useContext(UserContext)

  const ownMessage = userID === message.authorID
  const viewedMessage = (message?.viewedBy || []).includes(userID)

  const author = { ...amDict, ...plDict }[message.authorID]

  const messageDiv = (
    <>
      <div
        className={
          "message is-small" + (viewedMessage ? " is-link" : " is-warning")
        }
      >
        <div className="message-header">
          {author?.fullName} -{" "}
          {dayjs(message.created).format("DD/MM/YYYY HH:mm")}
        </div>

        <div className="message-body" style={{ whiteSpace: "pre-wrap" }}>
          {message.messageText}
        </div>
      </div>
    </>
  )

  return (
    <div className="columns">
      <div className={"column" + (ownMessage ? " is-5" : " is-7")}>
        {!ownMessage ? messageDiv : null}
      </div>

      <div className={"column" + (ownMessage ? " is-7" : " is-5")}>
        {ownMessage ? messageDiv : null}
      </div>
    </div>
  )
}

export default Message
