import { useContext } from "react"
import { Route, Switch, useRouteMatch } from "react-router"
import BlogPostContextProvider from "../../../context/BlogPostContext"
import { ProjectsContext } from "../../../context/ProjectsContext"
import { UserContext } from "../../../context/UserContext"
import BlogPostInfo from "../../data/blogpost/BlogPostInfo"
import BlogPostList from "../../data/blogpost/BlogPostList"
import BlogPostNew from "../../data/blogpost/BlogPostNew"
import MeetingPostList from "../../data/blogpost/MeetingPostList"
import MeetingPostNew from "../../data/blogpost/MeetingPostNew"
import LoadingSpinner from "../../helpers/LoadingSpinner"

const BlogView = () => {
  const { url } = useRouteMatch()
  const {
    user: { userType },
  } = useContext(UserContext)
  const { currentProject } = useContext(ProjectsContext)

  if (!currentProject?.id)
    return (
      <div className="box">
        <LoadingSpinner />
      </div>
    )

  return (
    <BlogPostContextProvider projectID={currentProject?.id}>
      <Switch>
        <Route exact path={url}>
          {userType !== "projectLeader" || (
            <>
              <MeetingPostNew projectID={currentProject?.id} />

              <BlogPostNew projectID={currentProject?.id} />
            </>
          )}
          <MeetingPostList />

          <BlogPostList />
        </Route>

        <Route path={url + "/:postID"}>
          <BlogPostInfo />
        </Route>
      </Switch>
    </BlogPostContextProvider>
  )
}

export default BlogView
