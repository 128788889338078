import { useTranslation } from "react-i18next"
import useProgrammes from "../../context/useProgrammes"
import LoadingSpinner from "../helpers/LoadingSpinner"
import ProgrammeListEntry from "./ProgrammeListEntry"

const ProrammeList = ({ needRefetch, setNeedRefetch, mode }) => {
  const { t } = useTranslation("data")
  const { programmes, loading, switchEditMode } = useProgrammes(
    needRefetch,
    setNeedRefetch,
    mode
  )

  const programmesTable = Object.values(programmes).length ? (
    <div className="table-container">
      <table className="table is-fullwidth">
        <thead>
          <tr>
            <th>{t("course.titleRu")}</th>
            <th>{t("course.titleEn")}</th>
            <th>{t("course.created")}</th>
            <th className="has-text-centered">{t("course.action")}</th>
          </tr>
        </thead>
        <tbody>
          {Object.values(programmes).map(programme => (
            <ProgrammeListEntry
              key={programme.id}
              programme={programme}
              switchEditMode={switchEditMode}
              setNeedRefetch={setNeedRefetch}
            />
          ))}
        </tbody>
      </table>
    </div>
  ) : (
    <p className="has-text-grey">{t("emptyList")}</p>
  )

  return (
    <div className="box">
      <p className="title is-5">{t("programme.listTitle")}</p>
      {loading ? (
        <LoadingSpinner text={t("loading.programmesList")} />
      ) : (
        programmesTable
      )}
    </div>
  )
}

export default ProrammeList
