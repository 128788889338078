import { createContext, useContext, useState } from "react"
import { AcademicProgrammeContext } from "./AcademicProgrammeContext"
import { SettingsContext } from "./SettingsContext"
import { UserContext } from "./UserContext"

export const ProjectsFilterContext = createContext()

const ProjectsFilterContextProvider = ({ children }) => {
  const { currentProgramme } = useContext(AcademicProgrammeContext)
  const {
    user: { userType },
  } = useContext(UserContext)
  const { settings } = useContext(SettingsContext)

  const [selectedDivision, setSelectedDivision] = useState(null)
  const [selectedParticipants, setSelectedParticipants] = useState(null)
  const [selectedFormat, setSelectedFormat] = useState(null)
  const [selectedLanguage, setSelectedLanguage] = useState(null)
  const [selectedTopics, setSelectedTopics] = useState(null)
  const [selectedCourses, setSelectedCourses] = useState(null)
  const [coursesOrAnd, setCoursesOrAnd] = useState("or")
  const [selectedYears, setSelectedYears] = useState(null)
  const [isOpenToExternal, setIsOpenToExternal] = useState("all")
  const [isOpenToOtherSchools, setIsOpenToOtherSchools] = useState("all")
  const [isOpenToOnlineProgrammes, setIsOpenToOnlineProgrammes] =
    useState("all")
  const [isShowRequested, setIsShowRequested] = useState(false)
  const [isShowRequestedSeats, setIsShowRequestedSeats] = useState(false)
  const [isShowOnlyRecomended, setIsShowOnlyRecomended] = useState(true)
  const [isShowOnlyFree, setIsShowOnlyFree] = useState(false)

  const predicate = proj => {
    const divisionPredicate = selectedDivision
      ? proj.divisionID === selectedDivision.value
      : true

    const participantsID = selectedParticipants?.length
      ? selectedParticipants.map(sp => sp.value)
      : []
    const participantsPredicate = participantsID.length
      ? [...proj.projectColeadersID, proj.projectLeaderID].some(r =>
          participantsID.includes(r)
        )
      : true

    const formatPredicate = selectedFormat
      ? proj.format === selectedFormat.value
      : true

    const openToExternalPredicate =
      selectedFormat &&
      ["online", "mixed"].includes(selectedFormat.value) &&
      isOpenToExternal !== "all"
        ? proj.isOpenToExternal === Boolean(isOpenToExternal === "false")
        : true

    const openToOnlinePredicate =
      selectedFormat &&
      ["online", "mixed"].includes(selectedFormat.value) &&
      isOpenToOnlineProgrammes !== "all"
        ? proj.isOpenToOnlineProgrammes ===
          Boolean(isOpenToOnlineProgrammes === "false")
        : true

    const openToOtherSchoolsPredicate =
      selectedFormat && isOpenToOtherSchools !== "all"
        ? proj.isOpenToOtherSchools ===
          Boolean(isOpenToOtherSchools === "false")
        : true

    const languagePredicate = selectedLanguage
      ? proj.lang === selectedLanguage.value
      : true

    const topicsID = selectedTopics?.length
      ? selectedTopics.map(st => st.value)
      : []
    const topicsPredicate = topicsID.length
      ? proj.topicsID.some(r => topicsID.includes(r))
      : true

    const coursesID = selectedCourses?.length
      ? selectedCourses.map(st => st.value)
      : []
    const coursesPredicate =
      coursesOrAnd === "or"
        ? coursesID.length
          ? proj.recomendedCoursesID.some(r => coursesID.includes(r))
          : true
        : coursesID.length
        ? coursesID.every(cour => proj.recomendedCoursesID.includes(cour))
        : true

    const yearsKeys = selectedYears?.length
      ? selectedYears.map(sy => sy.value)
      : []
    const yearsPredicate = yearsKeys.length
      ? yearsKeys.some(yk => proj.maxStudents[yk] > 0)
      : true

    const showOnlyRequestedPredicate = isShowRequested
      ? (proj?.requestedProgrammesID || []).includes(currentProgramme)
      : true

    const showOnlyRequestedSeatsPredicate = isShowRequestedSeats
      ? (proj?.loadSeats?.length > 0 &&
          proj?.loadSeats.some(prs => prs?.programmeID === currentProgramme)) ||
        (proj?.externalSeats?.length > 0 &&
          proj?.externalSeats.some(
            prs => prs?.programmeID === currentProgramme
          ))
      : true

    const showOnlyRecomendedPredicate =
      ["academicManager", "student"].includes(userType) && isShowOnlyRecomended
        ? (proj?.recomendingProgrammesID || []).includes(currentProgramme)
        : true

    const showOnlyFreePredicate =
      userType === "student" &&
      (isShowOnlyFree || settings.nonFreePlacesStudent === "notAllowed")
        ? proj?.freePlaces > 0
        : true

    return (
      divisionPredicate &&
      participantsPredicate &&
      formatPredicate &&
      openToExternalPredicate &&
      openToOtherSchoolsPredicate &&
      openToOnlinePredicate &&
      languagePredicate &&
      topicsPredicate &&
      coursesPredicate &&
      yearsPredicate &&
      showOnlyRequestedPredicate &&
      showOnlyRequestedSeatsPredicate &&
      showOnlyRecomendedPredicate &&
      showOnlyFreePredicate
    )
  }

  const resetFilter = () => {
    setSelectedDivision(null)
    setSelectedParticipants(null)
    setSelectedFormat(null)
    setSelectedLanguage(null)
    setSelectedTopics(null)
    setSelectedCourses(null)
    setCoursesOrAnd("or")
    setSelectedYears(null)
    setIsOpenToExternal(true)
    setIsOpenToOnlineProgrammes("all")
    setIsOpenToOtherSchools("all")
  }

  const value = {
    predicate,
    resetFilter,
    selectedDivision,
    setSelectedDivision,
    selectedParticipants,
    setSelectedParticipants,
    selectedFormat,
    setSelectedFormat,
    selectedLanguage,
    setSelectedLanguage,
    selectedTopics,
    setSelectedTopics,
    selectedCourses,
    setSelectedCourses,
    coursesOrAnd,
    setCoursesOrAnd,
    selectedYears,
    setSelectedYears,
    isOpenToExternal,
    setIsOpenToExternal,
    isShowRequested,
    setIsShowRequested,
    isShowRequestedSeats,
    setIsShowRequestedSeats,
    isShowOnlyRecomended,
    setIsShowOnlyRecomended,
    isShowOnlyFree,
    setIsShowOnlyFree,
    isOpenToOtherSchools,
    setIsOpenToOtherSchools,
    isOpenToOnlineProgrammes,
    setIsOpenToOnlineProgrammes,
  }
  return (
    <ProjectsFilterContext.Provider value={value}>
      {children}
    </ProjectsFilterContext.Provider>
  )
}

export default ProjectsFilterContextProvider
