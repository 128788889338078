import { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import Select from "react-select"
import { ApplicationsStudentContext } from "../../../context/ApplicationsStudentContext"
import { CommonDataContext } from "../../../context/CommonDataContext"
import { ProjectsContext } from "../../../context/ProjectsContext"

import { yearsOptions } from "../../../utils"
import FormEntry from "../../helpers/FormEntry"
import ApplicationInfo from "./ApplicationInfo"

const ApplicationList = ({ forProject = true, showStudentInfo = true }) => {
  const { t, i18n } = useTranslation("data")
  const { applications } = useContext(ApplicationsStudentContext)
  const {
    programmes: { options: progOptions },
    students: { dict: students },
  } = useContext(CommonDataContext)
  const { projects } = useContext(ProjectsContext)

  const [applicationsWithNames, setApplicationsWithNames] = useState([])
  const [displayedApps, setDisplayedApps] = useState([])

  const [selectedProgrammes, setSelectedProgrammes] = useState(null)
  const [selectedYears, setSelectedYears] = useState(null)
  const [selectedProject, setSelectedProject] = useState(null)
  const [selectedName, setSelectedName] = useState("")
  const [showAppByStatus, setShowAppByStatus] = useState("awaiting")

  useEffect(() => {
    setApplicationsWithNames(
      Object.values(applications).map(app => {
        const student = students[app.studentID]
        return {
          ...app,
          fullName: student?.fullName + " " + student?.fullNameEn,
          yearOfStudy: student?.yearOfStudy,
          programmeID: student?.programmeID,
        }
      })
    )
  }, [applications, students])

  useEffect(() => {
    const predicate = app => {
      const namePredicate = selectedName
        ? app?.fullName.toLowerCase().includes(selectedName.toLowerCase())
        : true

      const projectPredicate = selectedProject
        ? app?.projectID === selectedProject.value
        : true

      const programmesID = selectedProgrammes?.length
        ? selectedProgrammes.map(sp => sp.value)
        : []
      const programmesPredicate = programmesID.length
        ? programmesID.includes(app.programmeID)
        : true

      const yearsID = selectedYears?.length
        ? selectedYears.map(sy => sy.value)
        : []
      const yearsPredicate = yearsID.length
        ? yearsID.includes(app.yearOfStudy)
        : true

      const statusPredicate =
        showAppByStatus === "awaiting"
          ? app.status === "awaiting"
          : showAppByStatus === "accepted"
          ? ["accepted", "acceptedByLeader"].includes(app.status)
          : true

      return (
        namePredicate &&
        programmesPredicate &&
        yearsPredicate &&
        projectPredicate &&
        statusPredicate
      )
    }

    const filtered = applicationsWithNames.filter(predicate)

    setDisplayedApps(filtered)
  }, [
    applicationsWithNames,
    selectedYears,
    selectedProgrammes,
    selectedName,
    selectedProject,
    showAppByStatus,
  ])

  const usedFilter =
    selectedProgrammes?.length ||
    selectedYears?.length ||
    selectedProject ||
    selectedName

  const resetFilter = () => {
    setSelectedName("")
    setSelectedYears(null)
    setSelectedProgrammes(null)
    setSelectedProject(null)
  }

  if (!applicationsWithNames?.length)
    return (
      <div className="box">
        <p className="has-text-grey">{t("emptyList")}</p>
      </div>
    )

  return (
    <>
      <div className="box">
        <div className="columns">
          <div className="column is-6">
            <FormEntry labelText={t("application.studentProgramme")}>
              <Select
                isMulti
                isClearable
                options={progOptions[i18n.language]}
                placeholder={t("invite.programmePlaceholder")}
                value={selectedProgrammes}
                onChange={setSelectedProgrammes}
              />
            </FormEntry>
          </div>

          <div className="column is-6">
            <FormEntry labelText={t("application.studentYear")}>
              <Select
                isMulti
                isClearable
                options={yearsOptions[i18n.language]}
                placeholder={t("project.yearsPlaceholder")}
                value={selectedYears}
                onChange={setSelectedYears}
              />
            </FormEntry>
          </div>
        </div>

        <div className="columns">
          {!forProject ? (
            <div className="column is-6">
              <FormEntry labelText={t("proposal.titlePlaceholder")}>
                <Select
                  isClearable
                  options={projects.map(pr => ({
                    value: pr.id,
                    label: pr.title,
                  }))}
                  placeholder={t("application.projectPlaceholder")}
                  value={selectedProject}
                  onChange={setSelectedProject}
                />
              </FormEntry>
            </div>
          ) : null}

          <div className={"column " + (!forProject ? "is-6" : "is-12")}>
            <FormEntry labelText={t("application.studentName")}>
              <input
                type="text"
                className="input"
                placeholder={t("application.namePlaceholder")}
                value={selectedName}
                onChange={e => setSelectedName(e.target.value)}
              />
            </FormEntry>
          </div>
        </div>

        <hr />
        <p className="subtitle is-6">
          {t("application.showFilter")}
          <span
            className={
              "pointer" + (showAppByStatus === "all" ? " has-text-link" : "")
            }
            onClick={() => setShowAppByStatus("all")}
          >
            {t("application.all")}
          </span>
          /
          <span
            className={
              "pointer" +
              (showAppByStatus === "awaiting" ? " has-text-warning" : "")
            }
            onClick={() => setShowAppByStatus("awaiting")}
          >
            {t("application.awaiting")}
          </span>
          /
          <span
            className={
              "pointer" +
              (showAppByStatus === "accepted" ? " has-text-success" : "")
            }
            onClick={() => setShowAppByStatus("accepted")}
          >
            {t("application.accepted")}
          </span>
        </p>

        {usedFilter ? (
          <>
            <hr />

            <FormEntry>
              <button
                className="button is-link is-outlined is-fullwidth"
                onClick={resetFilter}
              >
                {t("project.resetFilter")}
              </button>
            </FormEntry>
          </>
        ) : null}
      </div>

      {usedFilter ? (
        <div
          className={
            "message " + (displayedApps?.length ? "is-success" : "is-warning")
          }
        >
          <div className="message-body">
            {displayedApps?.length
              ? t("application.filteredResults", {
                  count: displayedApps?.length,
                })
              : t("application.noResults")}
          </div>
        </div>
      ) : null}

      {displayedApps.map(app => (
        <ApplicationInfo
          application={app}
          showStudentInfo={showStudentInfo}
          showProject={!forProject}
          key={app.id}
        />
      ))}
    </>
  )
}

export default ApplicationList
