import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import axios from "axios"
import fileDownload from "js-file-download"
import { toast } from "react-toastify"
import { API_URL } from "../../utils"

const StudentInfoExport = () => {
  const exportToXLSX = async () => {
    try {
      const resp = await axios.get(API_URL + "/studentInfo/export", {
        headers: {
          "Content-Type":
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        },
        responseType: "arraybuffer",
      })

      fileDownload(resp.data, "students.xlsx")
    } catch (error) {
      console.log(error)
      toast.error("Server error!")
    }
  }

  return (
    <button className="button is-link" onClick={exportToXLSX}>
      <FontAwesomeIcon icon="file-excel" size="lg" />
      &nbsp; Список студентов
    </button>
  )
}

export default StudentInfoExport
