import axios from "axios"
import { useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import Select from "react-select"
import { toast } from "react-toastify"
import { CommonDataContext } from "../../../context/CommonDataContext"
import { UserContext } from "../../../context/UserContext"
import { API_URL, messageToToasts } from "../../../utils"

const CuratedDivisions = ({ curatedDivisions = [], managerID }) => {
  const {
    divisions: { dict: divDict, options: divOptions },
  } = useContext(CommonDataContext)
  const {
    user: { userType },
  } = useContext(UserContext)
  const { t, i18n } = useTranslation("data")

  const [selectedNewDivOption, setSelectedNewDivOption] = useState({
    label: "Выберите курируемое подразделение...",
    value: "",
  })

  const [curatedDivisionsView, setCuratedDivisionsView] = useState(
    curatedDivisions.reduce(
      (obj, division) =>
        Object.assign(obj, {
          [division]: {
            id: division,
            rmMode: false,
          },
        }),
      {}
    )
  )

  const switchMode = selectedDiv =>
    userType === "admin"
      ? setCuratedDivisionsView({
          ...curatedDivisionsView,
          [selectedDiv.id]: { ...selectedDiv, rmMode: !selectedDiv.rmMode },
        })
      : null

  const addDivision = async () => {
    try {
      const requestURL = `${API_URL}/user/divisionManager/addDivision`
      const resp = await axios.post(requestURL, {
        userID: managerID,
        divisionID: selectedNewDivOption.value,
      })
      const { status, message } = resp.data

      if (status === "failed") {
        messageToToasts(message, i18n.language)
        return
      }

      window.location.reload()
    } catch (error) {
      toast.error("Server error!")
    }
  }

  const removeDivision = async selectedID => {
    try {
      const requestURL = `${API_URL}/user/divisionManager/removeDivision`
      const resp = await axios.post(requestURL, {
        userID: managerID,
        divisionID: selectedID,
      })
      const { status, message } = resp.data

      if (status === "failed") {
        messageToToasts(message, i18n.language)
        return
      }

      window.location.reload()
    } catch (error) {
      toast.error("Server error!")
    }
  }

  return (
    <div className="box">
      <p className="title is-5">{t("user.curatedDivisions")}</p>

      <div className="field is-grouped is-grouped-multiline">
        {Object.values(curatedDivisionsView).map(curDiv => (
          <p className="control is-expanded mr-2" key={curDiv.id}>
            {!curDiv.rmMode ? (
              <button
                className="button is-light is-fullwidth"
                onClick={() => switchMode(curDiv)}
              >
                {(divDict[curDiv.id]?.title || "")[i18n.language]}
              </button>
            ) : (
              <>
                <button className="button is-danger" disabled>
                  {t("user.removeFromCurated")} "
                  {(divDict[curDiv.id]?.title || "")[i18n.language]}"?
                </button>
                <button
                  className="button is-danger is-outlined mx-3"
                  onClick={() => removeDivision(curDiv.id)}
                >
                  {t("yes")}
                </button>
                <button
                  className="button is-link is-outlined"
                  onClick={() => switchMode(curDiv)}
                >
                  {t("no")}
                </button>
              </>
            )}
          </p>
        ))}
      </div>

      {userType === "admin" ? (
        <>
          <hr />

          <div className="columns">
            <div className="column is-8">
              <Select
                options={divOptions[i18n.language]}
                value={selectedNewDivOption}
                onChange={setSelectedNewDivOption}
              />
            </div>
            <div className="column is-4">
              <button
                className="button is-link is-fullwidth"
                onClick={addDivision}
              >
                {t("user.addCuratedDivision")}
              </button>
            </div>
          </div>
        </>
      ) : null}
    </div>
  )
}

export default CuratedDivisions
