import axios from "axios"
import i18next from "i18next"
import { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { API_URL, messageToToasts } from "../utils"

const useMeetings = (prgrID = "", level = "project") => {
  const [meetings, setMeetings] = useState([])
  const [loading, setLoading] = useState(true)
  const [needRefetch, setNeedRefetch] = useState(true)

  useEffect(() => {
    const fetchMeetings = async () => {
      setLoading(true)

      try {
        const resp = await axios.get(
          `${API_URL}/meetingPost/list?prgrID=${prgrID}&level=${level}`
        )
        const { status, message, meetings } = resp.data

        setLoading(false)
        if (status === "failed")
          return messageToToasts(message, i18next.language)

        setMeetings(meetings)
      } catch (error) {
        setLoading(false)
        toast.error("Server error")
      }
    }

    fetchMeetings()
    setNeedRefetch(false)
  }, [needRefetch, prgrID, level])

  const refetch = () => setNeedRefetch(true)

  return { meetings, loading, refetch }
}

export default useMeetings
