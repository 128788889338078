import { useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import { CommonDataContext } from "../../../context/CommonDataContext"
import { SettingsContext } from "../../../context/SettingsContext"
import useListData from "../../../context/useListData"
import useLoads from "../../../context/useLoads"
import LeaderLoadExport from "../../data/LeaderLoadExport"
import LeaderLoadList from "../../data/LeaderLoadList"
import LoadingSpinner from "../../helpers/LoadingSpinner"

const LoadsPageView = () => {
  const [year, setYear] = useState("applyYear")
  const { t } = useTranslation("data")
  const { loads, loading } = useLoads("admin", "", year)
  const { settings } = useContext(SettingsContext)
  const {
    projectLeaders: { loading: plLoading },
  } = useContext(CommonDataContext)
  const { restrictions } = useListData("restrictions", "/loadRestrictions", {
    year: settings[year],
  })

  const restrictionsMap = (restrictions || []).reduce((acc, el) => {
    acc[el?.leaderID] = el
    return acc
  }, {})

  const studyYear = Number(settings.studyYear)
  const applyYear = Number(settings.applyYear)

  return (
    <>
      <div className="box">
        <p className="title is-4 has-text-right">{t("loads.title")}</p>
      </div>

      <LeaderLoadExport year={year} />

      <div className="box">
        <p className="is-size-5">
          {t("loads.yearFilter")}:&nbsp;
          <span
            className={
              "pointer" + (year === "studyYear" ? " has-text-success" : "")
            }
            onClick={() => setYear("studyYear")}
          >
            {studyYear}/{studyYear - 1999}
          </span>
          &nbsp;/&nbsp;
          <span
            className={
              "pointer" + (year === "applyYear" ? " has-text-success" : "")
            }
            onClick={() => setYear("applyYear")}
          >
            {applyYear}/{applyYear - 1999}
          </span>
        </p>
      </div>

      {loading || plLoading ? (
        <div className="box">
          <LoadingSpinner />
        </div>
      ) : (
        <LeaderLoadList
          loads={loads}
          restrictionsMap={restrictionsMap}
          mode=""
          year={settings[year]}
        />
      )}
    </>
  )
}

export default LoadsPageView
