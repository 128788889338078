import axios from "axios"
import { useState } from "react"
import Select from "react-select"
import { toast } from "react-toastify"
import useProgrammes from "../../context/useProgrammes"
import { API_URL } from "../../utils"
import FormEntry from "../helpers/FormEntry"

const courseOptions = [
  { value: "Ba-1", label: "Ba-1" },
  { value: "Ba-2", label: "Ba-2" },
  { value: "Ba-3", label: "Ba-3" },
  { value: "Ba-4", label: "Ba-4" },
  { value: "Ma-1", label: "Ma-1" },
  { value: "Ma-2", label: "Ma-2" },
]

const genericOptions = [
  { value: "nevermind", label: "Неважно" },
  { value: "yes", label: "Да" },
  { value: "no", label: "Нет" },
]

const ExportEmail = () => {
  const [nr, setNR] = useState(true)
  const { programmes, loading } = useProgrammes(nr, setNR, "")

  const [emails, setEmails] = useState(
    "Настройте фильтры и нажмите Сгенерировать"
  )

  const [selectedProgrammes, setSelectedProgrammes] = useState(null)
  const [selectedCourses, setSelectedCourses] = useState(null)
  const [block, setBlock] = useState(0)
  const [selectedProject, setSelectedProject] = useState(genericOptions[0])
  const [selectedAsgn, setSelectedAsgn] = useState(genericOptions[0])
  const [selectedApproved, setSelectedApproved] = useState(genericOptions[0])

  const programmeOptions = (Object.values(programmes) || []).map(pr => ({
    value: pr?.id,
    label: pr?.title.ru,
  }))

  const exportEmail = async () => {
    setEmails("Идёт загрузка... Не закрывайте страницу!")

    const resp = await axios.post(`${API_URL}/exportEmail`, {
      programmes: (selectedProgrammes || []).map(sp => sp.value),
      courses: (selectedCourses || []).map(sc => sc.value),
      block: Number(block),
      hasProject: selectedProject?.value,
      hasAsgn: selectedAsgn?.value,
      hasApproved: selectedApproved?.value,
    })

    const { status, message, emails } = resp.data
    if (status === "failed") return toast.error(message)

    setEmails(emails)
  }

  return (
    <>
      <div className="box has-text-right title is-4">Экспорт email</div>

      <div className="box">
        <div className="columns">
          <div className="column is-half">
            <FormEntry
              labelText="Образовательная программа"
              helpText="можно выбрать несколько; если не выбрать, то для всех"
            >
              <Select
                placeholder="Выберите образовательные программы..."
                options={programmeOptions}
                value={selectedProgrammes}
                onChange={setSelectedProgrammes}
                isLoading={loading}
                isMulti
              />
            </FormEntry>
          </div>

          <div className="column is-half">
            <FormEntry
              labelText="Курс"
              helpText="можно выбрать несколько; если не выбрать, то для всех"
            >
              <Select
                placeholder="Выберите курсы..."
                options={courseOptions}
                value={selectedCourses}
                onChange={setSelectedCourses}
                isLoading={loading}
                isMulti
              />
            </FormEntry>
          </div>
        </div>

        <div className="columns">
          <div className="column is-two-fifths">
            <FormEntry
              labelText="Адресов в блоке"
              helpText="если равно 0, то все адреса в одном блоке"
            >
              <input
                className="input"
                type="number"
                value={block}
                onChange={e => setBlock(e.target.value)}
              />
            </FormEntry>
          </div>

          <div className="column is-one-fifths">
            <FormEntry labelText="Есть проект">
              <Select
                options={genericOptions}
                value={selectedProject}
                onChange={setSelectedProject}
                isLoading={loading}
              />
            </FormEntry>
          </div>

          <div className="column is-one-fifths">
            <FormEntry labelText="Предложено ТЗ">
              <Select
                options={genericOptions}
                value={selectedAsgn}
                onChange={setSelectedAsgn}
                isLoading={loading}
              />
            </FormEntry>
          </div>

          <div className="column is-one-fifths">
            <FormEntry labelText="Принято ТЗ">
              <Select
                options={genericOptions}
                value={selectedApproved}
                onChange={setSelectedApproved}
                isLoading={loading}
              />
            </FormEntry>
          </div>
        </div>

        <div className="field is-grouped is-grouped-right">
          <button className="button is-link is-outlined" onClick={exportEmail}>
            Сгенерировать
          </button>
        </div>
      </div>

      <div className="box">
        <div className="content" style={{ whiteSpace: "pre-wrap" }}>
          {emails}
        </div>
      </div>
    </>
  )
}

export default ExportEmail
