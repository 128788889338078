import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useTranslation } from "react-i18next"
import ReactTooltip from "react-tooltip"

const ProjectFreePlaces = ({ project }) => {
  const { t } = useTranslation("data")

  return (
    <>
      <ReactTooltip multiline={true} />
      <hr />

      <p>
        <strong>{t("project.freePlacesLeft")}</strong>
        <span
          className={
            "tag" + (project.freePlaces ? " is-success" : " is-warning")
          }
        >
          {project.freePlaces + t("project.outOf") + project.maxForStudent}
        </span>

        {!project.freePlaces ? (
          <span
            className="tag is-dark is-pulled-right"
            data-tip={t("project.noFreePlacesLeft")}
          >
            <FontAwesomeIcon icon="info" />
          </span>
        ) : null}
      </p>
    </>
  )
}

export default ProjectFreePlaces
