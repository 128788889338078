import axios from "axios"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import { API_URL, messageToToasts } from "../utils"

const useSessions = (forUser, userID) => {
  const { i18n } = useTranslation()
  const [loading, setLoading] = useState(true)
  const [sessions, setSessions] = useState([])

  const requestURL = `${API_URL}/user/session/${
    forUser ? "listForUser?userID=" + userID : "list"
  }`

  useEffect(
    () => {
      const fetchUsers = async () => {
        try {
          const resp = await axios.get(requestURL)
          const { status, message, sessions } = resp.data

          if (status === "failed")
            return messageToToasts(message, i18n.language)

          setSessions(sessions)
          setLoading(false)
        } catch (error) {
          toast.error(
            "Server error. Try to reload page or contact administrator"
          )
        }
      }

      setLoading(true)
      fetchUsers()
    },
    // eslint-disable-next-line
    [requestURL]
  )

  return { loading, sessions }
}

export default useSessions
