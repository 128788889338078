import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import axios from "axios"
import dayjs from "dayjs"
import i18next from "i18next"
import { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import { CommonDataContext } from "../../../context/CommonDataContext"
import useGradings from "../../../context/useGradings"
import { UserContext } from "../../../context/UserContext"
import { API_URL, messageToToasts } from "../../../utils"
import LatexMarkdownForm from "../../helpers/LatexMarkdownForm"
import LoadingSpinner from "../../helpers/LoadingSpinner"
import MathMarkdown from "../../helpers/MathMarkdown"

const GradingView = ({ projectID, projectLang }) => {
  const { t } = useTranslation("data")
  const {
    user: { userType },
  } = useContext(UserContext)
  const { allUsersDict } = useContext(CommonDataContext)
  const { gradings, loading, refetch } = useGradings(projectID)

  const [mode, setMode] = useState("view")

  const [text, setText] = useState("")
  const [textEN, setTextEN] = useState("")

  useEffect(() => {
    if (gradings.length) {
      setText(gradings[0].text)
      setTextEN(gradings[0].textEN)
    }
  }, [gradings])

  if (loading)
    return (
      <div className="box">
        <LoadingSpinner />
      </div>
    )

  const authors = { ...allUsersDict.projectLeader, ...allUsersDict.admin }

  const addGrading = async () => {
    try {
      const data = {
        projectID,
        text,
        textEN,
      }

      const resp = await axios.post(`${API_URL}/grading/add`, data)

      const { status, message } = resp.data
      if (status === "failed") return messageToToasts(message, i18next.language)

      refetch()
      setMode("view")
      toast.success(t("grading.addSuccess"))
    } catch (error) {
      toast.error("Server error!")
    }
  }

  const viewMode = lang => {
    if (gradings.length) {
      const currentGrading = gradings[0]
      const currentText =
        lang === "ru" || projectLang === "ru"
          ? currentGrading?.text
          : currentGrading?.textEN

      return (
        <div className="content">
          <MathMarkdown>{currentText}</MathMarkdown>

          <p className="has-text-right has-text-grey is-size-7 has-text-weight-bold">
            {t("lastUpdated")}:&nbsp;
            {dayjs(currentGrading?.creationDate).format("DD/MM/YYYY HH:mm")}
          </p>

          {userType !== "admin" || (
            <p className="has-text-right has-text-grey is-size-7 has-text-weight-bold">
              {t("grading.author")}:&nbsp;
              {authors[currentGrading.authorID]?.fullName} (
              {authors[currentGrading.authorID]?.email})
            </p>
          )}
        </div>
      )
    } else {
      return (
        <span
          className={
            userType === "projectLeader" ? "has-text-danger" : "has-text-grey"
          }
        >
          {t("grading.empty")}
        </span>
      )
    }
  }

  const listMode = (
    <>
      <button
        className="button is-link is-outlined is-fullwidth is-small mb-4"
        onClick={() => setMode("view")}
      >
        {t("grading.toCurrent")}
      </button>

      {gradings.map(grading => {
        const author = authors[grading?.authorID]

        return (
          <div className="message is-small" key={grading?.id}>
            <div className="message-header">
              {author?.fullName} ({author?.email}) &nbsp;
              {dayjs(grading?.creationDate).format("DD/MM/YYYY HH:mm")}
            </div>

            <div className="message-body">
              <div className="content has-background-white p-2">
                <MathMarkdown>{grading?.text}</MathMarkdown>
              </div>

              {!grading.textEN.length || (
                <div className="content has-background-white p-2">
                  <MathMarkdown>{grading?.textEN}</MathMarkdown>
                </div>
              )}
            </div>
          </div>
        )
      })}

      <button
        className="button is-link is-outlined is-fullwidth is-small"
        onClick={() => setMode("view")}
      >
        {t("grading.toCurrent")}
      </button>
    </>
  )

  const editMode = (
    <>
      <LatexMarkdownForm
        labelText={t("grading.ru")}
        value={text}
        setValue={setText}
      />

      {projectLang === "ru" || (
        <LatexMarkdownForm
          labelText={t("grading.en")}
          value={textEN}
          setValue={setTextEN}
        />
      )}

      <div className="field is-grouped is-grouped-right">
        <p className="control">
          <button className="button is-light" onClick={() => setMode("view")}>
            {t("cancel")}
          </button>
          &nbsp;
          <button className="button is-link is-outlined" onClick={addGrading}>
            {t("update")}
          </button>
        </p>
      </div>
    </>
  )

  return (
    <div className="box">
      <div className="columns mb-0">
        <div className="column is-8 pb-0">
          <p className="title is-5">{t("grading.title")}</p>
        </div>

        <div className="column is-4 has-text-right pb-0">
          {!["admin", "projectLeader"].includes(userType) || (
            <>
              {gradings.length <= 1 || (
                <span
                  className="has-text-grey pointer"
                  onClick={() => setMode("history")}
                >
                  <FontAwesomeIcon icon="history" title={t("history")} />
                </span>
              )}
              &nbsp;&nbsp;
              <span
                className="has-text-grey pointer"
                onClick={() => setMode("edit")}
              >
                <FontAwesomeIcon icon="pen" title={t("update")} />
              </span>
            </>
          )}
        </div>
      </div>

      <hr />

      {mode !== "view" || viewMode(i18next.language)}

      {mode !== "edit" || editMode}

      {mode !== "history" || listMode}
    </div>
  )
}

export default GradingView
