import axios from "axios"
import dayjs from "dayjs"
import i18next from "i18next"
import { useContext, useState } from "react"
import { toast } from "react-toastify"
import { UserContext } from "../../../context/UserContext"
import { API_URL, messageToToasts } from "../../../utils"

const AdminInfoView = ({ profile }) => {
  const {
    user: { userType },
  } = useContext(UserContext)

  const [newInfo, setNewInfo] = useState(profile?.adminInfo)
  const [saved, setSaved] = useState("")

  if (userType !== "admin") return null

  const updateInfo = async () => {
    try {
      const resp = await axios.post(`${API_URL}/user/student/updateAdminInfo`, {
        studentID: profile?.id,
        newInfo,
      })

      const { status, message } = resp.data

      if (status === "failed") {
        return messageToToasts(message, i18next.language)
      }

      setSaved(dayjs(new Date()).format("HH:mm:ss"))
    } catch (error) {
      toast.error("Server error. Try to reload page or contact administrator")
    }
  }

  return (
    <>
      <hr />
      <div className="field">
        <div className="control">
          <label className="label">Информация для администраторов</label>
          <textarea
            className="textarea"
            placeholder="Здесь вы можете указать любую информацию о студенте. Она видна только администраторам..."
            value={newInfo}
            onChange={e => setNewInfo(e.target.value)}
            onBlur={updateInfo}
          />
          {saved?.length === 0 || (
            <p className="help has-text-right has-text-grey has-text-weight-semibold">
              Сохранено в {saved}
            </p>
          )}
        </div>
      </div>
    </>
  )
}

export default AdminInfoView
