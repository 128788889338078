import { useContext } from "react"
import { useTranslation } from "react-i18next"
import { CommonDataContext } from "../../../context/CommonDataContext"

const MaxStudents = ({
  maxStudents,
  loadSeats = [],
  externalSeats = [],
  showFEFU = true,
}) => {
  const { t, i18n } = useTranslation("data")
  const {
    programmes: { dict: programmesDict },
  } = useContext(CommonDataContext)

  return (
    <>
      <div className="columns">
        <div className="column is-half">
          <div className="field has-addons">
            <p className="control">
              <button className="button is-static">{t("proposal.b1")}</button>
            </p>
            <p className="control is-expanded">
              <input
                className="button has-text-right is-static is-fullwidth"
                type="text"
                min={0}
                placeholder={maxStudents?.firstBachelor}
              />
            </p>
          </div>

          <div className="field has-addons">
            <p className="control">
              <button className="button is-static">{t("proposal.b2")}</button>
            </p>
            <p className="control is-expanded">
              <input
                className="button is-static is-fullwidth has-text-right"
                type="text"
                min={0}
                placeholder={maxStudents?.secondBachelor}
              />
            </p>
          </div>

          <div className="field has-addons">
            <p className="control">
              <button className="button is-static">{t("proposal.b3")}</button>
            </p>
            <p className="control is-expanded">
              <input
                className="button is-static is-fullwidth has-text-right"
                type="text"
                min={0}
                placeholder={maxStudents?.thirdBachelor}
              />
            </p>
          </div>
        </div>
        <div className="column is-half">
          <div className="field has-addons">
            <p className="control">
              <button className="button is-static">{t("proposal.b4")}</button>
            </p>
            <p className="control is-expanded">
              <input
                className="button is-static is-fullwidth has-text-right"
                type="text"
                min={0}
                placeholder={maxStudents?.fourthBachelor}
              />
            </p>
          </div>

          <div className="field has-addons">
            <p className="control">
              <button className="button is-static">{t("proposal.m1")}</button>
            </p>
            <p className="control is-expanded">
              <input
                className="button is-static is-fullwidth has-text-right"
                type="text"
                min={0}
                placeholder={maxStudents?.firstMaster}
              />
            </p>
          </div>

          <div className="field has-addons">
            <p className="control">
              <button className="button is-static">{t("proposal.m2")}</button>
            </p>
            <p className="control is-expanded">
              <input
                className="button is-static is-fullwidth has-text-right"
                type="text"
                min={0}
                placeholder={maxStudents?.secondMaster}
              />
            </p>
          </div>
        </div>
      </div>

      {/* {!showFEFU || (
        <div className="field has-addons">
          <p className="control">
            <button className="button is-static">
              {t("proposal.studentsFEFU")}
            </button>
          </p>
          <p className="control is-expanded">
            <input
              className="button is-static is-fullwidth has-text-right"
              type="text"
              min={0}
              placeholder={maxStudents?.studentsFEFU}
            />
          </p>
        </div>
      )} */}

      {/* LOAD SEATS */}
      <hr />

      <div>
        <p className="title is-6">{t(`proposal.seatsTitleMain`)}</p>

        {(loadSeats?.length || 0) < 1 ? (
          <p className="has-text-grey">Список пуст</p>
        ) : (
          (loadSeats || []).map(seat => {
            const bachelorMode =
              programmesDict[seat?.programmeID]?.mode === "bachelor"

            return (
              <div
                key={seat?.programmeID}
                className="mb-5 has-background-info-light py-2"
              >
                <div className="field has-addons">
                  <p className="control is-expanded">
                    <button className="button is-fullwidth is-multiline is-static">
                      {programmesDict[seat?.programmeID]?.title[i18n.language]}
                    </button>
                  </p>
                </div>

                <div className="columns">
                  <div className="column is-half">
                    {!bachelorMode || (
                      <div className="field has-addons">
                        <p className="control">
                          <button className="button is-static">
                            {t("proposal.b1")}
                          </button>
                        </p>
                        <p className="control is-expanded">
                          <input
                            className="button is-static is-fullwidth has-text-right"
                            type="number"
                            min={0}
                            value={seat?.students?.firstBachelor}
                          />
                        </p>
                      </div>
                    )}

                    {!bachelorMode || (
                      <div className="field has-addons">
                        <p className="control">
                          <button className="button is-static">
                            {t("proposal.b2")}
                          </button>
                        </p>
                        <p className="control is-expanded">
                          <input
                            className="button is-static is-fullwidth has-text-right"
                            type="number"
                            min={0}
                            value={seat?.students?.secondBachelor}
                          />
                        </p>
                      </div>
                    )}

                    {bachelorMode || (
                      <div className="field has-addons">
                        <p className="control">
                          <button className="button is-static">
                            {t("proposal.m1")}
                          </button>
                        </p>
                        <p className="control is-expanded">
                          <input
                            className="button is-static is-fullwidth has-text-right"
                            type="number"
                            min={0}
                            value={seat?.students?.firstMaster}
                          />
                        </p>
                      </div>
                    )}
                  </div>

                  <div className="column is-half">
                    {!bachelorMode || (
                      <div className="field has-addons">
                        <p className="control">
                          <button className="button is-static">
                            {t("proposal.b3")}
                          </button>
                        </p>
                        <p className="control is-expanded">
                          <input
                            className="button is-static is-fullwidth has-text-right"
                            type="number"
                            min={0}
                            value={seat?.students?.thirdBachelor}
                          />
                        </p>
                      </div>
                    )}

                    {!bachelorMode || (
                      <div className="field has-addons">
                        <p className="control">
                          <button className="button is-static">
                            {t("proposal.b4")}
                          </button>
                        </p>
                        <p className="control is-expanded">
                          <input
                            className="button is-static is-fullwidth has-text-right"
                            type="number"
                            min={0}
                            value={seat?.students?.fourthBachelor}
                          />
                        </p>
                      </div>
                    )}

                    {bachelorMode || (
                      <div className="field has-addons">
                        <p className="control">
                          <button className="button is-static">
                            {t("proposal.m2")}
                          </button>
                        </p>
                        <p className="control is-expanded">
                          <input
                            className="button is-static is-fullwidth has-text-right"
                            type="number"
                            min={0}
                            value={seat?.students?.secondMaster}
                          />
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )
          })
        )}
      </div>

      {/* EXTERNAL SEATS */}
      <hr />

      <div>
        <p className="title is-6">{t(`proposal.seatsTitleExternal`)}</p>

        {(externalSeats?.length || 0) < 1 ? (
          <p className="has-text-grey">Список пуст</p>
        ) : (
          (externalSeats || []).map(seat => {
            const bachelorMode =
              programmesDict[seat?.programmeID]?.mode === "bachelor"

            return (
              <div
                key={seat?.programmeID}
                className="mb-5 has-background-info-light py-2"
              >
                <div className="field has-addons">
                  <p className="control is-expanded">
                    <button className="button is-fullwidth is-multiline is-static">
                      {programmesDict[seat?.programmeID]?.title[i18n.language]}
                    </button>
                  </p>
                </div>

                <div className="columns">
                  <div className="column is-half">
                    {!bachelorMode || (
                      <div className="field has-addons">
                        <p className="control">
                          <button className="button is-static">
                            {t("proposal.b1")}
                          </button>
                        </p>
                        <p className="control is-expanded">
                          <input
                            className="button is-static is-fullwidth has-text-right"
                            type="number"
                            min={0}
                            value={seat?.students?.firstBachelor}
                          />
                        </p>
                      </div>
                    )}

                    {!bachelorMode || (
                      <div className="field has-addons">
                        <p className="control">
                          <button className="button is-static">
                            {t("proposal.b2")}
                          </button>
                        </p>
                        <p className="control is-expanded">
                          <input
                            className="button is-static is-fullwidth has-text-right"
                            type="number"
                            min={0}
                            value={seat?.students?.secondBachelor}
                          />
                        </p>
                      </div>
                    )}

                    {bachelorMode || (
                      <div className="field has-addons">
                        <p className="control">
                          <button className="button is-static">
                            {t("proposal.m1")}
                          </button>
                        </p>
                        <p className="control is-expanded">
                          <input
                            className="button is-static is-fullwidth has-text-right"
                            type="number"
                            min={0}
                            value={seat?.students?.firstMaster}
                          />
                        </p>
                      </div>
                    )}
                  </div>

                  <div className="column is-half">
                    {!bachelorMode || (
                      <div className="field has-addons">
                        <p className="control">
                          <button className="button is-static">
                            {t("proposal.b3")}
                          </button>
                        </p>
                        <p className="control is-expanded">
                          <input
                            className="button is-static is-fullwidth has-text-right"
                            type="number"
                            min={0}
                            value={seat?.students?.thirdBachelor}
                          />
                        </p>
                      </div>
                    )}

                    {!bachelorMode || (
                      <div className="field has-addons">
                        <p className="control">
                          <button className="button is-static">
                            {t("proposal.b4")}
                          </button>
                        </p>
                        <p className="control is-expanded">
                          <input
                            className="button is-static is-fullwidth has-text-right"
                            type="number"
                            min={0}
                            value={seat?.students?.fourthBachelor}
                          />
                        </p>
                      </div>
                    )}

                    {bachelorMode || (
                      <div className="field has-addons">
                        <p className="control">
                          <button className="button is-static">
                            {t("proposal.m2")}
                          </button>
                        </p>
                        <p className="control is-expanded">
                          <input
                            className="button is-static is-fullwidth has-text-right"
                            type="number"
                            min={0}
                            value={seat?.students?.secondMaster}
                          />
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )
          })
        )}
      </div>
    </>
  )
}

export default MaxStudents
