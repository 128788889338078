import { useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import { UserContext } from "../../../context/UserContext"
import DivisionList from "../../data/DivisionList"
import DivisionNew from "../../data/DivisionNew"
import FromNewCSV from "../../data/FromNewCSV"

const DivisionView = () => {
  const { t } = useTranslation("ws")
  const [needRefetch, setNeedRefetch] = useState(true)
  const {
    user: { role },
  } = useContext(UserContext)

  const isModerator = role === "moderator"

  return (
    <>
      <div className="box has-text-right title is-4">
        {t("usersPage.division.title")}
      </div>

      {!isModerator ? <DivisionNew setNeedRefetch={setNeedRefetch} /> : null}

      {!isModerator ? (
        <FromNewCSV dataModel="division" setNeedRefetch={setNeedRefetch} />
      ) : null}

      <DivisionList needRefetch={needRefetch} setNeedRefetch={setNeedRefetch} />
    </>
  )
}

export default DivisionView
