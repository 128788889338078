import { useContext, useEffect, useState } from "react"
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom"
import AcademicProgrammeContextProvider from "../context/AcademicProgrammeContext"
import ApplicationsStudentContextProvider from "../context/ApplicationsStudentContext"
import CommonDataContextProvider from "../context/CommonDataContext"
import ProjectsContextProvider from "../context/ProjectsContext"
import TransferContextProvider from "../context/TransferContext"
import { UserContext } from "../context/UserContext"
import AuthLayout from "./helpers/AuthLayout"
import LoadingSpinner from "./helpers/LoadingSpinner"
import AcademicManagerWorkspace from "./workspaces/AcademicManagerWorkspace"
import AdminWorkspace from "./workspaces/AdminWorkspace"
import CommonWorkspace from "./workspaces/CommonWorkspace"
import DivisionManagerWorkspace from "./workspaces/DivisionManagerWorkspace"
import ExternalClientWorkspace from "./workspaces/ExternalClientWorkspace"
import ProjectLeaderWorkspace from "./workspaces/ProjectLeaderWorkspace"
import StudentWorkspace from "./workspaces/StudentWorkspace"

const RestrictedArea = () => {
  const [loading, setLoading] = useState(true)
  const {
    user: { userType },
  } = useContext(UserContext)

  const { path, url } = useRouteMatch()

  useEffect(() => {
    if (userType) setLoading(false)
  }, [userType])

  if (loading)
    return (
      <AuthLayout>
        <LoadingSpinner text="Идёт авторизация" />
      </AuthLayout>
    )
  else
    return (
      <CommonDataContextProvider>
        <AcademicProgrammeContextProvider>
          <Switch>
            <Route exact path={path}>
              <Redirect to={`${url}/${userType}`} />
            </Route>

            <Route path={path + "/admin"}>
              {userType === "admin" ? (
                <AdminWorkspace />
              ) : (
                <Redirect to={path} />
              )}
            </Route>

            <Route path={path + "/projectLeader"}>
              {userType === "projectLeader" ? (
                <ProjectsContextProvider userType="projectLeader">
                  <ApplicationsStudentContextProvider fetchMode="leader">
                    <TransferContextProvider>
                      <ProjectLeaderWorkspace />
                    </TransferContextProvider>
                  </ApplicationsStudentContextProvider>
                </ProjectsContextProvider>
              ) : (
                <Redirect to={path} />
              )}
            </Route>

            <Route path={path + "/externalClient"}>
              {userType === "externalClient" ? (
                <ExternalClientWorkspace />
              ) : (
                <Redirect to={path} />
              )}
            </Route>

            <Route path={path + "/divisionManager"}>
              {userType === "divisionManager" ? (
                <ProjectsContextProvider userType="divisionManager">
                  <DivisionManagerWorkspace />
                </ProjectsContextProvider>
              ) : (
                <Redirect to={path} />
              )}
            </Route>

            <Route path={path + "/academicManager"}>
              {userType === "academicManager" ? (
                <ProjectsContextProvider userType="academicManager">
                  <AcademicManagerWorkspace />
                </ProjectsContextProvider>
              ) : (
                <Redirect to={path} />
              )}
            </Route>

            <Route path={path + "/student"}>
              {userType === "student" ? (
                <ApplicationsStudentContextProvider fetchMode="student">
                  <StudentWorkspace />
                </ApplicationsStudentContextProvider>
              ) : (
                <Redirect to={path} />
              )}
            </Route>

            <Route path={path + "/common"}>
              <CommonWorkspace />
            </Route>

            <Route path="*">
              <Redirect to={path} />
            </Route>
          </Switch>
        </AcademicProgrammeContextProvider>
      </CommonDataContextProvider>
    )
}

export default RestrictedArea
