import axios from "axios"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import { API_URL, messageToToasts } from "../../../utils"

const ChangePasswordView = ({ userID, userType }) => {
  const { t, i18n } = useTranslation("ws")
  const [oldPassword, setOldPassword] = useState("")
  const [newPassword, setNewPassword] = useState("")
  const [newPasswordRep, setNewPasswordRep] = useState("")

  const changePwd = async event => {
    event.preventDefault()

    try {
      const data = {
        userID,
        userType,
        oldPassword,
        newPassword,
        newPasswordRep,
      }

      const resp = await axios.post(`${API_URL}/user/resetPassword`, data)

      const { status, message } = resp.data
      if (status === "failed") return messageToToasts(message, i18n.language)

      toast.success(t("userProfilePage.passwordChangeSuccessMessage"))
      setOldPassword("")
      setNewPassword("")
      setNewPasswordRep("")
    } catch (err) {
      toast.error("Server error. Try to reload page or contact administrator")
    }
  }

  return (
    <div className="box">
      <p className="title is-5">{t("userProfilePage.changePwd")}</p>
      <form>
        <div className="field">
          <label className="label">{t("userProfilePage.oldPwd")}</label>
          <div className="control">
            <input
              className="input"
              type="password"
              placeholder="********"
              value={oldPassword}
              onChange={e => setOldPassword(e.target.value)}
            />
          </div>
        </div>

        <div className="field">
          <label className="label">{t("userProfilePage.newPwd")}</label>
          <div className="control">
            <input
              className="input"
              type="password"
              placeholder="********"
              value={newPassword}
              onChange={e => setNewPassword(e.target.value)}
            />
          </div>
        </div>
        <div className="field">
          <label className="label">{t("userProfilePage.newPwdRep")}</label>
          <div className="control">
            <input
              className="input"
              type="password"
              placeholder="********"
              value={newPasswordRep}
              onChange={e => setNewPasswordRep(e.target.value)}
            />
          </div>
        </div>

        <div className="field has-text-centered mt-4">
          <p className="control">
            <button
              className="button is-link is-outlined is-fullwidth"
              type="submit"
              onClick={e => changePwd(e)}
            >
              {t("userProfilePage.changePwd")}
            </button>
          </p>
        </div>
      </form>
    </div>
  )
}

export default ChangePasswordView
