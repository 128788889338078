import { useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import { ProjectsContext } from "../../../context/ProjectsContext"
import ProjectFilter from "../../data/project/ProjectFilter"
import ProjectList from "../../data/project/ProjectList"
import LoadingSpinner from "../../helpers/LoadingSpinner"

const ProjectListView = () => {
  const { t } = useTranslation("data")
  const { projects, projectsLoading, refetchProjectsMode } =
    useContext(ProjectsContext)
  const [viewedProjects, setViewedProjects] = useState([])
  const [mode, setMode] = useState("manager")

  if (projectsLoading)
    return (
      <div className="box">
        <LoadingSpinner />
      </div>
    )

  return (
    <>
      <div className="box">
        <p className="is-size-5">
          {t("project.dmFilter")}:{" "}
          <span
            className={
              "pointer" + (mode === "manager" ? " has-text-success" : "")
            }
            onClick={() => {
              refetchProjectsMode("manager")
              setMode("manager")
            }}
          >
            {t("loads.my")}
          </span>
          /
          <span
            className={"pointer" + (mode === "all" ? " has-text-success" : "")}
            onClick={() => {
              refetchProjectsMode("all")
              setMode("all")
            }}
          >
            {t("loads.all")}
          </span>
        </p>
      </div>

      <ProjectFilter
        allProjects={projects}
        setViewedProjects={setViewedProjects}
      />

      <ProjectList projects={viewedProjects} />
    </>
  )
}

export default ProjectListView
