import { useTranslation } from "react-i18next"

const PracticePersonDataForm = ({ personType, form, setForm }) => {
  const { t } = useTranslation("data")

  return (
    <div className="message is-link">
      <div class="message-header">{t(`practice.${personType}Title`)}</div>
      <div className="message-body">
        <div className="columns">
          <div className="column">
            <div className="field">
              <div className="control">
                <label className="label">{t("practice.fullName")}</label>
                <input
                  value={form[`${personType}FullName`]}
                  onChange={e =>
                    setForm({
                      ...form,
                      [`${personType}FullName`]: e.target.value,
                    })
                  }
                  type="text"
                  className="input"
                />
                <p className="help">{t("required")}</p>
              </div>
            </div>

            <div className="field">
              <div className="control">
                <label className="label">Email</label>
                <input
                  value={form[`${personType}Email`]}
                  onChange={e =>
                    setForm({
                      ...form,
                      [`${personType}Email`]: e.target.value,
                    })
                  }
                  type="text"
                  className="input"
                />
                <p className="help">{t("required")}</p>
              </div>
            </div>
          </div>

          <div className="column">
            <div className="field">
              <div className="control">
                <label className="label">{t("practice.position")}</label>
                <input
                  value={form[`${personType}Position`]}
                  onChange={e =>
                    setForm({
                      ...form,
                      [`${personType}Position`]: e.target.value,
                    })
                  }
                  type="text"
                  className="input"
                />
                <p className="help">{t("required")}</p>
              </div>
            </div>

            <div className="field">
              <div className="control">
                <label className="label">{t("practice.phone")}</label>
                <input
                  value={form[`${personType}Phone`]}
                  onChange={e =>
                    setForm({
                      ...form,
                      [`${personType}Phone`]: e.target.value,
                    })
                  }
                  type="text"
                  className="input"
                />
                <p className="help">{t("desirable")}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PracticePersonDataForm
