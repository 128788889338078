import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import FormEntry from "../../helpers/FormEntry"
import Select from "react-select"
import useProgrammes from "../../../context/useProgrammes"

const extraFields = {
  programmesString: "",
}

const AcademicManagerSignUpForm = ({ fields, setFields, updateFields }) => {
  const { t, i18n } = useTranslation("auth")
  const [formNeedsEnrichment, setFormNeedsEnrichment] = useState(true)

  const [needRefetch, setNeedRefetch] = useState(true)
  const { programmeOptions, loading } = useProgrammes(
    needRefetch,
    setNeedRefetch
  )
  const [selectedProgrammes, setSelectedProgrammes] = useState(null)

  useEffect(() => {
    if (formNeedsEnrichment) {
      setFields({ ...fields, ...extraFields })
      setFormNeedsEnrichment(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(
    () => {
      if (fields.programmes && programmeOptions[i18n.language]?.length) {
        setSelectedProgrammes(
          fields.programmes.map(pr =>
            programmeOptions[i18n.language].find(el => el.value === pr)
          )
        )
        setFields({ ...fields, programmesString: fields.programmes })
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fields.programmes, programmeOptions, i18n.language]
  )

  return (
    <>
      <FormEntry labelText="Email" helpText={t("formLabels.req")}>
        <input
          className="input"
          name="email"
          value={fields.email}
          onChange={e => updateFields(e)}
          type="email"
          placeholder="some-mail@hse.ru"
          disabled
        />
      </FormEntry>

      <FormEntry labelText={t("formLabels.pwd")} helpText={t("formLabels.req")}>
        <input
          className="input"
          type="password"
          name="password"
          placeholder="********"
          value={fields.password}
          onChange={e => updateFields(e)}
        />
      </FormEntry>

      <FormEntry
        labelText={t("formLabels.pwdRep")}
        helpText={t("formLabels.req")}
      >
        <input
          className="input"
          type="password"
          name="passwordRep"
          placeholder="********"
          value={fields.passwordRep}
          onChange={e => updateFields(e)}
        />
      </FormEntry>

      <FormEntry
        labelText={t("formLabels.surname")}
        helpText={t("formLabels.req")}
      >
        <input
          className="input"
          name="surname"
          value={fields.surname}
          onChange={e => updateFields(e)}
          type="text"
          placeholder="Иванов"
        />
      </FormEntry>

      <FormEntry
        labelText={t("formLabels.name")}
        helpText={t("formLabels.req")}
      >
        <input
          className="input"
          name="name"
          value={fields.name}
          onChange={e => updateFields(e)}
          type="text"
          placeholder="Иван"
        />
      </FormEntry>

      <FormEntry labelText={t("formLabels.secondName")}>
        <input
          className="input"
          name="secondName"
          value={fields.secondName}
          onChange={e => updateFields(e)}
          type="text"
          placeholder="Иванович"
        />
      </FormEntry>

      <FormEntry
        labelText={t("formLabels.division")}
        helpText={t("formLabels.req")}
      >
        <Select
          options={programmeOptions[i18n.language]}
          value={selectedProgrammes}
          isLoading={loading}
          isMulti
          isDisabled
        />
      </FormEntry>
    </>
  )
}

export default AcademicManagerSignUpForm
