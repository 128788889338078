import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useContext } from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import { ProjectsContext } from "../../../context/ProjectsContext"
import useProjectStudents from "../../../context/useProjectStudents"
import { yearsToText } from "../../../utils"
import LoadingSpinner from "../../helpers/LoadingSpinner"
import ProjectStudentListExport from "./ProjectStudentListExport"

const ProjectStudentList = () => {
  const { t, i18n } = useTranslation("data")
  const { currentProject } = useContext(ProjectsContext)
  const { students, loading } = useProjectStudents(currentProject?.id)

  if (loading)
    return (
      <div className="box">
        <LoadingSpinner />
      </div>
    )

  if (students.length === 0)
    return (
      <div className="box">
        <p>{t("emptyList")}</p>
      </div>
    )

  return (
    <>
      <div className="message is-black">
        <div className="message-body">
          <div className="columns">
            <div className="column is-8">
              <p className="pt-2">
                {t("project.totalStudents")}: {students.length}
              </p>
            </div>

            <div className="column is-4 has-text-right">
              <ProjectStudentListExport projectID={currentProject?.id} />
            </div>
          </div>
        </div>
      </div>

      <div className="box">
        <p className="title is-5">Адреса для рассылки</p>
        <hr />
        {students.map(student => student?.email).join("; ")}
      </div>

      {students.map(student => (
        <div className="box" key={student.id}>
          <p className="title is-6">
            <Link
              to={`/r/common/profile?userID=${student.id}&userType=student`}
              target="_blank"
            >
              <FontAwesomeIcon icon="user" size="lg" />
            </Link>
            &nbsp;
            {i18n.language === "ru" ? student.fullName : student.fullNameEn} (
            <a href={"mailto:" + student.email}>{student.email}</a>)
            <span className="is-pulled-right has-text-grey">
              {yearsToText(student.yearOfStudy)}
            </span>
          </p>
          {student?.alternativeEmail ? (
            <p className="is-size-6">
              {t("user.altEmail")}:&nbsp;
              <a href={"mailto:" + student?.alternativeEmail}>
                {student?.alternativeEmail}
              </a>
            </p>
          ) : null}
        </div>
      ))}
    </>
  )
}

export default ProjectStudentList
