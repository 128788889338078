import dayjs from "dayjs"
import { useContext } from "react"
import { useTranslation } from "react-i18next"
import { CommonDataContext } from "../../../context/CommonDataContext"
import { UserContext } from "../../../context/UserContext"
import ProposalApproval from "./ProposalApproval"
import ProposalRespond from "./ProposalRespond"

const ProposalReview = ({ reviews, proposal }) => {
  const { t } = useTranslation("data")
  const { user } = useContext(UserContext)
  const {
    divisionManagers: { dict: dms },
    projectLeaders: { dict: pls },
  } = useContext(CommonDataContext)

  const dmAction =
    proposal?.status === "awaiting" ? (
      <ProposalApproval proposal={proposal} />
    ) : proposal?.status === "reviewed" ? (
      <div className="columns">
        <div className="column is-half"></div>
        <div className="column is-half">
          <article className="message is-small is-warning">
            <div className="message-body">{t("proposal.waitResponse")}...</div>
          </article>
        </div>
      </div>
    ) : null

  const plAction =
    proposal.status === "reviewed" ? (
      <ProposalRespond
        proposal={proposal}
        reviewID={reviews.filter(rw => rw.status === "active")[0]?.id}
        reviewsEmail={[
          ...new Set(reviews.map(rw => dms[rw.commentAuthorID]?.email)),
        ]}
      />
    ) : proposal.status === "awaiting" ? (
      <div className="columns">
        <div className="column is-half">
          <article className="message is-small is-warning">
            <div className="message-body">{t("proposal.waitDecision")}...</div>
          </article>
        </div>
        <div className="column is-half"></div>
      </div>
    ) : null

  return (
    <div className="box is-warning">
      <p className="title is-5">{t("proposal.approvalTitle")}</p>
      <hr />
      {reviews.map(review => (
        <div key={review.id}>
          <div className="columns">
            <div className="column is-half">
              <article
                className={
                  "message is-small" +
                  (review.status === "active" ? " is-warning" : " is-link")
                }
              >
                <div className="message-header">
                  <p>
                    {dms[review.commentAuthorID]?.fullName +
                      dayjs(review.commentDate).format(" (DD/MM/YYYY HH:mm) ")}
                  </p>
                </div>
                <div
                  className="message-body"
                  style={{ whiteSpace: "pre-wrap" }}
                >
                  {review.comment}
                </div>
              </article>
            </div>
            <div className="column is-half"></div>
          </div>

          {review.status === "closed" ? (
            <div className="columns">
              <div className="column is-half"></div>
              <div className="column is-half">
                <article className="message is-small is-link">
                  <div className="message-header">
                    <p>
                      {pls[review.responseAuthorID]?.fullName +
                        dayjs(review.responseDate).format(
                          " (DD/MM/YYYY HH:mm) "
                        )}
                    </p>
                  </div>
                  <div
                    className="message-body"
                    style={{ whiteSpace: "pre-wrap" }}
                  >
                    {review.response}
                  </div>
                </article>
              </div>
            </div>
          ) : null}
        </div>
      ))}

      {user.userType === "divisionManager" ? dmAction : null}

      {user.userType === "projectLeader" ? plAction : null}
    </div>
  )
}

export default ProposalReview
