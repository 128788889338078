import { createContext, useState } from "react"
import useProjects from "./useProjects"
import { nilObjectID } from "../utils"

const initialCurrentProject = {
  projectColeadersID: [],
  recomendedCoursesID: [],
  topicsID: [],
}

export const ProjectsContext = createContext()

const ProjectsContextProvider = ({
  userType,
  children,
  yearOfStudy = "none",
  programmeID = nilObjectID,
}) => {
  const {
    projects,
    updateProject,
    unrequest,
    loading: projectsLoading,
    setNeedRefetch: refetchProjects,
    refetchProjectsMode,
  } = useProjects(userType, yearOfStudy, programmeID)
  const [currentProject, setCurrentProject] = useState(initialCurrentProject)

  const refetch = what => {
    switch (what) {
      case "projects":
        refetchProjects(true)
        break
      default:
        return
    }
  }

  const switchCurrentProject = projectID =>
    setCurrentProject(projects.find(pr => pr.id === projectID))

  const value = {
    refetch,
    projects,
    projectsLoading,
    currentProject,
    switchCurrentProject,
    refetchProjectsMode,
    updateProject,
    unrequest,
  }
  return (
    <ProjectsContext.Provider value={value}>
      {children}
    </ProjectsContext.Provider>
  )
}

export default ProjectsContextProvider
