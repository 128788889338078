import { useTranslation } from "react-i18next"

const InviteNewFile = () => {
  const { t } = useTranslation("data")

  return (
    <div className="box">
      <p className="title is-5">{t("invite.newFileTitle")}</p>
      <p className="has-text-grey">UNDER DEVELOPMENT</p>
    </div>
  )
}

export default InviteNewFile
