import { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useHistory, useLocation, useParams } from "react-router"
import ApplicationsStudentContextProvider from "../../../context/ApplicationsStudentContext"
import { ProjectsContext } from "../../../context/ProjectsContext"
import { UserContext } from "../../../context/UserContext"
import ApplicationAssignStudent from "../../data/application/ApplicationAssignStudent"
import ApplicationList from "../../data/application/ApplicationList"
import GradingView from "../../data/grading/GradingView"
import ProjectInfo from "../../data/project/ProjectInfo"
import ProjectRecomendations from "../../data/project/ProjectRecomendations"
import ProjectRequest from "../../data/project/ProjectRequest"
import ProjectRequests from "../../data/project/ProjectRequests"
import ProjectStudentList from "../../data/project/ProjectStudentList"
import MaxStudents from "../../data/proposal/MaxStudents"
import LoadingSpinner from "../../helpers/LoadingSpinner"
import BlogView from "../projectLeader/BlogView"
import ProjectGroupsView from "../projectLeader/ProjectGroupsView"

const ProjectView = () => {
  const history = useHistory()
  const location = useLocation()
  const { t } = useTranslation(["ws", "data"])
  const { projectID } = useParams()
  const { projectsLoading, currentProject, switchCurrentProject } =
    useContext(ProjectsContext)
  const [pageMode, setPageMode] = useState(
    location?.hash ? location?.hash.replace("#", "") : "info"
  )
  const {
    user: { role },
  } = useContext(UserContext)

  const isModerator = role === "moderator"

  useEffect(() => {
    switchCurrentProject(projectID)
  }, [projectID, switchCurrentProject])

  if (projectsLoading || projectID !== currentProject.id)
    return (
      <div className="box">
        <LoadingSpinner />
      </div>
    )

  const infoPage = (
    <>
      <ProjectRecomendations />

      <ProjectRequests />

      {!isModerator ? <ProjectRequest /> : null}

      {!isModerator ? <ApplicationAssignStudent /> : null}

      <GradingView
        projectID={currentProject?.id}
        projectLang={currentProject?.lang}
      />

      <ProjectInfo />

      <div className="box">
        <p className="title is-5">{t("projectsPage.availableSeats")}</p>
        <hr />
        <MaxStudents
          maxStudents={currentProject.maxStudents}
          loadSeats={currentProject?.loadSeats}
          externalSeats={currentProject?.externalSeats}
        />
      </div>

      <div className="box">
        <p className="title is-5">{t("data:project.acceptedStudents")}</p>
        <hr />
        <MaxStudents maxStudents={currentProject.acceptedStudents} />
      </div>
    </>
  )

  return (
    <>
      <div className="box">
        <div className="columns">
          <div className="column is-5">
            <span
              className="has-text-link pointer"
              onClick={() => history.goBack()}
            >
              {t("proposalsPage.backToList")}
            </span>
          </div>
          <div className="column is-7 has-text-right">
            <span className="title is-4">
              {currentProject?.title}

              {currentProject.num ? (
                <span className="has-text-grey">
                  &nbsp;({currentProject?.projectYear}-{currentProject.num})
                </span>
              ) : null}
            </span>
          </div>
        </div>
      </div>

      <div className="box tabs is-centered">
        <ul>
          <li className={pageMode === "info" ? " is-active" : ""}>
            <a href="#info" onClick={() => setPageMode("info")}>
              {t("projectsPage.info")}
            </a>
          </li>

          <li className={pageMode === "blog" ? " is-active" : ""}>
            <a href="#blog" onClick={() => setPageMode("blog")}>
              {t("projectsPage.blog")}
            </a>
          </li>

          <li className={pageMode === "apps" ? " is-active" : ""}>
            <a href="#apps" onClick={() => setPageMode("apps")}>
              {t("projectsPage.apps")}
            </a>
          </li>

          <li className={pageMode === "students" ? " is-active" : ""}>
            <a href="#students" onClick={() => setPageMode("students")}>
              {t("projectsPage.students")}
            </a>
          </li>

          <li className={pageMode === "groups" ? " is-active" : ""}>
            <a href="#groups" onClick={() => setPageMode("groups")}>
              {t("projectsPage.groups")}
            </a>
          </li>
        </ul>
      </div>

      {pageMode === "info" ? infoPage : null}

      {pageMode === "apps" ? (
        <ApplicationsStudentContextProvider
          fetchMode="project"
          projectID={currentProject.id}
        >
          <ApplicationList />
        </ApplicationsStudentContextProvider>
      ) : null}

      {pageMode !== "blog" || <BlogView />}

      {pageMode !== "students" || <ProjectStudentList />}

      {pageMode !== "groups" || <ProjectGroupsView />}
    </>
  )
}

export default ProjectView
