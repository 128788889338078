import { toast } from "react-toastify"
import { API_URL, messageToToasts } from "../../../utils"
import i18next from "i18next"
import { useContext } from "react"
import { UserContext } from "../../../context/UserContext"
import axios from "axios"

const PracticeSwitchType = ({ practice, setPractice }) => {
  const {
    user: { userType, email: userEmail },
  } = useContext(UserContext)

  const update = async () => {
    try {
      const resp = await axios.post(`${API_URL}/practice/update`, {
        ...practice,
        isInternal: !practice.isInternal,
        userEmail,
        changedField: "isInternal",
      })

      const { status, message, practice: upd_practice } = resp.data
      if (status === "failed") return messageToToasts(message, i18next.language)

      setPractice(upd_practice)
    } catch (error) {
      toast.error(`System error: ${error}`)
    }
  }

  if (userType !== "admin") return null

  return (
    <div className="has-text-right has-text-link">
      <span className="pointer" onClick={update}>
        сменить организацию на {practice?.isInternal ? "внешнюю" : "ВШЭ"}
      </span>
    </div>
  )
}

export default PracticeSwitchType
