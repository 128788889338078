import axios from "axios"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import { API_URL, messageToToasts } from "../utils"

const modes = {
  bachelor: {
    ru: "бакалавриат",
    en: "bachelor",
  },
  master: {
    ru: "магистратура",
    en: "master",
  },
}

const useProgrammes = (needRefetch, setNeedRefetch, mode = "") => {
  const { i18n } = useTranslation()
  const [loading, setLoading] = useState(true)
  const [programmes, setProgrammes] = useState({})
  const [programmeOptions, setProgrammeOptions] = useState([])

  const switchEditMode = programmeID => {
    const programme = programmes[programmeID]

    setProgrammes({
      ...programmes,
      [programmeID]: { ...programme, editMode: !programme.editMode },
    })
  }

  useEffect(
    () => {
      const fetchProgrammes = async () => {
        try {
          const requestURL = `${API_URL}/programmes/list${
            mode ? "?mode=" + mode : ""
          }`
          const resp = await axios.get(requestURL)
          const { status, message, programmes } = resp.data

          if (status === "failed")
            return messageToToasts(message, i18n.language)

          const ruOptions = programmes.map(d => ({
            label: `${d.title.ru} (${modes[d?.mode]["ru"]})`,
            value: d.id,
            isVisible: d.isVisible,
            category: d?.category,
          }))
          const enOptions = programmes.map(d => ({
            label: `${d.title.en} (${modes[d?.mode]["en"]})`,
            value: d.id,
            isVisible: d.isVisible,
            category: d?.category,
          }))

          setProgrammes(
            programmes.reduce(
              (obj, course) =>
                Object.assign(obj, {
                  [course.id]: { ...course, editMode: false },
                }),
              {}
            )
          )

          setProgrammeOptions({ ru: ruOptions, en: enOptions })

          setLoading(false)
          setNeedRefetch(false)
        } catch (error) {
          toast.error(
            "Server error. Try to reload page or contact administrator"
          )
        }
      }

      if (needRefetch || mode) {
        setLoading(true)
        fetchProgrammes()
      }
    },
    // eslint-disable-next-line
    [needRefetch, mode]
  )

  return {
    loading,
    programmes,
    switchEditMode,
    programmeOptions,
  }
}

export default useProgrammes
