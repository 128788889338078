import axios from "axios"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import Select from "react-select"
import { toast } from "react-toastify"
import { API_URL, messageToToasts } from "../../utils"
import FormEntry from "../helpers/FormEntry"
import Submitting from "../helpers/Submitting"

const modeOptions = [
  { label: "Бакалавриат", value: "bachelor" },
  { label: "Магистратура", value: "master" },
]

const ProgrammeNew = ({ setNeedRefetch }) => {
  const { t, i18n } = useTranslation("data")
  const [titleRu, setTitleRu] = useState("")
  const [titleEn, setTitleEn] = useState("")
  const [selectedMode, setSelectedOption] = useState({
    label: t("programme.modePlaceholder"),
    value: "",
  })
  const [submitting, setSubmitting] = useState(false)

  const createDivision = async e => {
    e.preventDefault()
    setSubmitting(true)

    try {
      const data = {
        title: {
          ru: titleRu.trim(),
          en: titleEn.trim(),
        },
        mode: selectedMode.value,
      }

      const resp = await axios.post(`${API_URL}/programmes/add`, data)
      const { status, message } = resp.data

      if (status === "failed") {
        setSubmitting(false)
        return messageToToasts(message, i18n.language)
      }

      toast.success(t("division.createSuccessMessage"))
      setTitleRu("")
      setTitleEn("")
      setNeedRefetch(true)
      setSubmitting(false)
    } catch (err) {
      toast.error("Server error. Try to reload page or contact administrator")
    }
  }

  return (
    <div className="box">
      <p className="title is-5">{t("programme.newTitle")}</p>
      <form>
        <div className="columns">
          <div className="column is-4">
            <FormEntry labelText={t("course.titleRu")} helpText={t("req")}>
              <input
                className="input"
                type="text"
                value={titleRu}
                onChange={e => setTitleRu(e.target.value)}
                placeholder="Прикладная экономика"
              />
            </FormEntry>
          </div>

          <div className="column is-4">
            <FormEntry labelText={t("course.titleEn")} helpText={t("req")}>
              <input
                className="input"
                type="text"
                value={titleEn}
                onChange={e => setTitleEn(e.target.value)}
                placeholder="Applied economics"
              />
            </FormEntry>
          </div>

          <div className="column is-4">
            <FormEntry labelText={t("programme.mode")} helpText={t("req")}>
              <Select
                options={modeOptions}
                value={selectedMode}
                onChange={setSelectedOption}
              />
            </FormEntry>
          </div>
        </div>

        <Submitting submitting={submitting}>
          <button
            className="button is-link is-outlined is-fullwidth"
            onClick={createDivision}
          >
            {t("programme.newTitle")}
          </button>
        </Submitting>
      </form>
    </div>
  )
}

export default ProgrammeNew
