import axios from "axios"
import { toast } from "react-toastify"
import { useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import { AcademicProgrammeContext } from "../../../context/AcademicProgrammeContext"
import { API_URL, messageToToasts } from "../../../utils"
import { ProjectsContext } from "../../../context/ProjectsContext"

const ProjectRecomendList = ({ projects }) => {
  const [submitting, setSubmitting] = useState(false)
  const { t, i18n } = useTranslation("data")
  const { currentProgramme: programmeID } = useContext(AcademicProgrammeContext)
  const { refetch } = useContext(ProjectsContext)

  const updateList = async (mode = "add") => {
    setSubmitting(true)

    try {
      const data = {
        projectsID: projects.map(pr => pr.id),
        programmeID,
      }

      const resp = await axios.post(
        `${API_URL}/project/${mode === "pull" ? "un" : ""}recomendList`,
        data
      )
      setSubmitting(false)

      const { status, message, updRes } = resp.data
      if (status === "failed") return messageToToasts(message, i18n.language)

      if (mode === "pull")
        toast.success(t("project.unrecomendListSuccess", { updRes }))
      else toast.success(t("project.recomendListSuccess", { updRes }))

      refetch("projects")
    } catch (error) {
      setSubmitting(false)
      toast.error("Server error!")
    }
  }

  return (
    <>
      <div className="message">
        <div className="message-body">
          <div className="columns">
            <div className="column is-9">{t("project.recomendListText")}</div>

            <div className="column is-3 has-text-right">
              <button
                className="button is-success"
                onClick={updateList}
                disabled={submitting}
              >
                {t("project.recomendList")}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="message">
        <div className="message-body">
          <div className="columns">
            <div className="column is-9">{t("project.unrecomendListText")}</div>

            <div className="column is-3 has-text-right">
              <button
                className="button is-danger"
                onClick={() => updateList("pull")}
                disabled={submitting}
              >
                {t("project.unrecomendList")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ProjectRecomendList
