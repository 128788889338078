import axios from "axios"
import { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useHistory, useParams } from "react-router"
import { toast } from "react-toastify"
import { UserContext } from "../../../context/UserContext"
import useReviews from "../../../context/useReviews"
import { API_URL, messageToToasts } from "../../../utils"
import ProposalLog from "../../data/proposal/ProposalLog"
import ProposalReview from "../../data/proposal/ProposalReview"
import ProposalStatus from "../../data/proposal/ProposalStatus"
import ProposalCancel from "../../data/ProposalCancel"
import ProposalInfo from "../../data/ProposalInfo"
import ProposalNew from "../../data/ProposalNew"
import LoadingSpinner from "../../helpers/LoadingSpinner"
import Page404 from "../../helpers/Page404"

const ProposalView = () => {
  const history = useHistory()
  const { t, i18n } = useTranslation("ws")
  const { proposalID } = useParams()
  const { user } = useContext(UserContext)
  const { reviews } = useReviews(proposalID)

  const [needRefetch, setNeedRefetch] = useState(true)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [pageMode, setPageMode] = useState("view")

  const [proposal, setProposal] = useState({})
  const [ownProposal, setOwnProposal] = useState(false)

  useEffect(
    () => {
      const fetchProposal = async () => {
        try {
          const requestURL = `${API_URL}/project/proposal/info?proposalID=${proposalID}`
          const resp = await axios.get(requestURL)
          const { status, message, proposal } = resp.data

          if (status === "failed") {
            setError(true)
            return messageToToasts(message, i18n.language)
          }

          setProposal(proposal)
          setOwnProposal(
            proposal.projectLeaderID === user.userID ||
              proposal.projectColeaders.includes(user.userID)
          )
          setLoading(false)
          setNeedRefetch(false)
        } catch (err) {
          toast.error(
            "Server error. Try to reload page or contact administrator"
          )
        }
      }

      if (proposalID !== "" && needRefetch) {
        fetchProposal()
      }
    },
    // eslint-disable-next-line
    [proposalID, user.userID, needRefetch]
  )

  const publishDraft = async () => {
    try {
      const data = { proposalID }
      const resp = await axios.post(`${API_URL}/project/proposal/publish`, data)
      const { status, message } = resp.data

      if (status === "failed") return messageToToasts(message, i18n.language)

      toast.success(t("proposalsPage.successPublishMessage"))
      setNeedRefetch(true)
    } catch (err) {
      toast.error("Server error. Try to reload page or contact administrator")
    }
  }

  const isAdminOrOwnerView =
    pageMode === "view" &&
    ((user.userType === "admin" && user.role !== "moderator") ||
      (user.userType === "projectLeader" && ownProposal))

  if (error) return <Page404 />

  if (loading) {
    return (
      <div className="box">
        <LoadingSpinner text={t("proposalsPage.proposalLoading")} />
      </div>
    )
  }

  return (
    <>
      <div className="box">
        <div className="columns">
          <div className="column is-5">
            <span
              className="has-text-link pointer"
              onClick={() => history.goBack()}
            >
              {t("proposalsPage.backToList")}
            </span>
          </div>
          <div className="column is-7 has-text-right">
            <span className="title is-4">{proposal.title}</span>
          </div>
        </div>
      </div>

      {pageMode === "view" &&
      user.userType === "projectLeader" &&
      ownProposal &&
      proposal.status === "reviewed" ? (
        <div className="message is-warning">
          <div className="message-body">
            <span className="is-size-5">
              {t("proposalsPage.needImprovement")}!
            </span>
          </div>
        </div>
      ) : null}

      {reviews.length ? (
        <ProposalReview reviews={reviews} proposal={proposal} />
      ) : null}

      {isAdminOrOwnerView ? (
        <div className="box">
          <div className="columns">
            <div className="column is-half">
              {t("proposalsPage.status")}:{" "}
              <ProposalStatus status={proposal.status} />
            </div>
            {!["declined", "accepted"].includes(proposal.status) ? (
              <div className="column is-half has-text-right">
                {t("proposalsPage.allowedActions")}:{" "}
                <span
                  className="has-text-grey pointer"
                  onClick={() => setPageMode("edit")}
                >
                  {t("proposalsPage.edit")}
                </span>
                <span
                  className="has-text-grey pointer"
                  onClick={() => setPageMode("cancel")}
                >
                  {", " + t("proposalsPage.cancel")}
                </span>
              </div>
            ) : null}
          </div>
        </div>
      ) : null}

      {pageMode === "view" &&
      user.userType === "projectLeader" &&
      ownProposal &&
      proposal.status === "draft" ? (
        <div className="message is-warning">
          <div className="message-body">
            <div className="columns">
              <div className="column is-9">
                <span className="is-size-5">
                  {t("proposalsPage.notPublished")}!
                </span>
              </div>
              <div className="column is-3 has-text-right">
                <button className="button is-warning" onClick={publishDraft}>
                  {t("proposalsPage.publish")}
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : null}

      {pageMode === "view" ? <ProposalInfo proposal={proposal} /> : null}

      {isAdminOrOwnerView ? <ProposalLog proposalID={proposal.id} /> : null}

      {pageMode === "cancel" ? (
        <ProposalCancel proposal={proposal} setPageMode={setPageMode} />
      ) : null}

      {pageMode === "edit" ? (
        <ProposalNew
          edit={true}
          editProposal={proposal}
          setPageMode={setPageMode}
          setNeedRefetch={setNeedRefetch}
        />
      ) : null}
    </>
  )
}

export default ProposalView
