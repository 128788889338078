import { useContext } from "react"
import { useTranslation } from "react-i18next"
import { ProjectsContext } from "../../../context/ProjectsContext"
import MaxStudents from "../proposal/MaxStudents"

const computeProgrammeSeats = (projectsForProgramme, seatType, programmeID) => {
  var loadSeatsForProgramme = {}

  for (const proj of projectsForProgramme) {
    if (proj?.[seatType]?.length > 0) {
      for (const seat of proj?.[seatType]) {
        if (seat?.programmeID !== programmeID) continue

        loadSeatsForProgramme[seat?.programmeID] = {
          firstBachelor:
            Number(
              loadSeatsForProgramme[seat?.programmeID]?.firstBachelor || 0
            ) + Number(seat?.students?.firstBachelor || 0),
          secondBachelor:
            Number(
              loadSeatsForProgramme[seat?.programmeID]?.secondBachelor || 0
            ) + Number(seat?.students?.secondBachelor || 0),
          thirdBachelor:
            Number(
              loadSeatsForProgramme[seat?.programmeID]?.thirdBachelor || 0
            ) + Number(seat?.students?.thirdBachelor || 0),
          fourthBachelor:
            Number(
              loadSeatsForProgramme[seat?.programmeID]?.fourthBachelor || 0
            ) + Number(seat?.students?.fourthBachelor || 0),
          firstMaster:
            Number(loadSeatsForProgramme[seat?.programmeID]?.firstMaster || 0) +
            Number(seat?.students?.firstMaster || 0),
          secondMaster:
            Number(
              loadSeatsForProgramme[seat?.programmeID]?.secondMaster || 0
            ) + Number(seat?.students?.secondMaster || 0),
        }
      }
    }
  }

  loadSeatsForProgramme = Object.entries(loadSeatsForProgramme).map(
    ([k, v]) => ({
      programmeID: k,
      students: v,
    })
  )

  return loadSeatsForProgramme
}

const ProjectStatsForProgramme = ({ programmeID }) => {
  const { t } = useTranslation("data")
  const { projects } = useContext(ProjectsContext)

  const projectsForProgramme = projects.filter(pr =>
    pr.recomendingProgrammesID.includes(programmeID)
  )

  const seatsForProgramme = projectsForProgramme.reduce(
    (acc, el) => {
      return {
        firstBachelor: acc.firstBachelor + el.maxStudents.firstBachelor,
        secondBachelor: acc.secondBachelor + el.maxStudents.secondBachelor,
        thirdBachelor: acc.thirdBachelor + el.maxStudents.thirdBachelor,
        fourthBachelor: acc.fourthBachelor + el.maxStudents.fourthBachelor,
        firstMaster: acc.firstMaster + el.maxStudents.firstMaster,
        secondMaster: acc.secondMaster + el.maxStudents.secondMaster,
        studentsFEFU: acc.studentsFEFU + el.maxStudents.studentsFEFU,
      }
    },
    {
      firstBachelor: 0,
      secondBachelor: 0,
      thirdBachelor: 0,
      fourthBachelor: 0,
      firstMaster: 0,
      secondMaster: 0,
      studentsFEFU: 0,
    }
  )

  var loadSeatsForProgramme = computeProgrammeSeats(
    projectsForProgramme,
    "loadSeats",
    programmeID
  )
  var externalSeatsForProgramme = computeProgrammeSeats(
    projectsForProgramme,
    "externalSeats",
    programmeID
  )

  return (
    <div className="message">
      <div className="message-header">{t("project.seatsForProgramme")}</div>
      <div className="message-body">
        <MaxStudents
          maxStudents={seatsForProgramme}
          loadSeats={loadSeatsForProgramme}
          externalSeats={externalSeatsForProgramme}
          showFEFU={false}
        />
      </div>
    </div>
  )
}

export default ProjectStatsForProgramme
