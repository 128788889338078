import { createContext, useEffect, useState } from "react"
import axios from "axios"
import jwt from "jsonwebtoken"
import { API_URL } from "../utils"

export const UserContext = createContext()

const userFromToken = token => {
  if (token) {
    const { userID, email, userType, role } = jwt.decode(token)
    return { userID, email, userType, role }
  } else {
    return {}
  }
}

const tokenFromStorage = localStorage.getItem("token") || ""
const userFromStorage = userFromToken(tokenFromStorage)

const UserContextProvider = ({ children }) => {
  const [token, setToken] = useState(tokenFromStorage)
  const [user, setUser] = useState(userFromStorage)

  useEffect(() => {
    const validateToken = async () => {
      axios.defaults.headers.common["Authorization"] = "Bearer " + token
      const resp = await axios.post(`${API_URL}/user/validate`)
      const { status } = resp.data

      if (status === "failed") {
        localStorage.removeItem("token")
        setToken("")
        setUser({})
        return
      }

      setUser(userFromToken(token))
    }

    if (token) validateToken()
  }, [token])

  const login = newToken => {
    localStorage.setItem("token", newToken)
    setToken(newToken)
  }

  const logout = async () => {
    await axios.post(`${API_URL}/user/logout`)

    localStorage.removeItem("token")
    axios.defaults.headers.common["Authorization"] = ""
    setToken("")
    setUser({})
  }

  const value = {
    token,
    user,
    login,
    logout,
    isSigned: token.length,
  }

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}

export default UserContextProvider
