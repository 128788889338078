import { useState } from "react"
import { useTranslation } from "react-i18next"
import { userTypesOptions } from "../../../utils"
import InviteList from "../../data/InviteList"

const InviteListView = () => {
  const { t } = useTranslation(["ws", "translation"])
  const [userType, setUserType] = useState("admin")
  const [used, setUsed] = useState(false)

  return (
    <>
      <div className="box has-text-right title is-4">
        {t("usersPage.inviteList.title")}
      </div>
      <div className="columns">
        <div className="column is-4">
          <div className="box tabs">
            <ul>
              <li className={!used ? " is-active" : ""}>
                <a href="#filter" onClick={() => setUsed(false)}>
                  {t("usersPage.inviteList.active")}
                </a>
              </li>
              <li className={used ? " is-active" : ""}>
                <a href="#filter" onClick={() => setUsed(true)}>
                  {t("usersPage.inviteList.used")}
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="column is-8">
          <div className="box tabs">
            <ul>
              {userTypesOptions.map(uto => (
                <li
                  key={uto.value}
                  className={userType === uto.value ? " is-active" : ""}
                >
                  <a href="#filter" onClick={() => setUserType(uto.value)}>
                    {t(`translation:userType.${uto.value}`)}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <InviteList userType={userType} used={used} />
    </>
  )
}

export default InviteListView
