import axios from "axios"
import { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import { CommonDataContext } from "../../../context/CommonDataContext"
import { UserContext } from "../../../context/UserContext"
import { API_URL } from "../../../utils"
import MaxStudents from "../../data/proposal/MaxStudents"
import LoadingSpinner from "../../helpers/LoadingSpinner"

const ProposalStatsView = () => {
  const { t, i18n } = useTranslation("ws")
  const {
    courses: { dict: coursesDict },
    divisions: { dict: divsDict },
  } = useContext(CommonDataContext)
  const [stats, setStats] = useState({})
  const [loading, setLoading] = useState(true)
  const [mode, setMode] = useState("bachelor")
  const [needRefetch, setNeedRefetch] = useState(true)
  const {
    user: { role },
  } = useContext(UserContext)

  const isModerator = role === "moderator"

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const resp = await axios.get(`${API_URL}/project/proposal/stats`)
        const stats = resp.data
        setStats(stats)
        setLoading(false)
        setNeedRefetch(false)
      } catch (error) {
        toast.error("Server error!")
      }
    }

    if (needRefetch) fetchStats()
  }, [needRefetch])

  const forceProjects = async () => {
    try {
      await axios.post(`${API_URL}/project/forceProjects`)

      toast.success("Задача запущена")
      setNeedRefetch(true)
    } catch (error) {
      toast.error("Server error!")
    }
  }

  if (loading)
    return (
      <div className="box">
        <LoadingSpinner />
      </div>
    )

  const courseDesiredMap = Object.entries(stats.coursesDict)
    .sort((kv1, kv2) => kv2[1] - kv1[1])
    .reduce((obj, kv) => {
      const chosenCourse = coursesDict[kv[0]]
      obj[kv[0]] = {
        id: kv[0],
        title: chosenCourse?.title || "",
        count: kv[1],
        mode: chosenCourse?.mode || "",
      }
      return obj
    }, {})

  const usedDivsMap = Object.entries(stats.divisionsDict)
    .sort((kv1, kv2) => kv2[1] - kv1[1])
    .reduce((obj, kv) => {
      const chosenDivision = divsDict[kv[0]]
      obj[kv[0]] = {
        id: kv[0],
        title: chosenDivision?.title || "",
        count: kv[1],
      }
      return obj
    }, {})

  return (
    <>
      <div className="box has-text-right title is-4">
        {t("proposalsPage.stats")}
      </div>

      <div className="box">
        <nav className="level">
          <div className="level-item has-text-centered">
            <div>
              <p className="heading">{t("proposalsPage.activeProps")}</p>
              <p className="title">{stats.mainCount}</p>
            </div>
          </div>

          <div className="level-item has-text-centered">
            <div>
              <p className="heading">{t("proposalsPage.reviewedProps")}</p>
              <p className="title">{stats.reviewedCount}</p>
            </div>
          </div>

          <div className="level-item has-text-centered">
            <div>
              <p className="heading">{t("proposalsPage.acceptedProps")}</p>
              <p className="title">{stats.acceptedCount}</p>
            </div>
          </div>

          <div className="level-item has-text-centered">
            <div>
              <p className="heading">{t("proposalsPage.declinedProps")}</p>
              <p className="title">{stats.declinedCount}</p>
            </div>
          </div>

          <div className="level-item has-text-centered">
            <div>
              <p className="heading">{t("proposalsPage.draftProps")}</p>
              <p className="title">{stats.draftCount}</p>
            </div>
          </div>
        </nav>
      </div>

      {!isModerator ? (
        <div className="box">
          <div className="columns">
            <div className="column is-half has-text-centered">
              <button className="button is-link" onClick={forceProjects}>
                Форсировать проекты
              </button>
            </div>

            <div className="column is-half"></div>
          </div>
        </div>
      ) : null}

      <div className="box">
        <p className="subtitle is-6">
          <strong>{t("proposalsPage.vacantSeats")}: </strong>
        </p>

        <MaxStudents
          maxStudents={stats.vacantSeats}
          loadSeats={stats?.loadSeats}
          externalSeats={stats?.externalSeats}
        />
      </div>

      <div className="box">
        <div className="table-container">
          <table className="table is-fullwidth">
            <thead>
              <tr>
                <th>{t("proposalsPage.divisionName")}</th>
                <th className="has-text-centered">
                  {t("proposalsPage.proposalCount")}
                </th>
              </tr>
            </thead>
            <tbody>
              {Object.values(usedDivsMap).map(dc => (
                <tr key={dc.id}>
                  <td>{dc?.title[i18n.language]}</td>
                  <td className="has-text-centered">{dc?.count}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <div className="tabs is-centered">
        <ul>
          <li className={mode === "bachelor" ? " is-active" : ""}>
            <a href="#mode" onClick={() => setMode("bachelor")}>
              {t("usersPage.course.bachelor")}
            </a>
          </li>
          <li className={mode === "master" ? " is-active" : ""}>
            <a href="#mode" onClick={() => setMode("master")}>
              {t("usersPage.course.master")}
            </a>
          </li>
        </ul>
      </div>

      <div className="box">
        <div className="table-container">
          <table className="table is-fullwidth">
            <thead>
              <tr>
                <th>{t("proposalsPage.courseName")}</th>
                <th className="has-text-centered">
                  {t("proposalsPage.proposalCount")}
                </th>
              </tr>
            </thead>
            <tbody>
              {Object.values(courseDesiredMap)
                .filter(dc => dc.mode === mode)
                .map(dc => (
                  <tr key={dc.id}>
                    <td>{dc.title[i18n.language]}</td>
                    <td className="has-text-centered">{dc.count}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  )
}

export default ProposalStatsView
