import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import Select from "react-select"
import useGreetings from "../../../context/useGreetings"
import { userTypesOptions } from "../../../utils"
import GreetingList from "../../data/greeting/GreetingList"
import GreetingNew from "../../data/greeting/GreetingNew"
import LoadingSpinner from "../../helpers/LoadingSpinner"

const GreetingsView = () => {
  const { t } = useTranslation(["ws", "data"])
  const [addMode, setAddMode] = useState(false)
  const [selectedUserType, setSelectedUserType] = useState(userTypesOptions[0])
  const { greetings, loading, refetch } = useGreetings(selectedUserType.value)

  return (
    <>
      <div className="box has-text-right">
        <p className="title is-4">{t("usersPage.greetings")}</p>
      </div>

      <div className="box">
        <div className="columns">
          <div className="column is-half has-text-left">
            <p className="pt-2">{t("usersPage.selectUT")}:</p>
          </div>

          <div className="column is-half has-text-right">
            <Select
              value={selectedUserType}
              options={userTypesOptions}
              onChange={setSelectedUserType}
            />
          </div>
        </div>
      </div>

      {addMode ? (
        <GreetingNew
          userType={selectedUserType.value}
          setAddMode={setAddMode}
          refetch={refetch}
        />
      ) : (
        <div className="box has-text-centered">
          <span
            className="is-size-5 pointer"
            onClick={() => {
              setAddMode(true)
            }}
          >
            <FontAwesomeIcon icon="plus" /> {t("data:greeting.addNew")}
          </span>
        </div>
      )}

      {loading ? (
        <div className="box">
          <LoadingSpinner />
        </div>
      ) : (
        <GreetingList greetings={greetings} forAdmin={true} refetch={refetch} />
      )}
    </>
  )
}

export default GreetingsView
