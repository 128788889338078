import { useContext } from "react"
import { useTranslation } from "react-i18next"
import Select from "react-select"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import FormEntry from "../../helpers/FormEntry"
import { toast } from "react-toastify"
import { CommonDataContext } from "../../../context/CommonDataContext"

const ProposalNewTopics = ({ topicsStrings, setTopicsStrings }) => {
  const { t, i18n } = useTranslation("data")
  const {
    topics: { loading, options: topicOptions, dict: topicsServer },
  } = useContext(CommonDataContext)
  const selectedPlaceholder = {
    label: t("proposal.selectedTopicPlaceholder"),
    value: "",
  }

  const addTopic = option => {
    if (topicsStrings.includes(option.value)) return

    if (topicsStrings.length >= 3)
      return toast.error(t("proposal.limitTopicMessage"))

    setTopicsStrings([...topicsStrings, option.value])
  }

  const removeTopic = topicID => {
    setTopicsStrings(topicsStrings.filter(el => el !== topicID))
  }

  return (
    <FormEntry labelText={t("proposal.topics")}>
      <div className="columns">
        <div className="column is-half">
          <Select
            options={topicOptions[i18n.language]}
            value={selectedPlaceholder}
            onChange={addTopic}
            isSearchable={true}
            isLoading={loading}
          />
        </div>
        <div className="column is-half">
          {!Object.values(topicsStrings).length ? (
            <p className="has-text-grey has-text-centered">
              {t("proposal.emptyTopics")}
            </p>
          ) : (
            <>
              {topicsStrings.map(rc => {
                const { title } = topicsServer[rc]
                return (
                  <div className="field has-addons" key={rc}>
                    <p className="control">
                      <button
                        className="button is-danger"
                        onClick={() => removeTopic(rc)}
                      >
                        <FontAwesomeIcon icon="trash" />
                      </button>
                    </p>
                    <p className="control is-expanded">
                      <button className="button is-fullwidth is-multiline is-static">
                        {title[i18n.language]}
                      </button>
                    </p>
                  </div>
                )
              })}
            </>
          )}
        </div>
      </div>
    </FormEntry>
  )
}

export default ProposalNewTopics
