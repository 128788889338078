import axios from "axios"
import i18next from "i18next"
import { useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import { UserContext } from "../../../context/UserContext"
import { API_URL, messageToToasts } from "../../../utils"

const AsgnChangeActions = ({ asgn, refetch }) => {
  const { t } = useTranslation("data")
  const {
    user: { userType },
  } = useContext(UserContext)

  const [submitting, setSubmitting] = useState(false)
  const [mode, setMode] = useState("view")

  const declineAsgnCh = async () => {
    setSubmitting(true)

    try {
      const resp = await axios.post(`${API_URL}/asgnChange/decline`, {
        asgnChID: asgn?.id,
      })
      const { status, message } = resp.data
      setSubmitting(false)

      if (status === "failed") return messageToToasts(message, i18next.language)

      refetch()
      setMode("view")
    } catch (err) {
      setSubmitting(false)
      toast.error("Server error!")
    }
  }

  const acceptAsgnCh = async () => {
    setSubmitting(true)

    try {
      const resp = await axios.post(`${API_URL}/asgnChange/accept`, {
        asgnChID: asgn?.id,
      })
      const { status, message } = resp.data
      setSubmitting(false)

      if (status === "failed") return messageToToasts(message, i18next.language)

      refetch()
      setMode("view")
    } catch (err) {
      setSubmitting(false)
      toast.error("Server error!")
    }
  }

  if (mode === "decline")
    return (
      <div className="columns">
        <div className="column is-half">{t("group.declineHint")}</div>

        <div className="column is-half">
          <div className="field is-grouped is-grouped-right">
            <div className="control">
              <button
                className="button is-light"
                onClick={() => setMode("view")}
                disabled={submitting}
              >
                {t("cancel")}
              </button>
            </div>

            <div className="control">
              <button
                className="button is-danger"
                onClick={declineAsgnCh}
                disabled={submitting}
              >
                {t("decline")}
              </button>
            </div>
          </div>
        </div>
      </div>
    )

  if (mode === "approve")
    return (
      <div className="columns">
        <div className="column is-half">{t("group.acceptHint")}</div>

        <div className="column is-half">
          <div className="field is-grouped is-grouped-right">
            <div className="control">
              <button
                className="button is-light"
                onClick={() => setMode("view")}
                disabled={submitting}
              >
                {t("cancel")}
              </button>
            </div>

            <div className="control">
              <button
                className="button is-success"
                onClick={acceptAsgnCh}
                disabled={submitting}
              >
                {t("approve")}
              </button>
            </div>
          </div>
        </div>
      </div>
    )

  return (
    <div className="field is-grouped is-grouped-right">
      {!(
        asgn?.status === "awaiting" &&
        ["student", "projectLeader"].includes(userType)
      ) || (
        <div className="control">
          <button
            className="button is-danger"
            onClick={() => setMode("decline")}
          >
            {t("decline")}
          </button>
        </div>
      )}

      {!(
        asgn?.status === "awaiting" &&
        ((userType === "student" && !asgn.studentApproved) ||
          (userType === "projectLeader" && !asgn.leaderApproved))
      ) || (
        <div className="control">
          <button
            className="button is-success"
            onClick={() => setMode("approve")}
          >
            {t("approve")}
          </button>
        </div>
      )}
    </div>
  )
}

export default AsgnChangeActions
