import { useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import { UserContext } from "../../../context/UserContext"
import FromNewCSV from "../../data/FromNewCSV"
import TopicList from "../../data/TopicList"
import TopicNew from "../../data/TopicNew"

const TopicView = () => {
  const { t } = useTranslation("ws")
  const [needRefetch, setNeedRefetch] = useState(true)
  const {
    user: { role },
  } = useContext(UserContext)

  const isModerator = role === "moderator"

  return (
    <>
      <div className="box has-text-right title is-4">
        {t("usersPage.topic.title")}
      </div>

      {!isModerator ? <TopicNew setNeedRefetch={setNeedRefetch} /> : null}

      {!isModerator ? (
        <FromNewCSV dataModel="topic" setNeedRefetch={setNeedRefetch} />
      ) : null}

      <TopicList needRefetch={needRefetch} setNeedRefetch={setNeedRefetch} />
    </>
  )
}

export default TopicView
