import { useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import ProgrammeList from "../../data/ProgrammeList"
import FromNewCSV from "../../data/FromNewCSV"
import ProgrammeNew from "../../data/ProgrammeNew"
import { UserContext } from "../../../context/UserContext"

const ProgrammeView = () => {
  const { t } = useTranslation("ws")
  const {
    user: { role },
  } = useContext(UserContext)
  const [needRefetch, setNeedRefetch] = useState(true)
  const [mode, setMode] = useState("bachelor")

  const isModerator = role === "moderator"

  return (
    <>
      <div className="box has-text-right title is-4">
        {t("usersPage.programme.title")}
      </div>

      {!isModerator ? <ProgrammeNew setNeedRefetch={setNeedRefetch} /> : null}

      {!isModerator ? (
        <FromNewCSV dataModel="programme" setNeedRefetch={setNeedRefetch} />
      ) : null}

      <div className="tabs is-centered">
        <ul>
          <li className={mode === "bachelor" ? " is-active" : ""}>
            <a href="#mode" onClick={() => setMode("bachelor")}>
              {t("usersPage.course.bachelor")}
            </a>
          </li>
          <li className={mode === "master" ? " is-active" : ""}>
            <a href="#mode" onClick={() => setMode("master")}>
              {t("usersPage.course.master")}
            </a>
          </li>
        </ul>
      </div>

      <ProgrammeList
        needRefetch={needRefetch}
        setNeedRefetch={setNeedRefetch}
        mode={mode}
      />
    </>
  )
}

export default ProgrammeView
