import axios from "axios"
import i18next from "i18next"
import { useState, useContext, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import { API_URL, messageToToasts } from "../../../utils"
import FormEntry from "../../helpers/FormEntry"
import LatexMarkdownForm from "../../helpers/LatexMarkdownForm"
import { CommonDataContext } from "../../../context/CommonDataContext"
import Select from "react-select"

const GroupEdit = ({ group, refetch }) => {
  const { t } = useTranslation("data")
  const {
    projectLeaders: { dict: plDict },
  } = useContext(CommonDataContext)

  const plOptions = (group?.possibleLeadersID || []).map(leader => ({
    value: leader,
    label: plDict[leader]?.fullName,
  }))

  const [submitting, setSubmitting] = useState(false)

  const [theme, setTheme] = useState(group?.theme)
  const [selectedLeaders, setSelectedLeaders] = useState(null)
  const [target, setTarget] = useState(group?.target)
  const [tasks, setTasks] = useState(group?.tasks)
  const [requirements, setRequirements] = useState(group?.requirements)
  const [reportFormat, setReportFormat] = useState(group?.reportFormat)
  const [presentation, setPresentation] = useState(group?.presentation)

  useEffect(() => {
    if (plOptions?.length) {
      setSelectedLeaders(
        plOptions.filter(opt => group?.leadersID.includes(opt?.value))
      )
    }
    // eslint-disable-next-line
  }, [plOptions?.length])

  const resetChanges = () => {
    setTheme(group?.theme)
    setTarget(group?.target)
    setTasks(group?.tasks)
    setRequirements(group?.requirements)
    setReportFormat(group?.reportFormat)
    setPresentation(group?.presentation)
  }

  const updateGroup = async () => {
    setSubmitting(true)

    try {
      const data = {
        id: group?.id,
        theme,
        leadersID: (selectedLeaders || []).map(sl => sl?.value),
        target,
        tasks,
        requirements,
        reportFormat,
        presentation,
      }

      const resp = await axios.post(`${API_URL}/group/update`, data)
      const { status, message } = resp.data
      setSubmitting(false)

      if (status === "failed") return messageToToasts(message, i18next.language)

      refetch(false)
      toast.success(t("group.updateSuccess"))
    } catch (error) {
      setSubmitting(false)
      toast.error("Server error")
    }
  }

  return (
    <div className="box">
      <FormEntry labelText={t("group.theme")} helpText={t("req")}>
        <input
          type="text"
          className="input"
          placeholder={t("group.themePlh")}
          value={theme}
          onChange={e => setTheme(e.target.value)}
        />
      </FormEntry>

      <FormEntry labelText={t("group.leader")}>
        <Select
          options={plOptions}
          value={selectedLeaders}
          onChange={setSelectedLeaders}
          isMulti
          isClearable
        />
      </FormEntry>

      <FormEntry labelText={t("group.target")}>
        <textarea
          className="textarea"
          placeholder={t("group.targetPlh")}
          value={target}
          onChange={e => setTarget(e.target.value)}
        />
      </FormEntry>

      <LatexMarkdownForm
        labelText={t("group.tasks")}
        value={tasks}
        setValue={setTasks}
        isReq={false}
      />

      <FormEntry labelText={t("group.requirements")}>
        <textarea
          className="textarea"
          placeholder={t("group.requirementsPlh")}
          value={requirements}
          onChange={e => setRequirements(e.target.value)}
        />
      </FormEntry>

      <div className="columns">
        <div className="column is-half">
          <FormEntry labelText={t("group.reportFormat")}>
            <textarea
              className="textarea"
              placeholder={t("group.reportFormatPlh")}
              value={reportFormat}
              onChange={e => setReportFormat(e.target.value)}
            />
          </FormEntry>
        </div>

        <div className="column is-half">
          <FormEntry labelText={t("group.presentation")}>
            <textarea
              className="textarea"
              placeholder={t("group.presentationPlh")}
              value={presentation}
              onChange={e => setPresentation(e.target.value)}
            />
          </FormEntry>
        </div>
      </div>

      <div className="field is-grouped is-grouped-right">
        <div className="control">
          <button
            className="button is-light"
            onClick={resetChanges}
            disabled={submitting}
          >
            {t("cancel")}
          </button>
        </div>

        <div className="control">
          <button
            className="button is-link"
            onClick={updateGroup}
            disabled={submitting}
          >
            {t("update")}
          </button>
        </div>
      </div>
    </div>
  )
}

export default GroupEdit
