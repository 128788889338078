import { useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import { UserContext } from "../../../context/UserContext"
import CourseList from "../../data/CourseList"
import CourseNew from "../../data/CourseNew"
import FromNewCSV from "../../data/FromNewCSV"

const CourseView = () => {
  const { t } = useTranslation("ws")
  const [needRefetch, setNeedRefetch] = useState(true)
  const [mode, setMode] = useState("bachelor")
  const {
    user: { role },
  } = useContext(UserContext)

  const isModerator = role === "moderator"

  return (
    <>
      <div className="box has-text-right title is-4">
        {t("usersPage.course.title")}
      </div>

      {!isModerator ? <CourseNew setNeedRefetch={setNeedRefetch} /> : null}

      {!isModerator ? (
        <FromNewCSV dataModel="course" setNeedRefetch={setNeedRefetch} />
      ) : null}

      <div className="box tabs is-centered">
        <ul>
          <li className={mode === "bachelor" ? " is-active" : ""}>
            <a href="#mode" onClick={() => setMode("bachelor")}>
              {t("usersPage.course.bachelor")}
            </a>
          </li>
          <li className={mode === "master" ? " is-active" : ""}>
            <a href="#mode" onClick={() => setMode("master")}>
              {t("usersPage.course.master")}
            </a>
          </li>
        </ul>
      </div>

      <CourseList
        needRefetch={needRefetch}
        setNeedRefetch={setNeedRefetch}
        mode={mode}
      />
    </>
  )
}

export default CourseView
