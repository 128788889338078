import { useContext, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useHistory, useParams } from "react-router"
import { ProjectsContext } from "../../../context/ProjectsContext"
import ProjectInfo from "../../data/project/ProjectInfo"
import MaxStudents from "../../data/proposal/MaxStudents"
import LoadingSpinner from "../../helpers/LoadingSpinner"

const AllProjectView = () => {
  const history = useHistory()
  const { t } = useTranslation(["data", "ws"])
  const { projectID } = useParams()
  const { projectsLoading, currentProject, switchCurrentProject } =
    useContext(ProjectsContext)

  useEffect(() => {
    switchCurrentProject(projectID)
  }, [projectID, switchCurrentProject])

  if (projectsLoading || projectID !== currentProject.id)
    return (
      <div className="box">
        <LoadingSpinner />
      </div>
    )

  return (
    <>
      <div className="box">
        <div className="columns">
          <div className="column is-5">
            <span
              className="has-text-link pointer"
              onClick={() => history.goBack()}
            >
              {t("ws:proposalsPage.backToList")}
            </span>
          </div>
          <div className="column is-7 has-text-right">
            <span className="title is-4">{currentProject?.title}</span>
          </div>
        </div>
      </div>

      <ProjectInfo />

      <div className="box">
        <p className="title is-5">{t("ws:projectsPage.availableSeats")}</p>
        <hr />
        <MaxStudents maxStudents={currentProject.maxStudents} />
      </div>

      <div className="box">
        <p className="title is-5">{t("project.acceptedStudents")}</p>
        <hr />
        <MaxStudents maxStudents={currentProject.acceptedStudents} />
      </div>
    </>
  )
}

export default AllProjectView
