import axios from "axios"
import { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useParams,
  useRouteMatch,
} from "react-router"
import { Link } from "react-router-dom"
import { toast } from "react-toastify"
import { ProjectsContext } from "../../../context/ProjectsContext"
import { UserContext } from "../../../context/UserContext"
import { API_URL, messageToToasts } from "../../../utils"
import GradingView from "../../data/grading/GradingView"
import ProgrammeDiscussionsList from "../../data/project/ProgrammeDiscussionsList"
import ProjectInfo from "../../data/project/ProjectInfo"
import ProjectRecomendations from "../../data/project/ProjectRecomendations"
import ProjectRequest from "../../data/project/ProjectRequest"
import ProjectRequests from "../../data/project/ProjectRequests"
import ProjectStudentList from "../../data/project/ProjectStudentList"
import MaxStudents from "../../data/proposal/MaxStudents"
import LoadingSpinner from "../../helpers/LoadingSpinner"
import BlogView from "./BlogView"
import ProjectChangeReq from "./ProjectChangeReq"
import ProjectGroupsView from "./ProjectGroupsView"

const ProjectView = () => {
  const history = useHistory()
  const { url } = useRouteMatch()
  const { t, i18n } = useTranslation(["ws", "data"])
  const { projectID } = useParams()
  const { projectsLoading, currentProject, switchCurrentProject } =
    useContext(ProjectsContext)
  const {
    user: { userID },
  } = useContext(UserContext)

  const [programmeDiscussions, setProgrammeDiscussions] = useState([])

  useEffect(() => {
    switchCurrentProject(projectID)
  }, [projectID, switchCurrentProject])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const resp = await axios.get(
          `${API_URL}/project/listProgrammeDiscussions?projectID=${projectID}`
        )

        const { status, message, discussions } = resp.data
        if (status === "failed") return messageToToasts(message, i18n.language)

        setProgrammeDiscussions(discussions)
      } catch (error) {
        toast.error("Server error!")
      }
    }

    fetchData()
  }, [projectID, i18n.language])

  const unreadMessages = programmeDiscussions.length
    ? programmeDiscussions
        .map(
          disc =>
            (disc?.messages || []).filter(mes => !mes.viewedBy.includes(userID))
              .length
        )
        .reduce((a, b) => a + b, 0)
    : 0

  const projectMenu = (
    <div className="box">
      <aside className="menu">
        <p className="menu-label">{t("projectsPage.project")}</p>
        <ul className="menu-list">
          <li>
            <Link to={url + "/info"}>{t("projectsPage.info")}</Link>
          </li>

          <li>
            <Link to={url + "/blog"}>{t("projectsPage.blog")}</Link>
          </li>

          <li>
            <Link to={url + "/students"}>{t("projectsPage.students")}</Link>
          </li>

          <li>
            <Link to={url + "/groups"}>{t("projectsPage.groups")}</Link>
          </li>

          <li>
            <Link to={url + "/dialogs"}>
              {t("projectsPage.dialogs")}
              {unreadMessages > 0 ? (
                <span className="tag is-link is-pulled-right">
                  {unreadMessages}
                </span>
              ) : null}
            </Link>
          </li>

          <li>
            <Link to={url + "/change"}>{t("projectsPage.change")}</Link>
          </li>
        </ul>
      </aside>
    </div>
  )

  if (projectsLoading || projectID !== currentProject.id)
    return (
      <div className="box">
        <LoadingSpinner />
      </div>
    )

  return (
    <>
      <div className="box">
        <div className="columns">
          <div className="column is-5">
            <span
              className="has-text-link pointer"
              onClick={() => history.push("/r/projectLeader/projects/list")}
            >
              {t("proposalsPage.backToList")}
            </span>
          </div>
          <div className="column is-7 has-text-right">
            <span className="title is-4">
              {currentProject?.title}

              {currentProject.num ? (
                <span className="has-text-grey">
                  {" "}
                  ({currentProject?.projectYear}-{currentProject.num})
                </span>
              ) : null}
            </span>
          </div>
        </div>
      </div>

      <div className="columns">
        <div className="column is-2">{projectMenu}</div>

        <div className="column is-10">
          <Switch>
            <Route exact path={url}>
              <Redirect to={url + "/info"} />
            </Route>

            <Route exact path={url + "/info"}>
              <ProjectRecomendations />

              <ProjectRequests />

              <ProjectRequest />

              <GradingView
                projectID={currentProject?.id}
                projectLang={currentProject?.lang}
              />

              <ProjectInfo />

              <div className="box">
                <p className="title is-5">{t("projectsPage.availableSeats")}</p>
                <hr />
                <MaxStudents
                  maxStudents={currentProject.maxStudents}
                  loadSeats={currentProject?.loadSeats}
                  externalSeats={currentProject?.externalSeats}
                />
              </div>

              <div className="box">
                <p className="title is-5">
                  {t("data:project.acceptedStudents")}
                </p>
                <hr />
                <MaxStudents maxStudents={currentProject.acceptedStudents} />
              </div>
            </Route>

            <Route path={url + "/blog"}>
              <BlogView />
            </Route>

            <Route path={url + "/students"}>
              <ProjectStudentList />
            </Route>

            <Route path={url + "/groups"}>
              <ProjectGroupsView />
            </Route>

            <Route path={url + "/dialogs"}>
              <ProgrammeDiscussionsList
                programmeDiscussions={programmeDiscussions}
              />
            </Route>

            <Route path={url + "/change"}>
              <ProjectChangeReq />
            </Route>
          </Switch>
        </div>
      </div>
    </>
  )
}

export default ProjectView
