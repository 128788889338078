import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useTranslation } from "react-i18next"
import ReactTooltip from "react-tooltip"

const ApplicationFreePlaces = ({ project, student }) => {
  const { t } = useTranslation("data")

  const freePlaces = Math.max(
    0,
    project?.maxStudents[student?.yearOfStudy] -
      project?.acceptedStudents[student?.yearOfStudy]
  )
  const maxForStudent = project?.maxStudents[student?.yearOfStudy]

  return (
    <>
      <ReactTooltip multiline={true} />
      <hr />
      <p>
        <strong>{t("application.freePlacesLeft")}</strong>
        <span className={"tag" + (freePlaces ? " is-success" : " is-warning")}>
          {freePlaces + t("project.outOf") + maxForStudent}
        </span>

        {!freePlaces ? (
          <span
            className="tag is-dark is-pulled-right"
            data-tip={t("application.noFreePlacesLeft")}
          >
            <FontAwesomeIcon icon="info" />
          </span>
        ) : null}
      </p>
    </>
  )
}

export default ApplicationFreePlaces
