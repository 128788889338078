import axios from "axios"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import { API_URL, messageToToasts } from "../utils"

const useLoads = (userType, mode = "", year = "applyYear") => {
  const { i18n } = useTranslation()
  const [loading, setLoading] = useState(true)
  const [loads, setLoads] = useState([])
  const [needRefetch, setNeedRefetch] = useState(true)

  useEffect(
    () => {
      const fetchLoads = async () => {
        try {
          const requestURL = `${API_URL}/leadersLoad/listFor${
            userType === "admin" || mode === "all" ? "Admin" : "Manager"
          }?year=${year}`

          const resp = await axios.get(requestURL)
          const { status, message, loads } = resp.data

          if (status === "failed")
            return messageToToasts(message, i18n.language)

          setLoads(loads)
          setLoading(false)
        } catch (error) {
          toast.error(
            "Server error. Try to reload page or contact administrator"
          )
        }
      }

      setLoading(true)
      fetchLoads()
      setNeedRefetch(false)
    },
    // eslint-disable-next-line
    [needRefetch, userType, mode, year]
  )

  const refetch = () => {
    setNeedRefetch(true)
  }

  return { loading, loads, refetch }
}

export default useLoads
