import dayjs from "dayjs"
import { useContext } from "react"
import { useTranslation } from "react-i18next"
import { UserContext } from "../../context/UserContext"
import useSessions from "../../context/useSessions"
import LoadingSpinner from "../helpers/LoadingSpinner"

const SessionList = ({ forUser = false }) => {
  const { t } = useTranslation("data")
  const { user } = useContext(UserContext)
  const { loading, sessions } = useSessions(forUser, user.userID)

  if (!loading)
    return (
      <div className="table-container">
        <table className="table is-fullwidth">
          <thead>
            <tr>
              <th>{t("session.platform")}</th>
              <th>{t("session.ip")}</th>
              <th>{t("session.date")}</th>
            </tr>
          </thead>
          <tbody>
            {sessions.map(session => (
              <tr key={session.id}>
                <td>{session.platform}</td>
                <td>{session.ip}</td>
                <td>{dayjs(session.created).format("DD/MM/YYYY HH:mm:ss")}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    )
  else return <LoadingSpinner text={t("loading.sessionsList")} />
}

export default SessionList
