import axios from "axios"
import { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import { toast } from "react-toastify"
import { AcademicProgrammeContext } from "../../../context/AcademicProgrammeContext"
import { ProjectsContext } from "../../../context/ProjectsContext"
import { API_URL, messageToToasts } from "../../../utils"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import LoadingSpinner from "../../helpers/LoadingSpinner"
import { UserContext } from "../../../context/UserContext"
import fileDownload from "js-file-download"

const ApplicationStatisticsForProjects = ({ forManager = false }) => {
  const { t, i18n } = useTranslation("data")
  const [stats, setStats] = useState({})
  const [total, setTotal] = useState({})
  const [loading, setLoading] = useState(true)

  const { currentProgramme } = useContext(AcademicProgrammeContext)
  const { projects, projectsLoading } = useContext(ProjectsContext)
  const {
    user: { role },
  } = useContext(UserContext)

  const isModerator = role === "moderator"

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const resp = await axios.get(
          `${API_URL}/application/projectStats${
            forManager ? "forManager?programmeID=" + currentProgramme : ""
          }`
        )
        const { status, message, stats, total } = resp.data
        setLoading(false)

        if (status === "failed") return messageToToasts(message, i18n.language)

        setStats(stats)
        setTotal(total)
      } catch (error) {
        setLoading(false)
        toast.error("Server error")
      }
    }

    fetchStats()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProgramme])

  const remindLeaders = async () => {
    try {
      await axios.post(`${API_URL}/application/remindLeaders`)

      toast.success("Задача рассылки писем с напоминанием запущена")
    } catch (error) {
      toast.error("Server error!")
    }
  }

  const studentsNoProject = async () => {
    try {
      const resp = await axios.get(API_URL + "/user/student/exportNoProject")

      fileDownload(resp.data, "students.csv")
    } catch (error) {
      toast.error("Server error!")
    }
  }

  const studentsNewApps = async () => {
    try {
      const resp = await axios.get(API_URL + "/user/student/exportNewApps")

      fileDownload(resp.data, "students_new_apps.csv")
    } catch (error) {
      toast.error("Server error!")
    }
  }

  if (loading || projectsLoading)
    return (
      <div className="box">
        <LoadingSpinner />
      </div>
    )

  return (
    <>
      <div className="box">
        <p className="title is-4 has-text-right">
          {t("application.appStatsTitle")}
        </p>
      </div>

      <div className="box">
        <nav className="level">
          {Object.entries(total).map(([status, value]) => (
            <div className="level-item has-text-centered" key={status}>
              <div>
                <p className="heading">{t(`application.${status}Status`)}</p>
                <p className="title">{value}</p>
              </div>
            </div>
          ))}

          {/* <div className="level-item has-text-centered">
            <div>
              <p className="heading">{t("projectsPage.refresh")}</p>
              <p className="title">
                <FontAwesomeIcon
                  icon="sync"
                  className="pointer"
                  onClick={() => refetch("projects")}
                />
              </p>
            </div>
          </div> */}
        </nav>
      </div>

      {!forManager && !isModerator ? (
        <div className="box">
          <div className="columns">
            <div className="column is-4 has-text-centered">
              <button className="button is-link" onClick={remindLeaders}>
                <FontAwesomeIcon icon="envelope" size="lg" />
                &nbsp;Напомнить об ожидающих
              </button>
            </div>

            <div className="column is-4 has-text-centered">
              <button className="button is-link" onClick={studentsNoProject}>
                <FontAwesomeIcon icon="users" size="lg" />
                &nbsp;Студенты без проекта
              </button>
            </div>

            <div className="column is-4 has-text-centered">
              <button className="button is-link" onClick={studentsNewApps}>
                <FontAwesomeIcon icon="users" size="lg" />
                &nbsp;Студенты с П1-AwD
              </button>
            </div>
          </div>
        </div>
      ) : null}

      <div className="box table-container">
        <table className="table is-fullwidth">
          <thead>
            <tr>
              <th>No</th>
              <th>{t("proposal.title")}</th>
              <th className="has-text-centered">
                <abbr title={t("application.awaitingStatus")}>AwD</abbr>
              </th>
              <th className="has-text-centered">
                <abbr title={t("application.acceptedByLeaderStatus")}>AL</abbr>
              </th>
              <th className="has-text-centered">
                <abbr title={t("application.acceptedStatus")}>AS</abbr>
              </th>
              <th className="has-text-centered">
                <abbr title={t("application.declinedStatus")}>DL</abbr>
              </th>
              <th className="has-text-centered">
                <abbr title={t("application.declinedSelfStatus")}>DS</abbr>
              </th>
            </tr>
          </thead>
          <tbody>
            {Object.values(stats).map(proj => {
              const derivedProj = projects.find(pr => pr.id === proj.projectID)
              return (
                <tr key={derivedProj?.num}>
                  <td className="has-text-grey">{derivedProj?.num}</td>
                  <td>
                    <Link
                      to={
                        `/r/${
                          forManager ? "academicManager" : "admin"
                        }/projects/viewProject/` + derivedProj?.id
                      }
                      //   target="_blank"
                    >
                      {derivedProj?.title}
                    </Link>
                  </td>
                  <td
                    className={
                      "has-text-centered" +
                      (proj.awaiting > 0
                        ? " has-text-warning has-text-weight-bold"
                        : "")
                    }
                  >
                    {proj.awaiting}
                  </td>
                  <td
                    className={
                      "has-text-centered" +
                      (proj.acceptedByLeader > 0
                        ? " has-text-success has-text-weight-bold"
                        : "")
                    }
                  >
                    {proj.acceptedByLeader}
                  </td>
                  <td
                    className={
                      "has-text-centered" +
                      (proj.accepted > 0
                        ? " has-text-success has-text-weight-bold"
                        : "")
                    }
                  >
                    {proj.accepted}
                  </td>
                  <td
                    className={
                      "has-text-centered" +
                      (proj.declined > 0
                        ? " has-text-danger has-text-weight-bold"
                        : "")
                    }
                  >
                    {proj.declined}
                  </td>
                  <td
                    className={
                      "has-text-centered" +
                      (proj.declinedSelf > 0
                        ? " has-text-danger has-text-weight-bold"
                        : "")
                    }
                  >
                    {proj.declinedSelf}
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </>
  )
}

export default ApplicationStatisticsForProjects
