import dayjs from "dayjs"
import { useContext } from "react"
import { useTranslation } from "react-i18next"
import { CommonDataContext } from "../../../context/CommonDataContext"
import { ProjectsContext } from "../../../context/ProjectsContext"
import { yearsToText } from "../../../utils"
import TransferActions from "./TransferActions"
import TransferChat from "./TransferChat"
import TransferStatus from "./TransferStatus"

const TransferPreview = ({ transfer }) => {
  const { t } = useTranslation("data")
  const {
    students: { dict: studDict },
  } = useContext(CommonDataContext)
  const { projects } = useContext(ProjectsContext)

  const student = studDict[transfer?.studentID]
  const projectFrom = projects.find(pr => pr.id === transfer.fromProjectID)
  const projectTo = projects.find(pr => pr.id === transfer.toProjectID)

  return (
    <div className="box">
      <div className="columns">
        <div className="column is-half">
          <p>
            <strong>{t("transfer.studentName")}: </strong>
            {student?.fullName}
          </p>

          <p>
            <strong>Email: </strong>
            <a href={"mailto:" + student?.email}>{student?.email}</a>
          </p>

          <p>
            <strong>{t("transfer.studentYos")}: </strong>
            {yearsToText(student?.yearOfStudy)}
          </p>

          <p>
            <strong>{t("transfer.fromProject")}: </strong>
            <span
              className={
                "has-text-weight-semibold has-text-" +
                (transfer.fromAccepted ? "success" : "grey")
              }
            >
              {projectFrom?.num} - {projectFrom?.title}
            </span>
          </p>

          <p>
            <strong>{t("transfer.toProject")}: </strong>
            <span
              className={
                "has-text-weight-semibold has-text-" +
                (transfer.toAccepted ? "success" : "grey")
              }
            >
              {projectTo?.num} - {projectTo?.title}
            </span>
          </p>

          <p>
            <strong>{t("transfer.creationDate")}: </strong>
            {dayjs(transfer?.creationDate).format("DD/MM/YYYY HH:mm")}
          </p>
        </div>

        <div className="column is-half">
          <TransferChat
            chat={transfer?.chat}
            transferID={transfer?.id}
            isSendable={transfer?.status === "awaiting"}
          />
        </div>
      </div>

      <hr />

      <div className="columns">
        <div className="column is-4 has-text-centered">
          <TransferStatus status={transfer?.status} />
        </div>

        <div className="column is-8 has-text-right">
          <TransferActions transfer={transfer} />
        </div>
      </div>
    </div>
  )
}

export default TransferPreview
