import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import axios from "axios"
import { useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import { toast } from "react-toastify"
import { CommonDataContext } from "../../../context/CommonDataContext"
import { ProjectsContext } from "../../../context/ProjectsContext"
import { UserContext } from "../../../context/UserContext"
import { API_URL, messageToToasts, nilObjectID } from "../../../utils"

const RemoveProjectView = ({ studentID, projectID, oldProjectID }) => {
  const { t, i18n } = useTranslation("data")
  const { projects, projectsLoading } = useContext(ProjectsContext)
  const {
    projectLeaders: { dict: plDict },
  } = useContext(CommonDataContext)
  const {
    user: { userType },
  } = useContext(UserContext)

  const [submitting, setSubmitting] = useState(false)

  const projectLink = `/r/${userType}/projects/viewProject/${projectID}`
  const projectOldLink = `/r/${userType}/projects/viewProject/${oldProjectID}`

  const removeFromProject = async () => {
    setSubmitting(true)

    try {
      const resp = await axios.post(`${API_URL}/user/student/removeProject`, {
        studentID,
      })

      const { status, message } = resp.data

      if (status === "failed") {
        setSubmitting(false)
        return messageToToasts(message, i18n.language)
      }

      window.location.reload()
    } catch (error) {
      setSubmitting(false)
      toast.error("Server error")
    }
  }

  if (projectsLoading) return null

  const project = projects.find(pr => pr.id === projectID)
  const oldProject = projects.find(pr => pr.id === oldProjectID)

  return (
    <>
      {projectID === nilObjectID || (
        <div className="box">
          <div className="columns">
            <div className="column is-10">
              <p>
                <strong>{t("user.project")}:</strong>{" "}
                <Link to={projectLink}>
                  {`${project?.projectYear}-${project?.num} - ${project?.title}`}
                </Link>
                {` (${plDict[project?.projectLeaderID]?.fullName})`}
              </p>
            </div>

            {userType === "admin" && !submitting ? (
              <div className="column is-2 has-text-right">
                <div className="field">
                  <p className="control">
                    <span
                      className="has-text-danger pointer"
                      title={t("user.removeFromProject")}
                      onClick={removeFromProject}
                    >
                      <FontAwesomeIcon icon="times" size="lg" />
                    </span>
                  </p>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      )}

      {oldProjectID === nilObjectID || (
        <div className="box">
          <div className="columns">
            <div className="column is-10">
              <p>
                <strong>{t("user.project")} (прошлый):</strong>{" "}
                <Link to={projectOldLink}>
                  {`${oldProject?.projectYear}-${oldProject?.num} - ${oldProject?.title}`}
                </Link>
                {` (${plDict[oldProject?.projectLeaderID]?.fullName})`}
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
export default RemoveProjectView
