import { useTranslation } from "react-i18next"
import FormEntry from "../../helpers/FormEntry"

const AdminSignUpForm = ({ fields, updateFields }) => {
  const { t } = useTranslation("auth")

  return (
    <>
      <FormEntry labelText="Email" helpText={t("formLabels.req")}>
        <input
          className="input"
          name="email"
          value={fields.email}
          onChange={e => updateFields(e)}
          type="email"
          placeholder="some-mail@hse.ru"
          disabled
        />
      </FormEntry>

      <FormEntry labelText={t("formLabels.pwd")} helpText={t("formLabels.req")}>
        <input
          className="input"
          type="password"
          name="password"
          placeholder="********"
          value={fields.password}
          onChange={e => updateFields(e)}
        />
      </FormEntry>

      <FormEntry
        labelText={t("formLabels.pwdRep")}
        helpText={t("formLabels.req")}
      >
        <input
          className="input"
          type="password"
          name="passwordRep"
          placeholder="********"
          value={fields.passwordRep}
          onChange={e => updateFields(e)}
        />
      </FormEntry>

      <FormEntry
        labelText={t("formLabels.surname")}
        helpText={t("formLabels.req")}
      >
        <input
          className="input"
          name="surname"
          value={fields.surname}
          onChange={e => updateFields(e)}
          type="text"
          placeholder="Иванов"
        />
      </FormEntry>

      <FormEntry
        labelText={t("formLabels.name")}
        helpText={t("formLabels.req")}
      >
        <input
          className="input"
          name="name"
          value={fields.name}
          onChange={e => updateFields(e)}
          type="text"
          placeholder="Иван"
        />
      </FormEntry>

      <FormEntry labelText={t("formLabels.secondName")}>
        <input
          className="input"
          name="secondName"
          value={fields.secondName}
          onChange={e => updateFields(e)}
          type="text"
          placeholder="Иванович"
        />
      </FormEntry>
    </>
  )
}

export default AdminSignUpForm
