import { useTranslation } from "react-i18next"
import { Link, Route, Switch, useRouteMatch } from "react-router-dom"
import ProjectsContextProvider from "../../context/ProjectsContext"
import ProjectsFilterContextProvider from "../../context/ProjectsFilterContext"
import ProposalsContextProvider from "../../context/ProposalsContext"
import TransferContextProvider from "../../context/TransferContext"
import BulletinPage from "../data/bulettin/BulletinPage"
import TransferList from "../data/transfer/TransferList"
import NavBar from "../helpers/NavBar"
import GroupsPageView from "./admin/GroupsPageView"
import LoadsPageView from "./admin/LoadsPageView"
import PlacesPageView from "./admin/PlacesPageView"
import ProjectsPageView from "./admin/ProjectsPageView"
import UsersPageView from "./admin/UsersPageView"
import HomeView from "./HomeView"
import PracticeAdmin from "../data/practice/PracticeAdmin"

const AdminWorkspace = () => {
  const { t } = useTranslation("ws")
  const { path, url } = useRouteMatch()

  return (
    <>
      <NavBar>
        <Link className="navbar-item" to={url + "/users"}>
          {t("menu.system")}
        </Link>

        <Link className="navbar-item" to={url + "/projects"}>
          {t("menu.adminProjects")}
        </Link>

        <Link className="navbar-item" to={url + "/loads"}>
          {t("menu.leaderLoads")}
        </Link>

        <Link className="navbar-item" to={url + "/places"}>
          {t("menu.coursePlaces")}
        </Link>

        <Link className="navbar-item" to={url + "/transfers"}>
          {t("menu.transferRequests")}
        </Link>

        <Link className="navbar-item" to={url + "/bulletin"}>
          {t("menu.bulletin")}
        </Link>

        <Link className="navbar-item" to={url + "/practice"}>
          {t("menu.practice")}
        </Link>
      </NavBar>

      <div className="section">
        <div className="container">
          <Switch>
            <Route exact path={path}>
              <HomeView />
            </Route>

            <Route path={path + "/users"}>
              <UsersPageView />
            </Route>

            <Route path={path + "/projects"}>
              <ProposalsContextProvider userType="admin">
                <ProjectsContextProvider userType="admin">
                  <ProjectsFilterContextProvider>
                    <ProjectsPageView />
                  </ProjectsFilterContextProvider>
                </ProjectsContextProvider>
              </ProposalsContextProvider>
            </Route>

            <Route path={path + "/loads"}>
              <LoadsPageView />
            </Route>

            <Route path={path + "/groups"}>
              <GroupsPageView />
            </Route>

            <Route path={path + "/places"}>
              <PlacesPageView />
            </Route>

            <Route path={path + "/transfers"}>
              <TransferContextProvider>
                <ProjectsContextProvider userType="admin">
                  <TransferList />
                </ProjectsContextProvider>
              </TransferContextProvider>
            </Route>

            <Route path={path + "/bulletin"}>
              <BulletinPage />
            </Route>

            <Route path={path + "/practice"}>
              <PracticeAdmin />
            </Route>
          </Switch>
        </div>
      </div>
    </>
  )
}

export default AdminWorkspace
