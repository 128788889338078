import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import FormEntry from "../../helpers/FormEntry"
import Select from "react-select"
import useDivisions from "../../../context/useDivisions"

const projectLeaderFields = {
  divisionString: "",
}

const DivisionManagerSignUpForm = ({ fields, setFields, updateFields }) => {
  const { t, i18n } = useTranslation("auth")
  const [formNeedsEnrichment, setFormNeedsEnrichment] = useState(true)

  const [needRefetch, setNeedRefetch] = useState(true)
  const { divisionOptions, loading } = useDivisions(needRefetch, setNeedRefetch)
  const [selectedDivisionOption, setSelectedDivisionOption] = useState({
    label: t("placeholders.chooseDivision"),
    value: "",
  })

  useEffect(() => {
    if (formNeedsEnrichment) {
      setFields({ ...fields, ...projectLeaderFields })
      setFormNeedsEnrichment(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(
    () => {
      if (fields.division && divisionOptions[i18n.language]?.length) {
        setSelectedDivisionOption(
          divisionOptions[i18n.language].find(
            el => el.value === fields.division
          )
        )
        setFields({ ...fields, divisionString: fields.division })
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fields.division, divisionOptions, i18n.language]
  )

  return (
    <>
      <FormEntry labelText="Email" helpText={t("formLabels.req")}>
        <input
          className="input"
          name="email"
          value={fields.email}
          onChange={e => updateFields(e)}
          type="email"
          placeholder="some-mail@hse.ru"
          disabled
        />
      </FormEntry>

      <FormEntry labelText={t("formLabels.pwd")} helpText={t("formLabels.req")}>
        <input
          className="input"
          type="password"
          name="password"
          placeholder="********"
          value={fields.password}
          onChange={e => updateFields(e)}
        />
      </FormEntry>

      <FormEntry
        labelText={t("formLabels.pwdRep")}
        helpText={t("formLabels.req")}
      >
        <input
          className="input"
          type="password"
          name="passwordRep"
          placeholder="********"
          value={fields.passwordRep}
          onChange={e => updateFields(e)}
        />
      </FormEntry>

      <FormEntry
        labelText={t("formLabels.surname")}
        helpText={t("formLabels.req")}
      >
        <input
          className="input"
          name="surname"
          value={fields.surname}
          onChange={e => updateFields(e)}
          type="text"
          placeholder="Иванов"
        />
      </FormEntry>

      <FormEntry
        labelText={t("formLabels.name")}
        helpText={t("formLabels.req")}
      >
        <input
          className="input"
          name="name"
          value={fields.name}
          onChange={e => updateFields(e)}
          type="text"
          placeholder="Иван"
        />
      </FormEntry>

      <FormEntry labelText={t("formLabels.secondName")}>
        <input
          className="input"
          name="secondName"
          value={fields.secondName}
          onChange={e => updateFields(e)}
          type="text"
          placeholder="Иванович"
        />
      </FormEntry>

      <FormEntry
        labelText={t("formLabels.division")}
        helpText={t("formLabels.req")}
      >
        <Select
          options={divisionOptions[i18n.language]}
          value={selectedDivisionOption}
          onChange={setSelectedDivisionOption}
          isLoading={loading}
          isDisabled
        />
      </FormEntry>
    </>
  )
}

export default DivisionManagerSignUpForm
