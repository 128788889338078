import { useContext } from "react"
import { ProjectsContext } from "../../../context/ProjectsContext"
import ProjectList from "../../data/project/ProjectList"
import LoadingSpinner from "../../helpers/LoadingSpinner"

const ProjectListView = () => {
  const { projects, projectsLoading } = useContext(ProjectsContext)

  if (projectsLoading)
    return (
      <div className="box">
        <LoadingSpinner />
      </div>
    )

  return <ProjectList projects={projects} />
}

export default ProjectListView
