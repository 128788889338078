import axios from "axios"
import i18next from "i18next"
import { createContext, useContext, useEffect, useState } from "react"
import { toast } from "react-toastify"
import { API_URL, messageToToasts } from "../utils"
import { UserContext } from "./UserContext"
import useTransfers from "./useTransfers"

export const TransferContext = createContext()

const userTypeToMode = userType => {
  switch (userType) {
    case "student":
    case "admin":
      return userType
    case "projectLeader":
      return "from"
    default:
      return "err"
  }
}

const TransferContextProvider = ({ children }) => {
  const {
    user: { userType },
  } = useContext(UserContext)
  const { loading, transfers, refetch } = useTransfers(userType)

  const [mode, setMode] = useState("")

  useEffect(() => {
    setMode(userTypeToMode(userType))
  }, [userType])

  const switchMode = newMode => {
    if (
      userType === "projectLeader" &&
      ["from", "to", "both"].includes(newMode)
    )
      setMode(newMode)
  }

  const declineTransfer = async transferID => {
    try {
      const resp = await axios.post(`${API_URL}/transfer/decline`, {
        transferID,
        reason: mode,
      })
      const { status, message } = resp.data

      if (status === "failed") return messageToToasts(message, i18next.language)

      refetch()
    } catch (error) {
      toast.error("Server error!")
    }
  }

  const acceptTransfer = async transferID => {
    try {
      const resp = await axios.post(`${API_URL}/transfer/accept`, {
        transferID,
        mode,
      })
      const { status, message } = resp.data

      if (status === "failed") return messageToToasts(message, i18next.language)

      refetch()
    } catch (error) {
      toast.error("Server error!")
    }
  }

  const value = {
    mode,
    switchMode,
    loading,
    transfers,
    refetch,
    declineTransfer,
    acceptTransfer,
  }

  return (
    <TransferContext.Provider value={value}>
      {children}
    </TransferContext.Provider>
  )
}

export const useTransferContext = () => {
  const context = useContext(TransferContext)

  return context
}

export default TransferContextProvider
