import { useContext } from "react"
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom"
import { AcademicProgrammeContext } from "../../../context/AcademicProgrammeContext"
import ProjectStatsForProgramme from "../../data/project/ProjectStatsForProgramme"
import ProjectListView from "./ProjectListView"
import ProjectView from "./ProjectView"

const ProjectsPageView = () => {
  const { path, url } = useRouteMatch()
  const { currentProgramme } = useContext(AcademicProgrammeContext)

  return (
    <>
      <ProjectStatsForProgramme programmeID={currentProgramme} />

      <Switch>
        <Route exact path={path}>
          <Redirect to={url + "/list"} />
        </Route>

        <Route path={path + "/list"}>
          <ProjectListView />
        </Route>

        <Route path={path + "/viewProject/:projectID"}>
          <ProjectView />
        </Route>
      </Switch>
    </>
  )
}

export default ProjectsPageView
