import { Suspense, useContext } from "react"
import { Redirect, Route, Switch } from "react-router-dom"
import Auth from "./components/Auth"
import Footer from "./components/helpers/Footer"
import Page404 from "./components/helpers/Page404"
import ResetPassword from "./components/ResetPassword"
import RestrictedArea from "./components/RestrictedArea"
import Validate from "./components/Validate"
import SettingsContextProvider from "./context/SettingsContext"
import { UserContext } from "./context/UserContext"

function App() {
  const { isSigned } = useContext(UserContext)

  return (
    <Suspense fallback="loading...">
      <SettingsContextProvider>
        <div id="wrapper">
          <Switch>
            <Route exact path="/">
              {isSigned ? <Redirect to="/r" /> : <Redirect to="/auth" />}
            </Route>

            <Route path="/auth">
              {isSigned ? <Redirect to="/" /> : <Auth />}
            </Route>

            <Route path="/validate">
              {isSigned ? <Redirect to="/" /> : <Validate />}
            </Route>

            <Route path="/resetPassword">
              {isSigned ? <Redirect to="/" /> : <ResetPassword />}
            </Route>

            <Route path="/r">
              {isSigned ? <RestrictedArea /> : <Redirect to="/" />}
            </Route>

            <Route path="*" component={Page404} />
          </Switch>
        </div>
        <Footer />
      </SettingsContextProvider>
    </Suspense>
  )
}

export default App
