import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import { UserContext } from "../../../context/UserContext"
import useUsers from "../../../context/useUsers"
import { validateEmail } from "../../../utils"
import FormEntry from "../../helpers/FormEntry"

const ProposalNewCLE = ({
  coleadersExtras = [],
  existingColeaders = [],
  setColeadersExtras,
}) => {
  const { t } = useTranslation("data")
  const { users } = useUsers("projectLeader")
  const { user } = useContext(UserContext)

  const [newEmail, setNewEmail] = useState("")
  const [newSurname, setNewSurname] = useState("")
  const [newName, setNewName] = useState("")
  const [newSecondName, setNewSecondName] = useState("")
  const [newWorkPlace, setNewWorkPlace] = useState("")

  const clearForm = () => {
    setNewSurname("")
    setNewWorkPlace("")
    setNewName("")
    setNewSecondName("")
    setNewEmail("")
  }

  const addFromSuggest = (e, suggestedPL) => {
    e.preventDefault()
    const { email } = suggestedPL

    if (coleadersExtras.some(cle => cle.email === email))
      return toast.error(t("proposal.cleAlreadyMessage"))

    if (coleadersExtras.length >= 10)
      return toast.error(t("proposal.cleLimitMessage"))

    setColeadersExtras([...coleadersExtras, { email }])
    clearForm()
  }

  const addCLE = e => {
    e.preventDefault()

    if (!validateEmail(newEmail))
      return toast.error(t("proposal.badCLEmailMessage"))

    setColeadersExtras([
      ...coleadersExtras,
      {
        email: newEmail.toLowerCase(),
        surname: newSurname.trim(),
        name: newName.trim(),
        secondName: newSecondName.trim(),
        workPlace: newWorkPlace.trim(),
      },
    ])
    clearForm()
  }

  const suggestPL = () => {
    const filteredPLs = users.filter(
      el =>
        el.email.includes(newEmail.trim().toLowerCase()) &&
        el.email !== user.email
    )

    if (filteredPLs.length && newEmail.length)
      return (
        <article className="message is-success">
          <div className="message-header">{t("proposal.suggest")}</div>
          <div className="message-body">
            {filteredPLs.slice(0, 5).map(pl => (
              <div className="field has-addons" key={pl.email}>
                <p className="control">
                  <button
                    className="button is-link"
                    onClick={event => addFromSuggest(event, pl)}
                  >
                    <FontAwesomeIcon icon="plus" />
                  </button>
                </p>
                <p className="control">
                  <button className="button is-static">
                    {pl.workPlace === "hse" ? t("proposal.hse") : pl.workPlace}
                  </button>
                </p>
                <p className="control is-expanded">
                  <button className="button is-static is-fullwidth">
                    {pl.fullName + " (" + pl.email + ")"}
                  </button>
                </p>
              </div>
            ))}
          </div>
        </article>
      )
    else return null
  }

  const removeCLE = email => {
    setColeadersExtras(coleadersExtras.filter(cle => cle.email !== email))
  }

  return (
    <FormEntry labelText={t("proposal.cle")}>
      {suggestPL()}
      <div className="columns mt-3">
        <div className="column is-half">
          <FormEntry labelText="Email" helpText={t("req")}>
            <input
              type="text"
              className="input"
              value={newEmail}
              onChange={e => setNewEmail(e.target.value)}
            />
          </FormEntry>

          <FormEntry labelText={t("proposal.surname")}>
            <input
              type="text"
              className="input"
              value={newSurname}
              onChange={e => setNewSurname(e.target.value)}
            />
          </FormEntry>

          <FormEntry labelText={t("proposal.name")}>
            <input
              type="text"
              className="input"
              value={newName}
              onChange={e => setNewName(e.target.value)}
            />
          </FormEntry>

          <FormEntry labelText={t("proposal.secondName")}>
            <input
              type="text"
              className="input"
              value={newSecondName}
              onChange={e => setNewSecondName(e.target.value)}
            />
          </FormEntry>

          {!["hse.ru", "edu.hse.ru"].includes(newEmail.split("@")[1]) ? (
            <FormEntry labelText={t("proposal.workPlace")}>
              <input
                type="text"
                className="input"
                value={newWorkPlace}
                onChange={e => setNewWorkPlace(e.target.value)}
              />
            </FormEntry>
          ) : null}

          <FormEntry>
            <button
              className="button is-link is-outlined is-fullwidth"
              onClick={e => addCLE(e)}
            >
              {t("proposal.addCLE")}
            </button>
          </FormEntry>
        </div>

        <div className="column is-half">
          {/* проверяем что есть хоть какие-то */}
          {!coleadersExtras.length && !existingColeaders.length ? (
            <p className="has-text-grey has-text-centered">
              {t("proposal.emptyCLE")}
            </p>
          ) : null}

          {/* отображаем уже добавленных */}
          {existingColeaders.length ? (
            <>
              <p className="title is-6">{t("proposal.existingCLE")}</p>
              <p className="subtitle is-7">{t("proposal.helpExistingCLE")}</p>
              {existingColeaders.map(cle => {
                const { email } = cle
                return (
                  <div className="field has-addons" key={email}>
                    <p className="control">
                      <button
                        className="button is-danger"
                        onClick={() => removeCLE(email)}
                        disabled
                      >
                        <FontAwesomeIcon icon="trash" />
                      </button>
                    </p>
                    <p className="control is-expanded">
                      <button className="button is-fullwidth is-multiline is-static">
                        {email}
                      </button>
                    </p>
                  </div>
                )
              })}
            </>
          ) : null}

          {coleadersExtras.length && existingColeaders.length ? <hr /> : null}

          {/* отображаем добавляемых */}
          {coleadersExtras.length ? (
            <>
              <p className="title is-6">{t("proposal.newCLE")}</p>
              <p className="subtitle is-7">{t("proposal.helpNewCLE")}</p>
              {coleadersExtras.map(cle => {
                const { email } = cle
                return (
                  <div className="field has-addons" key={email}>
                    <p className="control">
                      <button
                        className="button is-danger"
                        onClick={() => removeCLE(email)}
                      >
                        <FontAwesomeIcon icon="trash" />
                      </button>
                    </p>
                    <p className="control is-expanded">
                      <button className="button is-fullwidth is-multiline is-static">
                        {email}
                      </button>
                    </p>
                  </div>
                )
              })}
            </>
          ) : null}
        </div>
      </div>
    </FormEntry>
  )
}

export default ProposalNewCLE
