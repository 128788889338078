import axios from "axios"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import { API_URL, messageToToasts } from "../utils"

const useInvites = (userType, used) => {
  const { i18n } = useTranslation()
  const [loading, setLoading] = useState(true)
  const [invites, setInvites] = useState([])

  useEffect(
    () => {
      const fetchInvites = async () => {
        try {
          const requestURL = `${API_URL}/user/listInvites?userType=${userType}&used=${used}`
          const resp = await axios.get(requestURL)
          const { status, message, invites } = resp.data

          if (status === "failed")
            return messageToToasts(message, i18n.language)

          setInvites(invites)
          setLoading(false)
        } catch (error) {
          toast.error(
            "Server error. Try to reload page or contact administrator"
          )
        }
      }

      setLoading(true)
      fetchInvites()
    },
    // eslint-disable-next-line
    [userType, used]
  )

  return { loading, invites }
}

export default useInvites
