import { useTranslation } from "react-i18next"
import ProjectPreview from "./ProjectPreview"

const ProjectList = ({ projects }) => {
  const { t } = useTranslation("data")

  return projects.length ? (
    projects.map(project => (
      <ProjectPreview project={project} key={project.id} />
    ))
  ) : (
    <div className="box">
      <p className="has-text-grey">{t("emptyList")}</p>
    </div>
  )
}

export default ProjectList
