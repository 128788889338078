import axios from "axios"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import { API_URL } from "../../../utils"

const ApplicationStudentInfo = ({ studentEmail }) => {
  const { t } = useTranslation("data")
  const [info, setInfo] = useState(null)

  useEffect(() => {
    const fetchInfo = async () => {
      try {
        const resp = await axios.get(
          `${API_URL}/studentInfo/get?studentEmail=${studentEmail}`
        )

        const { status, info } = resp.data
        if (status === "failed") return

        setInfo(info)
      } catch (error) {
        toast.error("Server error!")
      }
    }

    fetchInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!info)
    return (
      <>
        <hr />
        <p className="has-text-grey">{t("application.noStudentInfo")}</p>
      </>
    )

  return (
    <>
      <hr />
      <div className="columns has-text-centered">
        <div className="column is-6">
          <strong>{t("application.gpa")}:</strong> {info?.gpa}
        </div>
        <div className="column is-6">
          <strong>{t("application.rating")}:</strong> {info?.rating}
        </div>
      </div>
    </>
  )
}

export default ApplicationStudentInfo
