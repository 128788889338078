import { useTranslation } from "react-i18next"

const colorMap = {
  awaiting: "warning",
  accepted: "success",
  declined: "danger",
}

const TransferStatus = ({ status }) => {
  const { t } = useTranslation("data")

  return (
    <span className={"has-text-weight-semibold has-text-" + colorMap[status]}>
      {t(`transfer.${status}Status`)}
    </span>
  )
}

export default TransferStatus
