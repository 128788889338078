import dayjs from "dayjs"
import { useContext } from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import { CommonDataContext } from "../../context/CommonDataContext"
import { nilObjectID } from "../../utils"
import ProposalStatus from "./proposal/ProposalStatus"

const ProposalPreview = ({ proposal }) => {
  const { t, i18n } = useTranslation("data")
  const {
    projectLeaders: { dict: plDict },
    divisions: { dict: divDict },
  } = useContext(CommonDataContext)

  const projectLeader = plDict[proposal.projectLeaderID]

  return (
    <div className="box">
      {proposal.title ? (
        <p className="title is-5">
          {proposal.title}
          {proposal.lang === "ru" && proposal.titleEN !== ""
            ? ` (${proposal.titleEN})`
            : ""}
          <span className="is-pulled-right has-text-grey">{proposal.lang}</span>
        </p>
      ) : (
        <p className="title is-5 has-text-grey">
          {t("proposal.namelessDraft")}
          <span className="is-pulled-right has-text-grey">{proposal.lang}</span>
        </p>
      )}
      <hr />

      <p className="subtitle is-6">
        <strong>{t("proposal.leader")}: </strong>
        <Link
          to={`/r/common/profile?userID=${projectLeader?.id}&userType=projectLeader`}
          target="_blank"
        >
          {projectLeader?.fullName}
        </Link>
      </p>

      {proposal.division !== nilObjectID ? (
        <p className="subtitle is-6">
          <strong>{t("proposal.division")}: </strong>
          {" " + divDict[proposal.division]?.title[i18n.language]}
        </p>
      ) : null}

      <div className="content">{proposal.summary}</div>
      <hr />
      <div className="columns">
        <div className="column is-4">
          <Link to={"./view/" + proposal.id}>{t("proposal.read")}</Link>
        </div>
        <div className="column is-4 has-text-centered">
          <ProposalStatus status={proposal.status} />
        </div>
        <div className="column is-4 has-text-right has-text-grey">
          {dayjs(proposal.created).format("DD/MM/YYYY")}
        </div>
      </div>
    </div>
  )
}

export default ProposalPreview
