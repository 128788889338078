import i18next from "i18next"
import ReactMarkdown from "react-markdown"
import GreetingAdminPreview from "./GreetingAdminPreview"

const GreetingList = ({ greetings, forAdmin = false, refetch }) => {
  return greetings.map(greeting =>
    forAdmin ? (
      <GreetingAdminPreview
        key={greeting.id}
        greeting={greeting}
        refetch={refetch}
      />
    ) : (
      <div className="box content" key={greeting.id}>
        <ReactMarkdown>
          {i18next.language === "ru" ? greeting.text : greeting.textEN}
        </ReactMarkdown>
      </div>
    )
  )
}

export default GreetingList
