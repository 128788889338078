import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { UserContext } from "../../../context/UserContext"
import { toast } from "react-toastify"
import axios from "axios"
import { API_URL, messageToToasts } from "../../../utils"
import i18next from "i18next"

const PracticeStringField = ({
  practice,
  setPractice,
  field,
  labelCode = "",
}) => {
  const { t } = useTranslation("data")
  const {
    user: { userType, email: userEmail },
  } = useContext(UserContext)

  const [value, setValue] = useState(practice?.[field])
  const [oldValue, setOldValue] = useState(practice?.[field])
  const [mode, setMode] = useState("show")

  useEffect(() => {
    setValue(practice?.[field])
  }, [practice, field])

  const edit = () => {
    setOldValue(value)
    setMode("edit")
  }

  const cancel = () => {
    setValue(oldValue)
    setMode("show")
  }

  const update = async () => {
    try {
      const resp = await axios.post(`${API_URL}/practice/update`, {
        ...practice,
        [field]: value,
        userEmail,
        changedField: field,
      })

      const { status, message, practice: upd_practice } = resp.data
      if (status === "failed") return messageToToasts(message, i18next.language)

      setPractice(upd_practice)
      setMode("show")
    } catch (error) {
      toast.error(`System error: ${error}`)
    }
  }

  const showView = (
    <span>
      {value?.length > 0 ? (
        value
      ) : (
        <span className="has-text-grey">{t("practice.notFilled")}</span>
      )}
      &nbsp;
      {userType !== "admin" || (
        <FontAwesomeIcon icon="pen" className="pointer" onClick={edit} />
      )}
    </span>
  )

  const editView = (
    <div className="field is-fullwidth has-addons">
      <div className="control">
        <input
          type="text"
          className="input is-fullwidth is-small"
          value={value}
          onChange={e => setValue(e.target.value)}
        />
      </div>
      <div className="control">
        <button className="button is-small is-success px-3" onClick={update}>
          <FontAwesomeIcon icon="check" size="lg" />
        </button>
      </div>
      <div className="control">
        <button className="button is-small is-danger px-3" onClick={cancel}>
          <FontAwesomeIcon icon="times" size="lg" />
        </button>
      </div>
    </div>
  )

  return (
    <p>
      <strong>
        {labelCode?.length === 0
          ? t(`practice.${field}`)
          : t(`practice.${labelCode}`)}
      </strong>
      <br />
      {mode === "show" ? showView : editView}
    </p>
  )
}

export default PracticeStringField
