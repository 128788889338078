import { useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import { CommonDataContext } from "../../../context/CommonDataContext"
import usePlaces from "../../../context/usePlaces"
import PlacesList from "../../data/PlacesList"
import LoadingSpinner from "../../helpers/LoadingSpinner"

const PlacesPageView = () => {
  const { t } = useTranslation(["data", "ws"])
  const {
    courses: { loading: courLoading },
  } = useContext(CommonDataContext)
  const { loading, bachelors, masters } = usePlaces()
  const [mode, setMode] = useState("bachelor")

  return (
    <>
      <div className="box">
        <p className="title is-4 has-text-right">{t("places.title")}</p>
      </div>

      {loading || courLoading ? (
        <div className="box">
          <LoadingSpinner />
        </div>
      ) : (
        <>
          <div className="tabs is-centered">
            <ul>
              <li className={mode === "bachelor" ? " is-active" : ""}>
                <a href="#mode" onClick={() => setMode("bachelor")}>
                  {t("ws:usersPage.course.bachelor")}
                </a>
              </li>
              <li className={mode === "master" ? " is-active" : ""}>
                <a href="#mode" onClick={() => setMode("master")}>
                  {t("ws:usersPage.course.master")}
                </a>
              </li>
            </ul>
          </div>

          <PlacesList
            courses={mode === "bachelor" ? bachelors : masters}
            mode={mode}
          />
        </>
      )}
    </>
  )
}

export default PlacesPageView
