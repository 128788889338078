import axios from "axios"
import { useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import { AcademicProgrammeContext } from "../../../context/AcademicProgrammeContext"
import { API_URL, messageToToasts } from "../../../utils"

const ProjectWishNew = ({ projectID }) => {
  const { t, i18n } = useTranslation("data")
  const { currentProgramme: programmeID } = useContext(AcademicProgrammeContext)
  const [submitting, setSubmitting] = useState(false)
  const [wishText, setWishText] = useState("")

  const updateWishText = newVal => {
    if (newVal.length > 500) return

    setWishText(newVal)
  }

  const sendProjectWish = async () => {
    setSubmitting(true)

    if (!wishText.length) {
      setSubmitting(false)
      return toast.error(t("project.wish.emptyWishText"))
    }

    try {
      const data = {
        projectID,
        programmeID,
        wishText,
      }

      const resp = await axios.post(`${API_URL}/project/addWish`, data)
      setSubmitting(false)

      const { status, message } = resp.data
      if (status === "failed") return messageToToasts(message, i18n.language)

      toast.success(t("project.wish.sendSuccess"))
      setWishText("")
    } catch (error) {
      setSubmitting(false)
      toast.error("Server error!")
    }
  }

  return (
    <div className="box">
      <p className="title is-5">{t("project.wish.titleNew")}</p>
      <p>{t("project.wish.text")}</p>

      <div className="field mt-5">
        <p className="control">
          <textarea
            className="textarea"
            placeholder={t("project.wish.messagePlaceholder")}
            value={wishText}
            onChange={e => updateWishText(e.target.value)}
          ></textarea>
        </p>
        <p className="help">
          {t("project.wish.usedSymbols", { symbols: wishText.length })}
        </p>
      </div>

      <div className="field has-text-right">
        <p className="control">
          <button
            className="button is-link is-outlined is-capitalized"
            onClick={sendProjectWish}
            disabled={submitting}
          >
            {t("send")}
          </button>
        </p>
      </div>
    </div>
  )
}

export default ProjectWishNew
