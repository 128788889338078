import axios from "axios"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import { API_URL, useQuery } from "../utils"
import AuthLayout from "./helpers/AuthLayout"
import LangAuthSelector from "./helpers/LangAuthSelector"
import LoadingSpinner from "./helpers/LoadingSpinner"

const Validate = () => {
  const query = useQuery()

  const { t, i18n } = useTranslation()
  const [loading, setLoading] = useState(true)
  const [status, setStatus] = useState("")
  const [message, setMessage] = useState("")

  useEffect(
    () => {
      const postValidation = async () => {
        try {
          const data = { validationCode: query.get("token") }
          const resp = await axios.post(
            `${API_URL}/user/projectLeader/verifyEmail`,
            data
          )

          const { status, message } = resp.data
          setStatus(status)
          setMessage(message[i18n.language])
          setLoading(false)
        } catch (err) {
          setStatus("failed")
          setMessage(
            "Server error. Try to reload page or contact administrator"
          )
          setLoading(false)
        }
      }

      if (loading && status !== "ok") {
        postValidation()
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loading, query, status]
  )

  return (
    <AuthLayout>
      <h3 className="title is-4 has-text-centered pb-2">{t("appTitle")}</h3>
      <h4 className="subtitle has-text-centered capitalized">
        {t("validate")}
      </h4>
      {loading ? (
        <LoadingSpinner text="Идёт попытка верификации аккаунта!" />
      ) : (
        <>
          <div
            className={
              "message " + (status === "failed" ? "is-danger" : "is-link")
            }
          >
            <div className="message-body">
              {status === "failed" ? message : t("validateSuccess")}
            </div>
          </div>

          <p className="has-text-centered is-size- mt-5">
            <Link to="/" className="has-text-grey">
              {t("home")}
            </Link>
          </p>
        </>
      )}
      <hr />
      <LangAuthSelector />
    </AuthLayout>
  )
}

export default Validate
