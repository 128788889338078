import axios from "axios"
import i18next from "i18next"
import { useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import Select from "react-select"
import { toast } from "react-toastify"
import { CommonDataContext } from "../../../context/CommonDataContext"
import useGroups from "../../../context/useGroups"
import useProjectStudentsNoGroup from "../../../context/useProjectStudentsNoGroup"
import { UserContext } from "../../../context/UserContext"
import { API_URL, messageToToasts, yearsToText } from "../../../utils"
import FormEntry from "../../helpers/FormEntry"
import AsgnContextProvider from "./AsgnContext"
import AsgnSendEditted from "./AsgnSendEditted"
import GroupStudentsRow from "./GroupStudentsRow"
import GradingView from "../grading/GradingView"
import { StudentStatusHelp } from "./StudentStatus"

const GroupStudents = ({ group, refetch }) => {
  const { t } = useTranslation("data")
  const {
    user: { userType },
  } = useContext(UserContext)
  const {
    students: { dict: studDict },
    projectLeaders: { dict: plDict },
  } = useContext(CommonDataContext)
  const { students, refetch: studRefetch } = useProjectStudentsNoGroup(
    group?.projectID
  )
  const { groups } = useGroups()

  const [submitting, setSubmitting] = useState(false)

  const [selectedNewStudents, setSelectedNewStudents] = useState(null)

  const { assignments } = group

  const isAdminPL = ["admin", "projectLeader"].includes(userType)

  const leaderOptions = (group?.leadersID || []).map(leadID => ({
    value: leadID,
    label: plDict[leadID]?.fullName,
  }))

  const newStudentsOptions = students.map(st => ({
    value: st?.id,
    label: st?.fullName,
  }))

  const groupsOptions = (groups || [])
    .filter(gr => assignments?.[0]?.projectID === gr.projectID)
    .map(gr => ({
      value: gr?.id,
      label: gr?.theme,
    }))

  const enrichedAssignments = (assignments || [])
    .map(asgn => ({
      ...asgn,
      student: studDict[asgn?.studentID],
    }))
    .sort((a, b) =>
      (
        yearsToText(a?.student?.yearOfStudy) + " " + a?.student?.fullName || ""
      ).localeCompare(
        yearsToText(b?.student?.yearOfStudy) + " " + b?.student?.fullName || ""
      )
    )
  const lastAsgnID = enrichedAssignments[enrichedAssignments?.length - 1]?.id

  const addStudents = async () => {
    setSubmitting(true)

    try {
      const resp = await axios.post(`${API_URL}/group/addStudents`, {
        groupID: group?.id,
        studentsID: (selectedNewStudents || []).map(ss => ss.value),
      })

      const { status, message } = resp.data
      setSubmitting(false)

      if (status === "failed") return messageToToasts(message, i18next.language)

      setSelectedNewStudents(null)
      refetch(false)
      studRefetch()
    } catch (error) {
      setSubmitting(false)
      toast.error("Server error")
    }
  }

  return (
    <AsgnContextProvider
      asgns={enrichedAssignments}
      refetchGroup={refetch}
      group={group}
    >
      <GradingView projectID={group?.projectID} projectLang="ru" />
      {!isAdminPL || (
        <div className="box">
          <p className="title is-size-5">{t("group.addStudent")}</p>

          <hr />

          <div className="columns">
            <div className="column is-half">
              <FormEntry>
                <Select
                  options={newStudentsOptions}
                  value={selectedNewStudents}
                  onChange={setSelectedNewStudents}
                  placeholder={t("group.addStudentPlh")}
                  isMulti
                  isClearable
                />
              </FormEntry>
            </div>

            <div className="column is-half has-text-right">
              <FormEntry>
                <button
                  className="button is-link"
                  onClick={addStudents}
                  disabled={submitting}
                >
                  {t("group.addStudent")}
                </button>
              </FormEntry>
            </div>
          </div>
        </div>
      )}

      {!isAdminPL || (
        <div className="box">
          <StudentStatusHelp />
        </div>
      )}

      {!isAdminPL || <AsgnSendEditted />}

      <div className="box">
        <p className="title is-size-5">{t("group.student")}</p>

        <hr />

        {(enrichedAssignments || []).map(asgn => (
          <GroupStudentsRow
            key={asgn?.id}
            asgn={asgn}
            isAdminPL={isAdminPL}
            lastAsgnID={lastAsgnID}
            leaderOptions={leaderOptions}
            refetch={refetch}
            studRefetch={studRefetch}
            groupsOptions={groupsOptions}
            group={group}
          />
        ))}
      </div>
    </AsgnContextProvider>
  )
}

export default GroupStudents
