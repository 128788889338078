import { useTranslation } from "react-i18next"

const LangAuthSelector = () => {
  const { t, i18n } = useTranslation()

  return (
    <div className="has-text-centered has-text-grey mt-5">
      {t("appChangeLng")}:&nbsp;
      <span
        className={"pointer" + (i18n.language === "ru" ? " has-text-link" : "")}
        onClick={() => i18n.changeLanguage("ru")}
      >
        ru
      </span>
      &nbsp;
      <span
        className={"pointer" + (i18n.language === "en" ? " has-text-link" : "")}
        onClick={() => i18n.changeLanguage("en")}
      >
        en
      </span>
    </div>
  )
}

export default LangAuthSelector
