import { useContext } from "react"
import { useTranslation } from "react-i18next"
import { Link, Redirect, Route, Switch, useRouteMatch } from "react-router-dom"
import { UserContext } from "../../../context/UserContext"
import ProposalListView from "./ProposalListView"
import ProposalNewView from "./ProposalNewView"
import ProposalView from "./ProposalView"

const ProposalsPageView = () => {
  const { t } = useTranslation(["ws"])
  const { path, url } = useRouteMatch()
  const {
    user: { role },
  } = useContext(UserContext)

  const menu = (
    <div className="box">
      <aside className="menu">
        <p className="menu-label">{t("proposalsPage.proposals")}</p>
        <ul className="menu-list">
          {role === "leader" ? (
            <li>
              <Link to={url + "/new"}>{t("proposalsPage.proposalsNew")}</Link>
            </li>
          ) : null}

          <li>
            <Link to={url + "/listMain"}>{t("proposalsPage.mainList")}</Link>
          </li>

          <li>
            <Link to={url + "/listDraft"}>{t("proposalsPage.draftList")}</Link>
          </li>

          <li>
            <Link to={url + "/listArchive"}>
              {t("proposalsPage.archiveList")}
            </Link>
          </li>
        </ul>
      </aside>
    </div>
  )

  return (
    <div className="columns">
      <div className="column is-3">{menu}</div>
      <div className="column is-9">
        <Switch>
          <Route exact path={path}>
            <Redirect to={url + "/listMain"} />
          </Route>

          <Route path={path + "/new"}>
            {role === "leader" ? <ProposalNewView /> : <Redirect to={url} />}
          </Route>

          <Route path={path + "/listMain"}>
            <ProposalListView category="main" />
          </Route>

          <Route path={path + "/listDraft"}>
            <ProposalListView category="draft" />
          </Route>

          <Route path={path + "/listArchive"}>
            <ProposalListView category="archive" />
          </Route>

          <Route path={path + "/view/:proposalID"}>
            <ProposalView />
          </Route>
        </Switch>
      </div>
    </div>
  )
}

export default ProposalsPageView
