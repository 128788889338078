import { useTranslation } from "react-i18next"
import { Link, Redirect, Route, Switch, useRouteMatch } from "react-router-dom"
import ProposalListView from "../projectLeader/ProposalListView"
import ProposalView from "./ProposalView"

const ProposalsPageView = () => {
  const { t } = useTranslation(["ws"])
  const { path, url } = useRouteMatch()

  const menu = (
    <div className="box">
      <aside className="menu">
        <p className="menu-label">{t("proposalsPage.proposals")}</p>
        <ul className="menu-list">
          <li>
            <Link to={url + "/listPublished"}>
              {t("proposalsPage.requireReviewList") + " "}
            </Link>
          </li>

          <li>
            <Link to={url + "/listSentImprove"}>
              {t("proposalsPage.sentToImproveList")}
            </Link>
          </li>

          <li>
            <Link to={url + "/listArchive"}>
              {t("proposalsPage.archiveList")}
            </Link>
          </li>
        </ul>
      </aside>
    </div>
  )

  return (
    <div className="columns">
      <div className="column is-3">{menu}</div>
      <div className="column is-9">
        <Switch>
          <Route exact path={path}>
            <Redirect to={url + "/listPublished"} />
          </Route>

          <Route path={path + "/listPublished"}>
            <ProposalListView category="published" />
          </Route>

          <Route path={path + "/listSentImprove"}>
            <ProposalListView category="reviewed" />
          </Route>

          <Route path={path + "/listArchive"}>
            <ProposalListView category="archive" />
          </Route>

          <Route path={path + "/view/:proposalID"}>
            <ProposalView />
          </Route>
        </Switch>
      </div>
    </div>
  )
}

export default ProposalsPageView
