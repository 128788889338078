import { useTranslation } from "react-i18next"
import FormEntry from "../../helpers/FormEntry"
import ReactTooltip from "react-tooltip"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const ProposalNewStudents = ({ studentsMax, setStudentsMax }) => {
  const { t } = useTranslation("data")

  return (
    <FormEntry labelText={t("proposal.students")}>
      <ReactTooltip multiline={true} />
      <div className="columns">
        <div className="column is-half">
          <div className="field has-addons">
            <p className="control">
              <button className="button is-static">{t("proposal.b1")}</button>
            </p>
            <p className="control">
              <button
                className="button is-warning"
                data-tip={t("proposal.noLoad")}
              >
                <FontAwesomeIcon icon="info" />
              </button>
            </p>
            <p className="control is-expanded">
              <input
                className="input has-text-right"
                type="number"
                min={0}
                value={studentsMax.firstBachelor}
                onChange={e =>
                  setStudentsMax({
                    ...studentsMax,
                    firstBachelor: Number(e.target.value),
                  })
                }
              />
            </p>
          </div>

          <div className="field has-addons">
            <p className="control">
              <button className="button is-static">{t("proposal.b2")}</button>
            </p>
            <p className="control is-expanded">
              <input
                className="input has-text-right"
                type="number"
                min={0}
                value={studentsMax.secondBachelor}
                onChange={e =>
                  setStudentsMax({
                    ...studentsMax,
                    secondBachelor: Number(e.target.value),
                  })
                }
              />
            </p>
          </div>

          <div className="field has-addons">
            <p className="control">
              <button className="button is-static">{t("proposal.b3")}</button>
            </p>
            <p className="control is-expanded">
              <input
                className="input has-text-right"
                type="number"
                min={0}
                value={studentsMax.thirdBachelor}
                onChange={e =>
                  setStudentsMax({
                    ...studentsMax,
                    thirdBachelor: Number(e.target.value),
                  })
                }
              />
            </p>
          </div>
        </div>
        <div className="column is-half">
          <div className="field has-addons">
            <p className="control">
              <button className="button is-static">{t("proposal.b4")}</button>
            </p>
            <p className="control">
              <button
                className="button is-danger"
                data-tip={t("proposal.reqDegree")}
              >
                <FontAwesomeIcon icon="info" />
              </button>
            </p>
            <p className="control is-expanded">
              <input
                className="input has-text-right"
                type="number"
                min={0}
                value={studentsMax.fourthBachelor}
                onChange={e =>
                  setStudentsMax({
                    ...studentsMax,
                    fourthBachelor: Number(e.target.value),
                  })
                }
              />
            </p>
          </div>

          <div className="field has-addons">
            <p className="control">
              <button className="button is-static">{t("proposal.m1")}</button>
            </p>
            <p className="control">
              <button
                className="button is-danger"
                data-tip={t("proposal.reqDegree")}
              >
                <FontAwesomeIcon icon="info" />
              </button>
            </p>
            <p className="control is-expanded">
              <input
                className="input has-text-right"
                type="number"
                min={0}
                value={studentsMax.firstMaster}
                onChange={e =>
                  setStudentsMax({
                    ...studentsMax,
                    firstMaster: Number(e.target.value),
                  })
                }
              />
            </p>
          </div>

          <div className="field has-addons">
            <p className="control">
              <button className="button is-static">{t("proposal.m2")}</button>
            </p>
            <p className="control">
              <button
                className="button is-danger"
                data-tip={t("proposal.reqDegree")}
              >
                <FontAwesomeIcon icon="info" />
              </button>
            </p>
            <p className="control is-expanded">
              <input
                className="input has-text-right"
                type="number"
                min={0}
                value={studentsMax.secondMaster}
                onChange={e =>
                  setStudentsMax({
                    ...studentsMax,
                    secondMaster: Number(e.target.value),
                  })
                }
              />
            </p>
          </div>
        </div>
      </div>
    </FormEntry>
  )
}

export default ProposalNewStudents
