import axios from "axios"
import i18next from "i18next"
import { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { API_URL, messageToToasts } from "../utils"

const useGradings = projectID => {
  const [gradings, setGradings] = useState([])
  const [loading, setLoading] = useState(true)
  const [needRefetch, setNeedRefetch] = useState(true)

  useEffect(() => {
    const fetchGradings = async () => {
      try {
        const resp = await axios.get(
          `${API_URL}/grading/list?projectID=${projectID}`
        )
        const { status, message, gradings } = resp.data

        setLoading(false)
        if (status === "failed")
          return toast.error(messageToToasts(message, i18next.language))

        setGradings(gradings)
      } catch (error) {
        setLoading(false)
        toast.error("Server error")
      }
    }

    fetchGradings()
    setNeedRefetch(false)
  }, [needRefetch, projectID])

  const refetch = (needsReload = false) => {
    if (needsReload) setLoading(true)

    setNeedRefetch(true)
  }

  return { gradings, loading, refetch }
}

export default useGradings
