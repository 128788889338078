import axios from "axios"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useHistory, useParams } from "react-router"
import { toast } from "react-toastify"
import useReviews from "../../../context/useReviews"
import { API_URL, messageToToasts } from "../../../utils"
import ProposalApproval from "../../data/proposal/ProposalApproval"
import ProposalReview from "../../data/proposal/ProposalReview"

import ProposalInfo from "../../data/ProposalInfo"
import LoadingSpinner from "../../helpers/LoadingSpinner"
import Page404 from "../../helpers/Page404"

const ProposalView = () => {
  const history = useHistory()
  const { t, i18n } = useTranslation(["ws", "data"])
  const { proposalID } = useParams()

  const [needRefetch, setNeedRefetch] = useState(true)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)

  const [proposal, setProposal] = useState({})
  const { reviews } = useReviews(proposalID)

  useEffect(
    () => {
      const fetchProposal = async () => {
        try {
          const requestURL = `${API_URL}/project/proposal/info?proposalID=${proposalID}`
          const resp = await axios.get(requestURL)
          const { status, message, proposal } = resp.data

          if (status === "failed") {
            setError(true)
            return messageToToasts(message, i18n.language)
          }

          setProposal(proposal)
          setLoading(false)
          setNeedRefetch(false)
        } catch (err) {
          toast.error(
            "Server error. Try to reload page or contact administrator"
          )
        }
      }

      if (proposalID !== "" && needRefetch) {
        fetchProposal()
      }
    },
    // eslint-disable-next-line
    [proposalID, needRefetch]
  )

  if (error) return <Page404 />

  if (loading) {
    return (
      <div className="box">
        <LoadingSpinner text={t("proposalsPage.proposalLoading")} />
      </div>
    )
  }

  return (
    <>
      <div className="box">
        <div className="columns">
          <div className="column is-5">
            <span
              className="has-text-link pointer"
              onClick={() => history.goBack()}
            >
              {t("proposalsPage.backToList")}
            </span>
          </div>
          <div className="column is-7 has-text-right">
            <span className="title is-4">{proposal.title}</span>
          </div>
        </div>
      </div>

      {proposal.status === "awaiting" ? (
        <div className="message is-warning">
          <div className="message-body">
            <span className="is-size-5">
              {t("proposalsPage.needsApproval")}!
            </span>
          </div>
        </div>
      ) : null}

      {reviews.length ? (
        <ProposalReview reviews={reviews} proposal={proposal} />
      ) : null}

      <ProposalInfo proposal={proposal} />

      {proposal.status === "awaiting" ? (
        <div className="box">
          <p className="title is-5">{t("data:proposal.approvalTitle")}</p>
          <ProposalApproval proposal={proposal} />
        </div>
      ) : null}
    </>
  )
}

export default ProposalView
