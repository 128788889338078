import dayjs from "dayjs"
import { useTranslation } from "react-i18next"
import useJobs from "../../context/useJobs"
import LoadingSpinner from "../helpers/LoadingSpinner"

const JobsList = () => {
  const { t } = useTranslation("data")
  const { loading, jobs } = useJobs()

  const jobsTable = jobs.length ? (
    <div className="table-container">
      <table className="table is-fullwidth">
        <thead>
          <tr>
            <th>{t("job.name")}</th>
            <th>{t("job.status")}</th>
            <th>{t("job.latency")}</th>
            <th>{t("job.prevRun")}</th>
            <th>{t("job.nextRun")}</th>
          </tr>
        </thead>
        <tbody>
          {jobs.map(job => (
            <tr key={job.Id}>
              <th>{job?.JobRunner?.Name}</th>
              <td>{job?.JobRunner?.Status || "IDLE"}</td>
              <td>{job?.JobRunner?.Latency || "0ms"}</td>
              <td>
                {job?.Prev !== "0001-01-01T00:00:00Z"
                  ? dayjs(job?.Prev).format("DD/MM/YYYY HH:mm")
                  : "NO RUN AFTER RESTART"}
              </td>
              <td>{dayjs(job?.Next).format("DD/MM/YYYY HH:mm")}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  ) : (
    <p className="has-text-grey">{t("emptyList")}</p>
  )

  return (
    <div className="box">
      <p className="title is-5">{t("job.listTitle")}</p>
      {loading ? <LoadingSpinner text={t("loading.jobsList")} /> : jobsTable}
    </div>
  )
}

export default JobsList
