import React from "react"
import ReactDOM from "react-dom"
import { ToastContainer } from "react-toastify"
import { BrowserRouter } from "react-router-dom"

import "react-toastify/dist/ReactToastify.min.css"
import "react-mde/lib/styles/css/react-mde-all.css"
import "dayjs/locale/ru"
import "./index.scss"
import App from "./App"
import UserContextProvider from "./context/UserContext"

import "./i18n"
import "./fa"

ReactDOM.render(
  <React.StrictMode>
    <UserContextProvider>
      <BrowserRouter>
        <App />
        <ToastContainer
          position="bottom-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover
        />
      </BrowserRouter>
    </UserContextProvider>
  </React.StrictMode>,
  document.getElementById("root")
)
