import { useContext, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { Link, Route, Switch, useRouteMatch } from "react-router-dom"
import Select from "react-select"
import { AcademicProgrammeContext } from "../../context/AcademicProgrammeContext"
import { CommonDataContext } from "../../context/CommonDataContext"
import PaginatedApplicationsContextProvider from "../../context/PaginatedApplicationsContext"
import ProjectsFilterContextProvider from "../../context/ProjectsFilterContext"
import useProfile from "../../context/useProfile"
import { UserContext } from "../../context/UserContext"
import ApplicationStatisticsForProjects from "../data/application/ApplicationStatisticsForProjects"
import NavBar from "../helpers/NavBar"
import ProjectsPageView from "./academicManager/ProjectsPageView"
import HomeView from "./HomeView"
import ApplicationListView from "./academicManager/ApplicationListView"

const AcademicManagerWorkspace = () => {
  const { t, i18n } = useTranslation("ws")
  const { url, path } = useRouteMatch()
  const { user } = useContext(UserContext)
  const {
    programmes: { dict: progDict },
  } = useContext(CommonDataContext)
  const { currentProgramme, updateCurrentProgramme } = useContext(
    AcademicProgrammeContext
  )
  const { loading, profile } = useProfile(
    user.userType,
    user.userID,
    user.userID
  )

  useEffect(() => {
    if (
      profile?.curatedProgrammesID?.length &&
      (currentProgramme === "" ||
        !profile?.curatedProgrammesID.includes(currentProgramme))
    )
      updateCurrentProgramme(profile?.curatedProgrammesID[0])
  }, [profile?.curatedProgrammesID, updateCurrentProgramme, currentProgramme])

  const progOptions = loading
    ? []
    : profile.curatedProgrammesID.map(pr => ({
        value: pr,
        label: progDict[pr]?.title[i18n.language],
      }))

  return (
    <>
      <NavBar>
        <Link className="navbar-item" to={url + "/projects"}>
          {t("menu.adminProjects")}
        </Link>

        <Link className="navbar-item" to={url + "/appStats"}>
          {t("projectsPage.appStats")}
        </Link>

        <Link className="navbar-item" to={url + "/applications"}>
          {t("menu.studentApps")}
        </Link>
      </NavBar>

      {profile?.curatedProgrammesID?.length > 1 ? (
        <div className="columns pt-3 has-background-white-bis mb-0">
          <div className="column is-4"></div>

          <div className="column is-4">
            <Select
              options={progOptions}
              value={
                progOptions.find(opt => opt.value === currentProgramme) || null
              }
              onChange={opt => updateCurrentProgramme(opt.value)}
            />
          </div>

          <div className="column is-4"></div>
        </div>
      ) : null}

      <div
        className={
          "section" + (profile?.curatedProgrammesID?.length > 1 ? " pt-5" : "")
        }
      >
        <div className="container">
          <Switch>
            <Route exact path={path}>
              <HomeView />
            </Route>

            <Route path={path + "/projects"}>
              <ProjectsFilterContextProvider>
                <ProjectsPageView />
              </ProjectsFilterContextProvider>
            </Route>

            <Route path={path + "/appStats"}>
              <ApplicationStatisticsForProjects forManager={true} />
            </Route>

            <Route path={path + "/applications"}>
              <PaginatedApplicationsContextProvider>
                <ApplicationListView />
              </PaginatedApplicationsContextProvider>
            </Route>
          </Switch>
        </div>
      </div>
    </>
  )
}

export default AcademicManagerWorkspace
