import { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import Select from "react-select"
import { CommonDataContext } from "../../../context/CommonDataContext"
import { ProjectsFilterContext } from "../../../context/ProjectsFilterContext"
import { SettingsContext } from "../../../context/SettingsContext"
import { UserContext } from "../../../context/UserContext"
import { yearsOptions } from "../../../utils"
import FormEntry from "../../helpers/FormEntry"

const initialMixedFormatOption = {
  ru: [{ label: "Смешанные", value: "mixed" }],
  en: [{ label: "Mixed", value: "mixed" }],
}

const initialLanguageOptions = [
  { label: "Русский", value: "ru" },
  { label: "English", value: "en" },
  { label: "Mixed", value: "ru-en" },
]

const ProjectFilter = ({
  allProjects,
  setViewedProjects,
  initialVisibility = true,
}) => {
  const { t, i18n } = useTranslation("data")
  const {
    user: { userType },
  } = useContext(UserContext)
  const { settings } = useContext(SettingsContext)
  const {
    divisions,
    topics,
    courses,
    projectLeaders: { dict: plDict },
  } = useContext(CommonDataContext)
  const {
    predicate,
    resetFilter,
    selectedDivision,
    setSelectedDivision,
    selectedParticipants,
    setSelectedParticipants,
    selectedFormat,
    setSelectedFormat,
    selectedLanguage,
    setSelectedLanguage,
    selectedTopics,
    setSelectedTopics,
    selectedCourses,
    setSelectedCourses,
    coursesOrAnd,
    setCoursesOrAnd,
    selectedYears,
    setSelectedYears,
    isOpenToExternal,
    setIsOpenToExternal,
    isShowRequested,
    setIsShowRequested,
    isShowRequestedSeats,
    setIsShowRequestedSeats,
    isShowOnlyRecomended,
    setIsShowOnlyRecomended,
    isShowOnlyFree,
    setIsShowOnlyFree,
    isOpenToOtherSchools,
    setIsOpenToOtherSchools,
    isOpenToOnlineProgrammes,
    setIsOpenToOnlineProgrammes,
  } = useContext(ProjectsFilterContext)

  useEffect(() => {
    const filteredProjects = allProjects.filter(predicate)
    setViewedProjects(filteredProjects)
    setFoundCount(filteredProjects.length)
  }, [allProjects, predicate, setViewedProjects])

  const plOptions = Object.values(plDict).map(pl => ({
    value: pl.id,
    label: pl.fullName,
  }))

  const formatOptions = [
    { label: "Online", value: "online" },
    { label: "Offline", value: "offline" },
    ...initialMixedFormatOption[i18n.language],
  ]

  const [showFilter, setShowFilter] = useState(initialVisibility)
  const [foundCount, setFoundCount] = useState(0)

  const toggleVisibility = () => {
    resetFilter()
    setShowFilter(!showFilter)
  }

  const usedFilter =
    selectedDivision ||
    selectedParticipants?.length ||
    selectedFormat ||
    selectedLanguage ||
    selectedTopics?.length ||
    selectedCourses?.length ||
    selectedYears?.length ||
    isShowRequested

  const onlyRequestedForm = (
    <>
      <hr />
      <FormEntry>
        <label className="checkbox">
          <input
            type="checkbox"
            defaultChecked={isShowRequested}
            onChange={e => setIsShowRequested(e.target.checked)}
          />{" "}
          {t("project.showOnlyRequested")}
        </label>
      </FormEntry>

      <FormEntry>
        <label className="checkbox">
          <input
            type="checkbox"
            defaultChecked={isShowRequestedSeats}
            onChange={e => setIsShowRequestedSeats(e.target.checked)}
          />{" "}
          {t("project.showOnlyRequestedSeats")}
        </label>
      </FormEntry>
    </>
  )

  const onlyMyProgrammeForm = (
    <>
      <hr />
      <p className="subtitle is-5">
        {t("project.showOnlyRecomendedText")}:{" "}
        <span
          className={
            "pointer" + (isShowOnlyRecomended ? " has-text-success" : "")
          }
          onClick={() => setIsShowOnlyRecomended(true)}
        >
          {t("project.onlyRecomended")}
        </span>
        /
        <span
          className={
            "pointer" + (!isShowOnlyRecomended ? " has-text-danger" : "")
          }
          onClick={() => setIsShowOnlyRecomended(false)}
        >
          {t("project.all")}
        </span>
        &nbsp;&nbsp;
        {settings.nonFreePlacesStudent === "allowed" ? (
          <>
            <span
              className={
                "pointer" + (isShowOnlyFree ? " has-text-success" : "")
              }
              onClick={() => setIsShowOnlyFree(true)}
            >
              {t("project.onlyFree")}
            </span>
            /
            <span
              className={
                "pointer" + (!isShowOnlyFree ? " has-text-warning" : "")
              }
              onClick={() => setIsShowOnlyFree(false)}
            >
              {t("project.all")}
            </span>
          </>
        ) : (
          <span className="has-text-success pointer">
            ({t("project.onlyFreePlaces")})
          </span>
        )}
      </p>
    </>
  )

  const onlyRecommendedByMeForm = (
    <>
      <hr />
      <p className="subtitle is-6">
        {t("project.showOnlyRecomendedText")}:{" "}
        <span
          className={
            "pointer" + (isShowOnlyRecomended ? " has-text-success" : "")
          }
          onClick={() => setIsShowOnlyRecomended(true)}
        >
          {t("project.onlyRecomendedByMe")}
        </span>
        /
        <span
          className={
            "pointer" + (!isShowOnlyRecomended ? " has-text-warning" : "")
          }
          onClick={() => setIsShowOnlyRecomended(false)}
        >
          {t("project.all")}
        </span>
      </p>
    </>
  )

  const filterForm = (
    <>
      <div className="columns">
        <div className="column is-half">
          <FormEntry labelText={t("project.division")}>
            <Select
              value={selectedDivision}
              onChange={setSelectedDivision}
              options={divisions.options[i18n.language]}
              placeholder={t("proposal.divisionPlaceholder")}
              isClearable
            />
          </FormEntry>

          <FormEntry labelText={t("project.participant")}>
            <Select
              value={selectedParticipants}
              onChange={setSelectedParticipants}
              options={plOptions}
              placeholder={t("project.participantPlaceholder")}
              isMulti
              isClearable
            />
          </FormEntry>

          <FormEntry labelText={t("proposal.format")}>
            <Select
              value={selectedFormat}
              onChange={setSelectedFormat}
              options={formatOptions}
              placeholder={t("proposal.formatPlaceholder")}
              isClearable
            />
          </FormEntry>

          {["online", "mixed"].includes(selectedFormat?.value) ? (
            <FormEntry>
              <p>
                {t("proposal.externalStudents")}: &nbsp;
                <span
                  className={
                    "pointer" +
                    (isOpenToExternal === "all" ? " has-text-success" : "")
                  }
                  onClick={() => setIsOpenToExternal("all")}
                >
                  {t("project.all")}
                </span>
                /
                <span
                  className={
                    "pointer is-lowercase" +
                    (isOpenToExternal === "true" ? " has-text-success" : "")
                  }
                  onClick={() => setIsOpenToExternal("true")}
                >
                  {t("yes")}
                </span>
                /
                <span
                  className={
                    "pointer is-lowercase" +
                    (isOpenToExternal === "false" ? " has-text-success" : "")
                  }
                  onClick={() => setIsOpenToExternal("false")}
                >
                  {t("no")}
                </span>
              </p>
            </FormEntry>
          ) : null}

          {["online", "mixed"].includes(selectedFormat?.value) ? (
            <FormEntry>
              <p>
                {t("proposal.isOpenToOnlineProgrammes")}: &nbsp;
                <span
                  className={
                    "pointer" +
                    (isOpenToOnlineProgrammes === "all"
                      ? " has-text-success"
                      : "")
                  }
                  onClick={() => setIsOpenToOnlineProgrammes("all")}
                >
                  {t("project.all")}
                </span>
                /
                <span
                  className={
                    "pointer is-lowercase" +
                    (isOpenToOnlineProgrammes === "true"
                      ? " has-text-success"
                      : "")
                  }
                  onClick={() => setIsOpenToOnlineProgrammes("true")}
                >
                  {t("yes")}
                </span>
                /
                <span
                  className={
                    "pointer is-lowercase" +
                    (isOpenToOnlineProgrammes === "false"
                      ? " has-text-success"
                      : "")
                  }
                  onClick={() => setIsOpenToOnlineProgrammes("false")}
                >
                  {t("no")}
                </span>
              </p>
            </FormEntry>
          ) : null}

          {selectedFormat?.value ? (
            <FormEntry>
              <p>
                {t("proposal.isOpenToExternal")}: &nbsp;
                <span
                  className={
                    "pointer" +
                    (isOpenToOtherSchools === "all" ? " has-text-success" : "")
                  }
                  onClick={() => setIsOpenToOtherSchools("all")}
                >
                  {t("project.all")}
                </span>
                /
                <span
                  className={
                    "pointer is-lowercase" +
                    (isOpenToOtherSchools === "true" ? " has-text-success" : "")
                  }
                  onClick={() => setIsOpenToOtherSchools("true")}
                >
                  {t("yes")}
                </span>
                /
                <span
                  className={
                    "pointer is-lowercase" +
                    (isOpenToOtherSchools === "false"
                      ? " has-text-success"
                      : "")
                  }
                  onClick={() => setIsOpenToOtherSchools("false")}
                >
                  {t("no")}
                </span>
              </p>
            </FormEntry>
          ) : null}

          <FormEntry labelText={t("proposal.lang")}>
            <Select
              value={selectedLanguage}
              onChange={setSelectedLanguage}
              options={initialLanguageOptions}
              placeholder={t("proposal.langPlaceholder")}
              isClearable
            />
          </FormEntry>
        </div>

        <div className="column is-half">
          <FormEntry labelText={t("proposal.topics")}>
            <Select
              value={selectedTopics}
              onChange={setSelectedTopics}
              options={topics.options[i18n.language]}
              placeholder={t("project.topicPlaceholder")}
              isMulti
              isClearable
            />
          </FormEntry>

          <FormEntry labelText={t("proposal.recomendedCourses")}>
            <Select
              value={selectedCourses}
              onChange={setSelectedCourses}
              options={courses.options[i18n.language]}
              placeholder={t("project.coursePlaceholder")}
              isMulti
              isClearable
            />
            {selectedCourses?.length > 1 ? (
              <p className="help">
                {t("project.courseOrAnd")}{" "}
                <span
                  onClick={() => setCoursesOrAnd("or")}
                  className={
                    "pointer" +
                    (coursesOrAnd === "or" ? " has-text-success" : "")
                  }
                >
                  {t("project.or")}
                </span>
                /
                <span
                  onClick={() => setCoursesOrAnd("and")}
                  className={
                    "pointer" +
                    (coursesOrAnd === "and" ? " has-text-success" : "")
                  }
                >
                  {t("project.and")}
                </span>
              </p>
            ) : null}
          </FormEntry>

          <FormEntry labelText={t("project.years")}>
            <Select
              value={selectedYears}
              onChange={setSelectedYears}
              options={yearsOptions[i18n.language]}
              placeholder={t("project.yearsPlaceholder")}
              isMulti
              isClearable
            />
          </FormEntry>
        </div>
      </div>

      {userType === "academicManager" ? onlyRequestedForm : null}
      {userType === "academicManager" ? onlyRecommendedByMeForm : null}

      {userType === "student" ? onlyMyProgrammeForm : null}

      {usedFilter ? (
        <>
          <hr />

          <FormEntry>
            <button
              className="button is-link is-outlined is-fullwidth"
              onClick={resetFilter}
            >
              {t("project.resetFilter")}
            </button>
          </FormEntry>
        </>
      ) : null}
    </>
  )

  return (
    <>
      <div className="box">
        <div className="columns">
          <div className="column is-8">
            <p className="title is-5">{t("project.filter")}</p>
          </div>

          <div className="column is-4 has-text-right has-text-grey">
            <span className="pointer" onClick={toggleVisibility}>
              {showFilter ? t("hide") : t("show")}
            </span>
          </div>
        </div>
        {showFilter ? filterForm : null}
      </div>

      {usedFilter ? (
        <article
          className={
            "message" + (foundCount > 0 ? " is-success" : " is-warning")
          }
        >
          <div className="message-body">
            <p className="has-text-centered">
              {foundCount
                ? t("project.filteredResults") + ": " + foundCount
                : t("project.noResults")}
            </p>
          </div>
        </article>
      ) : null}
    </>
  )
}

export default ProjectFilter
