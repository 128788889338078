import { createContext, useContext, useEffect, useState } from "react"
import useBlogPosts from "./useBlogPosts"
import useMeetings from "./useMeetings"

export const BlogPostContext = createContext()

const BlogPostContextProvider = ({ children, projectID }) => {
  const [currentPage, setCurrentPage] = useState(1)
  const { posts, loading, refetch, pageCount } = useBlogPosts(
    currentPage,
    projectID
  )
  const {
    meetings,
    loading: meetingLoading,
    refetch: meetingRefetch,
  } = useMeetings(projectID)

  useEffect(() => {
    if (currentPage > pageCount) setCurrentPage(1)
  }, [currentPage, pageCount])

  const value = {
    posts,
    loading,
    refetch,
    currentPage,
    setCurrentPage,
    pageCount,
    meetings,
    meetingLoading,
    meetingRefetch,
  }

  return (
    <BlogPostContext.Provider value={value}>
      {children}
    </BlogPostContext.Provider>
  )
}

export const useBlogPostContext = () => {
  const context = useContext(BlogPostContext)

  return context
}

export default BlogPostContextProvider
