import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import axios from "axios"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import { API_URL, messageToToasts } from "../../utils"
import Submitting from "../helpers/Submitting"

const FromNewCSV = ({ dataModel, setNeedRefetch }) => {
  const { t, i18n } = useTranslation("data")
  const [file, setFile] = useState({ name: "" })
  const [keyFI, setKeyFI] = useState(Math.random().toString())
  const [submitting, setSubmitting] = useState(false)

  const createCourse = async () => {
    setSubmitting(true)

    try {
      const formData = new FormData()
      formData.append("file", file)

      const resp = await axios.post(
        `${API_URL}/${dataModel}s/addFromCSV`,
        formData
      )
      const { status, message, errNum } = resp.data

      if (status === "failed") {
        setSubmitting(false)
        return messageToToasts(message, i18n.language)
      }

      toast.success(t("course.fileAddSuccess") + errNum)
      setNeedRefetch(true)
      setFile({ name: "" })
      setSubmitting(false)
      setKeyFI(Math.random().toString())
    } catch (err) {
      toast.error("Server error. Try to reload page or contact administrator")
    }
  }

  return (
    <div className="box">
      <p className="title is-5">{t("course.newTitleCSV")}</p>

      <div className="file has-name is-fullwidth">
        <label className="file-label">
          <input
            className="file-input"
            type="file"
            name="file"
            key={keyFI}
            onChange={e => setFile(e.target.files[0])}
          />
          <span className="file-cta">
            <span className="file-icon">
              <FontAwesomeIcon icon="upload" />
            </span>
            <span className="file-label">{t("course.chooseFile")}</span>
          </span>
          <span className="file-name">
            {file?.name ? file.name : t("course.chooseFile")}
          </span>
        </label>
      </div>
      <p className="help mb-5">{t(`${dataModel}.csvHelpText`)}</p>

      <Submitting submitting={submitting}>
        <button
          className="button is-link is-outlined is-fullwidth"
          onClick={createCourse}
        >
          {t("course.newTitleCSV")}
        </button>
      </Submitting>
    </div>
  )
}

export default FromNewCSV
