import { useState } from "react"
import PracticeList from "./PracticeList"
import { useTranslation } from "react-i18next"
import PracticeStudentList from "./PracticeStudentList"
import { useLocation } from "react-router-dom"

const PracticeAdmin = () => {
  const { hash } = useLocation()

  const { t } = useTranslation("data")
  const [mode, setMode] = useState(hash ? hash.replace("#", "") : "apps")

  return (
    <>
      <div className="is-centered tabs">
        <ul>
          <li className={mode === "apps" ? " is-active" : ""}>
            <a href="#apps" onClick={() => setMode("apps")}>
              {t("practice.appsList")}
            </a>
          </li>
          <li className={mode === "students" ? " is-active" : ""}>
            <a href="#students" onClick={() => setMode("students")}>
              {t("practice.studentsList")}
            </a>
          </li>
        </ul>
      </div>

      {mode !== "apps" || <PracticeList />}

      {mode !== "students" || <PracticeStudentList />}
    </>
  )
}

export default PracticeAdmin
