import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import axios from "axios"
import i18next from "i18next"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import { API_URL, messageToToasts } from "../../../utils"

const GradeSheetUpload = ({ asgnID, refetch, mode = "add" }) => {
  const { t } = useTranslation("data")

  const [file, setFile] = useState({ name: "" })
  const [sub, setSub] = useState(false)

  const uploadGradeSheet = async () => {
    setSub(true)

    try {
      const formData = new FormData()
      formData.append("file", file)
      formData.append("asgnID", asgnID)
      formData.append("mode", mode)

      const resp = await axios.post(
        `${API_URL}/group/uploadGradeSheet`,
        formData
      )
      const { status, message } = resp.data

      if (status === "failed") {
        setSub(false)
        return messageToToasts(message, i18next.language)
      }

      toast.success(t("group.uploadSheetSuccess"))
      setFile({ name: "" })
      setSub(false)

      refetch(false)
    } catch (err) {
      setSub(false)
      toast.error("Server error. Try to reload page or contact administrator")
    }
  }

  return (
    <div>
      <div className="file has-name is-fullwidth">
        <label className="file-label">
          <input
            className="file-input"
            type="file"
            name="file"
            onChange={e => setFile(e.target.files[0])}
          />
          <span className="file-cta">
            <span className="file-icon">
              <FontAwesomeIcon icon="upload" />
            </span>
            <span className="file-label">{t("course.chooseFile")}</span>
          </span>
          <span className="file-name">
            {file?.name ? file.name : t("course.chooseFile")}
          </span>
        </label>
      </div>

      <button
        className="button is-link is-outlined is-fullwidth"
        onClick={uploadGradeSheet}
        disabled={sub || !file?.name?.length}
      >
        {t("group.uploadGradeSheet")}
      </button>

      <p className="help">{t("group.gradeSheetFormat")}</p>
    </div>
  )
}

export default GradeSheetUpload
