import { useTranslation } from "react-i18next"
import { userTypesOptions } from "../../../utils"
import UserList from "../../data/UserList"

const UsersListView = ({ userType }) => {
  const { t } = useTranslation(["ws", "translation"])
  const userTypeValue = userTypesOptions.find(uto => uto.value === userType)
    .value

  return (
    <>
      <div className="box has-text-right title is-4">
        {t("usersPage.userList.title")}.{" "}
        {t(`translation:userTypePlural.${userTypeValue}`)}
      </div>
      <UserList userType={userType} />
    </>
  )
}

export default UsersListView
