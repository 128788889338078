import axios from "axios"
import i18next from "i18next"
import { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { API_URL, messageToToasts } from "../utils"

const useGroupsForProject = projectID => {
  const [groups, setGroups] = useState([])
  const [loading, setLoading] = useState(true)
  const [needRefetch, setNeedRefetch] = useState(true)

  useEffect(() => {
    const fetchGroups = async () => {
      try {
        const resp = await axios.get(
          `${API_URL}/group/listForProject?projectID=${projectID}`
        )
        const { status, message, groups } = resp.data

        setLoading(false)
        if (status === "failed")
          return toast.error(messageToToasts(message, i18next.language))

        setGroups(groups)
      } catch (error) {
        setLoading(false)
        toast.error("Server error")
      }
    }

    fetchGroups()
    setNeedRefetch(false)
  }, [needRefetch, projectID])

  const refetch = (needsReload = true) => {
    if (needsReload) setLoading(true)

    setNeedRefetch(true)
  }

  return { groups, loading, refetch }
}

export default useGroupsForProject
