import { useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import { ProjectsContext } from "../../../context/ProjectsContext"
import ProjectFilter from "../../data/project/ProjectFilter"
import ProjectList from "../../data/project/ProjectList"
import LoadingSpinner from "../../helpers/LoadingSpinner"

const ProjectListView = () => {
  const { t } = useTranslation("ws")
  const { projects, projectsLoading } = useContext(ProjectsContext)

  const [viewedProjects, setViewedProjects] = useState([])

  if (projectsLoading)
    return (
      <div className="box">
        <LoadingSpinner />
      </div>
    )

  return (
    <>
      <div className="box has-text-right title is-4">
        {t("projectsPage.list")}
      </div>

      <ProjectFilter
        allProjects={projects}
        setViewedProjects={setViewedProjects}
      />

      <ProjectList projects={viewedProjects} />
    </>
  )
}

export default ProjectListView
