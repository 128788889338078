import i18next from "i18next"
import { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import Select from "react-select"
import { CommonDataContext } from "../../../context/CommonDataContext"
import { SettingsContext } from "../../../context/SettingsContext"
import LoadingSpinner from "../../helpers/LoadingSpinner"

const SettingsBlockedProgrammes = ({ isModerator }) => {
  const { t, i18n } = useTranslation("data")
  const { settings, updateSetting } = useContext(SettingsContext)
  const {
    programmes: { options, loading },
  } = useContext(CommonDataContext)
  const [selectedBlockedList, setSelectedBlockedList] = useState(null)

  useEffect(() => {
    const programmesList = settings?.blockedProgrammes?.split(",")
    if (programmesList?.length === 0) setSelectedBlockedList(null)

    setSelectedBlockedList(
      (programmesList || []).map(progID =>
        (options[i18next.language] || []).find(opt => opt.value === progID)
      )
    )
  }, [settings.blockedProgrammes, options])

  const updateList = async option => {
    if (option?.length > 0)
      updateSetting(
        "blockedProgrammes",
        option.map(opt => opt?.value).join(",")
      )
    else updateSetting("blockedProgrammes", "")
  }

  if (loading) return <LoadingSpinner />

  return (
    <div className="box">
      <p className="title is-5">{t("settings.blockedTitle")}</p>

      <hr />

      <div className="columns">
        <div className="column is-half">
          <strong>{t("settings.blockedList")}</strong>
        </div>

        <div className="column is-half">
          <Select
            isMulti
            isClearable
            isDisabled={isModerator}
            placeholder={t("settings.noBlocked")}
            options={options[i18n.language]}
            value={selectedBlockedList}
            onChange={updateList}
          />
        </div>
      </div>
    </div>
  )
}

export default SettingsBlockedProgrammes
