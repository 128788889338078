import axios from "axios"
import i18next from "i18next"
import { useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import { toast } from "react-toastify"
import { CommonDataContext } from "../../../context/CommonDataContext"
import useStudentAsgn from "../../../context/useStudentAsgn"
import { API_URL, messageToToasts } from "../../../utils"
import LoadingSpinner from "../../helpers/LoadingSpinner"
import MathMarkdown from "../../helpers/MathMarkdown"
import StudentStatus from "./StudentStatus"
import GradingView from "../grading/GradingView"

const AsgnStudent = () => {
  const { t } = useTranslation("data")
  const {
    projectLeaders: { dict: plDict },
  } = useContext(CommonDataContext)
  const { loading, hasAsgn, asgn, refetch } = useStudentAsgn()

  const [submitting, setSubmitting] = useState(false)

  if (loading)
    return (
      <div className="box">
        <LoadingSpinner />
      </div>
    )

  if (!hasAsgn)
    return (
      <div className="box">
        <p className="has-text-grey">{t("group.noAsgn")}</p>
      </div>
    )

  const pl = plDict[asgn?.leaderID]

  const textField = field => (
    <div className="box">
      <p className="title is-5">{t(`group.${field}`)}</p>

      <hr />

      {asgn?.[field] ? (
        <div className="content" style={{ whiteSpace: "pre-wrap" }}>
          {asgn?.[field]}
        </div>
      ) : (
        <span className="has-text-grey">{t("group.noInfo")}</span>
      )}
    </div>
  )

  const approveAsgn = async () => {
    setSubmitting(true)

    try {
      const resp = await axios.post(`${API_URL}/group/approveAssignment`, {
        asgnID: asgn?.id,
      })
      const { status, message } = resp.data
      setSubmitting(false)

      if (status === "failed") return messageToToasts(message, i18next.language)

      refetch(false)
    } catch (error) {
      setSubmitting(false)
      toast.error("Server error: " + error)
    }
  }

  return (
    <>
      <GradingView projectID={asgn?.projectID} projectLang="ru" />
      <div className="box">
        <div className="columns">
          <div className="column is-7">
            <p>
              <strong>{t("group.myLeader")}:</strong>
              &nbsp;
              <Link
                to={`/r/common/profile?userID=${pl?.id}&userType=projectLeader`}
                target="_blank"
              >
                {pl?.fullName}
              </Link>
              &nbsp;(<a href={"mailto:" + pl?.email}>{pl?.email}</a>)
            </p>

            <p>
              <strong>
                {t("group.statusHelp")}:&nbsp;
                <StudentStatus status={asgn?.status}>
                  {t(`group.${asgn?.status || "notAssigned"}`)}
                </StudentStatus>
              </strong>
            </p>
          </div>

          <div className="column is-5">
            {asgn?.status !== "awaiting" || (
              <div className="field is-grouped is-grouped-right">
                <p className="control">
                  <button
                    className="button is-success"
                    onClick={approveAsgn}
                    disabled={submitting}
                  >
                    {t("group.approve")}
                  </button>
                </p>
              </div>
            )}
          </div>
        </div>
      </div>

      {["notAssigned", ""].includes(asgn?.status) ? (
        <div className="box">
          <p className="has-text-grey">{t("group.noAsgn")}</p>
        </div>
      ) : (
        <>
          <div className="box">
            <p className="title is-5">{t("group.myTheme")}</p>
            <hr />
            <p className="is-size-5">{asgn?.theme}</p>
          </div>

          <div className="box">
            <p className="title is-5">{t("group.myTasks")}</p>
            <hr />
            {asgn?.tasks ? (
              <MathMarkdown styleClass="content">{asgn?.tasks}</MathMarkdown>
            ) : (
              <span className="has-text-grey">{t("group.noInfo")}</span>
            )}
          </div>

          {textField("requirements")}

          <div className="columns">
            <div className="column is-half">{textField("reportFormat")}</div>

            <div className="column is-half">{textField("presentation")}</div>
          </div>
        </>
      )}
    </>
  )
}

export default AsgnStudent
