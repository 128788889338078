import { useTranslation } from "react-i18next"
import ProposalList from "../../data/ProposalList"

const ProposalListView = ({ category }) => {
  const { t } = useTranslation("ws")

  return (
    <>
      <div className="box has-text-right title is-4">
        {category === "main" ? t("proposalsPage.mainList") : ""}
        {category === "draft" ? t("proposalsPage.draftList") : ""}
        {category === "published" ? t("proposalsPage.requireReviewList") : ""}
        {category === "reviewed" ? t("proposalsPage.sentToImproveList") : ""}
        {category === "archive" ? t("proposalsPage.archiveList") : ""}
      </div>

      <ProposalList category={category} />
    </>
  )
}

export default ProposalListView
