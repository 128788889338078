import { useTranslation } from "react-i18next"

const classFromStatus = status => {
  switch (status) {
    case "awaiting":
      return "has-text-link"
    case "draft":
      return "has-text-grey"
    case "reviewed":
      return "has-text-warning"
    case "accepted":
      return "has-text-success"
    default:
      return "has-text-danger"
  }
}
const ProposalStatus = ({ status }) => {
  const { t } = useTranslation("data")

  return (
    <span>
      <strong className={classFromStatus(status)}>
        {t(`proposal.${status}Status`)}
      </strong>
    </span>
  )
}

export default ProposalStatus
