import { useTranslation } from "react-i18next"

const AuthLayout = ({ children }) => {
  const { t } = useTranslation("auth")

  return (
    <div className="section mt-5">
      <div className="container">
        <div className="columns">
          <div className="column is-6 is-offset-3">
            <div className="box">{children}</div>

            <div className="box">
              <span className="has-text-grey">
                {t("problemAuth")}:{" "}
                <a href="mailto:fes-projects@hse.ru">fes-projects@hse.ru</a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AuthLayout
