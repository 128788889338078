import axios from "axios"
import publicIp from "public-ip"
import ReCAPTCHA from "react-google-recaptcha"
import { useContext, useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import { osName, osVersion, browserName } from "react-device-detect"
import { UserContext } from "../../context/UserContext"
import { API_URL, messageToToasts } from "../../utils"
import Submitting from "../helpers/Submitting"
import FormEntry from "../helpers/FormEntry"

const SITEKEY = "6LcWd44aAAAAAAMUSGtPpr_9j6hBI4Uhe6jPlnff"

const LoginForm = ({ userType }) => {
  const history = useHistory()
  const recaptchaRef = useRef()
  const { login } = useContext(UserContext)
  const {
    t,
    i18n: { language },
  } = useTranslation("auth")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [submitting, setSubmitting] = useState(false)

  const [needValidate, setNeedValidate] = useState(true)
  const [validated, setValidated] = useState(false)

  useEffect(() => {
    const fireCaptchaValidate = async () => {
      const token = await recaptchaRef.current.executeAsync()

      const resp = await axios.post(`${API_URL}/recap`, { token })
      const { status, message, validated } = resp.data

      if (status === "failed") return messageToToasts(message, language)

      setValidated(validated)
    }

    if (needValidate) {
      fireCaptchaValidate()
      setNeedValidate(false)
    }
  }, [needValidate, language])

  const signIn = async event => {
    event.preventDefault()
    setSubmitting(true)

    const clientIP = await publicIp.v4()

    const data = {
      email,
      password,
      platform: `${osName} ${osVersion} (${browserName})`,
      clientIP,
    }

    const resp = await axios.post(`${API_URL}/user/${userType}/auth`, data)
    const { status, message, jwt } = resp.data

    if (status === "failed") {
      setSubmitting(false)

      return messageToToasts(message, language)
    }

    login(jwt)
    setSubmitting(false)
    history.push("/")
  }

  return (
    <>
      <h4 className="subtitle has-text-centered capitalized">{t("signIn")}</h4>
      <form>
        <div className="field">
          <label className="label">Email</label>
          <div className="control">
            <input
              className="input"
              value={email}
              onChange={e => setEmail(e.target.value)}
              type="email"
              placeholder={`${userType.toLowerCase()}@${
                userType === "student" ? "edu." : ""
              }hse.ru`}
            />
          </div>
        </div>

        <div className="field">
          <label className="label">{t("formLabels.pwd")}</label>
          <div className="control">
            <input
              className="input"
              type="password"
              placeholder="********"
              value={password}
              onChange={e => setPassword(e.target.value)}
            />
          </div>
        </div>

        <FormEntry>
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={SITEKEY}
            size="invisible"
            onChange={() => setNeedValidate(true)}
          />
        </FormEntry>

        <div className="field has-text-centered mt-4">
          <Submitting submitting={submitting}>
            <p className="control">
              <button
                className="button is-link is-outlined is-fullwidth capitalized"
                type="submit"
                onClick={e => signIn(e)}
                disabled={!validated}
              >
                {t("signIn")}
              </button>
            </p>
          </Submitting>
        </div>
      </form>
    </>
  )
}

export default LoginForm
