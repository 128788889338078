import ReactMarkdown from "react-markdown"
import Tex from "@matejmazur/react-katex"
import math from "remark-math"
import "katex/dist/katex.min.css"

const MathMarkdown = ({ styleClass, children }) => {
  const renderers = {
    inlineMath: ({ value }) => <Tex math={value} />,
    math: ({ value }) => <Tex block math={value} />,
  }

  return (
    <ReactMarkdown
      plugins={[math]}
      renderers={renderers}
      className={styleClass + " content-img"}
    >
      {children}
    </ReactMarkdown>
  )
}

export default MathMarkdown
