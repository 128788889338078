import axios from "axios"
import { useContext } from "react"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import { CommonDataContext } from "../../../context/CommonDataContext"
import { ProjectsContext } from "../../../context/ProjectsContext"
import { UserContext } from "../../../context/UserContext"
import { API_URL, messageToToasts } from "../../../utils"

const ProjectRequests = () => {
  const { t, i18n } = useTranslation("data")
  const { currentProject, refetch } = useContext(ProjectsContext)
  const {
    user: { userType, userID },
  } = useContext(UserContext)
  const {
    programmes: { dict },
  } = useContext(CommonDataContext)

  const leadersProgramme =
    userType === "projectLeader" &&
    [
      currentProject.projectLeaderID,
      ...currentProject.projectColeadersID,
    ].includes(userID)

  const unrequestProgramme = async programmeID => {
    try {
      const data = {
        programmeID,
        projectID: currentProject.id,
      }

      const resp = await axios.post(`${API_URL}/project/unrequest`, data)

      const { status, message } = resp.data
      if (status === "failed") return messageToToasts(message, i18n.language)

      toast.success(t("project.unrequestSuccess"))
      refetch("projects")
    } catch (error) {
      toast.error("Server error!")
    }
  }

  return (currentProject?.requestedProgrammesID || []).map(rp => (
    <article className="message is-warning" key={rp}>
      <div className="message-body">
        <div className="columns">
          <div className="column is-9">
            {t("project.requestProg") + ": "}
            <strong>{dict[rp]?.title[i18n.language]}</strong>
          </div>

          <div className="column is-3 has-text-right">
            {userType === "admin" || leadersProgramme ? (
              <span className="pointer" onClick={() => unrequestProgramme(rp)}>
                {t("remove")}
              </span>
            ) : null}
          </div>
        </div>
      </div>
    </article>
  ))
}

export default ProjectRequests
