import { useContext } from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import { CommonDataContext } from "../../context/CommonDataContext"
import MathMarkdown from "../helpers/MathMarkdown"
import MaxStudents from "./proposal/MaxStudents"

const ProposalInfo = ({ proposal }) => {
  const { t, i18n } = useTranslation("data")
  const {
    projectLeaders: { dict: plDict },
    divisions: { dict: divDict },
    courses: { dict: courDict },
    topics: { dict: topicsDict },
  } = useContext(CommonDataContext)

  const {
    lang,
    division,
    titleEN,
    format,
    isOpenToExternal,
    description,
    topics,
    recomendedCourses,
    maxStudents,
    loadSeats,
    externalSeats,
  } = proposal

  const projectLeader = plDict[proposal.projectLeaderID]

  const coleaders = proposal.projectColeaders
    .map(clID => ({
      id: clID,
      fullName: plDict[clID]?.fullName,
      workPlace:
        plDict[clID]?.workPlace === "hse"
          ? t("proposal.hse")
          : plDict[clID]?.workPlace,
    }))
    .filter(cl => cl.fullName)

  const recBachelorCourses = recomendedCourses
    .map(rc => courDict[rc])
    .filter(rc => rc?.mode === "bachelor")

  const recMasterCourses = recomendedCourses
    .map(rc => courDict[rc])
    .filter(rc => rc?.mode === "master")

  return (
    <>
      <div className="box">
        <p className="title is-5">{t("proposal.projectInfo")}</p>
        <hr />

        {lang === "ru" && titleEN !== "" ? (
          <p className="subtitle is-6 mt-1">
            <strong>{t("proposal.titleEN")}: </strong>
            {titleEN}
          </p>
        ) : null}

        <p className="subtitle is-6 mt-1">
          <strong>{t("proposal.lang")}: </strong>
          {lang}
        </p>

        {proposal?.externalPartner?.length === 0 || (
          <p className="subtitle is-6 mt-1">
            <strong>{t("proposal.externalPartner")}: </strong>
            {proposal?.externalPartner}
          </p>
        )}

        <p className="subtitle is-6">
          <strong>{t("proposal.leader")}: </strong>
          <Link
            to={`/r/common/profile?userID=${projectLeader?.id}&userType=projectLeader`}
            target="_blank"
          >
            {projectLeader?.fullName}
          </Link>
        </p>

        <p className="subtitle is-6">
          <strong>{t("proposal.division")}: </strong>
          {divDict[division]?.title[i18n.language]}
        </p>

        {coleaders.length ? (
          <p className="subtitle is-6">
            <strong>{t("proposal.cle")}: </strong>
            {coleaders.map(cl => (
              <Link
                key={cl.id}
                to={`/r/common/profile?userID=${cl?.id}&userType=projectLeader`}
                target="_blank"
              >
                {cl.fullName + " (" + cl.workPlace + ") "}&nbsp;
              </Link>
            ))}
          </p>
        ) : null}

        {topics.length ? (
          <p className="subtitle is-6">
            <strong>{t("proposal.topics")}: </strong>
            {topics.map(rc => topicsDict[rc]?.title[i18n.language]).join("; ")}
          </p>
        ) : null}

        {recBachelorCourses.length ? (
          <p className="subtitle is-6">
            <strong>{t("proposal.recomendedBachelorCourses")}: </strong>
            {recBachelorCourses.map(rc => rc?.title[i18n.language]).join("; ")}
          </p>
        ) : null}

        {recMasterCourses.length ? (
          <p className="subtitle is-6">
            <strong>{t("proposal.recomendedMasterCourses")}: </strong>
            {recMasterCourses.map(rc => rc?.title[i18n.language]).join("; ")}
          </p>
        ) : null}

        <p className="subtitle is-6">
          <strong>{t("proposal.format")}: </strong>
          {format === "mixed" ? t("proposal.formatMixed") : format}
          {["online", "mixed"].includes(format) && !isOpenToExternal
            ? " (" + t("proposal.externalStudents") + ")"
            : null}
        </p>

        <hr />

        <p className="subtitle is-6">
          <strong>{t("proposal.students")}: </strong>
        </p>

        <MaxStudents
          maxStudents={maxStudents}
          loadSeats={loadSeats}
          externalSeats={externalSeats}
        />
      </div>

      <div className="box">
        <p className="title is-5">{t("proposal.description")}</p>
        <hr />
        <MathMarkdown styleClass="content">{description}</MathMarkdown>
      </div>
    </>
  )
}

export default ProposalInfo
