import { useTranslation } from "react-i18next"
import { Link, useRouteMatch } from "react-router-dom"

const ChooseRole = () => {
  const { url } = useRouteMatch()
  const { t } = useTranslation(["translation", "auth"])

  return (
    <>
      <h4 className="subtitle has-text-centered">{t("auth:loginAs")}</h4>

      <Link to={url + "/projectLeader"}>
        <button className="button is-link is-outlined is-fullwidth">
          {t("userType.projectLeader")}
        </button>
      </Link>

      <Link to={url + "/externalClient"}>
        <button className="button is-link is-outlined is-fullwidth mt-3">
          {t("userType.externalClient")}
        </button>
      </Link>

      <Link to={url + "/divisionManager"}>
        <button className="button is-link is-outlined is-fullwidth mt-3">
          {t("userType.divisionManager")}
        </button>
      </Link>

      <Link to={url + "/academicManager"}>
        <button className="button is-link is-outlined is-fullwidth mt-3">
          {t("userType.academicManager")}
        </button>
      </Link>

      <Link to={url + "/student"}>
        <button className="button is-link is-outlined is-fullwidth mt-3">
          {t("userType.student")}
        </button>
      </Link>

      <Link to={url + "/admin"}>
        <button className="button is-link is-outlined is-fullwidth mt-3">
          {t("userType.admin")}
        </button>
      </Link>
    </>
  )
}

export default ChooseRole
