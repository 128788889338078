import axios from "axios"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import { API_URL, messageToToasts } from "../utils"

const useProjects = (userType, yearOfStudy = "none", programmeID = "none") => {
  const { i18n } = useTranslation()
  const [loading, setLoading] = useState(true)
  const [projects, setProjects] = useState([])
  const [needRefetch, setNeedRefetch] = useState(true)

  useEffect(
    () => {
      const fetchProjects = async () => {
        try {
          let requestURL = ""
          if (userType === "admin")
            requestURL = `${API_URL}/project/listForAdmin`

          if (userType === "projectLeader")
            requestURL = `${API_URL}/project/listForLeader`

          if (userType === "academicManager" || userType === "student")
            requestURL = `${API_URL}/project/listForManager`

          if (userType === "divisionManager")
            requestURL = `${API_URL}/project/listForDivisionManager`

          const resp = await axios.get(requestURL)
          const { status, message, projects } = resp.data

          if (status === "failed")
            return messageToToasts(message, i18n.language)

          if (userType === "student")
            setProjects(
              projects.map(proj => ({
                ...proj,
                freePlaces: Math.max(
                  0,
                  proj.maxStudents[yearOfStudy] +
                    ((proj?.externalSeats || []).find(
                      seat => seat?.programmeID === programmeID
                    )?.students?.[yearOfStudy] || 0) +
                    ((proj?.loadSeats || []).find(
                      seat => seat?.programmeID === programmeID
                    )?.students?.[yearOfStudy] || 0) -
                    proj.acceptedStudents[yearOfStudy]
                ),
                maxForStudent:
                  proj.maxStudents[yearOfStudy] +
                  ((proj?.externalSeats || []).find(
                    seat => seat?.programmeID === programmeID
                  )?.students?.[yearOfStudy] || 0) +
                  ((proj?.loadSeats || []).find(
                    seat => seat?.programmeID === programmeID
                  )?.students?.[yearOfStudy] || 0),
              }))
            )
          else setProjects(projects)

          setLoading(false)
        } catch (error) {
          toast.error(
            "Server error. Try to reload page or contact administrator"
          )
        }
      }

      if (needRefetch) {
        if (projects.length === 0) setLoading(true)
        fetchProjects()
        setNeedRefetch(false)
      }
    },
    // eslint-disable-next-line
    [needRefetch, userType]
  )

  const updateProject = async (projectID, programmeID, mode) => {
    try {
      const data = { projectID, programmeID }
      const resp = await axios.post(
        `${API_URL}/project/${mode === "pull" ? "un" : ""}recomend`,
        data
      )

      const { status, message } = resp.data

      const prIdx = projects.findIndex(pr => pr.id === projectID)
      const newPrs = [...projects]

      if (mode === "push")
        newPrs[prIdx] = {
          ...projects[prIdx],
          recomendingProgrammesID: [
            ...projects[prIdx].recomendingProgrammesID,
            programmeID,
          ],
        }

      if (mode === "pull")
        newPrs[prIdx] = {
          ...projects[prIdx],
          recomendingProgrammesID: projects[
            prIdx
          ].recomendingProgrammesID.filter(item => item !== programmeID),
        }

      setProjects(newPrs)

      return { status, message }
    } catch (error) {
      console.log(error)
      return { status: "failed", message: "Server error!" }
    }
  }

  const unrequest = async (projectID, programmeID) => {
    try {
      const data = { projectID, programmeID }
      const resp = await axios.post(`${API_URL}/project/unrequest`, data)

      const { status, message } = resp.data
      setNeedRefetch(true)

      return { status, message }
    } catch (error) {
      console.log(error)
      return { status: "failed", message: "Server error!" }
    }
  }

  const refetchProjectsMode = async mode => {
    try {
      let requestURL = `${API_URL}/project/listForDivisionManager`
      if (mode === "all") requestURL = `${API_URL}/project/listForManager`

      const resp = await axios.get(requestURL)
      const { status, message, projects } = resp.data

      if (status === "failed") return messageToToasts(message, i18n.language)
      setProjects(projects)
    } catch (error) {
      toast.error("Server error")
    }
  }

  return {
    loading,
    projects,
    setNeedRefetch,
    updateProject,
    refetchProjectsMode,
    unrequest,
  }
}

export default useProjects
