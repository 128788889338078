import { useContext, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { ApplicationsStudentContext } from "../../../context/ApplicationsStudentContext"
import ApplicationInfo from "../../data/application/ApplicationInfo"

const ApplicationsPageView = () => {
  const { t } = useTranslation("data")
  const { applications, refetch } = useContext(ApplicationsStudentContext)

  useEffect(
    () => {
      refetch()
    },
    //eslint-disable-next-line
    []
  )

  return Object.values(applications)?.length ? (
    Object.values(applications).map(app => (
      <ApplicationInfo application={app} key={app.id} />
    ))
  ) : (
    <p>{t("emptyList")}</p>
  )
}

export default ApplicationsPageView
