import axios from "axios"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import { API_URL, messageToToasts } from "../utils"

const useTopics = (needRefetch, setNeedRefetch) => {
  const { i18n } = useTranslation()
  const [loading, setLoading] = useState(true)
  const [topics, setTopics] = useState({})
  const [topicOptions, setTopicOptions] = useState([])

  const switchEditMode = topicID => {
    const topic = topics[topicID]

    setTopics({
      ...topics,
      [topicID]: { ...topic, editMode: !topic.editMode },
    })
  }

  useEffect(
    () => {
      const fetchTopics = async () => {
        try {
          const requestURL = `${API_URL}/topics/list`
          const resp = await axios.get(requestURL)
          const { status, message, topics } = resp.data

          if (status === "failed")
            return messageToToasts(message, i18n.language)

          const ruOptions = topics.map(d => ({
            label: d.title.ru,
            value: d.id,
          }))
          const enOptions = topics.map(d => ({
            label: d.title.en,
            value: d.id,
          }))

          setTopics(
            topics.reduce(
              (obj, topic) =>
                Object.assign(obj, {
                  [topic.id]: { ...topic, editMode: false },
                }),
              {}
            )
          )
          setTopicOptions({ ru: ruOptions, en: enOptions })
          setLoading(false)
          setNeedRefetch(false)
        } catch (error) {
          toast.error(
            "Server error. Try to reload page or contact administrator"
          )
        }
      }

      if (needRefetch) {
        setLoading(true)
        fetchTopics()
      }
    },
    // eslint-disable-next-line
    [needRefetch, setNeedRefetch]
  )

  return { loading, topics, topicOptions, switchEditMode }
}

export default useTopics
