import { useContext } from "react"
import dayjs from "dayjs"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import { CommonDataContext } from "../../../context/CommonDataContext"
import { ProjectsContext } from "../../../context/ProjectsContext"
import { UserContext } from "../../../context/UserContext"
import { yearsOptions } from "../../../utils"
import FormEntry from "../../helpers/FormEntry"
import LoadingSpinner from "../../helpers/LoadingSpinner"
import ApplicationsActions from "./ApplicationsActions"
import ApplicationStatus from "./ApplicationStatus"
import ApplicationStudentInfo from "./ApplicationStudentInfo"
import ApplicationFreePlaces from "./ApplicationFreePlaces"

const ApplicationInfo = ({
  application,
  showProject = true,
  showStudentInfo = true,
  showActions = true,
}) => {
  const { t, i18n } = useTranslation("data")
  const {
    user: { userType },
  } = useContext(UserContext)
  const { projects } = useContext(ProjectsContext)
  const {
    students: { dict: students },
    programmes: { dict: progDict },
  } = useContext(CommonDataContext)

  const student = students[application.studentID]
  const project = projects.find(pr => pr.id === application.projectID)
  const projectLink = `/r/${userType}/projects/viewProject/${project?.id}`

  if (!student)
    return (
      <div className="box">
        <LoadingSpinner />
      </div>
    )

  const applicationOverflow =
    project?.acceptedStudents[student?.yearOfStudy] -
      project?.maxStudents[student?.yearOfStudy] >=
    0

  return (
    <div className="box">
      <p className="title is-5">
        {t("application.application") + " " + application.id}
      </p>
      <hr />

      <div className="columns">
        <div className="column is-6">
          {showProject ? (
            <p className="title is-6">
              {t("application.project") + ": "}
              <Link to={projectLink}>{project?.title}</Link>
            </p>
          ) : null}

          <p className="title is-6">
            {t("application.studentName") +
              ": " +
              student?.fullName +
              ` (${student?.fullNameEn})`}
          </p>

          <p className="title is-6">
            {t("application.studentEmail") + ": " + student?.email}
          </p>

          <p className="title is-6">
            {t("application.studentProgramme") +
              ": " +
              progDict[student?.programmeID]?.title[i18n.language]}
          </p>

          <p className="title is-6">
            {t("application.studentNextYear") +
              ": " +
              yearsOptions[i18n.language].find(
                opt => opt.value === student?.yearOfStudy
              )?.label}
          </p>

          <p className="title is-6">
            {t("application.appliedDate") +
              ": " +
              dayjs(application?.created).format("DD/MM/YYYY HH:mm:ss")}
          </p>

          {userType === "admin" && application.status === "acceptedByLeader" ? (
            <p className="title is-6">
              {t("application.aclDate") +
                ": " +
                dayjs(application?.alDate).format("DD/MM/YYYY HH:mm:ss")}
            </p>
          ) : null}
        </div>

        <div className="column is-6">
          <FormEntry labelText={t("application.motivationLetter")}>
            <textarea
              rows="6"
              defaultValue={
                application?.letter
                  ? application?.letter
                  : t("application.noMotivationLetter")
              }
              className="textarea"
              disabled
            />
          </FormEntry>

          {!["admin", "academicManager"].includes(userType) || (
            <FormEntry labelText={t("application.motivationLetterProgramme")}>
              <textarea
                rows="6"
                defaultValue={
                  application?.letterProgramme
                    ? application?.letterProgramme
                    : t("application.noMotivationLetter")
                }
                className="textarea"
                disabled
              />
            </FormEntry>
          )}
        </div>
      </div>

      {["admin", "projectLeader"].includes(userType) && showStudentInfo ? (
        <ApplicationStudentInfo studentEmail={student?.email} />
      ) : null}

      {["admin", "projectLeader"].includes(userType) &&
      application?.status === "awaiting" ? (
        <ApplicationFreePlaces
          application={application}
          project={project}
          student={student}
        />
      ) : null}

      {userType === "projectLeader" &&
      applicationOverflow &&
      application.status === "awaiting" ? (
        <>
          <hr />

          <div className="message is-warning">
            <div className="message-body">
              {t("application.applicationOverflowLeader")}
            </div>
          </div>
        </>
      ) : null}

      <hr />

      <div className="columns">
        <div className="column is-3">
          {/* TODO: create setting for the disabling */}
          {userType === "projectLeader" || (
            <strong>
              {t("application.priorityShort") + " " + application.priority}
            </strong>
          )}
        </div>

        <div className="column is-3">
          <ApplicationStatus status={application.status} />
        </div>

        <div className="column is-6">
          {!showActions || (
            <ApplicationsActions
              application={application}
              project={project}
              student={student}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default ApplicationInfo
