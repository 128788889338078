import { createContext, useEffect, useState } from "react"

export const AcademicProgrammeContext = createContext()

const AcademicProgrammeContextProvider = ({ children }) => {
  const [currentProgramme, setCurrentProgramme] = useState("")

  useEffect(() => {
    const cp = localStorage.getItem("currentProgramme")
    setCurrentProgramme(cp || "")
  }, [])

  const updateCurrentProgramme = programmeID => {
    setCurrentProgramme(programmeID)
    localStorage.setItem("currentProgramme", programmeID)
  }

  const value = {
    currentProgramme,
    updateCurrentProgramme,
  }
  return (
    <AcademicProgrammeContext.Provider value={value}>
      {children}
    </AcademicProgrammeContext.Provider>
  )
}

export default AcademicProgrammeContextProvider
