import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import axios from "axios"
import i18next from "i18next"
import { useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import Select from "react-select"
import { toast } from "react-toastify"
import { CommonDataContext } from "../../../context/CommonDataContext"
import { ProjectsContext } from "../../../context/ProjectsContext"
import useProjectStudentsNoGroup from "../../../context/useProjectStudentsNoGroup"
import { API_URL, messageToToasts } from "../../../utils"
import FormEntry from "../../helpers/FormEntry"
import LatexMarkdownForm from "../../helpers/LatexMarkdownForm"

const GroupNew = ({ refetch }) => {
  const { t } = useTranslation("data")
  const { currentProject } = useContext(ProjectsContext)
  const {
    projectLeaders: { dict: plDict },
  } = useContext(CommonDataContext)
  const {
    students,
    loading: studLoading,
    refetch: studRefetch,
  } = useProjectStudentsNoGroup(currentProject?.id)

  const [mode, setMode] = useState("view")
  const [sub, setSub] = useState(false)

  const [theme, setTheme] = useState("")
  const [target, setTarget] = useState("")
  const [tasks, setTasks] = useState("")
  const [selectedLeaders, setSelectedLeaders] = useState(null)
  const [selectedStudents, setSelectedStudents] = useState(null)
  const [requirements, setRequirements] = useState("")
  const [reportFormat, setReportFormat] = useState("")
  const [presentation, setPresentation] = useState("")

  const plOptions = Object.values([
    currentProject.projectLeaderID,
    ...currentProject?.projectColeadersID,
  ]).map(pl => ({
    value: pl,
    label: plDict[pl]?.fullName,
  }))

  const studOptions = (students || []).map(student => ({
    value: student?.id,
    label: student?.fullName,
  }))

  const resetForm = () => {
    setMode("view")

    setTheme("")
    setTasks("")
    setSelectedLeaders(null)
    setSelectedStudents(null)
    setRequirements("")
    setReportFormat("")
    setPresentation("")
    studRefetch()
  }

  const addNewGroup = async () => {
    setSub(true)

    try {
      const data = {
        projectID: currentProject?.id,
        leadersID: (selectedLeaders || []).map(opt => opt.value),
        studentsID: (selectedStudents || []).map(opt => opt.value),
        theme,
        target,
        tasks,
        requirements,
        reportFormat,
        presentation,
      }

      const resp = await axios.post(`${API_URL}/group/add`, data)
      const { status, message } = resp.data
      setSub(false)

      if (status === "failed") return messageToToasts(message, i18next.language)

      resetForm()
      refetch(false)
      toast.success(t("group.addSuccess"))
    } catch (error) {
      setSub(false)
      toast.error("Server error")
    }
  }

  if (mode === "view")
    return (
      <div className="box has-text-centered">
        <span
          className="is-size-5 pointer has-text-link"
          onClick={() => {
            setMode("edit")
          }}
        >
          <FontAwesomeIcon icon="plus" /> {t("group.addNew")}
        </span>
      </div>
    )

  return (
    <div className="box">
      <FormEntry labelText={t("group.theme")} helpText={t("req")}>
        <input
          type="text"
          className="input"
          placeholder={t("group.themePlh")}
          value={theme}
          onChange={e => setTheme(e.target.value)}
        />
      </FormEntry>

      <div className="columns">
        <div className="column is-half">
          <FormEntry labelText={t("group.leader")} helpText={t("req")}>
            <Select
              options={plOptions}
              placeholder={t("group.leaderPlh")}
              value={selectedLeaders}
              onChange={setSelectedLeaders}
              isMulti
              isClearable
            />
          </FormEntry>
        </div>

        <div className="column is-half">
          <FormEntry labelText={t("group.student")} helpText={t("req")}>
            <Select
              options={studOptions}
              placeholder={t("group.studentPlh")}
              value={selectedStudents}
              onChange={setSelectedStudents}
              isLoading={studLoading}
              isMulti
              isClearable
            />
          </FormEntry>
        </div>
      </div>

      <FormEntry labelText={t("group.target")}>
        <textarea
          className="textarea"
          placeholder={t("group.targetPlh")}
          value={target}
          onChange={e => setTarget(e.target.value)}
        />
      </FormEntry>

      <LatexMarkdownForm
        labelText={t("group.tasks")}
        value={tasks}
        setValue={setTasks}
        isReq={false}
      />

      <FormEntry labelText={t("group.requirements")}>
        <textarea
          className="textarea"
          placeholder={t("group.requirementsPlh")}
          value={requirements}
          onChange={e => setRequirements(e.target.value)}
        />
      </FormEntry>

      <div className="columns">
        <div className="column is-half">
          <FormEntry labelText={t("group.reportFormat")}>
            <textarea
              className="textarea"
              placeholder={t("group.reportFormatPlh")}
              value={reportFormat}
              onChange={e => setReportFormat(e.target.value)}
            />
          </FormEntry>
        </div>

        <div className="column is-half">
          <FormEntry labelText={t("group.presentation")}>
            <textarea
              className="textarea"
              placeholder={t("group.presentationPlh")}
              value={presentation}
              onChange={e => setPresentation(e.target.value)}
            />
          </FormEntry>
        </div>
      </div>

      <div className="field is-grouped is-grouped-right">
        <div className="control">
          <button
            className="button is-light"
            onClick={resetForm}
            disabled={sub}
          >
            {t("cancel")}
          </button>
        </div>

        <div className="control">
          <button
            className="button is-link"
            onClick={addNewGroup}
            disabled={sub}
          >
            {t("add")}
          </button>
        </div>
      </div>
    </div>
  )
}

export default GroupNew
