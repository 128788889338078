import axios from "axios"
import i18next from "i18next"
import { useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import { ProjectsContext } from "../../../context/ProjectsContext"
import { UserContext } from "../../../context/UserContext"
import { API_URL, messageToToasts } from "../../../utils"

const ProjectEnTitle = ({ lang, title, titleEN }) => {
  const { t } = useTranslation("data")
  const { user } = useContext(UserContext)
  const { currentProject } = useContext(ProjectsContext)

  const [submitting, setSubmitting] = useState(false)
  const [mode, setMode] = useState("view")
  const [newTitle, setNewTitle] = useState(titleEN)

  const changeTitleEn = async () => {
    setSubmitting(true)

    try {
      const resp = await axios.post(`${API_URL}/project/changeEnTitle`, {
        projectID: currentProject?.id,
        newTitle,
      })

      const { status, message } = resp.data
      setSubmitting(false)

      if (status === "failed") return messageToToasts(message, i18next.language)

      window.location.reload()
    } catch (error) {
      setSubmitting(false)
      toast.error("Server error")
    }
  }

  const projectLeaders = [
    currentProject?.projectLeaderID,
    ...(currentProject?.projectColeadersID || []),
  ]

  const validRights =
    user?.userType === "admin" ||
    (user?.userType === "projectLeader" &&
      projectLeaders.includes(user?.userID))

  if (mode === "edit")
    return (
      <div className="field has-addons">
        <p className="control is-expanded">
          <input
            type="text"
            className="input"
            value={newTitle}
            onChange={e => setNewTitle(e.target.value)}
          />
        </p>

        <p className="control">
          <button
            className="button is-dark"
            onClick={() => setMode("view")}
            disabled={submitting}
          >
            {t("cancel")}
          </button>
        </p>

        <p className="control">
          <button
            className="button is-link"
            onClick={changeTitleEn}
            disabled={submitting}
          >
            {t("update")}
          </button>
        </p>
      </div>
    )

  return (
    <p className="subtitle is-6 mt-1">
      <strong>{t("proposal.titleEN")}: </strong>
      {lang === "en" ? title : titleEN}
      {!(validRights && lang !== "en") || (
        <>
          &nbsp;(
          <span
            className="has-text-link pointer is-lowercase"
            onClick={() => setMode("edit")}
          >
            {t("update")}
          </span>
          )
        </>
      )}
    </p>
  )
}

export default ProjectEnTitle
