import { useContext } from "react"
import { useTranslation } from "react-i18next"
import useGreetings from "../../context/useGreetings"
import { UserContext } from "../../context/UserContext"
import GreetingList from "../data/greeting/GreetingList"
import StudentHomeGrade from "../data/groups/StudentHomeGrade"

const HomeView = () => {
  const { t } = useTranslation("data")
  const {
    user: { userType },
  } = useContext(UserContext)
  const { greetings } = useGreetings(userType)

  return (
    <>
      {userType !== "student" || <StudentHomeGrade />}

      {greetings.length === 0 ? (
        <div className="box">{t("greeting.welcome")}</div>
      ) : (
        <GreetingList greetings={greetings} />
      )}
    </>
  )
}

export default HomeView
