import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import axios from "axios"
import fileDownload from "js-file-download"
import { useState } from "react"
import { toast } from "react-toastify"
import { API_URL } from "../../../utils"

const ProjectStudentListExport = ({ projectID }) => {
  const [submitting, setSubmitting] = useState(false)

  const exportToXLSX = async () => {
    setSubmitting(true)

    try {
      const resp = await axios.get(
        API_URL + "/user/student/exportForProject?projectID=" + projectID,
        {
          headers: {
            "Content-Type":
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          },
          responseType: "arraybuffer",
        }
      )

      fileDownload(resp.data, "students-list.xlsx")
      setSubmitting(false)
    } catch (error) {
      setSubmitting(false)
      console.log(error)
      toast.error("Server error!")
    }
  }

  return (
    <button
      className="button is-link"
      onClick={exportToXLSX}
      disabled={submitting}
    >
      <FontAwesomeIcon icon="file-excel" size="lg" />
      &nbsp; xlsx
    </button>
  )
}

export default ProjectStudentListExport
