import axios from "axios"
import i18next from "i18next"
import { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { API_URL, messageToToasts } from "../utils"

const useProjectStudentsNoGroup = projectID => {
  const [students, setStudents] = useState([])
  const [loading, setLoading] = useState(true)
  const [needRefetch, setNeedRefetch] = useState(true)

  useEffect(() => {
    setNeedRefetch(true)
  }, [projectID])

  useEffect(() => {
    const fetchStudents = async () => {
      setLoading(true)

      try {
        const resp = await axios.get(
          `${API_URL}/group/listStudentsNoGroup?projectID=${projectID}`
        )
        const { status, message, students } = resp.data

        setLoading(false)
        if (status === "failed")
          return toast.error(messageToToasts(message, i18next.language))

        setStudents(students)
      } catch (error) {
        setLoading(false)
        toast.error("Server error")
      }
    }

    if (needRefetch && projectID?.length) {
      fetchStudents()
      setNeedRefetch(false)
    }
  }, [needRefetch, projectID])

  const refetch = () => setNeedRefetch(true)

  return { students, loading, refetch }
}

export default useProjectStudentsNoGroup
