import { useLocation } from "react-router-dom"
import { toast } from "react-toastify"

export const messageToToasts = (message, lang) => {
  const arrayMessages = message[lang].split("\n")

  if (arrayMessages.length === 1) return toast.error(arrayMessages[0])
  else
    return arrayMessages.slice(0, -1).forEach(mes => {
      toast.error(mes)
    })
}

export const sleep = m => new Promise(r => setTimeout(r, m))

export const capitalize = s => s && s[0].toUpperCase() + s.slice(1)

export function useQuery() {
  return new URLSearchParams(useLocation().search)
}

export const validateEmail = email => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

export const userTypesOptions = [
  { label: "Администратор", labelPlural: "Администраторы", value: "admin" },
  {
    label: "Руководитель ИПС",
    labelPlural: "Руководители ИПС",
    value: "projectLeader",
  },
  {
    label: "Внешний заказчик",
    labelPlural: "Внешние заказчики",
    value: "externalClient",
  },
  {
    label: "Менеджер подразделения",
    labelPlural: "Менеджеры подразделения",
    value: "divisionManager",
  },
  {
    label: "Руководитель ОП",
    labelPlural: "Руководитель ОП",
    value: "academicManager",
  },
  {
    label: "Студент",
    labelPlural: "Студенты",
    value: "student",
  },
]

export const rolesOptions = {
  admin: [
    { label: "Root", value: "root" },
    { label: "Модератор", value: "moderator" },
  ],
  projectLeader: [
    { label: "Руководитель", value: "leader" },
    { label: "Соруководитель", value: "coleader" },
  ],
  externalClient: [{ label: "Заказчик", value: "externalClient" }],
  divisionManager: [{ label: "Менеджер", value: "manager" }],
  academicManager: [{ label: "Руководитель", value: "manager" }],
  student: [{ label: "Студент", value: "student" }],
}

export const yearsOptions = {
  ru: [
    { value: "firstBachelor", label: "Бакалавры 1 курс" },
    { value: "secondBachelor", label: "Бакалавры 2 курс" },
    { value: "thirdBachelor", label: "Бакалавры 3 курс" },
    { value: "fourthBachelor", label: "Бакалавры 4 курс" },
    { value: "firstMaster", label: "Магистры 1 курс" },
    { value: "secondMaster", label: "Магистры 2 курс" },
    { value: "studentsFEFU", label: "Студенты ДВФУ" },
  ],
  en: [
    { value: "firstBachelor", label: "Bachelors 1 year" },
    { value: "secondBachelor", label: "Bachelors 2 year" },
    { value: "thirdBachelor", label: "Bachelors 3 year" },
    { value: "fourthBachelor", label: "Bachelors 4 year" },
    { value: "firstMaster", label: "Masters 1 year" },
    { value: "secondMaster", label: "Masters 2 year" },
    { value: "studentsFEFU", label: "FEFU students" },
  ],
}

export const yearsToFullText = {
  ru: {
    firstBachelor: "Бакалавры 1 курс",
    secondBachelor: "Бакалавры 2 курс",
    thirdBachelor: "Бакалавры 3 курс",
    fourthBachelor: "Бакалавры 4 курс",
    firstMaster: "Магистры 1 курс",
    secondMaster: "Магистры 2 курс",
    studentsFEFU: "Студенты ДВФУ",
  },
  en: {
    firstBachelor: "Bachelors 1 year",
    secondBachelor: "Bachelors 2 year",
    thirdBachelor: "Bachelors 3 year",
    fourthBachelor: "Bachelors 4 year",
    firstMaster: "Masters 1 year",
    secondMaster: "Masters 2 year",
    studentsFEFU: "FEFU students",
  },
}

export const yearsToText = year => {
  switch (year) {
    case "firstBachelor":
      return "Ba-1"
    case "secondBachelor":
      return "Ba-2"
    case "thirdBachelor":
      return "Ba-3"
    case "fourthBachelor":
      return "Ba-4"
    case "firstMaster":
      return "Ma-1"
    case "secondMaster":
      return "Ma-2"
    case "studentsFEFU":
      return "ДВФУ"
    case "graduate":
      return "Grad"
    default:
      return "err"
  }
}

export const API_URL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:3070"
    : "https://fes-projects.ru/api"

export const PROPOSALS_PER_PAGE = 5

export const nilObjectID = "000000000000000000000000"
