import axios from "axios"
import { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { API_URL } from "../utils"

const useJobs = () => {
  const [loading, setLoading] = useState(true)
  const [jobs, setJobs] = useState([])

  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const requestURL = `${API_URL}/jobs`

        const resp = await axios.get(requestURL)
        const { jobrunner } = resp.data

        setJobs(jobrunner)
        setLoading(false)
      } catch (error) {
        toast.error("Server error. Try to reload page or contact administrator")
      }
    }

    setLoading(true)
    fetchJobs()
  }, [])

  return { loading, jobs }
}

export default useJobs
