import axios from "axios"
import i18next from "i18next"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import ReactMarkdown from "react-markdown"
import ReactMde from "react-mde"
import Select from "react-select"
import { toast } from "react-toastify"
import { API_URL, messageToToasts } from "../../../utils"

export const priorityOptions = [
  { value: "a", label: "Приоритет 1 (высокий)" },
  { value: "b", label: "Приоритет 2" },
  { value: "c", label: "Приоритет 3" },
  { value: "d", label: "Приоритет 4 (средний)" },
  { value: "e", label: "Приоритет 5" },
  { value: "f", label: "Приоритет 6" },
  { value: "g", label: "Приоритет 7 (низкий)" },
]

const visibilityOptions = [
  { value: true, label: "Отображать" },
  { value: false, label: "Не отображать" },
]

const GreetingNew = ({ userType, setAddMode, refetch }) => {
  const { t } = useTranslation("data")

  const [selectedTab, setSelectedTab] = useState("write")
  const [selectedTabEN, setSelectedTabEN] = useState("write")
  const [selectedPriority, setSelectedPriority] = useState(null)
  const [selectedVisibility, setSelectedVisibility] = useState(null)
  const [text, setText] = useState("")
  const [textEN, setTextEN] = useState("")

  const resetForm = () => {
    setText("")
    setSelectedPriority(null)
    setSelectedVisibility(null)
    setSelectedTab("write")
    setAddMode(false)
  }

  const addGreeting = async () => {
    try {
      const data = {
        userType,
        text,
        textEN,
        isVisible: Boolean(selectedVisibility?.value),
        priority: selectedPriority?.value || "c",
      }

      const resp = await axios.post(`${API_URL}/greeting/add`, data)

      const { status, message } = resp.data
      if (status === "failed") return messageToToasts(message, i18next.language)

      resetForm()
      refetch()
      toast.success(t("greeting.addSuccess"))
    } catch (error) {
      toast.error("Server error!")
    }
  }

  return (
    <div className="box">
      <p className="title is-5">{t("greeting.addNew")}</p>

      <hr className="mb-3" />

      <div className="field">
        <label className="label">{t("greeting.text")}</label>
        <div className="control">
          <ReactMde
            value={text}
            onChange={setText}
            selectedTab={selectedTab}
            onTabChange={setSelectedTab}
            generateMarkdownPreview={markdown =>
              Promise.resolve(<ReactMarkdown>{markdown}</ReactMarkdown>)
            }
          />
        </div>
      </div>

      <div className="field">
        <label className="label">{t("greeting.textEN")}</label>
        <div className="control">
          <ReactMde
            value={textEN}
            onChange={setTextEN}
            selectedTab={selectedTabEN}
            onTabChange={setSelectedTabEN}
            generateMarkdownPreview={markdown =>
              Promise.resolve(<ReactMarkdown>{markdown}</ReactMarkdown>)
            }
          />
        </div>
      </div>

      <div className="columns">
        <div className="column is-half">
          <div className="field">
            <label className="label">{t("greeting.priority")}</label>
            <div className="control">
              <Select
                options={priorityOptions}
                value={selectedPriority}
                onChange={setSelectedPriority}
                placeholder={t("greeting.priorityPlaceholder")}
              />
            </div>
          </div>
        </div>

        <div className="column is-half">
          <div className="field">
            <label className="label">{t("greeting.isVisible")}</label>
            <div className="control">
              <Select
                options={visibilityOptions}
                value={selectedVisibility}
                onChange={setSelectedVisibility}
                placeholder={t("greeting.visibilityPlaceholder")}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="field is-grouped is-grouped-right">
        <div className="control">
          <button className="button is-light mr-4" onClick={resetForm}>
            {t("cancel")}
          </button>

          <button className="button is-link" onClick={addGreeting}>
            {t("greeting.add")}
          </button>
        </div>
      </div>
    </div>
  )
}

export default GreetingNew
