import { Route, Switch, useRouteMatch } from "react-router-dom"
import UserProfilePage from "./common/UserProfilePage"
import NavBar from "../helpers/NavBar"

const CommonWorkspace = () => {
  const { path } = useRouteMatch()

  return (
    <>
      <NavBar rightMenu={false} />
      <div className="section">
        <div className="container">
          <Switch>
            <Route path={path + "/profile"}>
              <UserProfilePage />
            </Route>
          </Switch>
        </div>
      </div>
    </>
  )
}

export default CommonWorkspace
