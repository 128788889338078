import axios from "axios"
import { useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router"
import { toast } from "react-toastify"
import { ProposalsContext } from "../../../context/ProposalsContext"
import { API_URL, messageToToasts } from "../../../utils"
import FormEntry from "../../helpers/FormEntry"
import Submitting from "../../helpers/Submitting"

const ProposalRespond = ({ proposal, reviewID, reviewsEmail }) => {
  const history = useHistory()
  const { refetch } = useContext(ProposalsContext)
  const { t, i18n } = useTranslation("data")
  const [response, setResponse] = useState("")
  const [submitting, setSubmitting] = useState(false)

  const respondToReview = async () => {
    setSubmitting(true)

    try {
      const data = {
        reviewID,
        proposalID: proposal.id,
        proposalTitle: proposal.title,
        response,
        emails: reviewsEmail,
      }
      const requestURL = `${API_URL}/project/proposal/respondToReview`
      const resp = await axios.post(requestURL, data)

      const { status, message } = resp.data
      if (status === "failed") {
        setSubmitting(false)
        messageToToasts(message, i18n.language)
      }

      toast.success(t("proposal.respondToReviewSuccess"))
      refetch("mainProposals")
      history.push(`../listMain`)
    } catch (error) {
      setSubmitting(false)
      toast.error("Server error!")
    }
  }

  if (submitting) return <Submitting submitting={true} />

  return (
    <>
      <hr />
      <FormEntry labelText={t("proposal.respondText")} helpText={t("req")}>
        <textarea
          className="textarea"
          value={response}
          onChange={e => setResponse(e.target.value)}
          placeholder={t("proposal.respondTextPlaceholder")}
        />
      </FormEntry>

      <FormEntry>
        <div className="has-text-right">
          <button
            className="button is-link is-outlined"
            onClick={respondToReview}
          >
            {t("proposal.respond")}
          </button>
        </div>
      </FormEntry>
    </>
  )
}

export default ProposalRespond
