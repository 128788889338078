import { useTranslation } from "react-i18next"
import ProposalNew from "../../data/ProposalNew"

const ProposalNewView = () => {
  const { t } = useTranslation("ws")

  return (
    <>
      <div className="box has-text-right title is-4">
        {t("proposalsPage.proposalsNew")}
      </div>

      <ProposalNew />
    </>
  )
}

export default ProposalNewView
