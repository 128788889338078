import axios from "axios"
import i18next from "i18next"
import { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { API_URL, messageToToasts, nilObjectID } from "../utils"

const useInfoUpdates = (studentID = nilObjectID) => {
  const [infoUpdates, setInfoUpdates] = useState([])
  const [loading, setLoading] = useState(true)
  const [needRefetch, setNeedRefetch] = useState(true)

  useEffect(() => {
    const fetchInfoUpdates = async () => {
      try {
        const resp = await axios.get(
          `${API_URL}/studentInfoUpdate/list?studentID=${studentID}`
        )
        const { status, message, updates } = resp.data

        setLoading(false)
        if (status === "failed")
          return toast.error(messageToToasts(message, i18next.language))

        setInfoUpdates(updates)
      } catch (error) {
        setLoading(false)
        toast.error("Server error")
      }
    }

    fetchInfoUpdates()
    setNeedRefetch(false)
  }, [needRefetch])

  const refetch = (needsReload = false) => {
    if (needsReload) setLoading(true)

    setNeedRefetch(true)
  }

  const decline = async iuID => {
    try {
      const resp = await axios.post(`${API_URL}/studentInfoUpdate/decline`, {
        iuID,
      })
      const { status, message } = resp.data

      if (status === "failed")
        return toast.error(messageToToasts(message, i18next.language))

      refetch()
    } catch (error) {
      toast.error("Server error")
    }
  }

  const accept = async iuID => {
    try {
      const resp = await axios.post(`${API_URL}/studentInfoUpdate/accept`, {
        iuID,
      })
      const { status, message } = resp.data

      if (status === "failed")
        return toast.error(messageToToasts(message, i18next.language))

      refetch()
    } catch (error) {
      toast.error("Server error")
    }
  }

  return { infoUpdates, loading, refetch, decline, accept }
}

export default useInfoUpdates
