import { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { CommonDataContext } from "../../context/CommonDataContext"

const PlacesList = ({ courses, mode }) => {
  const { t, i18n } = useTranslation("data")
  const {
    courses: { dict: courDict },
  } = useContext(CommonDataContext)
  const [viewedCourses, setViewedCourses] = useState([])

  useEffect(() => {
    setViewedCourses(
      courses
        .map(cour => ({ ...cour, title: courDict[cour.courseID]?.title }))
        .sort((a, b) =>
          a.title[i18n.language].localeCompare(b.title[i18n.language])
        )
    )
  }, [courses, courDict, i18n.language])

  return (
    <div className="box table-container">
      <table className="table is-fullwidth">
        <thead>
          <tr>
            <th>{t("course.title")}</th>
            <th>{t("application.studentProgramme")}</th>
            {mode === "bachelor" ? (
              <>
                <th>Ba-I</th>
                <th>Ba-II</th>
                <th>Ba-III</th>
                <th>Ba-IV</th>
              </>
            ) : (
              <>
                <th>Ma-I</th>
                <th>Ma-II</th>
              </>
            )}
          </tr>
        </thead>

        <tbody>
          {viewedCourses.map(cour => {
            const course = courDict[cour.courseID]
            return (
              <tr key={cour.courseID}>
                <td>{course?.title[i18n.language]}</td>
                <td>{course?.group}</td>
                {mode === "bachelor" ? (
                  <>
                    <td>{cour.firstBachelor}</td>
                    <td>{cour.secondBachelor}</td>
                    <td>{cour.thirdBachelor}</td>
                    <td>{cour.fourthBachelor}</td>
                  </>
                ) : (
                  <>
                    <td>{cour.firstMaster}</td>
                    <td>{cour.secondMaster}</td>
                  </>
                )}
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export default PlacesList
