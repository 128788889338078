import { createContext, useState } from "react"
import useProposals from "./useProposals"

export const ProposalsContext = createContext()

const ProposalsContextProvider = ({ userType, children }) => {
  const [mpRefetch, setMPRefetch] = useState(true)
  const {
    proposals: mainProposals,
    loading: mainProposalsLoading,
  } = useProposals(userType, "main", mpRefetch, setMPRefetch)

  const [dpRefetch, setDPRefetch] = useState(true)
  const {
    proposals: draftProposals,
    loading: draftProposalsLoading,
  } = useProposals(userType, "draft", dpRefetch, setDPRefetch)

  const [apRefetch, setAPRefetch] = useState(true)
  const {
    proposals: archiveProposals,
    loading: archiveProposalsLoading,
  } = useProposals(userType, "archive", apRefetch, setAPRefetch)

  const [ppRefetch, setPPRefetch] = useState(true)
  const {
    proposals: publishedProposals,
    loading: publishedProposalsLoading,
  } = useProposals(userType, "published", ppRefetch, setPPRefetch)

  const [rpRefetch, setRPRefetch] = useState(true)
  const {
    proposals: reviewedProposals,
    loading: reviewedProposalsLoading,
  } = useProposals(userType, "reviewed", rpRefetch, setRPRefetch)

  const refetch = what => {
    switch (what) {
      case "mainProposals":
        setMPRefetch(true)
        break
      case "draftProposals":
        setDPRefetch(true)
        break
      case "archiveProposals":
        setAPRefetch(true)
        break
      case "publishedProposals":
        setPPRefetch(true)
        break
      case "reviewedProposals":
        setRPRefetch(true)
        break
      default:
        return
    }
  }

  const value = {
    refetch,
    mainProposals,
    mainProposalsLoading,
    draftProposals,
    draftProposalsLoading,
    archiveProposals,
    archiveProposalsLoading,
    publishedProposals,
    publishedProposalsLoading,
    reviewedProposals,
    reviewedProposalsLoading,
  }
  return (
    <ProposalsContext.Provider value={value}>
      {children}
    </ProposalsContext.Provider>
  )
}

export default ProposalsContextProvider
