import { useTranslation } from "react-i18next"

const Submitting = ({ submitting, children }) => {
  const { t } = useTranslation()

  return submitting ? (
    <>
      <hr />
      <progress className="progress is-link" max="100">
        30%
      </progress>
      <p className="has-text-centered has-text-grey">
        {" " + t("submittingText")}
      </p>
    </>
  ) : (
    children
  )
}

export default Submitting
