import axios from "axios"
import i18next from "i18next"
import { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { API_URL, messageToToasts } from "../utils"

const usePaginatedApplications = (
  currentPage,
  queryStatus = "",
  programmeID = "",
  studentID = "",
  projectID = ""
) => {
  const [applications, setApplications] = useState([])
  const [loading, setLoading] = useState(true)
  const [needRefetch, setNeedRefetch] = useState(true)
  const [pageCount, setPageCount] = useState(0)

  useEffect(() => {
    const fetchApplications = async () => {
      setLoading(true)

      try {
        const resp = await axios.get(
          `${API_URL}/application/listForProgramme?page=${currentPage}&status=${queryStatus}&programmeID=${programmeID}&studentID=${studentID}&projectID=${projectID}`
        )
        const { status, message, applications, pageCount } = resp.data

        setLoading(false)
        if (status === "failed")
          return messageToToasts(message, i18next.language)

        setApplications(applications)
        setPageCount(pageCount)
      } catch (error) {
        setLoading(false)
        toast.error("Server error")
      }
    }

    fetchApplications()
    setNeedRefetch(false)
  }, [needRefetch, currentPage, queryStatus, programmeID, studentID, projectID])

  const refetch = () => setNeedRefetch(true)

  return { applications, loading, refetch, pageCount }
}

export default usePaginatedApplications
