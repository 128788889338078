import { useTranslation } from "react-i18next"

const ApplicationStatus = ({ status }) => {
  const { t } = useTranslation("data")

  const styleFromStatus = () => {
    switch (status) {
      case "awaiting":
        return "has-text-warning"
      case "declined":
      case "declinedSelf":
        return "has-text-danger"
      case "accepted":
        return "has-text-success"
      case "acceptedByLeader":
        return "has-text-link"
      default:
        return ""
    }
  }

  return (
    <strong className={styleFromStatus()}>
      {t(`application.${status}Status`)}
    </strong>
  )
}

export default ApplicationStatus
