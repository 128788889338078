import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import axios from "axios"
import dayjs from "dayjs"
import fileDownload from "js-file-download"
import { useContext } from "react"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import { SettingsContext } from "../../context/SettingsContext"
import { API_URL } from "../../utils"

const LeaderLoadExport = ({ year }) => {
  const { t } = useTranslation("data")
  const { settings } = useContext(SettingsContext)

  const derivedYear = settings[year]

  const exportToXLSX = async () => {
    try {
      const resp = await axios.get(
        `${API_URL}/leadersLoad/export?year=${year}`,
        {
          headers: {
            "Content-Type":
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          },
          responseType: "arraybuffer",
        }
      )

      fileDownload(resp.data, "loads.xlsx")
    } catch (error) {
      console.log(error)
      toast.error("Server error!")
    }
  }

  return (
    <div className="box">
      <div className="columns">
        <div className="column is-half">
          <p className="pt-3">
            <strong>{t("loads.lastRun")}: </strong>
            {dayjs(settings.lastLeadersLoadRun).format("DD/MM/YYYY HH:mm ")}
            {t("loads.updatesDaily")}
          </p>
        </div>

        <div className="column is-half has-text-right">
          <button className="button is-success" onClick={exportToXLSX}>
            <FontAwesomeIcon icon="file-excel" size="lg" />
            &nbsp;{t("loads.exportToXLSX")}&nbsp;({derivedYear}/
            {derivedYear - 1999})
          </button>
        </div>
      </div>
    </div>
  )
}

export default LeaderLoadExport
