import { useState } from "react"
import { useTranslation } from "react-i18next"
import FormEntry from "../../helpers/FormEntry"
import { useAsgnContext } from "./AsgnContext"

const AsgnSendEditted = () => {
  const { t } = useTranslation("data")
  const { updateEditedAssignments } = useAsgnContext()

  const [submitting, setSubmitting] = useState(false)

  return (
    <div className="box">
      <div className="columns">
        <div className="column is-half pt-5">
          <span className="has-text-grey">{t("group.edittedCount")}</span>
        </div>

        <div className="column is-half has-text-right">
          <FormEntry>
            <button
              className="button is-link"
              onClick={updateEditedAssignments}
              disabled={submitting}
            >
              {t("group.sendEditted")}
            </button>
          </FormEntry>
        </div>
      </div>
    </div>
  )
}

export default AsgnSendEditted
