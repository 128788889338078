import { createContext, useState } from "react"
import useCourses from "./useCourses"
import useDivisions from "./useDivisions"
import useProgrammes from "./useProgrammes"
import useTopics from "./useTopics"
import useUsers from "./useUsers"

export const CommonDataContext = createContext()

const CommonDataContextProvider = ({ children }) => {
  const { usersMap: pls, loading: plLoading } = useUsers("projectLeader")
  const { usersMap: admins, loading: adminLoading } = useUsers("admin")
  const { usersMap: ecs, loading: ecLoading } = useUsers("externalClient")
  const { usersMap: dms, loading: dmLoading } = useUsers("divisionManager")
  const { usersMap: ams, loading: amLoading } = useUsers("academicManager")
  const {
    usersMap: ss,
    loading: sLoading,
    setNeedRefetch: studentRefetch,
  } = useUsers("student")

  const [divRefetch, setDivRefetch] = useState(true)
  const {
    divisions,
    divisionOptions,
    loading: divisionsLoading,
  } = useDivisions(divRefetch, setDivRefetch)

  const [progRefetch, setProgRefetch] = useState(true)
  const {
    programmes,
    programmeOptions,
    loading: programmesLoading,
  } = useProgrammes(progRefetch, setProgRefetch)

  const [courRefetch, setCourRefetch] = useState(true)
  const {
    courses,
    courseOptions,
    bachelorCourseOptions,
    masterCourseOptions,
    loading: coursesLoading,
  } = useCourses(courRefetch, setCourRefetch)

  const [topicRefetch, setTopicRefetch] = useState(true)
  const {
    topics,
    topicOptions,
    loading: topicsLoading,
  } = useTopics(topicRefetch, setTopicRefetch)

  const refetch = what => {
    switch (what) {
      case "divisions":
        setDivRefetch(true)
        break
      case "topics":
        setTopicRefetch(true)
        break
      case "courses":
        setCourRefetch(true)
        break
      case "students":
        studentRefetch(true)
        break
      default:
        return
    }
  }

  const value = {
    refetch,
    projectLeaders: {
      dict: pls,
      loading: plLoading,
    },
    externalClients: {
      dict: ecs,
      loading: ecLoading,
    },
    divisionManagers: {
      dict: dms,
      loading: dmLoading,
    },
    academicManagers: {
      dict: ams,
      loading: amLoading,
    },
    admins: {
      dict: admins,
      loading: adminLoading,
    },
    students: {
      dict: ss,
      loading: sLoading,
    },
    allUsersDict: {
      projectLeader: pls,
      admin: admins,
      divisionManager: dms,
      academicManager: ams,
      students: ss,
    },
    divisions: {
      dict: divisions,
      options: divisionOptions,
      loading: divisionsLoading,
    },
    programmes: {
      dict: programmes,
      options: programmeOptions,
      loading: programmesLoading,
    },
    courses: {
      dict: courses,
      options: courseOptions,
      bachelorOptions: bachelorCourseOptions,
      masterOptions: masterCourseOptions,
      loading: coursesLoading,
    },
    topics: {
      dict: topics,
      options: topicOptions,
      loading: topicsLoading,
    },
  }
  return (
    <CommonDataContext.Provider value={value}>
      {children}
    </CommonDataContext.Provider>
  )
}

export default CommonDataContextProvider
