import i18next from "i18next"
import { useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import { CommonDataContext } from "../../../context/CommonDataContext"
import { SettingsContext } from "../../../context/SettingsContext"
import { UserContext } from "../../../context/UserContext"
import { API_URL, messageToToasts, yearsToText } from "../../../utils"
import Select from "react-select"
import StudentStatus from "./StudentStatus"
import { toast } from "react-toastify"
import axios from "axios"
import GradeSheetUpload from "./GradeSheetUpload"
import MakeGradeSheet from "./MakeGradeSheet"
import GetGradeSheet from "./GetGradeSheet"

const gradeOptions = [...Array(10).keys()].map(el => ({
  value: Number(el) + 1,
  label: Number(el) + 1,
}))

const GroupGrades = ({ group, refetch }) => {
  const { t } = useTranslation("data")
  const {
    user: { userType, userID },
  } = useContext(UserContext)
  const {
    students: { dict: studDict },
    projectLeaders: { dict: plDict },
  } = useContext(CommonDataContext)
  const { settings } = useContext(SettingsContext)

  const [sub, setSub] = useState(false)

  const { assignments } = group
  const enrichedAssignments = assignments
    .filter(asgn => asgn?.status !== "notAssigned")
    .map(asgn => ({
      ...asgn,
      student: studDict[asgn?.studentID],
    }))
    .sort((a, b) =>
      (
        yearsToText(a?.student?.yearOfStudy) + " " + a?.student?.fullName || ""
      ).localeCompare(
        yearsToText(b?.student?.yearOfStudy) + " " + b?.student?.fullName || ""
      )
    )

  const updateGrade = async (asgnID, option, mode = "add") => {
    setSub(true)

    try {
      const data = { asgnID, grade: Number(option?.value), mode }
      const resp = await axios.post(`${API_URL}/group/updateAsgnGrade`, data)
      const { status, message } = resp.data
      setSub(false)

      if (status === "failed") return messageToToasts(message, i18next.language)

      refetch(false)
    } catch (error) {
      setSub(false)
      toast.error("Server error!")
    }
  }

  return (
    <>
      <div className="message is-warning">
        <div className="message-body">{t("group.noTzWarning")}</div>
      </div>

      <div className="message is-warning">
        <div className="message-body">
          {t("group.gradesYear")}: {settings?.currentYear}/
          {Number(settings?.currentYear) - 1999}
        </div>
      </div>

      {(enrichedAssignments || []).map(asgn => {
        const student = asgn?.student
        const studentName =
          i18next.language === "ru" ? student?.fullName : student?.fullNameEn

        return (
          <div className="box" key={asgn?.id}>
            <div className="columns">
              <div className="column is-7">
                <p>
                  <strong>{yearsToText(student?.yearOfStudy)}</strong>&nbsp;
                  {studentName}
                  &nbsp;(
                  <a href={"mailto:" + student?.email}>{student?.email}</a>
                  {student?.alternativeEmail ? (
                    <span>
                      ,{" "}
                      <a href={"mailto:" + student?.alternativeEmail}>
                        {student?.alternativeEmail}
                      </a>
                    </span>
                  ) : (
                    ""
                  )}
                  )
                </p>

                <p className="mt-2">
                  <strong>{t("group.asgnTheme")}: </strong>
                  {asgn?.theme}
                </p>

                <p>
                  <strong>{t("group.asgnLeader")}: </strong>
                  {plDict[asgn?.leaderID]?.fullName}
                </p>
              </div>

              <div className="column is-5">
                <div className="field is-grouped is-grouped-right">
                  <div className="control is-expanded">
                    <Select
                      options={gradeOptions}
                      placeholder={t("group.gradePlh")}
                      value={
                        asgn?.grade
                          ? gradeOptions.find(opt => opt?.value === asgn?.grade)
                          : null
                      }
                      onChange={opt => updateGrade(asgn?.id, opt)}
                      isDisabled={
                        sub ||
                        asgn?.status === "finished" ||
                        !["projectLeader", "admin"].includes(userType)
                      }
                    />
                  </div>

                  {!(
                    asgn?.status === "awaitsGrade" &&
                    (userType === "admin" ||
                      asgn?.leaderID === userID ||
                      group?.projectLeaderID === userID)
                  ) || (
                    <MakeGradeSheet
                      asgnID={asgn?.id}
                      studentEmail={student?.email}
                    />
                  )}
                </div>

                {!(
                  asgn?.status === "awaitsGrade" &&
                  (userType === "admin" ||
                    asgn?.leaderID === userID ||
                    group?.projectLeaderID === userID)
                ) || (
                  <>
                    <hr />
                    <GradeSheetUpload asgnID={asgn?.id} refetch={refetch} />
                  </>
                )}

                {!(
                  asgn?.status === "finished" &&
                  (userType === "admin" ||
                    asgn?.leaderID === userID ||
                    group?.projectLeaderID === userID)
                ) || (
                  <>
                    <hr />
                    <GetGradeSheet
                      asgnID={asgn?.id}
                      gradeSheetFilename={asgn?.gradeSheetFilename}
                      refetch={refetch}
                    />
                  </>
                )}

                {!(asgn?.status === "finished" && asgn?.grade < 4) || (
                  <>
                    <hr />
                    <p className="title is-6">{t("group.retake")}</p>

                    <div className="field is-grouped is-grouped-right">
                      <div className="control is-expanded">
                        <Select
                          options={gradeOptions}
                          placeholder={t("group.gradePlh")}
                          value={
                            asgn?.gradeRetake
                              ? gradeOptions.find(
                                  opt => opt?.value === asgn?.gradeRetake
                                )
                              : null
                          }
                          onChange={opt => updateGrade(asgn?.id, opt, "retake")}
                          isDisabled={
                            sub ||
                            (asgn?.status === "finished" &&
                              asgn?.gradeSheetFilenameRetake?.length) ||
                            !["projectLeader", "admin"].includes(userType)
                          }
                        />
                      </div>

                      {!(
                        asgn?.status === "finished" &&
                        asgn?.gradeRetake > 0 &&
                        !asgn?.gradeSheetFilenameRetake?.length &&
                        (userType === "admin" ||
                          asgn?.leaderID === userID ||
                          group?.projectLeaderID === userID)
                      ) || (
                        <MakeGradeSheet
                          asgnID={asgn?.id}
                          studentEmail={student?.email}
                          mode="retake"
                        />
                      )}
                    </div>

                    {!(
                      asgn?.status === "finished" &&
                      asgn?.gradeRetake > 0 &&
                      !asgn?.gradeSheetFilenameRetake?.length &&
                      (userType === "admin" ||
                        asgn?.leaderID === userID ||
                        group?.projectLeaderID === userID)
                    ) || (
                      <>
                        <hr />
                        <GradeSheetUpload
                          asgnID={asgn?.id}
                          refetch={refetch}
                          mode="retake"
                        />
                      </>
                    )}

                    {!(
                      asgn?.status === "finished" &&
                      asgn?.gradeSheetFilenameRetake?.length &&
                      (userType === "admin" ||
                        asgn?.leaderID === userID ||
                        group?.projectLeaderID === userID)
                    ) || (
                      <>
                        <hr />
                        <GetGradeSheet
                          asgnID={asgn?.id}
                          gradeSheetFilename={asgn?.gradeSheetFilenameRetake}
                          refetch={refetch}
                          showRemove={false}
                        />
                      </>
                    )}
                  </>
                )}
              </div>
            </div>

            <hr className="mt-0 mb-2" />

            <div className="has-text-centered">
              <StudentStatus status={asgn?.status}>
                {t(`group.${asgn?.status}`)}
              </StudentStatus>
            </div>
          </div>
        )
      })}
    </>
  )
}

export default GroupGrades
