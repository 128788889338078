import axios from "axios"
import i18next from "i18next"
import { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { API_URL, messageToToasts } from "../utils"

const useListData = (dataName, url, query = {}) => {
  const [fullData, setFullData] = useState([])
  const [data, setData] = useState([])
  const [dataMap, setDataMap] = useState({})
  const [loading, setLoading] = useState(true)
  const [needRefetch, setNeedRefetch] = useState(true)
  const [currentPage, setCurrentPage] = useState(1)
  const [pageCount, setPageCount] = useState(1)

  const queryString = (Object.entries(query) || []).reduce(
    (acc, [key, value]) => (value?.length ? acc + `&${key}=${value}` : acc),
    ""
  )

  useEffect(() => {
    setNeedRefetch(true)
  }, [currentPage])

  useEffect(() => {
    setNeedRefetch(true)
  }, [queryString])

  useEffect(() => {
    const fetch = async () => {
      try {
        const resp = await axios.get(
          `${API_URL + url}/list?page=${currentPage}${queryString}`
        )
        const { status, message, pageCount } = resp.data

        setLoading(false)
        if (status === "failed")
          return toast.error(messageToToasts(message, i18next.language))

        setFullData(resp.data)
        const loadData = resp.data[dataName]
        setData(loadData)
        const loadDataMap = (loadData || []).reduce((acc, el) => {
          acc[el?.id] = el
          return acc
        }, {})
        setDataMap(loadDataMap)

        if (pageCount) setPageCount(pageCount)
      } catch (error) {
        setLoading(false)
        toast.error("Server error!")
      }
    }

    if (needRefetch) {
      fetch()
      setNeedRefetch(false)
    }
  }, [needRefetch, dataName, url, currentPage, queryString])

  const refetch = (needsReload = false) => {
    if (needsReload) setLoading(true)

    setCurrentPage(1)
    setNeedRefetch(true)
  }

  return {
    fullData,
    [dataName]: data,
    [dataName + "Map"]: dataMap,
    loading,
    refetch,
    pageCount,
    currentPage,
    setCurrentPage,
  }
}

export default useListData
