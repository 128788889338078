import dayjs from "dayjs"
import { useContext } from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import { CommonDataContext } from "../../../context/CommonDataContext"
import MathMarkdown from "../../helpers/MathMarkdown"

const GroupInfo = ({ group }) => {
  const { t } = useTranslation("data")
  const {
    projectLeaders: { dict: plDict },
  } = useContext(CommonDataContext)

  const lastPlID = group?.leadersID[group?.leadersID.length - 1]
  const pls = group?.leadersID.map(pl => (
    <span key={pl}>
      <Link
        to={`/r/common/profile?userID=${pl}&userType=projectLeader`}
        target="_blank"
      >
        {plDict[pl]?.fullName}
      </Link>
      {pl !== lastPlID ? ", " : ""}
    </span>
  ))

  const noInfo = <span className="has-text-grey">{t("group.noInfo")}</span>

  const textField = field => (
    <div className="box">
      <p className="title is-5">{t(`group.${field}`)}</p>

      <hr />

      {group?.[field] ? (
        <div className="content" style={{ whiteSpace: "pre-wrap" }}>
          {group?.[field]}
        </div>
      ) : (
        noInfo
      )}
    </div>
  )

  return (
    <>
      <div className="box">
        <p className="title is-5">{t("group.generalInfo")}</p>

        <hr />

        <p>
          <strong>{t("group.leader")}: </strong>
          {pls}
        </p>

        <p>
          <strong>{t("group.creationDate")}: </strong>
          {dayjs(group?.creationDate).format("DD/MM/YYYY HH:mm")}
        </p>
      </div>

      {textField("target")}

      <div className="box">
        <p className="title is-5">{t("group.tasks")}</p>

        <hr />

        {group?.tasks ? (
          <MathMarkdown styleClass="content">{group?.tasks}</MathMarkdown>
        ) : (
          noInfo
        )}
      </div>

      {textField("requirements")}

      <div className="columns">
        <div className="column is-half">{textField("reportFormat")}</div>

        <div className="column is-half">{textField("presentation")}</div>
      </div>
    </>
  )
}

export default GroupInfo
