import i18next from "i18next"
import { useContext } from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import { CommonDataContext } from "../../../context/CommonDataContext"
import { UserContext } from "../../../context/UserContext"

const GroupPreview = ({ group, needsProject = false }) => {
  const { t } = useTranslation("data")
  const {
    user: { userType },
  } = useContext(UserContext)
  const {
    projectLeaders: { dict: plDict },
    students: { dict: studDict },
  } = useContext(CommonDataContext)

  const lastPlID = group?.leadersID[group?.leadersID.length - 1]
  const pls = group?.leadersID.map(pl => (
    <span key={pl}>
      <Link
        to={`/r/common/profile?userID=${pl}&userType=projectLeader`}
        target="_blank"
      >
        {plDict[pl]?.fullName}
      </Link>
      {pl !== lastPlID ? ", " : ""}
    </span>
  ))

  const lastStudID = group?.studentsID[group?.studentsID.length - 1]
  const students = group?.studentsID.map(pl => (
    <span key={pl}>
      <Link
        to={`/r/common/profile?userID=${pl}&userType=student`}
        target="_blank"
      >
        {studDict[pl]?.fullName}
      </Link>
      {pl !== lastStudID ? ", " : ""}
    </span>
  ))

  return (
    <div className="box">
      <p className="title is-5">
        <Link to={`/r/${userType}/groups/${group?.id}`}>{group?.theme}</Link>
      </p>

      <hr />

      {!needsProject || (
        <p>
          <strong>{t("group.project")}: </strong>
          {i18next.language === "ru"
            ? group?.projectName
            : group?.projectNameEN}
        </p>
      )}

      <p>
        <strong>{t("group.leader")}: </strong>
        {pls}
      </p>

      <p>
        <strong>{t("group.student")}: </strong>
        {students}
      </p>
    </div>
  )
}

export default GroupPreview
