import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import axios from "axios"
import dayjs from "dayjs"
import i18next from "i18next"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import { useBlogPostContext } from "../../../context/BlogPostContext"
import { API_URL, messageToToasts } from "../../../utils"
import FormEntry from "../../helpers/FormEntry"

const MeetingPostNew = ({ projectID, meeting = null }) => {
  const { t } = useTranslation("data")
  const { meetingRefetch } = useBlogPostContext()

  const [submitting, setSubmitting] = useState(false)
  const [mode, setMode] = useState(meeting ? "add" : "view")

  const [theme, setTheme] = useState(meeting?.theme || "")
  const [text, setText] = useState(meeting?.text || "")
  const [auditorium, setAuditorium] = useState(meeting?.auditorium || "")
  const [link, setLink] = useState(meeting?.link || "")
  const [date, setDate] = useState(
    dayjs(meeting?.meetingDate).format("YYYY-MM-DD")
  )
  const [time, setTime] = useState(
    meeting?.meetingDate ? dayjs(meeting?.meetingDate).format("HH:mm") : "12:00"
  )
  const [isEmail, setIsEmail] = useState(true)

  const resetForm = () => {
    if (meeting) meeting.setEditMode(false)

    setTheme("")
    setAuditorium("")
    setLink("")
    setText("")
    setMode("view")
  }

  const updateText = event => {
    const newValue = event.target.value
    if (newValue.length <= 5000) setText(newValue)
    else setText(newValue.substring(0, 5000))
  }

  const addMeetingPost = async () => {
    setSubmitting(true)

    try {
      const meetingDate = `${date}T${time}:00+03:00`

      const resp = await axios.post(
        `${API_URL}/meetingPost/${meeting ? "update" : "add"}`,
        {
          id: meeting?.id,
          projectID,
          theme,
          text,
          auditorium,
          link,
          meetingDate,
          level: "project", // TODO: FIX WHEN GROUP!!!
          authorID: meeting?.authorID,
          creationDate: meeting?.creationDate,
          updatesCount: meeting?.updatesCount,
          isEmail,
        }
      )
      const { status, message } = resp.data
      setSubmitting(false)

      if (status === "failed") return messageToToasts(message, i18next.language)

      setSubmitting(false)
      resetForm()
      meetingRefetch()
      toast.success(t("blogpost.addSuccess"))
    } catch (error) {
      setSubmitting(false)
    }
  }

  if (mode === "view")
    return (
      <div className="box has-text-centered">
        <span
          className="is-size-5 pointer has-text-link"
          onClick={() => setMode("add")}
        >
          <FontAwesomeIcon icon="plus" />
          &nbsp;{t("blogpost.addMeetingPhl")}
        </span>
      </div>
    )

  return (
    <div className="box">
      {/* <div className="message is-grey">
        <div className="message-body">{t("blogpost.meetingHelp")}</div>
      </div> */}

      <div className="columns">
        <div className="column is-half">
          <FormEntry labelText={t("blogpost.theme")} helpText={t("req")}>
            <input
              type="text"
              className="input"
              placeholder={t("blogpost.themePlh")}
              value={theme}
              onChange={e => setTheme(e.target.value)}
            />
          </FormEntry>
        </div>

        <div className="column is-half">
          <div className="field has-addons">
            <div className="control">
              <label className="label">{t("blogpost.date")}</label>
              <input
                type="date"
                className="input"
                value={date}
                onChange={e => setDate(e.target.value)}
              />
              <p className="help">{t("req")}</p>
            </div>

            <div className="control is-expanded">
              <label className="label">{t("blogpost.time")}</label>
              <input
                type="time"
                className="input"
                value={time}
                onChange={e => setTime(e.target.value)}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="columns">
        <div className="column is-4">
          <FormEntry labelText={t("blogpost.auditorium")}>
            <input
              type="text"
              className="input"
              placeholder={t("blogpost.auditoriumPhl")}
              value={auditorium}
              onChange={e => setAuditorium(e.target.value)}
            />
          </FormEntry>
        </div>

        <div className="column is-8">
          <FormEntry labelText={t("blogpost.link")}>
            <input
              type="text"
              className="input"
              placeholder={t("blogpost.linkPhl")}
              value={link}
              onChange={e => setLink(e.target.value)}
            />
          </FormEntry>
        </div>
      </div>

      <FormEntry
        labelText={t("blogpost.text")}
        helpText={text.length + "/5000 " + t("used")}
      >
        <textarea
          className="textarea"
          placeholder={t("blogpost.meetingTextPhl")}
          value={text}
          onChange={updateText}
        />
      </FormEntry>

      <div className="field pt-2">
        <label className="switch is-rounded">
          <input
            type="checkbox"
            value="false"
            checked={isEmail}
            onChange={e => setIsEmail(e.target.checked)}
          />
          <span className="check is-link"></span>
          &nbsp; {t("blogpost.isEmail")}
        </label>
      </div>

      <div className="columns">
        <div className="column is-half"></div>

        <div className="column is-half">
          <div className="field is-grouped is-grouped-right">
            <p className="control">
              <button className="button is-light" onClick={() => resetForm()}>
                {t("cancel")}
              </button>
            </p>
            <p className="control">
              <button
                className="button is-link"
                disabled={submitting}
                onClick={addMeetingPost}
              >
                {meeting ? t("update") : t("add")}
              </button>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MeetingPostNew
