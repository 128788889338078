import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom"
import AllProjectListView from "./AllProjectListView"
import AllProjectView from "./AllProjectView"

const AllProjectsPageView = () => {
  const { path, url } = useRouteMatch()

  return (
    <Switch>
      <Route exact path={path}>
        <Redirect to={url + "/list"} />
      </Route>

      <Route path={path + "/list"}>
        <AllProjectListView />
      </Route>

      <Route path={path + "/viewProject/:projectID"}>
        <AllProjectView />
      </Route>
    </Switch>
  )
}

export default AllProjectsPageView
