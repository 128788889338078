import { library } from "@fortawesome/fontawesome-svg-core"
import {
  faPen,
  faCheck,
  faTrash,
  faPlus,
  faUpload,
  faTimes,
  faInfo,
  faBackward,
  faSquareRootAlt,
  faSync,
  faEnvelope,
  faFileCsv,
  faSortNumericUp,
  faArrowDown,
  faArrowUp,
  faGlobeEurope,
  faUser,
  faUsers,
  faFileExcel,
  faHistory,
  faChevronDown,
  faChevronUp,
  faChevronCircleLeft,
  faChevronCircleRight,
  faThumbtack,
  faVideo,
  faGlobe,
  faExchangeAlt,
  faEllipsisV,
  faEye,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons"

import {
  faSkype,
  faMicrosoft,
  faDiscord,
} from "@fortawesome/free-brands-svg-icons"

library.add(
  faPen,
  faCheck,
  faTrash,
  faPlus,
  faUpload,
  faTimes,
  faInfo,
  faBackward,
  faSquareRootAlt,
  faSync,
  faEnvelope,
  faFileCsv,
  faSortNumericUp,
  faArrowDown,
  faArrowUp,
  faGlobeEurope,
  faUser,
  faUsers,
  faFileExcel,
  faHistory,
  faChevronDown,
  faChevronUp,
  faThumbtack,
  faSkype,
  faMicrosoft,
  faDiscord,
  faVideo,
  faGlobe,
  faExchangeAlt,
  faEllipsisV,
  faEye,
  faEyeSlash,
  faChevronCircleLeft,
  faChevronCircleRight
)
