import axios from "axios"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router"
import { toast } from "react-toastify"
import { API_URL, messageToToasts, useQuery } from "../utils"
import AuthLayout from "./helpers/AuthLayout"
import FormEntry from "./helpers/FormEntry"
import LangAuthSelector from "./helpers/LangAuthSelector"
import Submitting from "./helpers/Submitting"

const ResetPassword = () => {
  const query = useQuery()
  const history = useHistory()
  const { t, i18n } = useTranslation(["auth", "translation"])

  const [checkID, setCheckID] = useState("")
  const [userType, setUserType] = useState("")
  const [userEmail, setUserEmail] = useState("")
  const [newPassword, setNewPassword] = useState("")
  const [newPasswordRep, setNewPasswordRep] = useState("")
  const [submitting, setSubmitting] = useState(false)

  useEffect(() => {
    setCheckID(query.get("forgotID"))
    setUserType(query.get("userType"))
  }, [query])

  const resetPassword = async e => {
    e.preventDefault()
    setSubmitting(true)

    try {
      const data = { userEmail, userType, checkID, newPassword, newPasswordRep }

      const resp = await axios.post(`${API_URL}/user/forgotPasswordCheck`, data)
      const { status, message } = resp.data

      setSubmitting(false)
      if (status === "failed") {
        return messageToToasts(message, i18n.language)
      }

      toast.success(t("resetPwdSuccessMessage"))
      history.push("/")
    } catch (error) {
      toast.error("Server error. Try to reload page or contact administrator")
    }
  }

  return (
    <AuthLayout>
      <h3 className="title is-4 has-text-centered pb-2">
        {t("translation:appTitle")}
      </h3>
      <h4 className="subtitle has-text-centered capitalized">
        {t("resetPassword")}
      </h4>

      <form>
        <FormEntry labelText="Email">
          <input
            className="input"
            value={userEmail}
            onChange={e => setUserEmail(e.target.value)}
            type="email"
            placeholder={`${userType.toLowerCase()}@${
              userType === "student" ? "edu." : ""
            }hse.ru`}
          />
        </FormEntry>

        <FormEntry
          labelText={t("formLabels.pwd")}
          helpText={t("formLabels.req")}
        >
          <input
            className="input"
            type="password"
            name="password"
            placeholder="********"
            value={newPassword}
            onChange={e => setNewPassword(e.target.value)}
          />
        </FormEntry>

        <FormEntry
          labelText={t("formLabels.pwdRep")}
          helpText={t("formLabels.req")}
        >
          <input
            className="input"
            type="password"
            name="passwordRep"
            placeholder="********"
            value={newPasswordRep}
            onChange={e => setNewPasswordRep(e.target.value)}
          />
        </FormEntry>

        <FormEntry>
          <Submitting submitting={submitting}>
            <p className="control">
              <button
                className="button is-link is-outlined is-fullwidth"
                type="submit"
                onClick={resetPassword}
              >
                {t("resetPassword")}
              </button>
            </p>
          </Submitting>
        </FormEntry>
      </form>

      <hr />
      <LangAuthSelector />
    </AuthLayout>
  )
}

export default ResetPassword
