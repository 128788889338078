import axios from "axios"
import { useContext } from "react"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import { CommonDataContext } from "../../../context/CommonDataContext"
import { ProjectsContext } from "../../../context/ProjectsContext"
import useProfile from "../../../context/useProfile"
import { UserContext } from "../../../context/UserContext"
import { API_URL, messageToToasts } from "../../../utils"

const ProjectRecomendations = () => {
  const { t, i18n } = useTranslation("data")
  const { currentProject, refetch } = useContext(ProjectsContext)
  const {
    user: { userType, userID, role },
  } = useContext(UserContext)
  const { profile } = useProfile(userType, userID, userID)
  const {
    programmes: { dict },
  } = useContext(CommonDataContext)

  const isModerator = role === "moderator"

  const managersProgramme = prID =>
    userType === "academicManager" &&
    (profile?.curatedProgrammesID || []).includes(prID)

  const unrecomendProgramme = async programmeID => {
    try {
      const data = {
        programmeID,
        projectID: currentProject.id,
      }

      const resp = await axios.post(`${API_URL}/project/unrecomend`, data)

      const { status, message } = resp.data
      if (status === "failed") return messageToToasts(message, i18n.language)

      toast.success(t("project.unrecomendSuccess"))
      refetch("projects")
    } catch (error) {
      toast.error("Server error!")
    }
  }

  return (currentProject?.recomendingProgrammesID || []).map(rp =>
    dict[rp]?.isVisible || userType !== "projectLeader" ? (
      <article className="message is-success" key={rp}>
        <div className="message-body">
          <div className="columns">
            <div className="column is-9">
              {t("project.recprog") + ": "}
              <strong>{dict[rp]?.title[i18n.language]}</strong>
            </div>

            <div className="column is-3 has-text-right">
              {(userType === "admin" && !isModerator) ||
              managersProgramme(rp) ? (
                <span
                  className="pointer"
                  onClick={() => unrecomendProgramme(rp)}
                >
                  {t("remove")}
                </span>
              ) : null}
            </div>
          </div>
        </div>
      </article>
    ) : null
  )
}

export default ProjectRecomendations
