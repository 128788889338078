import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useTranslation } from "react-i18next"

const StudentStatus = ({ status, children }) => {
  let mode = "danger"
  if (status === "awaiting") mode = "warning"
  if (status === "approved") mode = "info"
  if (status === "awaitsGrade") mode = "violet"
  if (status === "finished") mode = "success"

  return <span className={"has-text-" + mode}>{children}</span>
}

export const StudentStatusHelp = () => {
  const { t } = useTranslation("data")

  return (
    <div>
      <strong>
        <p>{t("group.statusHelp")}:&nbsp;</p>
        <span className="has-text-danger">
          <FontAwesomeIcon icon="ellipsis-v" size="lg" />
          &nbsp;{t("group.notAssigned")}
        </span>
        ,&nbsp;
        <span className="has-text-warning">
          <FontAwesomeIcon icon="ellipsis-v" size="lg" />
          &nbsp;{t("group.awaiting")}
        </span>
        ,&nbsp;
        <span className="has-text-info">
          <FontAwesomeIcon icon="ellipsis-v" size="lg" />
          &nbsp;{t("group.approved")}
        </span>
        ,&nbsp;
        <span className="has-text-violet">
          <FontAwesomeIcon icon="ellipsis-v" size="lg" />
          &nbsp;{t("group.awaitsGradeStatus")}
        </span>
        ,&nbsp;
        <span className="has-text-success">
          <FontAwesomeIcon icon="ellipsis-v" size="lg" />
          &nbsp;{t("group.finished")}
        </span>
      </strong>
    </div>
  )
}

export default StudentStatus
