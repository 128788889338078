import axios from "axios"
import { useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ApplicationsStudentContext } from "../../../context/ApplicationsStudentContext"
import { SettingsContext } from "../../../context/SettingsContext"
import { UserContext } from "../../../context/UserContext"
import { API_URL, messageToToasts } from "../../../utils"

const ActionsWrapper = ({ children }) => (
  <div className="field is-grouped is-grouped-right">{children}</div>
)

const ApplicationsActions = ({ application, project, student }) => {
  const { t, i18n } = useTranslation("data")
  const {
    user: { userType, role },
  } = useContext(UserContext)
  const { refetch } = useContext(ApplicationsStudentContext)
  const { settings } = useContext(SettingsContext)
  const [submitting, setSubmitting] = useState(false)
  const [viewMode, setViewMode] = useState("view")

  const isModerator = role === "moderator"

  const updatePriority = async mode => {
    setSubmitting(true)

    try {
      const data = {
        applicationID: application.id,
        currentPriority: application.priority,
      }

      const resp = await axios.post(
        `${API_URL}/application/${
          mode === "inc" ? "increase" : "decrease"
        }Priority`,
        data
      )
      setSubmitting(false)
      const { status, message } = resp.data
      if (status === "failed") return messageToToasts(message, i18n.language)

      refetch()
    } catch (e) {
      setSubmitting(false)
      toast.error("Server error")
    }
  }

  const declineApplication = async () => {
    setSubmitting(true)

    try {
      const data = {
        applicationID: application.id,
        projectTitle: project?.title,
        email: student?.email,
      }
      const resp = await axios.post(`${API_URL}/application/decline`, data)

      setSubmitting(false)
      const { status, message } = resp.data
      if (status === "failed") return messageToToasts(message, i18n.language)

      refetch()
      setViewMode("view")
      toast.success(t("application.declineSuccess"))
    } catch (error) {
      setSubmitting(false)
      toast.error("Server error!")
    }
  }

  const acceptApplication = async (mode = "leader") => {
    setSubmitting(true)

    try {
      const data = {
        applicationID: application.id,
        projectTitle: project?.title,
        email: student?.email,
      }
      const resp = await axios.post(
        `${API_URL}/application/accept${
          mode === "leader" ? "Leader" : "Student"
        }`,
        data
      )

      setSubmitting(false)
      const { status, message } = resp.data
      if (status === "failed") return messageToToasts(message, i18n.language)

      refetch()

      setViewMode("view")
      toast.success(t("application.acceptSuccess"))

      if (userType === "student") {
        window.location.replace("/")
      }
    } catch (error) {
      setSubmitting(false)
      toast.error("Server error!")
    }
  }

  const decline = ![
    "accepted",
    "acceptedByLeader",
    "declined",
    "declinedSelf",
  ].includes(application.status) ? (
    <p className="control">
      <button
        className="button is-danger is-outlined"
        onClick={() => setViewMode("decline")}
      >
        {t("application.decline")}
      </button>
    </p>
  ) : null

  const accept = (
    <p className="control">
      <button
        className="button is-success is-outlined"
        onClick={() => setViewMode("accept")}
        disabled={submitting}
      >
        {t("application.accept")}
      </button>
    </p>
  )

  const updPriority = mode => (
    <p className="control">
      <button
        className="button is-link is-outlined"
        onClick={() => updatePriority(mode)}
        disabled={
          (mode === "inc" &&
            application.priority >= Number(settings?.applicationsPerStudent)) ||
          (mode === "dec" && application.priority <= 1)
        }
      >
        <FontAwesomeIcon
          icon={mode === "dec" ? "arrow-up" : "arrow-down"}
          size="lg"
        />
      </button>
    </p>
  )

  const cancelButton = (
    <p className="control">
      <button className="button is-light" onClick={() => setViewMode("view")}>
        {t("cancel")}
      </button>
    </p>
  )

  const sureWrapper = mode => {
    let action
    if (mode === "decline") {
      action = (
        <p className="control">
          <button
            className="button is-danger"
            onClick={declineApplication}
            disabled={submitting}
          >
            {t("application.decline")}
          </button>
        </p>
      )
    }

    if (mode === "acceptLeader") {
      action = (
        <p className="control">
          <button
            className="button is-success"
            onClick={() => acceptApplication("leader")}
            disabled={submitting}
          >
            {t("application.accept")}
          </button>
        </p>
      )
    }

    if (mode === "acceptStudent") {
      action = (
        <p className="control">
          <button
            className="button is-success"
            onClick={() => acceptApplication("student")}
            disabled={submitting}
          >
            {t("application.accept")}
          </button>
        </p>
      )
    }

    return (
      <ActionsWrapper>
        <p className="control">
          <button className="button is-static">
            <strong>{t("areSure")}</strong>
          </button>
        </p>

        {action}

        {cancelButton}
      </ActionsWrapper>
    )
  }

  const studentActions =
    viewMode === "view" ? (
      <ActionsWrapper>
        {!["accepted", "declined", "declinedSelf"].includes(application.status)
          ? updPriority("inc")
          : null}
        {!["accepted", "declined", "declinedSelf"].includes(application.status)
          ? updPriority("dec")
          : null}
        {decline}
        {application.status === "acceptedByLeader" ? accept : null}
      </ActionsWrapper>
    ) : viewMode === "decline" ? (
      sureWrapper("decline")
    ) : (
      sureWrapper("acceptStudent")
    )

  const leaderActions =
    viewMode === "view" ? (
      <ActionsWrapper>
        {decline}{" "}
        {application.status === "awaiting" &&
        (application.priority === 1 ||
          settings.leaderAcceptsFirstPriority === "notAllowed")
          ? accept
          : null}
      </ActionsWrapper>
    ) : viewMode === "decline" ? (
      sureWrapper("decline")
    ) : (
      sureWrapper("acceptLeader")
    )

  const adminActions = !isModerator ? (
    viewMode === "view" ? (
      <ActionsWrapper>
        {decline}{" "}
        {application.status === "awaiting" &&
        (application.priority === 1 ||
          settings.leaderAcceptsFirstPriority === "notAllowed")
          ? accept
          : null}
      </ActionsWrapper>
    ) : viewMode === "decline" ? (
      sureWrapper("decline")
    ) : (
      sureWrapper("acceptLeader")
    )
  ) : null

  if (userType === "student") return studentActions

  if (userType === "projectLeader")
    return settings.leaderApplicationsActions === "allowed"
      ? leaderActions
      : null

  if (userType === "admin") return adminActions

  return null
}

export default ApplicationsActions
