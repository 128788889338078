import { useTranslation } from "react-i18next"
import { usePaginatedApplicationsContext } from "../../../context/PaginatedApplicationsContext"
import ApplicationInfo from "../../data/application/ApplicationInfo"
import LoadingSpinner from "../../helpers/LoadingSpinner"
import Pagination from "../../helpers/Pagination"
import Select from "react-select"
import { useContext } from "react"
import { CommonDataContext } from "../../../context/CommonDataContext"
import { AcademicProgrammeContext } from "../../../context/AcademicProgrammeContext"
import { ProjectsContext } from "../../../context/ProjectsContext"

const ApplicationListView = () => {
  const { t } = useTranslation("data")
  const {
    applications,
    loading,
    currentPage,
    setCurrentPage,
    pageCount,
    switchStatus,
    switchStudentID,
    switchProjectID,
    queryStatus,
  } = usePaginatedApplicationsContext()
  const { students } = useContext(CommonDataContext)
  const { currentProgramme } = useContext(AcademicProgrammeContext)
  const { projects } = useContext(ProjectsContext)

  const pagination =
    pageCount > 1 ? (
      <Pagination
        page={currentPage}
        pageCount={pageCount}
        setPage={setCurrentPage}
      />
    ) : null

  const studentOptions = Object.values(students.dict)
    .filter(s => s.programmeID === currentProgramme)
    .sort((a, b) => a.fullName.localeCompare(b.fullName))
    .map(s => ({ value: s?.id, label: s?.fullName }))

  const projectOptions = projects.map(pr => ({
    value: pr?.id,
    label: `${pr?.num} - ${pr?.title}`,
  }))

  return (
    <>
      <div className="box">
        <div className="columns">
          <div className="column is-half">
            <Select
              options={studentOptions}
              onChange={opt => switchStudentID(opt?.value)}
              placeholder={t("application.studentPlaceholder")}
              isClearable
            />
          </div>

          <div className="column is-half">
            <Select
              options={projectOptions}
              onChange={opt => switchProjectID(opt?.value)}
              placeholder={t("application.projectPlaceholder")}
              isClearable
            />
          </div>
        </div>

        <hr />

        <p className="subtitle is-6">
          {t("application.showFilter")}
          <span
            className={"pointer" + (queryStatus === "" ? " has-text-link" : "")}
            onClick={() => switchStatus("")}
          >
            {t("application.all")}
          </span>
          /
          <span
            className={
              "pointer" +
              (queryStatus === "awaiting" ? " has-text-warning" : "")
            }
            onClick={() => switchStatus("awaiting")}
          >
            {t("application.awaiting")}
          </span>
          /
          <span
            className={
              "pointer" +
              (queryStatus === "accepted" ? " has-text-success" : "")
            }
            onClick={() => switchStatus("accepted")}
          >
            {t("application.accepted")}
          </span>
        </p>
      </div>

      {loading ? (
        <div className="box">
          <LoadingSpinner />
        </div>
      ) : (
        <>
          {applications.map(app => (
            <ApplicationInfo
              key={app?.id}
              application={app}
              showActions={false}
            />
          ))}

          {pagination}
        </>
      )}
    </>
  )
}

export default ApplicationListView
