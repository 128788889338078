import { useContext, useState } from "react"
import useProjectWishes from "../../../context/useProjectWishes"
import LoadingSpinner from "../../helpers/LoadingSpinner"
import { CommonDataContext } from "../../../context/CommonDataContext"
import dayjs from "dayjs"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import { toast } from "react-toastify"
import axios from "axios"
import { API_URL, messageToToasts } from "../../../utils"

const ProjectWishList = ({ mode }) => {
  const [submitting, setSubmitting] = useState(false)
  const { t, i18n } = useTranslation("data")
  const {
    academicManagers: { dict: ams },
    programmes: { dict: progDict },
  } = useContext(CommonDataContext)
  const { wishes, loading, refetch } = useProjectWishes(mode)

  const archiveWish = async wishID => {
    setSubmitting(true)

    try {
      const resp = await axios.post(`${API_URL}/project/archiveWish`, {
        wishID,
      })
      setSubmitting(false)

      const { status, message } = resp.data
      if (status === "failed") messageToToasts(message, i18n.language)

      refetch()
    } catch (error) {
      setSubmitting(false)
      toast.error("Server error!")
    }
  }

  if (loading)
    return (
      <div className="box">
        <LoadingSpinner />
      </div>
    )

  if (!wishes.length)
    return (
      <div className="box">
        <p className="has-text-grey">{t("emptyList")}</p>
      </div>
    )

  return (
    <div className="box">
      <p className="title is-5">{t("project.wish.list")}</p>
      <hr />

      {wishes.map(wish => (
        <div className="message is-link is-small" key={wish.id}>
          <div className="message-header">
            {ams[wish.managerID]?.fullName} (
            {progDict[wish.programmeID]?.title[i18n.language]}) -{" "}
            {dayjs(wish.created).format("DD/MM/YYYY HH:mm")}
          </div>
          <div className="message-body">
            <div style={{ whiteSpace: "pre-wrap" }}>{wish.wishText}</div>

            <div className="field is-grouped is-grouped-right mt-2">
              <p className="control">
                <Link
                  className="button is-small is-link is-outlined"
                  to={"/r/admin/projects/viewProject/" + wish.projectID}
                  target="_blank"
                >
                  {t("project.wish.toProject")}
                </Link>
              </p>
              {wish.active ? (
                <p className="control">
                  <button
                    className="button is-small is-link is-outlined"
                    onClick={() => archiveWish(wish.id)}
                    disabled={submitting}
                  >
                    {t("project.wish.markDone")}
                  </button>
                </p>
              ) : null}
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default ProjectWishList
