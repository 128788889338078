import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import axios from "axios"
import i18next from "i18next"
import { useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import { CommonDataContext } from "../../../context/CommonDataContext"
import { API_URL, messageToToasts } from "../../../utils"
import Select from "react-select"

const BulletinNew = ({ refetch }) => {
  const { t } = useTranslation("data")
  const {
    topics: { options },
  } = useContext(CommonDataContext)

  const [title, setTitle] = useState("")
  const [description, setDescription] = useState("")
  const [studentRequirements, setStudentRequirements] = useState("")
  const [contacts, setContacts] = useState("")
  const [selectedTopics, setSelectedTopics] = useState(null)

  const [sub, setSub] = useState(false)
  const [mode, setMode] = useState("view")

  const resetForm = () => {
    setTitle("")
    setDescription("")
    setStudentRequirements("")
    setContacts("")
    setSelectedTopics(null)

    setMode("view")
  }

  const addBulletin = async () => {
    setSub(true)

    try {
      const data = {
        title,
        description,
        studentRequirements,
        contacts,
        topicsID: (selectedTopics || []).map(opt => opt?.value),
      }

      const resp = await axios.post(`${API_URL}/bulletin/add`, data)
      const { status, message } = resp.data
      setSub(false)

      if (status === "failed")
        return toast.error(messageToToasts(message, i18next.language))

      resetForm()
      refetch()
    } catch (err) {
      setSub(false)
      toast.error("Server error")
    }
  }

  if (mode === "view")
    return (
      <div className="box has-text-centered">
        <span
          className="is-size-5 pointer has-text-link"
          onClick={() => setMode("add")}
        >
          <FontAwesomeIcon icon="plus" />
          &nbsp;{t("bulletin.addNewTitle")}
        </span>
      </div>
    )

  return (
    <div className="box">
      <p className="title is-5">{t("bulletin.addNewTitle")}</p>
      <hr />

      <div className="field">
        <div className="control">
          <label className="label">{t("bulletin.title")}</label>
          <input
            type="text"
            className="input"
            value={title}
            onChange={e => setTitle(e.target.value)}
            placeholder={t("bulletin.titlePlh")}
          />
        </div>
      </div>

      <div className="field">
        <div className="control">
          <label className="label">{t("bulletin.topics")}</label>
          <Select
            options={options[i18next?.language]}
            placeholder={t("bulletin.topicsPlh")}
            value={selectedTopics}
            onChange={setSelectedTopics}
            isMulti
            isClearable
          />
        </div>
      </div>

      <div className="columns">
        <div className="column is-half">
          <div className="field">
            <div className="control">
              <label className="label">{t("bulletin.description")}</label>
              <textarea
                rows="15"
                className="textarea"
                value={description}
                onChange={e => setDescription(e.target.value)}
                placeholder={t("bulletin.descriptionPlh")}
              />
            </div>
          </div>
        </div>

        <div className="column is-half">
          <div className="field">
            <div className="control">
              <label className="label">{t("bulletin.studentsReq")}</label>
              <textarea
                rows="6"
                className="textarea"
                value={studentRequirements}
                onChange={e => setStudentRequirements(e.target.value)}
                placeholder={t("bulletin.studentsReqPlh")}
              />
            </div>
          </div>

          <div className="field">
            <div className="control">
              <label className="label">{t("bulletin.contacts")}</label>
              <textarea
                rows="6"
                className="textarea"
                value={contacts}
                onChange={e => setContacts(e.target.value)}
                placeholder={t("bulletin.contactsPlh")}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="field is-grouped is-grouped-right">
        <div className="control">
          <button
            className="button is-light"
            onClick={() => setMode("view")}
            disabled={sub}
          >
            {t("cancel")}
          </button>
        </div>

        <div className="control">
          <button
            className="button is-link"
            onClick={addBulletin}
            disabled={sub}
          >
            {t("add")}
          </button>
        </div>
      </div>
    </div>
  )
}

export default BulletinNew
