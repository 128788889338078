import { useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import Select from "react-select"
import { CommonDataContext } from "../../../context/CommonDataContext"
import {
  API_URL,
  messageToToasts,
  yearsOptions,
  yearsToText,
} from "../../../utils"
import axios from "axios"
import { toast } from "react-toastify"
import useInfoUpdates from "../../../context/useInfoUpdates"
import dayjs from "dayjs"
import { UserContext } from "../../../context/UserContext"

const UpdateInfo = ({ profile, refetchProfile }) => {
  const { t, i18n } = useTranslation("ws")
  const {
    user: { userType },
  } = useContext(UserContext)
  const {
    programmes: { options: progOptions, dict: progDict },
  } = useContext(CommonDataContext)
  const { infoUpdates, refetch, decline, accept } = useInfoUpdates(profile?.id)

  const [selectedProgramme, setSelectedProgramme] = useState("")
  const [selectedYos, setSelectedYos] = useState("")

  const awaitingInfoUpdates = (infoUpdates || []).filter(
    iu => iu.status === "awaiting"
  )

  const requestUpdate = async () => {
    try {
      const data = {
        studentID: profile?.id,
        oldYos: profile?.yearOfStudy,
        newYos: selectedYos?.value || profile?.yearOfStudy,
        oldProgrammeID: profile?.programmeID,
        newProgrammeID: selectedProgramme?.value || profile?.programmeID,
      }

      const resp = await axios.post(`${API_URL}/studentInfoUpdate/add`, data)

      const { status, message } = resp.data
      if (status === "failed") return messageToToasts(message, i18n.language)

      toast.success(t("userProfilePage.sendRequestSuccess"))
      refetch()
      refetchProfile()
      setSelectedProgramme("")
      setSelectedYos("")
    } catch (err) {
      toast.error("Server error. Try to reload page or contact administrator")
    }
  }

  return (
    <div className="box">
      <p className="title is-5">{t("userProfilePage.updateInfo")}</p>

      <div className="field">
        <label className="label">{t("userProfilePage.programme")}</label>
        <div className="control">
          <Select
            placeholder={t("userProfilePage.selectProgramme")}
            options={progOptions[i18n.language]}
            value={selectedProgramme}
            onChange={setSelectedProgramme}
          />
        </div>
      </div>

      <div className="field">
        <label className="label">{t("userProfilePage.yearOfStudy")}</label>
        <div className="control">
          <Select
            placeholder={t("userProfilePage.selectYearOfStudy")}
            options={yearsOptions[i18n.language]}
            value={selectedYos}
            onChange={setSelectedYos}
          />
        </div>
      </div>

      <div className="field has-text-centered mt-4">
        <p className="control">
          <button
            className="button is-link is-outlined is-fullwidth"
            type="submit"
            onClick={requestUpdate}
          >
            {t("userProfilePage.sendRequest")}
          </button>
        </p>
      </div>

      {awaitingInfoUpdates.length === 0 || (
        <>
          <hr />

          <p className="title is-5">{t("userProfilePage.activeRequests")}</p>

          {awaitingInfoUpdates.map(iu => (
            <div key={iu?.id} className="mb-3">
              <p className="has-text-grey is-underlined">
                {dayjs(iu?.creationDate).format("DD-MM-YYYY HH:mm")}
              </p>

              <p>
                <strong>{t("userProfilePage.programme")}: </strong>
                {progDict[iu?.oldProgrammeID]?.title?.[i18n.language]}
                &nbsp;=&gt;&nbsp;
                {progDict[iu?.newProgrammeID]?.title?.[i18n.language]}
              </p>

              <p>
                <strong>{t("userProfilePage.yearOfStudy")}: </strong>
                {yearsToText(iu?.oldYos)}
                &nbsp;=&gt;&nbsp;
                {yearsToText(iu?.newYos)}
              </p>

              {iu?.status === "awaiting" ? (
                <div className="field is-grouped is-grouped-right mt-1">
                  {userType !== "admin" || (
                    <div className="control">
                      <span
                        className="has-text-success is-underlined pointer"
                        onClick={() => {
                          accept(iu?.id)
                          refetchProfile()
                        }}
                      >
                        {t("userProfilePage.accept")}
                      </span>
                    </div>
                  )}

                  <div className="control">
                    <span
                      className="has-text-danger is-underlined pointer"
                      onClick={() => decline(iu?.id)}
                    >
                      {t("userProfilePage.decline")}
                    </span>
                  </div>
                </div>
              ) : null}
            </div>
          ))}
        </>
      )}
    </div>
  )
}

export default UpdateInfo
