import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useContext } from "react"
import { useTranslation } from "react-i18next"
import Select from "react-select"
import { CommonDataContext } from "../../../context/CommonDataContext"
import FormEntry from "../../helpers/FormEntry"

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

const initialStudents = {
  firstBachelor: 0,
  secondBachelor: 0,
  thirdBachelor: 0,
  fourthBachelor: 0,
  firstMaster: 0,
  secondMaster: 0,
  studentsFEFU: 0,
}

const ProposalNewSeats = ({ seats, setSeats, category = "main" }) => {
  const { t, i18n } = useTranslation("data")
  const {
    programmes: {
      options: programmeOptions,
      loading: programmesLoading,
      dict: programmesDict,
    },
  } = useContext(CommonDataContext)

  const nullSelector = null

  const selectProgramme = opt => {
    if (
      (seats || []).filter(seat => seat?.programmeID === opt?.value)?.length > 0
    )
      return

    setSeats([...seats, { programmeID: opt?.value, students: initialStudents }])
  }

  const removeProgramme = programmeID => {
    setSeats(seats.filter(seat => seat?.programmeID !== programmeID))
  }

  const updateStudentSeat = (programmeID, position, event) => {
    const progs = [...seats]
    const index = seats.findIndex(seat => seat?.programmeID === programmeID)

    progs[index] = {
      programmeID,
      students: {
        ...progs[index].students,
        [position]: Number(event.target.value),
      },
    }

    setSeats(progs)
  }

  return (
    <>
      <FormEntry
        labelText={t(`proposal.seatsTitle${capitalizeFirstLetter(category)}`)}
        helpText={t("proposal.seatsHelp")}
      >
        <Select
          placeholder={t("proposal.seatsPlaceholder")}
          options={(programmeOptions[i18n.language] || []).filter(
            opt => opt?.category === category
          )}
          value={nullSelector}
          onChange={selectProgramme}
          isLoading={programmesLoading}
        />
      </FormEntry>

      <div>
        {(seats || []).map(seat => {
          const bachelorMode =
            programmesDict[seat?.programmeID]?.mode === "bachelor"

          return (
            <div
              key={seat?.programmeID}
              className="mb-5 has-background-info-light py-2"
            >
              <div className="field has-addons">
                <p className="control is-expanded">
                  <button className="button is-fullwidth is-multiline is-static">
                    {programmesDict[seat?.programmeID]?.title[i18n.language]}
                  </button>
                </p>
                <p className="control">
                  <button
                    className="button is-danger"
                    onClick={() => removeProgramme(seat?.programmeID)}
                  >
                    <FontAwesomeIcon icon="trash" />
                  </button>
                </p>
              </div>

              <div className="columns">
                <div className="column is-half">
                  {!bachelorMode || (
                    <div className="field has-addons">
                      <p className="control">
                        <button className="button is-static">
                          {t("proposal.b1")}
                        </button>
                      </p>
                      <p className="control is-expanded">
                        <input
                          className="input has-text-right"
                          type="number"
                          min={0}
                          value={seat?.students?.firstBachelor}
                          onChange={e =>
                            updateStudentSeat(
                              seat?.programmeID,
                              "firstBachelor",
                              e
                            )
                          }
                        />
                      </p>
                    </div>
                  )}

                  {!bachelorMode || (
                    <div className="field has-addons">
                      <p className="control">
                        <button className="button is-static">
                          {t("proposal.b2")}
                        </button>
                      </p>
                      <p className="control is-expanded">
                        <input
                          className="input has-text-right"
                          type="number"
                          min={0}
                          value={seat?.students?.secondBachelor}
                          onChange={e =>
                            updateStudentSeat(
                              seat?.programmeID,
                              "secondBachelor",
                              e
                            )
                          }
                        />
                      </p>
                    </div>
                  )}

                  {bachelorMode || (
                    <div className="field has-addons">
                      <p className="control">
                        <button className="button is-static">
                          {t("proposal.m1")}
                        </button>
                      </p>
                      <p className="control is-expanded">
                        <input
                          className="input has-text-right"
                          type="number"
                          min={0}
                          value={seat?.students?.firstMaster}
                          onChange={e =>
                            updateStudentSeat(
                              seat?.programmeID,
                              "firstMaster",
                              e
                            )
                          }
                        />
                      </p>
                    </div>
                  )}
                </div>

                <div className="column is-half">
                  {!bachelorMode || (
                    <div className="field has-addons">
                      <p className="control">
                        <button className="button is-static">
                          {t("proposal.b3")}
                        </button>
                      </p>
                      <p className="control is-expanded">
                        <input
                          className="input has-text-right"
                          type="number"
                          min={0}
                          value={seat?.students?.thirdBachelor}
                          onChange={e =>
                            updateStudentSeat(
                              seat?.programmeID,
                              "thirdBachelor",
                              e
                            )
                          }
                        />
                      </p>
                    </div>
                  )}

                  {!bachelorMode || (
                    <div className="field has-addons">
                      <p className="control">
                        <button className="button is-static">
                          {t("proposal.b4")}
                        </button>
                      </p>
                      <p className="control is-expanded">
                        <input
                          className="input has-text-right"
                          type="number"
                          min={0}
                          value={seat?.students?.fourthBachelor}
                          onChange={e =>
                            updateStudentSeat(
                              seat?.programmeID,
                              "fourthBachelor",
                              e
                            )
                          }
                        />
                      </p>
                    </div>
                  )}

                  {bachelorMode || (
                    <div className="field has-addons">
                      <p className="control">
                        <button className="button is-static">
                          {t("proposal.m2")}
                        </button>
                      </p>
                      <p className="control is-expanded">
                        <input
                          className="input has-text-right"
                          type="number"
                          min={0}
                          value={seat?.students?.secondMaster}
                          onChange={e =>
                            updateStudentSeat(
                              seat?.programmeID,
                              "secondMaster",
                              e
                            )
                          }
                        />
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )
        })}
      </div>

      {/* <pre>{JSON.stringify(seats, "", 2)}</pre> */}
    </>
  )
}

export default ProposalNewSeats
