import { useTranslation } from "react-i18next"
import {
  Link,
  Redirect,
  Route,
  Switch,
  useParams,
  useRouteMatch,
} from "react-router-dom"
import ForgotPasswordForm from "./ForgotPasswordForm"
import LoginForm from "./LoginForm"
import SignUpForm from "./SignUpForm"

const Login = () => {
  const { path, url } = useRouteMatch()
  const { userType } = useParams()
  const { t } = useTranslation("auth")

  return (
    <>
      <Switch>
        <Route exact path={path}>
          <Redirect to={url + "/signIn"} />
        </Route>
        <Route exact path={path + "/signIn"}>
          <LoginForm userType={userType} />
        </Route>
        <Route exact path={path + "/signUp"}>
          <SignUpForm userType={userType} />
        </Route>
        <Route exact path={path + "/forgotPassword"}>
          <ForgotPasswordForm userType={userType} />
        </Route>
      </Switch>

      <p className="has-text-centered is-size- mt-5">
        <Link to="/auth" className="has-text-grey">
          {t("back")}
        </Link>
        &nbsp;|&nbsp;
        <Route exact path={path + "/signIn"}>
          <Link to={url + "/signUp"} className="has-text-grey">
            {t("signUp")}
          </Link>
          &nbsp;|&nbsp;
          <Link to={url + "/forgotPassword"} className="has-text-grey">
            {t("forgotPassword")}
          </Link>
        </Route>
        <Route exact path={path + "/signUp"}>
          <Link to={url + "/signIn"} className="has-text-grey">
            {t("signIn")}
          </Link>
          &nbsp;|&nbsp;
          <Link to={url + "/forgotPassword"} className="has-text-grey">
            {t("forgotPassword")}
          </Link>
        </Route>
        <Route exact path={path + "/forgotPassword"}>
          <Link to={url + "/signIn"} className="has-text-grey">
            {t("signIn")}
          </Link>
          &nbsp;|&nbsp;
          <Link to={url + "/signUp"} className="has-text-grey">
            {t("signUp")}
          </Link>
        </Route>
      </p>
    </>
  )
}

export default Login
