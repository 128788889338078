import { useTranslation } from "react-i18next"
import { useTransferContext } from "../../../context/TransferContext"
import TransferList from "../../data/transfer/TransferList"

const TransferView = () => {
  const { t } = useTranslation("data")
  const { mode, switchMode, transfers } = useTransferContext()

  const fromCount = transfers.filter(
    tr => tr.mode === "from" && tr.status === "awaiting"
  ).length
  const toCount = transfers.filter(
    tr => tr.mode === "to" && tr.status === "awaiting"
  ).length
  const bothCount = transfers.filter(
    tr => tr.mode === "both" && tr.status === "awaiting"
  ).length

  return (
    <>
      <div className="tabs is-centered">
        <ul>
          <li className={mode === "from" ? " is-active" : ""}>
            <a href="#from" onClick={() => switchMode("from")}>
              {t("transfer.from")}
              {fromCount <= 0 || (
                <>
                  &nbsp;
                  <span className="tag is-rounded is-danger">{fromCount}</span>
                </>
              )}
            </a>
          </li>

          <li className={mode === "to" ? " is-active" : ""}>
            <a href="#to" onClick={() => switchMode("to")}>
              {t("transfer.to")}
              {toCount <= 0 || (
                <>
                  &nbsp;
                  <span className="tag is-rounded is-danger">{toCount}</span>
                </>
              )}
            </a>
          </li>

          <li className={mode === "both" ? " is-active" : ""}>
            <a href="#both" onClick={() => switchMode("both")}>
              {t("transfer.both")}
              {bothCount <= 0 || (
                <>
                  &nbsp;
                  <span className="tag is-rounded is-danger">{bothCount}</span>
                </>
              )}
            </a>
          </li>
        </ul>
      </div>

      <TransferList />
    </>
  )
}

export default TransferView
