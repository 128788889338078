import axios from "axios"
import { useContext } from "react"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import { UserContext } from "../../../context/UserContext"
import { API_URL, messageToToasts } from "../../../utils"
import SessionList from "../../data/SessionList"

const SessionsView = () => {
  const { user, logout } = useContext(UserContext)
  const { t, i18n } = useTranslation("ws")

  const signOutAll = async () => {
    try {
      const data = {
        userID: user.userID,
      }

      const resp = await axios.post(
        `${API_URL}/user/session/removeForUser`,
        data
      )

      const { status, message } = resp.data

      if (status === "failed") return messageToToasts(message, i18n.language)

      toast.success(t("userProfilePage.signOutSuccessMessage"))
      logout()
    } catch (err) {
      toast.error("Server error. Try to reload page or contact administrator")
    }
  }

  return (
    <div className="box">
      <p className="title is-5">{t("userProfilePage.mySessionsTitle")}</p>
      <SessionList forUser={true} />
      <button
        className="button is-danger is-outlined is-fullwidth"
        onClick={signOutAll}
      >
        {t("userProfilePage.removeAllSessions")}
      </button>
    </div>
  )
}

export default SessionsView
