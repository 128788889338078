import axios from "axios"
import dayjs from "dayjs"
import { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import { toast } from "react-toastify"
import { CommonDataContext } from "../../../context/CommonDataContext"
import { API_URL, messageToToasts } from "../../../utils"

const ProposalLog = ({ proposalID }) => {
  const { t, i18n } = useTranslation("data")
  const { allUsersDict } = useContext(CommonDataContext)
  const [history, setHistory] = useState({})
  const [loading, setLoading] = useState(true)

  useEffect(
    () => {
      const fetchHistory = async () => {
        try {
          const requestURL = `${API_URL}/project/proposal/history?proposalID=${proposalID}`
          const resp = await axios.get(requestURL)
          const { status, message, history } = resp.data

          if (status === "failed") {
            return messageToToasts(message, i18n.language)
          }

          setHistory(history)
          setLoading(false)
        } catch (err) {
          toast.error(
            "Server error. Try to reload page or contact administrator"
          )
        }
      }

      fetchHistory()
    },
    // eslint-disable-next-line
    [proposalID]
  )

  if (loading) return null

  return (
    <div className="box">
      <p className="title is-5">{t("proposal.history")}</p>

      <div className="table-container">
        <table className="table is-fullwidth">
          <thead>
            <tr>
              <th>{t("proposal.userEmail")}</th>
              <th>{t("proposal.action")}</th>
              <th>{t("proposal.date")}</th>
            </tr>
          </thead>
          <tbody>
            {history.history.map(entry => {
              const entryUser = allUsersDict[entry.userType][entry.userID]

              return (
                <tr key={entry.date}>
                  <td>
                    <Link
                      to={`/r/common/profile?userID=${entryUser?.id}&userType=${entry.userType}`}
                      target="_blank"
                    >
                      <strong>{entryUser?.email}</strong>
                    </Link>
                  </td>
                  <td>{t(`proposal.${entry.action}Action`)}</td>
                  <td>{dayjs(entry.date).format("DD/MM/YYYY HH:mm:ss")}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default ProposalLog
