import axios from "axios"
import i18next from "i18next"
import { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { API_URL, messageToToasts } from "../utils"

const useProjectChanges = (mode, projectID = "") => {
  const [changes, setChanges] = useState([])
  const [loading, setLoading] = useState(true)
  const [needRefetch, setNeedRefetch] = useState(true)

  useEffect(() => {
    const fetchChanges = async () => {
      try {
        const requestURL =
          mode === "all"
            ? `${API_URL}/project/listProjectChangeReqForAdmin`
            : `${API_URL}/project/listProjectChangeReqForProject?projectID=${projectID}`

        const resp = await axios.get(requestURL)
        const { status, message, changes } = resp.data

        if (status === "failed")
          return messageToToasts(message, i18next.language)

        setChanges(changes)
        setLoading(false)
        setNeedRefetch(false)
      } catch (error) {
        toast.error("Server error")
      }
    }

    if (needRefetch) {
      setLoading(true)
      fetchChanges()
    }
  }, [mode, projectID, needRefetch])

  const refetch = () => setNeedRefetch(true)

  return { changes, loading, refetch }
}

export default useProjectChanges
