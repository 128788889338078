import { useContext } from "react"
import { useTranslation } from "react-i18next"
import { ApplicationsStudentContext } from "../../../context/ApplicationsStudentContext"
import { SettingsContext } from "../../../context/SettingsContext"
import ApplicationList from "../../data/application/ApplicationList"

const ApplicationListView = () => {
  const { t } = useTranslation("data")
  const { settings } = useContext(SettingsContext)
  const { awaitingLength } = useContext(ApplicationsStudentContext)

  return (
    <>
      {settings?.leaderAcceptApplication === "allowed" ? (
        <div className="message is-success">
          <div className="message-body">
            {t("application.shortProcedureLeader")}
          </div>
        </div>
      ) : null}

      {settings?.leaderAcceptsFirstPriority === "allowed" ? (
        <div className="message is-warning">
          <div className="message-body">
            {t("application.acceptOnlyFirstPriority")}
          </div>
        </div>
      ) : null}

      <div
        className={"message " + (awaitingLength ? "is-warning" : "is-success")}
      >
        <div className="message-body has-text-centered">
          <strong>
            {awaitingLength
              ? t("application.awaitsDecisionText", { awaitingLength })
              : t("application.nothingAwaits")}
          </strong>
        </div>
      </div>

      <ApplicationList forProject={false} />
    </>
  )
}

export default ApplicationListView
