import axios from "axios"
import { useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router"
import { toast } from "react-toastify"
import { AcademicProgrammeContext } from "../../../context/AcademicProgrammeContext"
import { CommonDataContext } from "../../../context/CommonDataContext"
import { ProjectsContext } from "../../../context/ProjectsContext"
import useProgrammeDiscussion from "../../../context/useProgrammeDiscussion"
import { UserContext } from "../../../context/UserContext"
import { API_URL, messageToToasts } from "../../../utils"
import FormEntry from "../../helpers/FormEntry"
import LoadingSpinner from "../../helpers/LoadingSpinner"
import Message from "./Message"

const ProgrammeDiscussion = () => {
  const {
    user: { userType },
  } = useContext(UserContext)
  const {
    programmes: { dict: progDict },
  } = useContext(CommonDataContext)
  const {
    currentProject: { id: projectID },
  } = useContext(ProjectsContext)
  const { currentProgramme } = useContext(AcademicProgrammeContext)
  const { programmeID } = useParams()
  const { t, i18n } = useTranslation("data")
  const [messageText, setMessageText] = useState("")

  const derivedProgrammeID = programmeID ? programmeID : currentProgramme
  const { discussion, loading, setNeedRefetch } = useProgrammeDiscussion(
    projectID,
    derivedProgrammeID
  )

  const updateMessageText = newVal => {
    if (newVal.length > 500) return

    setMessageText(newVal)
  }

  const sendMessage = async () => {
    try {
      const data = {
        projectID,
        programmeID: derivedProgrammeID,
        messageText,
      }

      const resp = await axios.post(
        `${API_URL}/project/addProgrammeMessage`,
        data
      )
      const { status, message } = resp.data

      if (status === "failed") return messageToToasts(message, i18n.language)

      toast.success(t("project.messageSendSuccess"))
      setNeedRefetch(true)
      setMessageText("")
    } catch (error) {
      toast.error("Server error!")
    }
  }

  if (loading)
    return (
      <div className="box">
        <LoadingSpinner />
      </div>
    )

  console.log(discussion)

  return (
    <div className="box">
      {userType === "academicManager" ? (
        <p className="title is-5">{t("project.programmeDiscussionTitle")}</p>
      ) : null}

      {userType === "projectLeader" ? (
        <>
          <p className="is-size-5 has-text-centered">
            {t("project.dialogForEP") +
              ": " +
              progDict[derivedProgrammeID]?.title[i18n.language]}
          </p>
          <hr />
        </>
      ) : null}

      {(discussion?.messages || []).length ? (
        discussion?.messages.map(message => (
          <Message message={message} key={message.id} />
        ))
      ) : (
        <p className="mb-5">{t("project.discussionHelp")}</p>
      )}

      <FormEntry>
        <textarea
          className="textarea"
          value={messageText}
          onChange={e => updateMessageText(e.target.value)}
          placeholder={t("project.messageProgrammePlaceholder")}
        />
        <p className="help">
          {t("project.wish.usedSymbols", { symbols: messageText.length })}
        </p>
      </FormEntry>

      <div className="field is-grouped is-grouped-right">
        <p className="control">
          <button
            className="button is-link is-outlined is-capitalized"
            onClick={sendMessage}
          >
            {t("send")}
          </button>
        </p>
      </div>
    </div>
  )
}

export default ProgrammeDiscussion
