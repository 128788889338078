import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import axios from "axios"
import dayjs from "dayjs"
import i18next from "i18next"
import { useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import { CommonDataContext } from "../../../context/CommonDataContext"
import { useTransferContext } from "../../../context/TransferContext"
import { UserContext } from "../../../context/UserContext"
import { API_URL, messageToToasts, nilObjectID } from "../../../utils"
import FormEntry from "../../helpers/FormEntry"

const TransferChat = ({ chat, isSendable, transferID }) => {
  const { t } = useTranslation("data")
  const { refetch } = useTransferContext()
  const {
    user: { userID },
  } = useContext(UserContext)
  const { allUsersDict } = useContext(CommonDataContext)
  const authors = {
    [nilObjectID]: { fullName: "FES Projects" },
    ...allUsersDict.students,
    ...allUsersDict.projectLeader,
    ...allUsersDict.admin,
  }

  const [mode, setMode] = useState("view")
  const [text, setText] = useState("")

  const updateText = event => {
    const newValue = event.target.value
    if (newValue.length <= 300) setText(newValue)
    else setText(newValue.substring(0, 300))
  }

  const sendMessage = async () => {
    try {
      const data = {
        transferID,
        text,
      }

      const resp = await axios.post(`${API_URL}/transfer/sendMsg`, data)
      const { status, message } = resp.data

      if (status === "failed") return messageToToasts(message, i18next.language)

      setText("")
      refetch()
    } catch (error) {
      toast.error("Server error!")
    }
  }

  if (mode === "chat")
    return (
      <>
        <div className="has-background-light px-1">
          {chat.map(msg => (
            <div
              className="columns"
              key={msg.authorID + ":::" + msg.creationDate}
            >
              <div
                className={
                  "column is-7" +
                  (msg.authorID === userID ? " is-offset-5" : "")
                }
              >
                <div
                  className={
                    "message is-small is-" +
                    (msg?.authorID === nilObjectID ? "info" : "link")
                  }
                >
                  <div className="message-header is-size-7">
                    {authors[msg?.authorID]?.fullName}
                  </div>
                  <div
                    className="message-body"
                    style={{ whiteSpace: "pre-wrap" }}
                  >
                    <p>{msg?.text}</p>
                    <br />
                    <p className="has-text-right is-italic">
                      {dayjs(msg?.creationDate).format("DD/MM/YYYY HH:mm")}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        {isSendable ? (
          <>
            <hr />

            <FormEntry helpText={t("used") + ": " + text.length + "/300"}>
              <textarea
                className="textarea"
                rows="2"
                placeholder={t("transfer.msgPlaceholder")}
                value={text}
                onChange={updateText}
              />
            </FormEntry>

            <div className="field is-grouped is-grouped-right">
              <button
                className="button is-small is-light"
                onClick={() => setMode("view")}
              >
                {t("cancel")}
              </button>
              &nbsp;
              <button
                className="button is-small is-link is-capitalized"
                onClick={sendMessage}
              >
                {t("send")}
              </button>
            </div>
          </>
        ) : (
          <div className="field mt-3">
            <p className="control">
              <button
                className="button is-fullwidth is-small"
                onClick={() => setMode("view")}
              >
                <FontAwesomeIcon icon="chevron-up" />
                &nbsp;
                {t("transfer.hideChat")}&nbsp;
                <FontAwesomeIcon icon="chevron-up" />
              </button>
            </p>
          </div>
        )}
      </>
    )

  return (
    <div className="has-background-light px-1">
      <div className="columns">
        <div
          className={
            "column is-7" + (chat[0]?.authorID === userID ? " is-offset-5" : "")
          }
        >
          <div
            className={
              "message is-small is-" +
              (chat[0]?.authorID === nilObjectID ? "info" : "link")
            }
          >
            <div className="message-header is-size-7">
              {authors[chat[0]?.authorID]?.fullName}
            </div>
            <div className="message-body" style={{ whiteSpace: "pre-wrap" }}>
              <p>{chat[0]?.text}</p>
              <br />
              <p className="has-text-right is-italic">
                {dayjs(chat[0]?.creationDate).format("DD/MM/YYYY HH:mm")}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="field">
        <p className="control">
          <button
            className="button is-fullwidth is-small"
            onClick={() => setMode("chat")}
          >
            <FontAwesomeIcon icon="chevron-down" />
            &nbsp;
            {t("transfer.chat")}&nbsp;
            <FontAwesomeIcon icon="chevron-down" />
          </button>
        </p>
      </div>
    </div>
  )
}

export default TransferChat
