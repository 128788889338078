import axios from "axios"
import i18next from "i18next"
import { useContext, useEffect, useState } from "react"
import { toast } from "react-toastify"
import { API_URL, messageToToasts } from "../utils"
import { UserContext } from "./UserContext"

const useGroup = groupID => {
  const {
    user: { userType },
  } = useContext(UserContext)
  const [group, setGroup] = useState([])
  const [loading, setLoading] = useState(true)
  const [needRefetch, setNeedRefetch] = useState(true)

  useEffect(() => {
    const fetchGroup = async () => {
      try {
        const resp = await axios.get(`${API_URL}/group/get?groupID=${groupID}`)
        const { status, message, group } = resp.data

        setLoading(false)

        if (status === "failed" && userType === "student") return

        if (status === "failed")
          return toast.error(messageToToasts(message, i18next.language))

        setGroup(group)
      } catch (error) {
        setLoading(false)
        toast.error("Server error")
      }
    }

    fetchGroup()
    setNeedRefetch(false)
  }, [needRefetch, groupID, userType])

  const refetch = (needsReload = true) => {
    if (needsReload) setLoading(true)

    setNeedRefetch(true)
  }

  return { group, loading, refetch }
}

export default useGroup
