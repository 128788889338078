import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import axios from "axios"
import i18next from "i18next"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import { useBlogPostContext } from "../../../context/BlogPostContext"
import { API_URL, messageToToasts } from "../../../utils"
import FormEntry from "../../helpers/FormEntry"
import LatexMarkdownForm from "../../helpers/LatexMarkdownForm"

const BlogPostNew = ({ projectID }) => {
  const { t } = useTranslation("data")
  const { refetch } = useBlogPostContext()

  const [submitting, setSubmitting] = useState(false)
  const [mode, setMode] = useState("view")

  const [title, setTitle] = useState("")
  const [text, setText] = useState("")
  const [isCommentable, setIsCommentable] = useState(true)
  const [isEmail, setIsEmail] = useState(true)

  const resetForm = () => {
    setTitle("")
    setText("")
    setIsCommentable(true)
    setIsEmail(true)
    setMode("view")
  }

  const addBlogPost = async () => {
    setSubmitting(true)

    try {
      const resp = await axios.post(`${API_URL}/blogPost/add`, {
        projectID,
        title,
        text,
        isCommentable,
        isEmail,
      })
      const { status, message } = resp.data
      setSubmitting(false)

      if (status === "failed") return messageToToasts(message, i18next.language)

      setSubmitting(false)
      resetForm()
      refetch()
      toast.success(t("blogpost.addSuccess"))
    } catch (error) {
      setSubmitting(false)
    }
  }

  if (mode === "view")
    return (
      <div className="box has-text-centered">
        <span
          className="is-size-5 pointer has-text-link"
          onClick={() => setMode("add")}
        >
          <FontAwesomeIcon icon="plus" />
          &nbsp;{t("blogpost.addPhl")}
        </span>
      </div>
    )

  return (
    <div className="box">
      <FormEntry labelText={t("blogpost.title")} helpText={t("req")}>
        <input
          type="text"
          className="input"
          placeholder={t("blogpost.titlePlh")}
          value={title}
          onChange={e => setTitle(e.target.value)}
        />
      </FormEntry>

      <LatexMarkdownForm
        labelText={t("blogpost.text")}
        value={text}
        setValue={setText}
      />

      <div className="field pt-2">
        <label className="switch is-rounded">
          <input
            type="checkbox"
            value="false"
            checked={isEmail}
            onChange={e => setIsEmail(e.target.checked)}
          />
          <span className="check is-link"></span>
          &nbsp; {t("blogpost.isEmail")}
        </label>
      </div>

      <div className="columns">
        <div className="column is-half">
          <div className="field pt-2">
            <label className="switch is-rounded">
              <input
                type="checkbox"
                value="false"
                checked={isCommentable}
                onChange={e => setIsCommentable(e.target.checked)}
              />
              <span className="check is-link"></span>
              &nbsp; {t("blogpost.comments")}
            </label>
          </div>
        </div>

        <div className="column is-half">
          <div className="field is-grouped is-grouped-right">
            <p className="control">
              <button className="button is-light" onClick={() => resetForm()}>
                {t("cancel")}
              </button>
            </p>
            <p className="control">
              <button
                className="button is-link"
                disabled={submitting}
                onClick={addBlogPost}
              >
                {t("add")}
              </button>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BlogPostNew
