import { useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import { useLocation, useParams } from "react-router"
import useGroup from "../../../context/useGroup"
import { UserContext } from "../../../context/UserContext"
import LoadingSpinner from "../../helpers/LoadingSpinner"
import AsgnChangePage from "./AsgnChangePage"
import AsgnStudent from "./AsgnStudent"
import GroupEdit from "./GroupEdit"
import GroupGrades from "./GroupGrades"
import GroupInfo from "./GroupInfo"
import GroupStudents from "./GroupStudents"

const GroupPage = () => {
  const {
    user: { userType, userID },
  } = useContext(UserContext)
  const { t } = useTranslation("data")
  const { hash } = useLocation()
  const { groupID } = useParams()
  const { group, loading, refetch } = useGroup(groupID)

  const [pageMode, setPageMode] = useState(
    hash ? hash.replace("#", "") : "info"
  )

  const myProject =
    (group?.leadersID || []).includes(userID) ||
    userID === group?.projectLeaderID

  if (loading || (!group?.leadersID?.length && userType !== "student"))
    return (
      <div className="box">
        <LoadingSpinner />
      </div>
    )

  if (!group?.theme && userType === "student")
    return <div className="box">{t("group.noGroup")}</div>

  return (
    <>
      <div className="box">
        <p className="title is-4">{group?.theme}</p>
      </div>

      <div className="box">
        <p>
          {t("group.groupYear")}: {group?.groupYear}/
          {Number(group?.groupYear) - 1999}
        </p>
      </div>

      <div className="columns">
        <div className="column is-2">
          <div className="box">
            <aside className="menu">
              <p className="menu-label">{t("group.group")}</p>
              <ul className="menu-list">
                <li>
                  <a href="#info" onClick={() => setPageMode("info")}>
                    {t("group.info")}
                  </a>
                </li>

                {!(myProject || userType === "admin") || (
                  <li>
                    <a href="#edit" onClick={() => setPageMode("edit")}>
                      {t("group.edit")}
                    </a>
                  </li>
                )}

                {userType !== "student" || (
                  <li>
                    <a href="#asgn" onClick={() => setPageMode("asgn")}>
                      {t("group.myAsgn")}
                    </a>
                  </li>
                )}

                <li>
                  <a
                    href="#asgnChange"
                    onClick={() => setPageMode("asgnChange")}
                  >
                    {t("group.asgnChange")}
                  </a>
                </li>

                <li>
                  <a href="#students" onClick={() => setPageMode("students")}>
                    {t("group.students")}
                  </a>
                </li>

                {!(myProject || userType === "admin") || (
                  <li>
                    <a href="#grades" onClick={() => setPageMode("grades")}>
                      {t("group.grades")}
                    </a>
                  </li>
                )}
              </ul>
            </aside>
          </div>
        </div>

        <div className="column is-10">
          {pageMode !== "info" || <GroupInfo group={group} />}

          {pageMode !== "asgn" || <AsgnStudent />}

          {pageMode !== "asgnChange" || <AsgnChangePage groupID={group?.id} />}

          {pageMode !== "edit" || <GroupEdit group={group} refetch={refetch} />}

          {pageMode !== "students" || (
            <GroupStudents group={group} refetch={refetch} />
          )}

          {pageMode !== "grades" || (
            <GroupGrades group={group} refetch={refetch} />
          )}
        </div>
      </div>
    </>
  )
}

export default GroupPage
