import { useState } from "react"

function chunk(arr, len) {
  var chunks = [],
    i = 0,
    n = arr.length

  while (i < n) {
    chunks.push(arr.slice(i, (i += len)))
  }

  return chunks
}

const PracticeStudentEmails = ({ students }) => {
  const [showEmail, setShowEmail] = useState(false)
  const [emailPerGroup, setEmailPerGroup] = useState(30)
  const [separator, setSeparator] = useState(", ")

  return (
    <>
      <div className="box has-text-centered">
        <span
          className="pointer tag is-link"
          onClick={() => setShowEmail(!showEmail)}
        >
          {showEmail ? "Скрыть" : "Показать"}
        </span>
        &nbsp;email группами по&nbsp;
        <span
          onClick={() => setEmailPerGroup(20)}
          className={
            "pointer tag " + (emailPerGroup === 20 ? "is-link" : "is-dark")
          }
        >
          20
        </span>
        &nbsp;
        <span
          onClick={() => setEmailPerGroup(30)}
          className={
            "pointer tag " + (emailPerGroup === 30 ? "is-link" : "is-dark")
          }
        >
          30
        </span>
        &nbsp;
        <span
          onClick={() => setEmailPerGroup(50)}
          className={
            "pointer tag " + (emailPerGroup === 50 ? "is-link" : "is-dark")
          }
        >
          50
        </span>
        &nbsp;адресов с разделителем&nbsp;
        <span
          onClick={() => setSeparator(", ")}
          className={
            "pointer tag " + (separator === ", " ? "is-link" : "is-dark")
          }
        >
          ,
        </span>
        &nbsp;
        <span
          onClick={() => setSeparator("; ")}
          className={
            "pointer tag " + (separator === "; " ? "is-link" : "is-dark")
          }
        >
          ;
        </span>
      </div>

      {!showEmail ||
        chunk(
          students.map(st => st?.email),
          emailPerGroup
        ).map(group => (
          <div className="box" key={group[0]}>
            {group.join(separator)}
          </div>
        ))}
    </>
  )
}

export default PracticeStudentEmails
