import { useContext, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { Link, Redirect, Route, Switch, useRouteMatch } from "react-router-dom"
import { AcademicProgrammeContext } from "../../context/AcademicProgrammeContext"
import { ApplicationsStudentContext } from "../../context/ApplicationsStudentContext"
import { CommonDataContext } from "../../context/CommonDataContext"
import ProjectsContextProvider from "../../context/ProjectsContext"
import ProjectsFilterContextProvider from "../../context/ProjectsFilterContext"
import { SettingsContext } from "../../context/SettingsContext"
import TransferContextProvider from "../../context/TransferContext"
import useProfile from "../../context/useProfile"
import { UserContext } from "../../context/UserContext"
import { nilObjectID } from "../../utils"
import TransferList from "../data/transfer/TransferList"
import LoadingSpinner from "../helpers/LoadingSpinner"
import NavBar from "../helpers/NavBar"
import HomeView from "./HomeView"
import BlogView from "./student/BlogView"
import ApplicationsPageView from "./student/ApplicationsPageView"
import MyProjectView from "./student/MyProjectView"
import ProjectsPageView from "./student/ProjectsPageView"
import GroupPage from "../data/groups/GroupPage"
import PracticeNew from "../data/practice/PracticeNew"

const StudentWorkspace = () => {
  const { t, i18n } = useTranslation("ws")
  const { url, path } = useRouteMatch()
  const { user } = useContext(UserContext)
  const { settings } = useContext(SettingsContext)
  const {
    programmes: { dict: progDict },
  } = useContext(CommonDataContext)
  const { currentProgramme, updateCurrentProgramme } = useContext(
    AcademicProgrammeContext
  )
  const { applicationsLength, acceptedByLeaderLength } = useContext(
    ApplicationsStudentContext
  )
  const { loading, profile } = useProfile(
    user.userType,
    user.userID,
    user.userID
  )

  useEffect(() => {
    if (
      profile?.programmeID &&
      (currentProgramme === "" || profile.programmeID !== currentProgramme)
    )
      updateCurrentProgramme(profile.programmeID)
  }, [profile?.programmeID, updateCurrentProgramme, currentProgramme])

  if (loading) return <LoadingSpinner />

  const hasProject = profile.projectID !== nilObjectID

  return (
    <>
      {hasProject ? (
        <NavBar>
          {/* Temporal add to show apps if have project */}
          {applicationsLength ? (
            <Link className="navbar-item" to={url + "/applications"}>
              {t("menu.myApplications")}&nbsp;
              {acceptedByLeaderLength > 0 ? (
                <span className="tag is-rounded is-danger">
                  {acceptedByLeaderLength}
                </span>
              ) : null}
            </Link>
          ) : null}
          {/* Temporal add to show apps if have project */}

          <Link className="navbar-item" to={url + "/myProject"}>
            {t("menu.myProject")}
          </Link>

          <Link className="navbar-item" to={url + "/myGroup"}>
            {t("menu.myGroup")}
          </Link>

          <Link className="navbar-item" to={url + "/blog"}>
            {t("menu.announcements")}
          </Link>

          {settings.listProjectsStudent === "allowed" ? (
            <Link className="navbar-item" to={url + "/projects"}>
              {t("menu.allProjects")}
            </Link>
          ) : null}

          <Link className="navbar-item" to={url + "/transfers"}>
            {t("menu.transferRequests")}
          </Link>

          {["fourthBachelor", "secondMaster"].includes(profile?.yearOfStudy) ? (
            <Link className="navbar-item" to={url + "/practice"}>
              {t("menu.practice")}
            </Link>
          ) : null}
        </NavBar>
      ) : (
        <NavBar>
          {applicationsLength ? (
            <Link className="navbar-item" to={url + "/applications"}>
              {t("menu.myApplications")}&nbsp;
              {acceptedByLeaderLength > 0 ? (
                <span className="tag is-rounded is-danger">
                  {acceptedByLeaderLength}
                </span>
              ) : null}
            </Link>
          ) : null}

          <Link className="navbar-item" to={url + "/myGroup"}>
            {t("menu.myGroup")}
          </Link>

          {settings.listProjectsStudent === "allowed" ? (
            <Link className="navbar-item" to={url + "/projects"}>
              {t("menu.adminProjects")}
            </Link>
          ) : null}

          {["fourthBachelor", "secondMaster"].includes(profile?.yearOfStudy) ? (
            <Link className="navbar-item" to={url + "/practice"}>
              {t("menu.practice")}
            </Link>
          ) : null}
        </NavBar>
      )}

      <div className="section">
        <div className="container">
          {hasProject ? (
            <Switch>
              <Route exact path={path}>
                <div className="box has-text-centered">
                  {t("myProgramme", {
                    programme: progDict[currentProgramme]?.title[i18n.language],
                  })}
                </div>
                <HomeView />
              </Route>

              <Route path={path + "/myProject"}>
                <MyProjectView />
              </Route>

              <Route path={path + "/myGroup"}>
                <GroupPage />
              </Route>

              <Route path={path + "/blog"}>
                <BlogView projectID={profile.projectID} />
              </Route>

              <Route path={path + "/practice"}>
                <PracticeNew />
              </Route>

              <Route path={path + "/transfers"}>
                <ProjectsContextProvider
                  userType="student"
                  yearOfStudy={profile.yearOfStudy}
                  programmeID={profile?.programmeID}
                >
                  <TransferContextProvider>
                    <TransferList />
                  </TransferContextProvider>
                </ProjectsContextProvider>
              </Route>

              {settings.listProjectsStudent === "allowed" ? (
                <Route path={path + "/projects"}>
                  <ProjectsContextProvider
                    userType="student"
                    yearOfStudy={profile.yearOfStudy}
                    programmeID={profile?.programmeID}
                  >
                    <ProjectsFilterContextProvider>
                      <ProjectsPageView />
                    </ProjectsFilterContextProvider>
                  </ProjectsContextProvider>
                </Route>
              ) : (
                <Redirect to={path} />
              )}
            </Switch>
          ) : (
            <ProjectsContextProvider
              userType="student"
              yearOfStudy={profile.yearOfStudy}
              programmeID={profile?.programmeID}
            >
              <Switch>
                <Route exact path={path}>
                  <div className="box has-text-centered">
                    {t("myProgramme", {
                      programme:
                        progDict[currentProgramme]?.title[i18n.language],
                    })}
                  </div>
                  <HomeView />
                </Route>

                {settings.listProjectsStudent === "allowed" ? (
                  <Route path={path + "/projects"}>
                    <ProjectsFilterContextProvider>
                      <ProjectsPageView />
                    </ProjectsFilterContextProvider>
                  </Route>
                ) : (
                  <Redirect to={path} />
                )}

                <Route path={path + "/myGroup"}>
                  <GroupPage />
                </Route>

                <Route path={path + "/applications"}>
                  <ApplicationsPageView />
                </Route>

                <Route path={path + "/practice"}>
                  <PracticeNew />
                </Route>
              </Switch>
            </ProjectsContextProvider>
          )}
        </div>
      </div>
    </>
  )
}

export default StudentWorkspace
