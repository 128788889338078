import { useContext, useState } from "react"
import { UserContext } from "../../../context/UserContext"
import { useTranslation } from "react-i18next"
import axios from "axios"
import { API_URL, messageToToasts } from "../../../utils"
import { toast } from "react-toastify"

const PracticeApproval = ({ practice, setPractice }) => {
  const { t, i18n } = useTranslation("data")
  const {
    user: { userType, email: userEmail },
  } = useContext(UserContext)

  const [mode, setMode] = useState("view")
  const [declineReason, setDeclineReason] = useState("")

  const update = async newStatus => {
    try {
      if (newStatus === "declined" && declineReason?.length < 1)
        return toast.error(t("practice.emptyDeclineReason"))

      const resp = await axios.post(`${API_URL}/practice/update`, {
        ...practice,
        status: newStatus,
        declineReason: newStatus === "declined" ? declineReason : "",
        userEmail,
        changedField: "status",
      })

      const { status, message, practice: upd_practice } = resp.data
      if (status === "failed") return messageToToasts(message, i18n.language)

      setPractice(upd_practice)
      setMode("show")
    } catch (error) {
      toast.error(`System error: ${error}`)
    }
  }

  if (
    !(
      (userType === "projectLeader" && practice.status === "awaiting") ||
      userType === "admin"
    )
  )
    return null

  if (mode === "decline")
    return (
      <>
        <hr />

        <div className="field">
          <div className="control">
            <label className="label">{t("practice.declineReason")}</label>
            <textarea
              rows="3"
              className="textarea"
              value={declineReason}
              onChange={e => setDeclineReason(e.target.value)}
            />
          </div>
        </div>

        <div className="field is-grouped is-grouped-right">
          <div className="control">
            <button
              className="button is-small is-light"
              onClick={() => setMode("view")}
            >
              {t("cancel")}
            </button>
          </div>

          <div className="control">
            <button
              className="button is-small is-danger"
              onClick={() => update("declined")}
            >
              {t("practice.decline")}
            </button>
          </div>
        </div>
      </>
    )

  if (mode === "approve")
    return (
      <>
        <hr />

        <p className="title is-6">{t("practice.approveRequest")}</p>

        <div className="field is-grouped is-grouped-right">
          <div className="control">
            <button
              className="button is-small is-light"
              onClick={() => setMode("view")}
            >
              {t("cancel")}
            </button>
          </div>

          <div className="control">
            <button
              className="button is-small is-success"
              onClick={() => update("accepted")}
            >
              {t("practice.approve")}
            </button>
          </div>
        </div>
      </>
    )

  return (
    <>
      <hr />

      <div className="field is-grouped is-grouped-right">
        <div className="control">
          <button
            className="button is-small is-danger"
            onClick={() => setMode("decline")}
          >
            {t("practice.decline")}
          </button>
        </div>

        <div className="control">
          <button
            className="button is-small is-success"
            onClick={() => setMode("approve")}
          >
            {t("practice.approve")}
          </button>
        </div>
      </div>
    </>
  )
}

export default PracticeApproval
