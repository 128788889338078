import axios from "axios"
import { useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import { ProjectsContext } from "../../../context/ProjectsContext"
import useProjectChanges from "../../../context/useProjectChanges"
import { API_URL, messageToToasts } from "../../../utils"
import ProjectChangeList from "../../data/project/ProjectChangeList"
import MaxStudents from "../../data/proposal/MaxStudents"
import LoadingSpinner from "../../helpers/LoadingSpinner"

const ProjectChangeReq = () => {
  const { t, i18n } = useTranslation(["data", "ws"])
  const { currentProject } = useContext(ProjectsContext)
  const { changes, loading, refetch } = useProjectChanges(
    "project",
    currentProject.id
  )

  const [firstBachelor, setFirstBachelor] = useState(
    currentProject.maxStudents.firstBachelor
  )
  const [secondBachelor, setSecondBachelor] = useState(
    currentProject.maxStudents.secondBachelor
  )
  const [thirdBachelor, setThirdBachelor] = useState(
    currentProject.maxStudents.thirdBachelor
  )
  const [fourthBachelor, setFourthBachelor] = useState(
    currentProject.maxStudents.fourthBachelor
  )
  const [firstMaster, setFirstMaster] = useState(
    currentProject.maxStudents.firstMaster
  )
  const [secondMaster, setSecondMaster] = useState(
    currentProject.maxStudents.secondMaster
  )
  const [studentsFEFU, setStudentsFEFU] = useState(
    currentProject.maxStudents.studentsFEFU
  )

  const resetForm = () => {
    setFirstBachelor(currentProject.maxStudents.firstBachelor)
    setSecondBachelor(currentProject.maxStudents.secondBachelor)
    setThirdBachelor(currentProject.maxStudents.thirdBachelor)
    setFourthBachelor(currentProject.maxStudents.fourthBachelor)

    setFirstMaster(currentProject.maxStudents.firstMaster)
    setSecondMaster(currentProject.maxStudents.secondMaster)

    setStudentsFEFU(currentProject.maxStudents.studentsFEFU)
  }

  const sendRequest = async () => {
    try {
      const data = {
        projectID: currentProject.id,
        newMaxStudents: {
          firstBachelor: Number(firstBachelor),
          secondBachelor: Number(secondBachelor),
          thirdBachelor: Number(thirdBachelor),
          fourthBachelor: Number(fourthBachelor),
          firstMaster: Number(firstMaster),
          secondMaster: Number(secondMaster),
          studentsFEFU: Number(studentsFEFU),
        },
      }

      const resp = await axios.post(
        `${API_URL}/project/addProjectChangeReq`,
        data
      )
      const { status, message } = resp.data

      if (status === "failed") return messageToToasts(message, i18n.language)

      resetForm()
      refetch()
      toast.success(t("project.sendReqSuccess"))
      window.scrollTo(0, 0)
    } catch (error) {
      toast.error("Server error")
    }
  }

  const newStudentsForm = (
    <>
      <div className="columns">
        <div className="column is-half">
          <div className="field has-addons">
            <p className="control">
              <button className="button is-static">{t("proposal.b1")}</button>
            </p>
            <p className="control is-expanded">
              <input
                className="input has-text-right is-fullwidth"
                type="number"
                min={0}
                value={firstBachelor}
                onChange={e => setFirstBachelor(e.target.value)}
              />
            </p>
          </div>

          <div className="field has-addons">
            <p className="control">
              <button className="button is-static">{t("proposal.b2")}</button>
            </p>
            <p className="control is-expanded">
              <input
                className="input has-text-right"
                type="number"
                min={0}
                value={secondBachelor}
                onChange={e => setSecondBachelor(e.target.value)}
              />
            </p>
          </div>

          <div className="field has-addons">
            <p className="control">
              <button className="button is-static">{t("proposal.b3")}</button>
            </p>
            <p className="control is-expanded">
              <input
                className="input has-text-right"
                type="number"
                min={0}
                value={thirdBachelor}
                onChange={e => setThirdBachelor(e.target.value)}
              />
            </p>
          </div>
        </div>
        <div className="column is-half">
          <div className="field has-addons">
            <p className="control">
              <button className="button is-static">{t("proposal.b4")}</button>
            </p>
            <p className="control is-expanded">
              <input
                className="input has-text-right"
                type="number"
                min={0}
                value={fourthBachelor}
                onChange={e => setFourthBachelor(e.target.value)}
              />
            </p>
          </div>

          <div className="field has-addons">
            <p className="control">
              <button className="button is-static">{t("proposal.m1")}</button>
            </p>
            <p className="control is-expanded">
              <input
                className="input has-text-right"
                type="number"
                min={0}
                value={firstMaster}
                onChange={e => setFirstMaster(e.target.value)}
              />
            </p>
          </div>

          <div className="field has-addons">
            <p className="control">
              <button className="button is-static">{t("proposal.m2")}</button>
            </p>
            <p className="control is-expanded">
              <input
                className="input has-text-right"
                type="number"
                min={0}
                value={secondMaster}
                onChange={e => setSecondMaster(e.target.value)}
              />
            </p>
          </div>
        </div>
      </div>
      <div className="field has-addons">
        <p className="control">
          <button className="button is-static">
            {t("proposal.studentsFEFU")}
          </button>
        </p>
        <p className="control is-expanded">
          <input
            className="input has-text-right"
            type="number"
            min={0}
            value={studentsFEFU}
            onChange={e => setStudentsFEFU(e.target.value)}
          />
        </p>
      </div>

      <hr />
      <div className="field is-grouped is-grouped-right">
        <p className="control">
          <button className="button is-link is-outlined" onClick={sendRequest}>
            {t("project.sendChangeRequest")}
          </button>
        </p>
      </div>
    </>
  )

  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <ProjectChangeList changes={changes} refetch={refetch} />
      )}

      <div className="box">
        <p className="title is-5">{t("project.changeReqTitle")}</p>
        <hr />

        <p className="title is-6">{t("ws:projectsPage.availableSeats")}</p>

        <MaxStudents maxStudents={currentProject.maxStudents} />

        <hr />

        <p className="title is-6">{t("project.acceptedStudents")}</p>

        <MaxStudents maxStudents={currentProject.acceptedStudents} />

        <hr />

        <p className="title is-6">{t("project.newMaxStudents")}</p>
        {newStudentsForm}
      </div>
    </>
  )
}

export default ProjectChangeReq
