import axios from "axios"
import i18next from "i18next"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import { API_URL, messageToToasts } from "../../../utils"
import FormEntry from "../../helpers/FormEntry"
import LatexMarkdownForm from "../../helpers/LatexMarkdownForm"

const AsgnChangeForm = ({ asgn, setMode, refetch = null }) => {
  const { t } = useTranslation("data")

  const [submitting, setSubmitting] = useState(false)
  const [theme, setTheme] = useState(asgn?.theme)
  const [target, setTarget] = useState(asgn?.target)
  const [tasks, setTasks] = useState(asgn?.tasks)
  const [requirements, setRequirements] = useState(asgn?.requirements)
  const [reportFormat, setReportFormat] = useState(asgn?.reportFormat)
  const [presentation, setPresentation] = useState(asgn?.presentation)

  const requestChange = async () => {
    setSubmitting(true)
    const formData = {
      theme,
      target,
      tasks,
      requirements,
      reportFormat,
      presentation,
    }

    const changes = [
      "theme",
      "target",
      "tasks",
      "requirements",
      "reportFormat",
      "presentation",
    ]
      .map(field =>
        formData[field]?.trim() !== asgn[field]?.trim()
          ? { field, oldValue: asgn[field], newValue: formData[field] }
          : {}
      )
      .filter(el => el?.field?.length)

    try {
      const data = {
        asgnID: asgn?.id,
        groupID: asgn?.groupID,
        leaderID: asgn?.leaderID,
        changes,
      }

      const resp = await axios.post(`${API_URL}/asgnChange/add`, data)
      const { status, message } = resp.data
      setSubmitting(false)

      if (status === "failed") return messageToToasts(message, i18next.language)

      toast.success(t("group.asgnChangeSuccess"))
      setMode("view")

      if (refetch !== null) refetch()
    } catch (err) {
      toast.error("Server error")

      setSubmitting(false)
    }
  }

  return (
    <>
      <FormEntry labelText={t("group.asgnTheme")} helpText={t("req")}>
        <input
          type="text"
          className="input"
          placeholder={t("group.asgnThemePlh")}
          value={theme}
          onChange={e => setTheme(e.target.value)}
        />
      </FormEntry>

      <FormEntry labelText={t("group.asgnTarget")} helpText={t("req")}>
        <textarea
          className="textarea"
          placeholder={t("group.asgnTargetPlh")}
          value={target}
          onChange={e => setTarget(e.target.value)}
        />
      </FormEntry>

      <LatexMarkdownForm
        labelText={t("group.asgnTasks")}
        value={tasks}
        setValue={setTasks}
        isReq={false}
      />

      <FormEntry labelText={t("group.requirements")} helpText={t("req")}>
        <textarea
          className="textarea"
          placeholder={t("group.requirementsPlh")}
          value={requirements}
          onChange={e => setRequirements(e.target.value)}
        />
      </FormEntry>

      <div className="columns">
        <div className="column is-half">
          <FormEntry labelText={t("group.reportFormat")} helpText={t("req")}>
            <textarea
              className="textarea"
              placeholder={t("group.reportFormatPlh")}
              value={reportFormat}
              onChange={e => setReportFormat(e.target.value)}
            />
          </FormEntry>
        </div>

        <div className="column is-half">
          <FormEntry labelText={t("group.presentation")} helpText={t("req")}>
            <textarea
              className="textarea"
              placeholder={t("group.presentationPlh")}
              value={presentation}
              onChange={e => setPresentation(e.target.value)}
            />
          </FormEntry>
        </div>
      </div>

      <div className="field is-grouped is-grouped-right">
        <p className="control">
          <button
            className="button is-small is-light"
            onClick={() => setMode("view")}
            disabled={submitting}
          >
            {t("cancel")}
          </button>
        </p>
        <p className="control">
          <button
            className="button is-small is-link"
            onClick={requestChange}
            disabled={submitting}
          >
            {t("group.asgnRequestChange")}
          </button>
        </p>
      </div>

      <hr />
    </>
  )
}

export default AsgnChangeForm
