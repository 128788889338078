import { useState } from "react"
import { useTranslation } from "react-i18next"
import ReactMde from "react-mde"
import ReactTooltip from "react-tooltip"
import FormEntry from "./FormEntry"
import MathMarkdown from "./MathMarkdown"

const inlineMathCommand = {
  name: "inline-math",
  icon: () => (
    <span role="img" aria-label="inline-math">
      <strong>$</strong>
    </span>
  ),
  execute: opts => {
    let modifyText = "$" + opts.initialState.selectedText + "$"
    if (!opts.initialState.selectedText) {
      modifyText = "$ $"
    }

    opts.textApi.replaceSelection(modifyText)
  },
}

const blockMathCommand = {
  name: "block-math",
  icon: () => (
    <span role="img" aria-label="inline-math">
      <strong>$$</strong>
    </span>
  ),
  execute: opts => {
    let modifyText = "$$\n" + opts.initialState.selectedText + "\n$$"
    if (!opts.initialState.selectedText) {
      modifyText = "$$\n\n$$"
    }

    opts.textApi.replaceSelection(modifyText)
  },
}

const toolbar = [
  ["header", "bold", "italic"],
  ["link", "quote", "code"],
  ["unordered-list", "ordered-list"],
  ["inline-math", "block-math"],
]

const LatexMarkdownForm = ({ labelText, value, setValue, isReq = true }) => {
  const { t } = useTranslation("data")
  const [selectedTab, setSelectedTab] = useState("write")

  return (
    <FormEntry labelText={labelText}>
      <ReactTooltip multiline={true} />
      <ReactMde
        value={value}
        commands={{
          "inline-math": inlineMathCommand,
          "block-math": blockMathCommand,
        }}
        onChange={setValue}
        selectedTab={selectedTab}
        onTabChange={setSelectedTab}
        toolbarCommands={toolbar}
        generateMarkdownPreview={markdown =>
          Promise.resolve(<MathMarkdown>{markdown}</MathMarkdown>)
        }
        childProps={{
          writeButton: {
            tabIndex: -1,
          },
        }}
      />
      <p className="help">
        {isReq ? t("req") + ", " : ""}
        <span className="has-text-link pointer" data-tip={t("proposal.latex")}>
          {t("proposal.latexHelp")}
        </span>
      </p>
    </FormEntry>
  )
}

export default LatexMarkdownForm
