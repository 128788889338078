import { useContext, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useHistory, useParams } from "react-router"
import { ProjectsContext } from "../../../context/ProjectsContext"
import ProjectInfo from "../../data/project/ProjectInfo"
import ProjectRecomendations from "../../data/project/ProjectRecomendations"
import ProjectRequests from "../../data/project/ProjectRequests"
import MaxStudents from "../../data/proposal/MaxStudents"
import LoadingSpinner from "../../helpers/LoadingSpinner"

const ProjectView = () => {
  const history = useHistory()
  const { t } = useTranslation(["ws", "data"])
  const { projectID } = useParams()
  const { projectsLoading, currentProject, switchCurrentProject } =
    useContext(ProjectsContext)

  useEffect(() => {
    switchCurrentProject(projectID)
  }, [projectID, switchCurrentProject])

  if (projectsLoading || projectID !== currentProject.id)
    return (
      <div className="box">
        <LoadingSpinner />
      </div>
    )

  return (
    <>
      <div className="box">
        <div className="columns">
          <div className="column is-5">
            <span
              className="has-text-link pointer"
              onClick={() => history.goBack()}
            >
              {t("proposalsPage.backToList")}
            </span>
          </div>
          <div className="column is-7 has-text-right">
            <span className="title is-4">
              {currentProject?.title}

              {currentProject.num ? (
                <span className="has-text-grey">
                  &nbsp;({currentProject?.projectYear}-{currentProject.num})
                </span>
              ) : null}
            </span>
          </div>
        </div>
      </div>

      <ProjectRecomendations />

      <ProjectRequests />

      <ProjectInfo />

      <div className="box">
        <p className="title is-5">{t("projectsPage.availableSeats")}</p>
        <hr />
        <MaxStudents maxStudents={currentProject.maxStudents} />
      </div>

      <div className="box">
        <p className="title is-5">{t("data:project.acceptedStudents")}</p>
        <hr />
        <MaxStudents maxStudents={currentProject.acceptedStudents} />
      </div>
    </>
  )
}

export default ProjectView
