const FormEntry = ({ children, labelText = "", helpText = "" }) => (
  <div className="field">
    {labelText ? <label className="label">{labelText}</label> : null}

    <div className="control">{children}</div>
    {helpText ? <p className="help">{helpText}</p> : null}
  </div>
)

export default FormEntry
