import { useContext, useState } from "react"
import { ProjectsContext } from "../../../context/ProjectsContext"
import ProjectFilter from "../../data/project/ProjectFilter"
import ProjectList from "../../data/project/ProjectList"
import ProjectRecomendList from "../../data/project/ProjectRecomendList"
import LoadingSpinner from "../../helpers/LoadingSpinner"

const ProjectListView = () => {
  const { projects, projectsLoading } = useContext(ProjectsContext)
  const [viewedProjects, setViewedProjects] = useState([])

  if (projectsLoading)
    return (
      <div className="box">
        <LoadingSpinner />
      </div>
    )

  return (
    <>
      <ProjectFilter
        allProjects={projects}
        setViewedProjects={setViewedProjects}
      />

      {projects.length !== viewedProjects.length ? (
        <ProjectRecomendList projects={viewedProjects} />
      ) : null}

      <ProjectList projects={viewedProjects} />
    </>
  )
}

export default ProjectListView
