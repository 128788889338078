import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import FormEntry from "../../helpers/FormEntry"

const projectLeaderFields = {
  organization: "",
  position: "",
}

const ExternalClientSignUpForm = ({ fields, setFields, updateFields }) => {
  const { t } = useTranslation("auth")
  const [formNeedsEnrichment, setFormNeedsEnrichment] = useState(true)

  useEffect(() => {
    if (formNeedsEnrichment) {
      setFields({ ...fields, ...projectLeaderFields, inviteCode: "ec" })
      setFormNeedsEnrichment(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <FormEntry labelText="Email" helpText={t("formLabels.req")}>
        <input
          className="input"
          name="email"
          value={fields.email}
          onChange={e => updateFields(e)}
          type="email"
          placeholder="some-mail@hse.ru"
          disabled
        />
      </FormEntry>

      <FormEntry labelText={t("formLabels.pwd")} helpText={t("formLabels.req")}>
        <input
          className="input"
          type="password"
          name="password"
          placeholder="********"
          value={fields.password}
          onChange={e => updateFields(e)}
        />
      </FormEntry>

      <FormEntry
        labelText={t("formLabels.pwdRep")}
        helpText={t("formLabels.req")}
      >
        <input
          className="input"
          type="password"
          name="passwordRep"
          placeholder="********"
          value={fields.passwordRep}
          onChange={e => updateFields(e)}
        />
      </FormEntry>

      <FormEntry
        labelText={t("formLabels.surname")}
        helpText={t("formLabels.req")}
      >
        <input
          className="input"
          name="surname"
          value={fields.surname}
          onChange={e => updateFields(e)}
          type="text"
          placeholder="Иванов"
        />
      </FormEntry>

      <FormEntry
        labelText={t("formLabels.name")}
        helpText={t("formLabels.req")}
      >
        <input
          className="input"
          name="name"
          value={fields.name}
          onChange={e => updateFields(e)}
          type="text"
          placeholder="Иван"
        />
      </FormEntry>

      <FormEntry labelText={t("formLabels.secondName")}>
        <input
          className="input"
          name="secondName"
          value={fields.secondName}
          onChange={e => updateFields(e)}
          type="text"
          placeholder="Иванович"
        />
      </FormEntry>

      <FormEntry
        labelText={t("formLabels.organization")}
        helpText={t("formLabels.req")}
      >
        <input
          className="input"
          name="organization"
          value={fields.organization}
          onChange={e => updateFields(e)}
          type="text"
          placeholder="ООО Вектор"
        />
      </FormEntry>

      <FormEntry
        labelText={t("formLabels.position")}
        helpText={t("formLabels.req")}
      >
        <input
          className="input"
          name="position"
          value={fields.position}
          onChange={e => updateFields(e)}
          type="text"
          placeholder="Старший аналитик"
        />
      </FormEntry>
    </>
  )
}

export default ExternalClientSignUpForm
