import axios from "axios"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import { API_URL, messageToToasts } from "../utils"

const useApplications = (fetchMode, studentID = "", projectID = "") => {
  const { i18n } = useTranslation()
  const [loading, setLoading] = useState(true)
  const [applications, setApplications] = useState({})
  const [needRefetch, setNeedRefetch] = useState(true)

  useEffect(
    () => {
      const fetchApplications = async () => {
        try {
          let requestURL = `${API_URL}/application/list`
          if (fetchMode === "student")
            requestURL += `ForStudent${
              studentID ? "?studentID=" + studentID : ""
            }`

          if (fetchMode === "project")
            requestURL += `ForProject?projectID=${projectID}`

          if (fetchMode === "leader") requestURL += "ForLeader"

          if (fetchMode === "admin") requestURL += "ForAdmin"

          const resp = await axios.get(requestURL)
          const { status, message, applications } = resp.data

          if (status === "failed")
            return messageToToasts(message, i18n.language)

          setApplications(
            applications.reduce(
              (obj, app) =>
                Object.assign(obj, {
                  [app.id]: app,
                }),
              {}
            )
          )

          setLoading(false)
          setNeedRefetch(false)
        } catch (error) {
          toast.error(
            "Server error. Try to reload page or contact administrator"
          )
        }
      }

      if (needRefetch) {
        setLoading(true)
        fetchApplications()
      }
    },
    // eslint-disable-next-line
    [needRefetch]
  )

  return {
    loading,
    applications,
    setNeedRefetch,
  }
}

export default useApplications
