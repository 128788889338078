import axios from "axios"
import { useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import Select from "react-select"
import { toast } from "react-toastify"
import { CommonDataContext } from "../../../context/CommonDataContext"
import { ProjectsContext } from "../../../context/ProjectsContext"
import { API_URL, messageToToasts } from "../../../utils"
import FormEntry from "../../helpers/FormEntry"

const ProjectRequest = () => {
  const { t, i18n } = useTranslation("data")
  const {
    programmes: { options: progOptions },
  } = useContext(CommonDataContext)
  const { refetch, currentProject } = useContext(ProjectsContext)
  const [submitting, setSubmitting] = useState(false)
  const [selectedProgrammes, setSelectedProgrammes] = useState(null)

  const requestProject = async () => {
    setSubmitting(true)

    try {
      const data = {
        projectID: currentProject.id,
        programmesID: selectedProgrammes.map(sp => sp.value),
      }
      const resp = await axios.post(`${API_URL}/project/request`, data)
      setSubmitting(false)

      const { status, message } = resp.data
      if (status === "failed") return messageToToasts(message, i18n.language)

      toast.success(t("project.requestSuccess"))
      setSelectedProgrammes(null)
      refetch("projects")
    } catch (error) {
      setSubmitting(false)
      toast.error("Server error!")
    }
  }

  const availableOptions = (progOptions[i18n.language] || []).filter(
    opt =>
      ![
        ...(currentProject?.requestedProgrammesID || []),
        ...(currentProject?.recomendingProgrammesID || []),
      ].includes(opt.value) && opt.isVisible
  )

  return availableOptions?.length ? (
    <div className="box">
      <p className="title is-5">{t("project.requestTitle")}</p>
      <hr />
      <p className="mb-3">{t("project.requestHint")}</p>

      <FormEntry>
        <Select
          isMulti={true}
          value={selectedProgrammes}
          onChange={setSelectedProgrammes}
          placeholder={t("project.requestPlaceholder")}
          options={availableOptions}
        />
      </FormEntry>

      <div className="field has-text-right">
        <p className="control">
          <button
            className="button is-link is-outlined"
            onClick={requestProject}
            disabled={submitting}
          >
            {t("project.request")}
          </button>
        </p>
      </div>
    </div>
  ) : null
}

export default ProjectRequest
