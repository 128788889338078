import axios from "axios"
import i18next from "i18next"
import { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import { toast } from "react-toastify"
import { CommonDataContext } from "../../context/CommonDataContext"
import { API_URL, messageToToasts } from "../../utils"

const LeaderLoadList = ({
  loads,
  restrictionsMap = {},
  mode = "all",
  year = "2021",
}) => {
  const { t } = useTranslation("data")
  const {
    projectLeaders: { dict: plDict },
  } = useContext(CommonDataContext)
  const [displayedLoads, setDisplayedLoads] = useState([])
  const [sub, setSub] = useState(false)

  useEffect(() => {
    setDisplayedLoads(
      loads
        .map(load => ({
          ...load,
          fullName:
            plDict[load.leaderID]?.fullName +
            `\n(${plDict[load.leaderID]?.email})`,
        }))
        .sort((a, b) => a.fullName.localeCompare(b.fullName))
    )
  }, [loads, plDict])

  const updateRestriction = async (
    leaderID,
    year,
    maxHours,
    isEqual = false
  ) => {
    if (isEqual) return

    setSub(true)
    try {
      const data = {
        leaderID,
        projectYear: Number(year),
        maxHours: Number(maxHours),
      }

      const resp = await axios.post(`${API_URL}/loadRestrictions/update`, data)
      const { status, message } = resp.data
      setSub(false)

      if (status === "failed")
        return toast.error(messageToToasts(message, i18next.language))
    } catch (error) {
      setSub(false)
      toast.error("Server error!")
    }
  }

  return (
    <div className="box">
      <div className="table-container">
        <table className="table is-fullwidth">
          <thead>
            <tr>
              <th>{t("loads.name")}</th>
              <th>{t("loads.restriction")}</th>
              <th>{t("loads.total")}</th>
              <th>{t("loads.totalNoVKR")}</th>
              <th>{t("loads.credits")}</th>
              {/* <th>{t("loads.bachThesis")}</th> */}
              {/* <th>{t("loads.masterCourse")}</th> */}
              {/* <th>{t("loads.masterThesis")}</th> */}
              <th>B1</th>
              <th>B2</th>
              <th>B3</th>
              <th>B4</th>
              <th>M1</th>
              <th>M2</th>
            </tr>
          </thead>
          <tbody>
            {displayedLoads.map(load => {
              const maxHours = restrictionsMap?.[load.leaderID]?.leaderID
                ? restrictionsMap?.[load.leaderID]?.maxHours
                : 0

              return (
                <tr key={load.id}>
                  <td style={{ whiteSpace: "pre-wrap" }}>
                    <Link
                      to={`/r/common/profile?userID=${load.leaderID}&userType=projectLeader`}
                      target="_blank"
                    >
                      {load.fullName}
                    </Link>
                  </td>
                  <td>
                    <div className="field">
                      <div className="control">
                        <input
                          type="number"
                          className="input is-small"
                          defaultValue={maxHours}
                          onBlur={e =>
                            updateRestriction(
                              load.leaderID,
                              year,
                              e.target.value,
                              Number(e.target.value) === Number(maxHours)
                            )
                          }
                          disabled={mode !== "manager" || sub}
                        />
                      </div>
                    </div>
                  </td>
                  <td>{load?.totalLoad?.toFixed(0)}</td>
                  <td>{load?.totalLoadNoVKR?.toFixed(0)}</td>
                  <td>{load?.totalCredit?.toFixed(0)}</td>
                  {/* <td>{load.fourthBachelor.toFixed(0)}</td> */}
                  {/* <td>{load.firstMaster.toFixed(0)}</td> */}
                  {/* <td>{load.secondMaster.toFixed(0)}</td> */}
                  <td>{load.firstBachelor.toFixed(0)}</td>
                  <td>{load.secondBachelor.toFixed(0)}</td>
                  <td>{load.thirdBachelor.toFixed(0)}</td>
                  <td>{load.fourthBachelor.toFixed(0)}</td>
                  <td>{load.firstMaster.toFixed(0)}</td>
                  <td>{load.secondMaster.toFixed(0)}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default LeaderLoadList
