import axios from "axios"
import { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import { toast } from "react-toastify"
import { CommonDataContext } from "../../../context/CommonDataContext"
import { UserContext } from "../../../context/UserContext"
import { API_URL, messageToToasts, nilObjectID } from "../../../utils"
import LoadingSpinner from "../../helpers/LoadingSpinner"

const LeaderProjectsView = ({ leaderID }) => {
  const { t, i18n } = useTranslation("data")
  const [loading, setLoading] = useState(true)
  const [projects, setProjects] = useState([])
  const {
    projectLeaders: { dict: plDict },
    divisions: { dict: divDict },
  } = useContext(CommonDataContext)
  const {
    user: { userType },
  } = useContext(UserContext)

  useEffect(
    () => {
      const fetchProjects = async () => {
        try {
          const resp = await axios.get(
            `${API_URL}/project/listLeaderProjects?leaderID=${leaderID}`
          )

          const { status, message, projects } = resp.data
          if (status === "failed")
            return messageToToasts(message, i18n.language)

          setProjects(projects)
          setLoading(false)
        } catch (error) {
          toast.error("Server error")
        }
      }

      fetchProjects()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [leaderID]
  )

  if (loading)
    return (
      <div className="box">
        <LoadingSpinner />
      </div>
    )

  return projects.map(project => {
    const projectLeader = plDict[project.projectLeaderID]

    const coleaders = project.projectColeadersID
      .map(clID => ({
        id: clID,
        fullName: plDict[clID]?.fullName,
        workPlace:
          plDict[clID]?.workPlace === "hse"
            ? t("proposal.hse")
            : plDict[clID]?.workPlace,
      }))
      .filter(cl => cl.fullName)

    return (
      <div className="box" key={project.id}>
        <p className="title is-5">
          {project?.num ? (
            <span className="has-text-grey">
              {project?.projectYear}-{project?.num} -{" "}
            </span>
          ) : null}

          {["admin", "academicManager", "student", "divisionManager"].includes(
            userType
          ) ? (
            <Link to={`/r/${userType}/projects/viewProject/` + project.id}>
              {project.title}
              {project.lang === "ru" && project.titleEN !== ""
                ? ` (${project.titleEN})`
                : ""}
            </Link>
          ) : (
            <>
              {project.title}
              {project.lang === "ru" && project.titleEN !== ""
                ? ` (${project.titleEN})`
                : ""}
            </>
          )}
          <span className="is-pulled-right has-text-grey">{project.lang}</span>
        </p>

        <hr />

        <p className="subtitle is-6">
          <strong>{t("project.leader")}: </strong>
          <Link
            to={`/r/common/profile?userID=${projectLeader?.id}&userType=projectLeader`}
            target="_blank"
          >
            {projectLeader?.fullName}
          </Link>
        </p>

        {coleaders.length ? (
          <p className="subtitle is-6">
            <strong>{t("project.cle")}: </strong>
            {coleaders.map(cl => (
              <Link
                key={cl.id}
                to={`/r/common/profile?userID=${cl?.id}&userType=projectLeader`}
                target="_blank"
              >
                {cl.fullName + " (" + cl.workPlace + ") "}&nbsp;
              </Link>
            ))}
          </p>
        ) : null}

        {project.division !== nilObjectID ? (
          <p className="subtitle is-6">
            <strong>{t("project.division")}: </strong>
            {" " + divDict[project.divisionID]?.title[i18n.language]}
          </p>
        ) : null}

        <hr />
        <div className="content">{project.summary}</div>
      </div>
    )
  })
}

export default LeaderProjectsView
