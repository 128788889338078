import { useTranslation } from "react-i18next"
import { Route, Switch, useRouteMatch } from "react-router-dom"
import ChooseRole from "./auth/ChooseRole"
import Login from "./auth/Login"
import AuthLayout from "./helpers/AuthLayout"
import LangAuthSelector from "./helpers/LangAuthSelector"

const Auth = () => {
  const { path } = useRouteMatch()
  const { t } = useTranslation()

  return (
    <AuthLayout>
      <h3 className="title is-4 has-text-centered pb-2">{t("appTitle")}</h3>
      <Switch>
        <Route exact path={path} component={ChooseRole} />
        <Route path={path + "/:userType"} component={Login} />
      </Switch>
      <hr />
      <LangAuthSelector />
    </AuthLayout>
  )
}

export default Auth
