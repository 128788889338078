import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useTransferContext } from "../../../context/TransferContext"
import LoadingSpinner from "../../helpers/LoadingSpinner"
import TransferPreview from "./TransferPreview"

const TransferList = () => {
  const { t } = useTranslation("data")
  const { loading, transfers, mode } = useTransferContext()
  const [onlyAwaiting, setOnlyAwaiting] = useState(false)
  const [viewedTransfers, setViewedTransfers] = useState(transfers)

  useEffect(() => {
    const predicate = tr => {
      const modePredicate = ["from", "to", "both"].includes(mode)
        ? tr?.mode === mode
        : true

      const awaitingPredicate = onlyAwaiting ? tr?.status === "awaiting" : true

      return modePredicate && awaitingPredicate
    }

    setViewedTransfers(transfers.filter(predicate))
  }, [onlyAwaiting, transfers, mode])

  if (loading) return <LoadingSpinner />

  return (
    <>
      <div className="box">
        <p className="title is-4 has-text-right">{t("transfer.title")}</p>
      </div>

      <div className="box">
        <p className="subtitle is-5">
          {t("transfer.showStatus")}:{" "}
          <span
            className={"pointer" + (onlyAwaiting ? "" : " has-text-success")}
            onClick={() => setOnlyAwaiting(false)}
          >
            {t("project.all")}
          </span>
          /
          <span
            className={"pointer" + (!onlyAwaiting ? "" : " has-text-success")}
            onClick={() => setOnlyAwaiting(true)}
          >
            {t("transfer.onlyAwaiting")}
          </span>
        </p>
      </div>

      {viewedTransfers.length ? (
        viewedTransfers.map(transfer => (
          <TransferPreview transfer={transfer} key={transfer.id} />
        ))
      ) : (
        <div className="box has-text-grey">{t("emptyList")}</div>
      )}
    </>
  )
}

export default TransferList
