import { useContext, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useHistory, useParams } from "react-router"
import { AcademicProgrammeContext } from "../../../context/AcademicProgrammeContext"
import { ApplicationsStudentContext } from "../../../context/ApplicationsStudentContext"
import { ProjectsContext } from "../../../context/ProjectsContext"
import { SettingsContext } from "../../../context/SettingsContext"
import useProfile from "../../../context/useProfile"
import { UserContext } from "../../../context/UserContext"
import { nilObjectID } from "../../../utils"
import ApplicationNew from "../../data/application/ApplicationNew"
import ProjectInfo from "../../data/project/ProjectInfo"
import MaxStudents from "../../data/proposal/MaxStudents"
import TransferNew from "../../data/transfer/TransferNew"
import LoadingSpinner from "../../helpers/LoadingSpinner"

const ProjectView = () => {
  const history = useHistory()
  const { t } = useTranslation(["data", "ws"])
  const { projectID } = useParams()
  const { projectsLoading, currentProject, switchCurrentProject } =
    useContext(ProjectsContext)
  const { projectsID } = useContext(ApplicationsStudentContext)
  const { settings } = useContext(SettingsContext)
  const { currentProgramme } = useContext(AcademicProgrammeContext)

  const { user } = useContext(UserContext)
  const { profile } = useProfile(user.userType, user.userID, user.userID)

  const hasProject = profile?.projectID !== nilObjectID

  useEffect(() => {
    switchCurrentProject(projectID)
  }, [projectID, switchCurrentProject])

  if (projectsLoading || projectID !== currentProject.id)
    return (
      <div className="box">
        <LoadingSpinner />
      </div>
    )

  const isRecomended = (currentProject?.recomendingProgrammesID || []).includes(
    currentProgramme
  )

  const applicationSent = projectsID.includes(projectID)

  return (
    <>
      <div className="box">
        <div className="columns">
          <div className="column is-5">
            <span
              className="has-text-link pointer"
              onClick={() => history.goBack()}
            >
              {t("ws:proposalsPage.backToList")}
            </span>
          </div>
          <div className="column is-7 has-text-right">
            <span className="title is-4">
              {currentProject?.title}

              {currentProject.num ? (
                <span className="has-text-grey">
                  &nbsp;({currentProject?.projectYear}-{currentProject.num})
                </span>
              ) : null}
            </span>
          </div>
        </div>
      </div>

      <div
        className={"message" + (isRecomended ? " is-success" : " is-danger")}
      >
        <div className="message-body">
          {isRecomended
            ? t("project.recomendedStudent")
            : t("project.notRecomendedStudent")}
        </div>
      </div>

      {applicationSent ? (
        <div className="message is-success">
          <div className="message-body">{t("application.alreadySent")}</div>
        </div>
      ) : null}

      {settings.sendApplications !== "allowed" && !applicationSent ? (
        <div className="message is-warning">
          <div className="message-body">
            {t("application.temporarilyClosed")}
          </div>
        </div>
      ) : null}

      {!hasProject &&
      settings.sendApplications === "allowed" &&
      !applicationSent &&
      (isRecomended || settings.sendNotRecomended === "allowed") ? (
        <ApplicationNew />
      ) : null}

      {hasProject &&
      projectID !== profile?.projectID &&
      settings.requestTransferStudent === "allowed" ? (
        <TransferNew />
      ) : null}

      <ProjectInfo />

      <div className="box">
        <p className="title is-5">{t("ws:projectsPage.availableSeats")}</p>
        <hr />
        <MaxStudents
          maxStudents={currentProject.maxStudents}
          loadSeats={currentProject?.loadSeats}
          externalSeats={currentProject?.externalSeats}
        />
      </div>

      <div className="box">
        <p className="title is-5">{t("project.acceptedStudents")}</p>
        <hr />
        <MaxStudents maxStudents={currentProject.acceptedStudents} />
      </div>
    </>
  )
}

export default ProjectView
