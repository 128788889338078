import axios from "axios"
import { useContext, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { toast } from "react-toastify"
import { API_URL, messageToToasts, nilObjectID } from "../../../utils"
import i18next from "i18next"
import { CommonDataContext } from "../../../context/CommonDataContext"
import { ProjectsContext } from "../../../context/ProjectsContext"
import Select from "react-select"
import dayjs from "dayjs"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const actionsMap = {
  signUp: "регистрация",
  applicationAccept: "принят",
  transfer: "перевод",
  projectRemove: "удален",
  projectAssign: "назначен",
}

const StudentLogList = () => {
  const {
    students: { dict: studDict },
  } = useContext(CommonDataContext)
  const { projects } = useContext(ProjectsContext)

  const projMap = projects.reduce((acc, el) => {
    acc[el?.id] = el
    return acc
  }, {})

  const [page, setPage] = useState(1)
  const [selectedReason, setSelectedReason] = useState(null)
  const [studentLogs, setStudentLogs] = useState([])

  useEffect(() => {
    const fetchLog = async () => {
      try {
        const resp = await axios.get(
          `${API_URL}/studentLog/list?page=${page}&reason=${
            selectedReason?.value ?? ""
          }`
        )

        const { status, message, studentLogs } = resp.data
        if (status === "failed")
          return messageToToasts(message, i18next.language)

        setStudentLogs(studentLogs)
      } catch (error) {
        toast.error(`Server error: ${error}`)
      }
    }

    fetchLog()
  }, [page, selectedReason])

  const enrichedLogs = studentLogs.map(slog => {
    const oldProject = projMap?.[slog?.oldProjectID]
    const newProject = projMap?.[slog?.newProjectID]

    return {
      ...slog,
      viewed: false,
      oldProject: oldProject
        ? `${oldProject?.projectYear}-${oldProject?.num}`
        : `без проекта`,
      newProject: newProject
        ? `${newProject?.projectYear}-${newProject?.num}`
        : `без проекта`,
    }
  })

  const filter = (
    <div className="columns">
      <div className="column is-6">
        <div className="field">
          <div className="control">
            <Select
              options={Object.entries(actionsMap).map(([key, value]) => ({
                label: value,
                value: key,
              }))}
              value={selectedReason}
              onChange={option => {
                setSelectedReason(option)
                setPage(1)
              }}
              placeholder="Выберите действие для фильтра..."
              isClearable
            />
          </div>
        </div>
      </div>

      <div className="column is-3"></div>

      <div className="column is-3">
        <div className="field has-addons">
          <div className="control">
            <button
              className="button"
              onClick={() => setPage(page - 1)}
              disabled={page === 1}
            >
              <FontAwesomeIcon
                icon="chevron-circle-left"
                className="has-text-link"
              />
            </button>
          </div>

          <div className="control is-expanded">
            <span className="button is-fullwidth has-text-grey">
              Страница {page}
            </span>
          </div>

          <div className="control">
            <button className="button" onClick={() => setPage(page + 1)}>
              <FontAwesomeIcon
                icon="chevron-circle-right"
                className="has-text-link"
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  )

  return (
    <>
      <div className="box">
        <p className="title is-4 has-text-right">
          Движение студентов по проектам
        </p>
      </div>

      <div className="box">
        {filter}

        <div className="table-container">
          <table className="table is-fullwidth">
            <thead>
              <th>ФИО студента</th>
              <th>Старый проект</th>
              <th>Новый проект</th>
              <th>Действие</th>
              <th>Дата</th>
            </thead>

            <tfoot>
              <th>ФИО студента</th>
              <th>Старый проект</th>
              <th>Новый проект</th>
              <th>Действие</th>
              <th>Дата</th>
            </tfoot>

            <tbody>
              {enrichedLogs.map(slog => (
                <tr key={slog?.id}>
                  <td>
                    <Link
                      to={`/r/common/profile?userID=${slog?.studentID}&userType=student`}
                      target="_blank"
                      className="has-text-black"
                    >
                      {studDict[slog?.studentID]?.fullName}
                    </Link>
                  </td>
                  <td>
                    {slog?.oldProjectID !== nilObjectID ? (
                      <Link
                        className="tag pointer is-link"
                        to={
                          "/r/admin/projects/viewProject/" + slog?.oldProjectID
                        }
                      >
                        {slog?.oldProject}
                      </Link>
                    ) : (
                      <span className="tag is-link">{slog?.oldProject}</span>
                    )}
                  </td>
                  <td>
                    {slog?.newProjectID !== nilObjectID ? (
                      <Link
                        className="tag pointer is-link"
                        to={
                          "/r/admin/projects/viewProject/" + slog?.newProjectID
                        }
                      >
                        {slog?.newProject}
                      </Link>
                    ) : (
                      <span className="tag is-link">{slog?.newProject}</span>
                    )}
                  </td>
                  <td>
                    <span className="tag is-info">
                      {actionsMap[slog?.reason]}
                    </span>
                  </td>
                  <td>
                    {dayjs(slog?.creationDate).format("DD/MM/YYYY HH:mm")}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {filter}
      </div>
    </>
  )
}

export default StudentLogList
