import { useTranslation } from "react-i18next"
import { Route, Switch, useRouteMatch } from "react-router"
import useGroups from "../../../context/useGroups"
import GroupPage from "../../data/groups/GroupPage"
import GroupPreview from "../../data/groups/GroupPreview"
import LoadingSpinner from "../../helpers/LoadingSpinner"

const GroupsPageView = () => {
  const { t } = useTranslation("data")
  const { path } = useRouteMatch()
  const { groups, loading } = useGroups()

  return (
    <Switch>
      <Route exact path={path}>
        <div className="message">
          <div className="message-body">{t("group.createHint")}</div>
        </div>

        {loading ? (
          <div className="box">
            <LoadingSpinner />
          </div>
        ) : (
          groups.map(group => (
            <GroupPreview key={group.id} group={group} needsProject={true} />
          ))
        )}
      </Route>

      <Route path={path + "/:groupID"}>
        <GroupPage />
      </Route>
    </Switch>
  )
}

export default GroupsPageView
