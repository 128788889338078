import { useTranslation } from "react-i18next"
import JobsList from "../../data/JobsList"

const JobView = () => {
  const { t } = useTranslation("ws")

  return (
    <>
      <div className="box has-text-right title is-4">
        {t("usersPage.job.title")}
      </div>

      <JobsList />
    </>
  )
}

export default JobView
