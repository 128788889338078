import { useState } from "react"
import { useTranslation } from "react-i18next"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { toast } from "react-toastify"
import { API_URL, messageToToasts } from "../../utils"
import axios from "axios"

const StudentInfoNew = () => {
  const [submitting, setSubmitting] = useState(false)
  const { t, i18n } = useTranslation("data")
  const [keyFI, setKeyFI] = useState(Math.random().toString())
  const [uploadFile, setUploadFile] = useState(null)

  const updateStudentInfo = async () => {
    setSubmitting(true)

    try {
      const formData = new FormData()
      formData.append("file", uploadFile)

      const resp = await axios.post(`${API_URL}/studentInfo/update`, formData)
      setSubmitting(false)
      const { status, message } = resp.data

      if (status === "failed") {
        return messageToToasts(message, i18n.language)
      }

      toast.success(t("settings.studentsInfoSuccess"))
      setUploadFile(null)
      setKeyFI(Math.random().toString())
    } catch (error) {
      setSubmitting(false)
      toast.error("Server error!")
    }
  }

  const updateStudentYosFormat = async () => {
    setSubmitting(true)

    try {
      const resp = await axios.post(`${API_URL}/user/student/updateYosFormat`)
      setSubmitting(false)
      const { status, updated, message } = resp.data

      if (status === "failed") {
        return messageToToasts(message, i18n.language)
      }

      toast.success(t("settings.studentsYosFormatSuccess") + updated)
    } catch (error) {
      setSubmitting(false)
      toast.error("Server error!")
    }
  }

  return (
    <div className="box">
      <p className="title is-5">{t("settings.studentInfoNew")}</p>
      <hr />

      <div className="columns">
        <div className="column is-4"></div>
        <div className="column is-4 has-text-centered">
          <button
            className="button is-link"
            onClick={updateStudentYosFormat}
            disabled={submitting}
          >
            {t("settings.updateYosFormat")}
          </button>
        </div>
        <div className="column is-4"></div>
      </div>

      <hr className="mt-1" />

      <div className="file has-name is-fullwidth">
        <label className="file-label">
          <input
            className="file-input"
            type="file"
            name="file"
            key={keyFI}
            onChange={e => setUploadFile(e.target.files[0])}
          />
          <span className="file-cta">
            <span className="file-icon">
              <FontAwesomeIcon icon="upload" />
            </span>
            <span className="file-label">{t("settings.chooseFile")}</span>
          </span>
          <span className="file-name">
            {uploadFile ? uploadFile.name : t("settings.noFileSelected")}
          </span>
        </label>
      </div>

      <div className="field mt-5">
        <p className="control">
          <button
            className="button is-link is-outlined is-fullwidth"
            onClick={updateStudentInfo}
            disabled={submitting}
          >
            {t("settings.studentInfoNew")}
          </button>
        </p>
      </div>
    </div>
  )
}

export default StudentInfoNew
