import { useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import { UserContext } from "../../context/UserContext"
import ruFlag from "./_ruFlag.png"
import enFlag from "./_enFlag.png"

const NavBar = ({ children, rightMenu = true }) => {
  const {
    user: { userID, userType },
  } = useContext(UserContext)
  const { t, i18n } = useTranslation()
  const [showBurger, setShowBurger] = useState(false)
  const { logout } = useContext(UserContext)

  const changeLng = () => {
    if (i18n.language === "ru") i18n.changeLanguage("en")
    else i18n.changeLanguage("ru")
  }

  const lngImg = (
    <img
      src={i18n.language === "ru" ? ruFlag : enFlag}
      alt={"current language: " + i18n.language}
    />
  )

  return (
    <nav
      className="navbar is-link is-spaced"
      role="navigation"
      aria-label="main navigation"
    >
      <div className="navbar-brand">
        <Link
          className="navbar-item has-text-weight-semibold is-uppercase is-size-4 mr-5"
          to={"/r/" + userType}
        >
          {t("appTitle")}
        </Link>

        <span
          role="button"
          className="navbar-burger"
          aria-label="menu"
          aria-expanded="false"
          data-target="appNavbar"
          onClick={() => setShowBurger(!showBurger)}
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </span>
      </div>

      <div
        id="appNavbar"
        className={"navbar-menu" + (showBurger ? " is-active" : "")}
      >
        <div className="navbar-start">{children}</div>

        {rightMenu ? (
          <div className="navbar-end">
            <div className="navbar-item">
              <div className="buttons">
                <button className="button is-light" onClick={changeLng}>
                  {lngImg}
                </button>
              </div>
            </div>

            <div className="navbar-item">
              <div className="buttons">
                <Link
                  className="button is-light"
                  to={`/r/common/profile?userID=${userID}&userType=${userType}`}
                  target="_blank"
                >
                  <strong>{t("nav.myProfile")}</strong>
                </Link>
              </div>
            </div>

            <div className="navbar-item">
              <div className="buttons">
                <button className="button is-light" onClick={logout}>
                  <strong>{t("nav.signOut")}</strong>
                </button>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </nav>
  )
}

export default NavBar
