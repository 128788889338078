import axios from "axios"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import { API_URL, messageToToasts } from "../utils"

const useProfile = (userType, userID, currentUserID) => {
  const { i18n } = useTranslation()
  const [loading, setLoading] = useState(true)
  const [profile, setProfile] = useState({ id: "" })
  const [needRefetch, setNeedRefetch] = useState(true)

  useEffect(
    () => {
      const fetchProfile = async () => {
        try {
          const url = `${API_URL}/user/profile?userType=${userType}&userID=${userID}`
          const resp = await axios.get(url)
          const { status, message, user } = resp.data

          if (status === "failed")
            return messageToToasts(message, i18n.language)

          setProfile(user)
          setLoading(false)
        } catch (err) {
          toast.error(
            "Server error. Try to reload page or contact administrator"
          )
        }
      }

      setLoading(true)
      fetchProfile()
      setNeedRefetch(false)
    },
    // eslint-disable-next-line
    [userID, userType, needRefetch]
  )

  const refetch = (needsReload = false) => {
    if (needsReload) setLoading(true)

    setNeedRefetch(true)
  }

  return {
    loading,
    profile: { ...profile, userType },
    currentProfile: profile.id === currentUserID,
    refetch,
  }
}

export default useProfile
