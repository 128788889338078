import axios from "axios"
import dayjs from "dayjs"
import i18next from "i18next"
import { useContext, useEffect, useState } from "react"
import { useParams } from "react-router"
import { toast } from "react-toastify"
import { useBlogPostContext } from "../../../context/BlogPostContext"
import { CommonDataContext } from "../../../context/CommonDataContext"
import { UserContext } from "../../../context/UserContext"
import { API_URL, messageToToasts } from "../../../utils"
import MathMarkdown from "../../helpers/MathMarkdown"
import BlogCommentsList from "./BlogCommentsList"
import BlogVisibilitySwitch from "./BlogVisibilitySwitch"

const BlogPostInfo = () => {
  const {
    user: { userType },
  } = useContext(UserContext)
  const { refetch } = useBlogPostContext()
  const { postID } = useParams()
  const [post, setPost] = useState(null)
  const [nr, setNR] = useState(true)
  const {
    projectLeaders: { dict: plDict },
  } = useContext(CommonDataContext)

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const resp = await axios.get(`${API_URL}/blogpost/get?postID=${postID}`)
        const { status, message, post } = resp.data

        setNR(false)
        if (status === "failed")
          return messageToToasts(message, i18next.language)

        setPost(post)
      } catch (error) {
        setNR(false)
        toast.error("Server error")
      }
    }

    if (postID && nr) fetchPost()
  }, [postID, nr])

  return (
    <>
      <div className="box">
        {["admin", "projectLeader"].includes(userType) ? (
          <div className="columns mb-0">
            <div className="column is-10 pb-0">
              <p className="title is-size-4">
                {post?.title ? post.title : "loading..."}
              </p>
            </div>

            <div className="column is-2 pb-0 has-text-right">
              <BlogVisibilitySwitch
                post={post}
                refetch={() => {
                  setNR(true)
                  refetch()
                }}
              />
            </div>
          </div>
        ) : (
          <p className="title is-size-4">
            {post?.title ? post.title : "loading..."}
          </p>
        )}
      </div>

      <div className="box">
        <MathMarkdown styleClass="content">{post?.text}</MathMarkdown>

        <hr />

        <div className="columns">
          <div className="column is-half has-text-grey">
            {plDict[post?.authorID]?.fullName}
          </div>

          <div className="column is-half has-text-grey has-text-right">
            {dayjs(post?.creationDate).format("DD/MM/YYYY HH:mm")}
          </div>
        </div>
      </div>

      {!post?.isCommentable || <BlogCommentsList postID={post?.id} />}
    </>
  )
}

export default BlogPostInfo
