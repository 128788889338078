import axios from "axios"
import i18next from "i18next"
import { useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import { ProjectsContext } from "../../../context/ProjectsContext"
import { API_URL, messageToToasts } from "../../../utils"
import FormEntry from "../../helpers/FormEntry"
import ProjectFreePlaces from "../project/ProjectFreePlaces"

const TransferNew = () => {
  const { t } = useTranslation("data")
  const { currentProject } = useContext(ProjectsContext)

  const [text, setText] = useState("")

  const updateText = event => {
    const newValue = event.target.value
    if (newValue.length <= 300) setText(newValue)
    else setText(newValue.substring(0, 300))
  }

  const addTransfer = async () => {
    try {
      const data = {
        toProjectID: currentProject.id,
        text,
      }

      const resp = await axios.post(`${API_URL}/transfer/add`, data)
      const { status, message } = resp.data

      if (status === "failed") return messageToToasts(message, i18next.language)

      setText("")
      toast.success(t("transfer.addSuccess"))
    } catch (error) {
      toast.error("Server error!")
    }
  }

  return (
    <div className="box">
      <p className="title is-5">{t("transfer.titleNew")}</p>
      <hr />

      <FormEntry helpText={t("used") + ": " + text.length + "/300"}>
        <textarea
          className="textarea"
          value={text}
          onChange={updateText}
          placeholder={t("transfer.motivationPlaceholder")}
        />
      </FormEntry>

      <ProjectFreePlaces project={currentProject} />

      <div className="field is-grouped is-grouped-right">
        <button className="button is-link is-outlined" onClick={addTransfer}>
          {t("transfer.request")}
        </button>
      </div>
    </div>
  )
}

export default TransferNew
