import { Route, Switch, useRouteMatch } from "react-router"
import BlogPostContextProvider from "../../../context/BlogPostContext"
import BlogPostInfo from "../../data/blogpost/BlogPostInfo"
import BlogPostList from "../../data/blogpost/BlogPostList"
import MeetingPostList from "../../data/blogpost/MeetingPostList"
import LoadingSpinner from "../../helpers/LoadingSpinner"

const BlogView = ({ projectID }) => {
  const { url } = useRouteMatch()

  if (!projectID)
    return (
      <div className="box">
        <LoadingSpinner />
      </div>
    )

  return (
    <BlogPostContextProvider projectID={projectID}>
      <Switch>
        <Route exact path={url}>
          <MeetingPostList />

          <BlogPostList />
        </Route>

        <Route path={url + "/:postID"}>
          <BlogPostInfo />
        </Route>
      </Switch>
    </BlogPostContextProvider>
  )
}

export default BlogView
