import { useContext } from "react"
import { useTranslation } from "react-i18next"

import { ProposalsContext } from "../../context/ProposalsContext"
import LoadingSpinner from "../helpers/LoadingSpinner"
import ProposalPreview from "./ProposalPreview"

const ProposalList = ({ category }) => {
  const { t } = useTranslation("data")
  const {
    [category + "Proposals"]: proposals,
    [category + "ProposalsLoading"]: loading,
  } = useContext(ProposalsContext)

  if (loading)
    return (
      <div className="box">
        <LoadingSpinner text={t("loading.proposalList")} />
      </div>
    )

  return proposals.length ? (
    <>
      {proposals.map(proposal => (
        <ProposalPreview key={proposal.id} proposal={proposal} />
      ))}
    </>
  ) : (
    <div className="box">
      <p className="has-text-grey">{t("emptyList")}</p>
    </div>
  )
}

export default ProposalList
