import { useTranslation } from "react-i18next"
import useCourses from "../../context/useCourses"
import LoadingSpinner from "../helpers/LoadingSpinner"
import CourseListEntry from "./CourseListEntry"

const CourseList = ({ needRefetch, setNeedRefetch, mode }) => {
  const { t } = useTranslation("data")
  const { courses, loading, switchEditMode } = useCourses(
    needRefetch,
    setNeedRefetch,
    mode
  )

  const coursesTable = Object.values(courses).length ? (
    <div className="table-container">
      <table className="table is-fullwidth">
        <thead>
          <tr>
            <th>{t("course.titleRu")}</th>
            <th>{t("course.titleEn")}</th>
            <th>{t("course.group")}</th>
            <th>{t("course.created")}</th>
            <th className="has-text-centered">{t("course.action")}</th>
          </tr>
        </thead>
        <tbody>
          {Object.values(courses).map(course => (
            <CourseListEntry
              key={course.id}
              course={course}
              switchEditMode={switchEditMode}
              setNeedRefetch={setNeedRefetch}
            />
          ))}
        </tbody>
      </table>
    </div>
  ) : (
    <p className="has-text-grey">{t("emptyList")}</p>
  )

  return (
    <div className="box">
      <p className="title is-5">{t("course.listTitle")}</p>
      {loading ? (
        <LoadingSpinner text={t("loading.coursesList")} />
      ) : (
        coursesTable
      )}
    </div>
  )
}

export default CourseList
