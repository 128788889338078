import { useState } from "react"
import { useTranslation } from "react-i18next"
import ProjectWishList from "../../data/project/ProjectWishList"

const WishListView = () => {
  const { t } = useTranslation("data")
  const [mode, setMode] = useState("active")

  return (
    <>
      <div className="box title is-4 has-text-right">
        {t("project.wish.list")}
      </div>

      <div className="tabs is-centered">
        <ul>
          <li className={mode === "active" ? " is-active" : ""}>
            <a href="#mode" onClick={() => setMode("active")}>
              {t("project.wish.active")}
            </a>
          </li>
          <li className={mode === "archive" ? " is-active" : ""}>
            <a href="#mode" onClick={() => setMode("archive")}>
              {t("project.wish.archive")}
            </a>
          </li>
        </ul>
      </div>

      <ProjectWishList mode={mode} />
    </>
  )
}

export default WishListView
