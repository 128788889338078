import axios from "axios"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import { API_URL, messageToToasts } from "../../../utils"

const ApplicationsActionsView = () => {
  const [submitting, setSubmitting] = useState(false)
  const { t, i18n } = useTranslation(["data", "ws"])

  const [fhpToken, setfhpToken] = useState("")
  const [aaToken, setaaToken] = useState("")
  const [mode, setMode] = useState("exceptAccepted")
  const [dpToken, setdpToken] = useState("")
  const [priority, setPriority] = useState("0")

  const forceHighest = async () => {
    setSubmitting(true)

    try {
      const resp = await axios.post(
        `${API_URL}/application/forceHighestPriority`,
        { fhpToken }
      )
      setSubmitting(false)

      console.log(resp.data)
      const { status, message } = resp.data

      if (status === "failed") return messageToToasts(message, i18n.language)

      toast.success("Задача выполнена!")
    } catch (error) {
      setSubmitting(false)
      toast.error("Server error")
    }
  }

  const distributePriority = async () => {
    setSubmitting(true)

    try {
      const resp = await axios.post(
        `${API_URL}/application/distributePriority`,
        { dpToken, priority: Number(priority) }
      )
      setSubmitting(false)

      console.log(resp.data)
      const { status, message, distributed, errorsNum } = resp.data

      if (status === "failed") return messageToToasts(message, i18n.language)

      toast.success(
        "Задача выполнена! Распределено студентов на проекты: " + distributed
      )
      if (errorsNum > 0)
        toast.warning("Не распределено приоритетов: " + errorsNum)
    } catch (error) {
      setSubmitting(false)
      toast.error("Server error")
    }
  }

  const archiveApplications = async () => {
    setSubmitting(true)

    try {
      const resp = await axios.post(
        `${API_URL}/application/archiveApplications`,
        { mode, aaToken }
      )
      setSubmitting(false)

      console.log(resp.data)
      const { status, message, archived } = resp.data

      if (status === "failed") return messageToToasts(message, i18n.language)

      toast.success("Задача выполнена! Архивировано: " + archived)
    } catch (error) {
      setSubmitting(false)
      toast.error("Server error")
    }
  }

  return (
    <>
      <div className="box">
        <p className="title is-4 has-text-right">{t("application.actions")}</p>
      </div>

      <div className="box">
        <p className="title is-5">{t("application.forceHighestPriority")}</p>
        <hr />

        <p>
          Если у студента есть принятые руководителем заявки, но он не утвердил
          ни одну из них окончательно, то автоматически утверждается одобренная
          руководителем заявка с наиболее высоким приоритетом
        </p>

        <div className="field has-addons mt-3">
          <p className="control is-expanded">
            <input
              type="text"
              className="input"
              value={fhpToken}
              onChange={e => setfhpToken(e.target.value)}
              placeholder={t("application.actionKey")}
            />
          </p>
          <p className="control">
            <button
              className="button is-link"
              onClick={forceHighest}
              disabled={submitting}
            >
              {t("run")}
            </button>
          </p>
        </div>
      </div>

      <div className="box">
        <p className="title is-5">{t("application.distributeApplications")}</p>
        <hr />

        <p className="mb-3">
          Места заполняются согласно выставленным приоритетам при наличии
          свободных мест после распределения на более ранних этапах. Заявки
          сортируются в соответствии с GPA студента. При запуске, выберите
          заявки какого приоритета требуется распределить.
        </p>

        <div className="message is-danger">
          <div className="message-body">
            Внимание! При запуске для отдельного приоритета, если функция не
            была запущена последовательно для всех старших приоритетов, то
            результаты работы будут некорректны
          </div>
        </div>

        <div className="field has-addons mt-3">
          <p className="control is-expanded">
            <input
              type="text"
              className="input"
              value={dpToken}
              onChange={e => setdpToken(e.target.value)}
              placeholder={t("application.actionKey")}
            />
          </p>

          <p className="control">
            <span className="select">
              <select
                value={priority}
                onChange={e => setPriority(e.target.value)}
              >
                <option value={0}>Все приоритеты</option>
                <option value={1}>Приоритет 1</option>
                <option value={2}>Приоритет 2</option>
                <option value={3}>Приоритет 3</option>
                <option value={4}>Приоритет 4</option>
                <option value={5}>Приоритет 5</option>
              </select>
            </span>
          </p>

          <p className="control">
            <button
              className="button is-link"
              onClick={distributePriority}
              disabled={submitting}
            >
              {t("run")}
            </button>
          </p>
        </div>
      </div>

      <div className="box">
        <p className="title is-5">{t("application.archiveApplications")}</p>
        <hr />

        <p>
          Заявки помещаются в архив и пропадают как у студентов, так и
          руководителей. Можно выбрать, архивировать все заявки или все кроме
          окончательно принятых.
        </p>

        <div className="field has-addons mt-3">
          <p className="control is-expanded">
            <input
              type="text"
              className="input"
              value={aaToken}
              onChange={e => setaaToken(e.target.value)}
              placeholder={t("application.actionKey")}
            />
          </p>

          <p className="control">
            <span className="select">
              <select value={mode} onChange={e => setMode(e.target.value)}>
                <option value="exceptAccepted">кроме принятых</option>
                <option value="all">все</option>
              </select>
            </span>
          </p>

          <p className="control">
            <button
              className="button is-link"
              onClick={archiveApplications}
              disabled={submitting}
            >
              {t("run")}
            </button>
          </p>
        </div>
      </div>
    </>
  )
}

export default ApplicationsActionsView
