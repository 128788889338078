import axios from "axios"
import { useContext } from "react"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router"
import { toast } from "react-toastify"
import { ProposalsContext } from "../../context/ProposalsContext"
import { API_URL, messageToToasts } from "../../utils"

const ProposalCancel = ({ proposal, setPageMode }) => {
  const history = useHistory()
  const { refetch } = useContext(ProposalsContext)
  const { t, i18n } = useTranslation("data")

  const cancelProposal = async () => {
    try {
      const data = { proposalID: proposal.id }
      const resp = await axios.post(`${API_URL}/project/proposal/cancel`, data)
      const { status, message } = resp.data

      if (status === "failed") return messageToToasts(message, i18n.language)

      toast.success(t("proposal.cancelSuccessMessage"))
      refetch(proposal.status === "draft" ? "draftProposals" : "mainProposals")
      refetch("archiveProposals")
      history.push(`../list${proposal.status === "draft" ? "Draft" : "Main"}`)
    } catch (err) {
      toast.error("Server error. Try to reload page or contact administrator")
    }
  }

  return (
    <div className="box">
      <p>
        {t("proposal.confirmCancel")} "{proposal.title}"?
        {proposal.status === "draft"
          ? " " + t("proposal.cancelDraftText") + "!"
          : null}
      </p>
      <div className="columns mt-3">
        <div className="column is-half has-text-centered">
          <button
            className="button is-link is-outlined"
            onClick={() => setPageMode("view")}
          >
            {t("proposal.back")}
          </button>
        </div>
        <div className="column is-half has-text-centered">
          <button className="button is-danger" onClick={cancelProposal}>
            {proposal.status === "draft"
              ? t("proposal.cancelDraft")
              : t("proposal.cancel")}
          </button>
        </div>
      </div>
    </div>
  )
}

export default ProposalCancel
