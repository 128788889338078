import dayjs from "dayjs"
import { useState } from "react"
import FormEntry from "../helpers/FormEntry"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { toast } from "react-toastify"
import axios from "axios"
import { API_URL, messageToToasts } from "../../utils"
import { useTranslation } from "react-i18next"

const DivisionListEntry = ({ division, switchEditMode, setNeedRefetch }) => {
  const { t, i18n } = useTranslation("data")
  const [editTitleRu, setEditTitleRu] = useState(division.title.ru)
  const [editTitleEn, setEditTitleEn] = useState(division.title.en)

  const updateDivision = async () => {
    if (division.title.ru === editTitleRu && division.title.en === editTitleEn)
      return switchEditMode(division.id)

    try {
      const data = {
        title: {
          ru: editTitleRu.trim(),
          en: editTitleEn.trim(),
        },
        updateID: division.id,
      }

      const resp = await axios.post(`${API_URL}/divisions/update`, data)
      const { status, message } = resp.data
      if (status === "failed") return messageToToasts(message, i18n.language)

      toast.success(t("division.updateSuccessMessage"))
      switchEditMode(division.id)
      setNeedRefetch(true)
    } catch (err) {
      toast.error("Server error. Try to reload page or contact administrator")
    }
  }

  return (
    <tr key={division.id}>
      <td>
        {!division.editMode ? (
          division.title.ru
        ) : (
          <FormEntry>
            <input
              className="input is-small"
              type="text"
              value={editTitleRu}
              onChange={e => setEditTitleRu(e.target.value)}
            />
          </FormEntry>
        )}
      </td>
      <td>
        {" "}
        {!division.editMode ? (
          division.title.en
        ) : (
          <FormEntry>
            <input
              className="input is-small"
              type="text"
              value={editTitleEn}
              onChange={e => setEditTitleEn(e.target.value)}
            />
          </FormEntry>
        )}
      </td>
      <td>{dayjs(division.created).format("DD/MM/YYYY")}</td>
      <td className="has-text-centered">
        {!division.editMode ? (
          <button
            className="button is-link is-outlined is-small"
            onClick={() => switchEditMode(division.id)}
          >
            <FontAwesomeIcon icon="pen" />
          </button>
        ) : (
          <>
            <button
              className="button is-link is-outlined mr-2 is-small"
              onClick={updateDivision}
            >
              <FontAwesomeIcon icon="check" />
            </button>
            <button
              className="button is-link is-outlined is-small"
              onClick={() => switchEditMode(division.id)}
            >
              <FontAwesomeIcon icon="backward" />
            </button>
          </>
        )}
      </td>
    </tr>
  )
}

export default DivisionListEntry
