import dayjs from "dayjs"
import { useState } from "react"
import FormEntry from "../helpers/FormEntry"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { toast } from "react-toastify"
import axios from "axios"
import { API_URL, messageToToasts } from "../../utils"
import { useTranslation } from "react-i18next"

const CourseListEntry = ({ course, switchEditMode, setNeedRefetch }) => {
  const { t, i18n } = useTranslation("data")
  const [editTitleRu, setEditTitleRu] = useState(course.title.ru)
  const [editTitleEn, setEditTitleEn] = useState(course.title.en)
  const [editGroup, setEditGroup] = useState(course.group)

  const updateCourse = async () => {
    if (
      course.title.ru === editTitleRu &&
      course.title.en === editTitleEn &&
      course.group === editGroup
    )
      return switchEditMode(course.id)

    try {
      const data = {
        title: {
          ru: editTitleRu.trim(),
          en: editTitleEn.trim(),
        },
        mode: course.mode,
        group: editGroup,
        updateID: course.id,
      }

      const resp = await axios.post(`${API_URL}/courses/update`, data)
      const { status, message } = resp.data
      if (status === "failed") return messageToToasts(message, i18n.language)

      toast.success(t("course.updateSuccessMessage"))
      switchEditMode(course.id)
      setNeedRefetch(true)
    } catch (err) {
      toast.error("Server error. Try to reload page or contact administrator")
    }
  }

  return (
    <tr key={course.id}>
      <td>
        {!course.editMode ? (
          course.title.ru
        ) : (
          <FormEntry>
            <input
              className="input is-small"
              type="text"
              value={editTitleRu}
              onChange={e => setEditTitleRu(e.target.value)}
            />
          </FormEntry>
        )}
      </td>
      <td>
        {!course.editMode ? (
          course.title.en
        ) : (
          <FormEntry>
            <input
              className="input is-small"
              type="text"
              value={editTitleEn}
              onChange={e => setEditTitleEn(e.target.value)}
            />
          </FormEntry>
        )}
      </td>

      <td>
        {!course.editMode ? (
          course.group ? (
            course.group
          ) : (
            t("course.others")
          )
        ) : (
          <FormEntry>
            <input
              className="input is-small"
              type="text"
              value={editGroup}
              onChange={e => setEditGroup(e.target.value)}
            />
          </FormEntry>
        )}
      </td>

      <td>{dayjs(course.created).format("DD/MM/YYYY")}</td>
      <td className="has-text-centered">
        {!course.editMode ? (
          <button
            className="button is-link is-outlined is-small"
            onClick={() => switchEditMode(course.id)}
          >
            <FontAwesomeIcon icon="pen" />
          </button>
        ) : (
          <>
            <button
              className="button is-link is-outlined mr-2 is-small"
              onClick={updateCourse}
            >
              <FontAwesomeIcon icon="check" />
            </button>
            <button
              className="button is-link is-outlined is-small"
              onClick={() => switchEditMode(course.id)}
            >
              <FontAwesomeIcon icon="backward" />
            </button>
          </>
        )}
      </td>
    </tr>
  )
}

export default CourseListEntry
