import { useContext } from "react"
import useListData from "../../../context/useListData"
import { UserContext } from "../../../context/UserContext"
import LoadingSpinner from "../../helpers/LoadingSpinner"
import BulletinList from "./BulletinList"
import BulletinNew from "./BulletinNew"

const BulletinPage = () => {
  const {
    user: { userType },
  } = useContext(UserContext)
  const { bulletins, refetch, loading } = useListData("bulletins", "/bulletin")

  if (loading)
    return (
      <div className="box">
        <LoadingSpinner />
      </div>
    )

  return (
    <>
      {userType !== "externalClient" || <BulletinNew refetch={refetch} />}

      <BulletinList bulletins={bulletins} refetch={refetch} />
    </>
  )
}

export default BulletinPage
