import axios from "axios"
import i18next from "i18next"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import { API_URL, messageToToasts, yearsToText } from "../../../utils"
import FormEntry from "../../helpers/FormEntry"
import LatexMarkdownForm from "../../helpers/LatexMarkdownForm"
import MathMarkdown from "../../helpers/MathMarkdown"
import { useAsgnContext } from "./AsgnContext"
import StudentStatus from "./StudentStatus"

const AsgnUpdate = ({ asgn, student, group, setModeView, refetch }) => {
  const { t } = useTranslation("data")
  const { updateAsgnData } = useAsgnContext()

  const [submitting, setSubmitting] = useState(false)
  const [theme, setTheme] = useState(asgn?.theme || group?.theme)
  const [target, setTarget] = useState(asgn?.target || group?.target)
  const [tasks, setTasks] = useState(asgn?.tasks || group?.tasks)
  const [requirements, setRequirements] = useState(
    asgn?.requirements || group?.requirements
  )
  const [reportFormat, setReportFormat] = useState(
    asgn?.reportFormat || group?.reportFormat
  )
  const [presentation, setPresentation] = useState(
    asgn?.presentation || group?.presentation
  )

  useEffect(
    () => {
      console.log("fired!")
      updateAsgnData(
        {
          theme,
          target,
          tasks,
          requirements,
          reportFormat,
          presentation,
        },
        asgn?.studentID
      )
    },
    // eslint-disable-next-line
    [theme, target, tasks, requirements, reportFormat, presentation]
  )

  const studentName =
    i18next.language === "ru" ? student?.fullName : student?.fullNameEn

  const updateAssignment = async () => {
    setSubmitting(true)

    try {
      const data = {
        studentID: asgn?.studentID,
        groupID: asgn?.groupID,
        theme,
        target,
        tasks,
        requirements,
        reportFormat,
        presentation,
      }

      const resp = await axios.post(`${API_URL}/group/updateAssignment`, data)
      const { status, message } = resp.data
      setSubmitting(false)

      if (status === "failed") return messageToToasts(message, i18next.language)

      refetch(false)
      setModeView()
    } catch (error) {
      setSubmitting(false)
      toast.error("Server error: " + error)
    }
  }

  return (
    <>
      <div className="columns">
        <div className="column is-7">
          <strong>{yearsToText(student?.yearOfStudy)}</strong>&nbsp;
          {studentName}
        </div>

        <div className="column is-5 has-text-right">
          <strong>{t("group.statusHelp")}:</strong>&nbsp;
          <StudentStatus status={asgn?.status}>
            {t(`group.${asgn?.status || "notAssigned"}`)}
          </StudentStatus>
        </div>
      </div>

      <FormEntry labelText={t("group.asgnTheme")} helpText={t("req")}>
        <input
          type="text"
          className="input"
          placeholder={t("group.asgnThemePlh")}
          value={theme}
          onChange={e => setTheme(e.target.value)}
          disabled={asgn?.status === "approved"}
        />
      </FormEntry>

      <FormEntry labelText={t("group.asgnTarget")} helpText={t("req")}>
        <textarea
          className="textarea"
          placeholder={t("group.asgnTargetPlh")}
          value={target}
          onChange={e => setTarget(e.target.value)}
          disabled={asgn?.status === "approved"}
        />
      </FormEntry>

      {asgn?.status === "approved" ? (
        <>
          <FormEntry labelText={t("group.asgnTasks")} helpText={t("req")}>
            <MathMarkdown styleClass="content has-background-light p-2 has-text-grey">
              {asgn?.tasks}
            </MathMarkdown>
          </FormEntry>
        </>
      ) : (
        <LatexMarkdownForm
          labelText={t("group.asgnTasks")}
          value={tasks}
          setValue={setTasks}
          isReq={false}
        />
      )}

      <FormEntry labelText={t("group.requirements")} helpText={t("req")}>
        <textarea
          className="textarea"
          placeholder={t("group.requirementsPlh")}
          value={requirements}
          onChange={e => setRequirements(e.target.value)}
          disabled={asgn?.status === "approved"}
        />
      </FormEntry>

      <div className="columns">
        <div className="column is-half">
          <FormEntry labelText={t("group.reportFormat")} helpText={t("req")}>
            <textarea
              className="textarea"
              placeholder={t("group.reportFormatPlh")}
              value={reportFormat}
              onChange={e => setReportFormat(e.target.value)}
              disabled={asgn?.status === "approved"}
            />
          </FormEntry>
        </div>

        <div className="column is-half">
          <FormEntry labelText={t("group.presentation")} helpText={t("req")}>
            <textarea
              className="textarea"
              placeholder={t("group.presentationPlh")}
              value={presentation}
              onChange={e => setPresentation(e.target.value)}
              disabled={asgn?.status === "approved"}
            />
          </FormEntry>
        </div>
      </div>

      <div className="field is-grouped is-grouped-right">
        <p className="control">
          <button
            className="button is-small is-light is-capitalized"
            onClick={setModeView}
            disabled={submitting}
          >
            {t(asgn?.status === "approved" ? "hide" : "cancel")}
          </button>
        </p>
        {asgn?.status === "approved" || (
          <p className="control">
            <button
              className="button is-small is-link"
              onClick={updateAssignment}
              disabled={submitting}
            >
              {t("group.asgnSend")}
            </button>
          </p>
        )}
      </div>

      <hr />
    </>
  )
}

export default AsgnUpdate
