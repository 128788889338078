import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import axios from "axios"
import i18next from "i18next"
import { useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import { UserContext } from "../../../context/UserContext"
import { API_URL, messageToToasts } from "../../../utils"

const AlternativeEmailView = ({ currentProfile, profile }) => {
  const { t } = useTranslation("data")
  const {
    user: { userType },
  } = useContext(UserContext)

  const [mode, setMode] = useState("view")
  const [newEmail, setNewEmail] = useState(profile?.alternativeEmail || "")

  const updateAltEmail = async () => {
    try {
      const resp = await axios.post(`${API_URL}/user/student/updateAltEmail`, {
        studentID: profile?.id,
        newEmail: newEmail.toLowerCase(),
      })

      const { status, message } = resp.data
      if (status === "failed") return messageToToasts(message, i18next.language)

      window.location.reload()
    } catch (error) {
      toast.error("Server error")
    }
  }

  if (mode === "update")
    return (
      <div className="box">
        <div className="field has-addons">
          <div className="control is-expanded">
            <input
              value={newEmail}
              onChange={e => setNewEmail(e.target.value)}
              className="input"
              type="text"
              placeholder="your_another_mail@domain.com"
            />
            <p className="help">{t("user.altEmailHelp")}</p>
          </div>

          <div className="control">
            <button className="button is-link" onClick={updateAltEmail}>
              {t("update")}
            </button>
          </div>

          <div className="control">
            <button
              className="button is-warning"
              onClick={() => setMode("view")}
            >
              {t("cancel")}
            </button>
          </div>
        </div>
      </div>
    )

  return (
    <div className="box">
      <div className="columns">
        <div className="column is-10">
          <p>
            <strong>{t("user.altEmail")}: </strong>
            {profile?.alternativeEmail ? (
              <a href={"mailto:" + profile.alternativeEmail}>
                {profile?.alternativeEmail}
              </a>
            ) : (
              <span className="has-text-grey">{t("user.noAltEmail")}</span>
            )}
          </p>
        </div>

        {userType === "admin" || currentProfile ? (
          <div className="column is-2 has-text-right">
            <div className="field">
              <p className="control">
                <span
                  className="has-text-grey pointer"
                  title={t("user.removeFromProject")}
                  onClick={() => setMode("update")}
                >
                  <FontAwesomeIcon icon="pen" size="lg" />
                </span>
              </p>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default AlternativeEmailView
