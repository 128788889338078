import axios from "axios"
import { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { API_URL } from "../utils"

const useReviews = proposalID => {
  const [reviews, setReviews] = useState([])

  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const requestURL = `${API_URL}/project/proposal/listReviews?proposalID=${proposalID}`

        const resp = await axios.get(requestURL)
        const { status, reviews } = resp.data

        if (status === "ok") setReviews(reviews)
      } catch (error) {
        toast.error("Server error. Try to reload page or contact administrator")
      }
    }

    fetchReviews()
  }, [proposalID])

  return { reviews }
}

export default useReviews
