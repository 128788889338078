import dayjs from "dayjs"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import useUsers from "../../context/useUsers"
import { nilObjectID, rolesOptions, yearsToText } from "../../utils"
import LoadingSpinner from "../helpers/LoadingSpinner"
import StudentInfoExport from "../data/StudentInfoExport"

const UserList = ({ userType }) => {
  const { t } = useTranslation("data")
  const { loading, users } = useUsers(userType)

  const roles = rolesOptions[userType].reduce((acc, role) => {
    acc[role.value] = role.label
    return acc
  }, {})

  const userTypeHeader = userType => {
    switch (userType) {
      case "admin":
        return (
          <>
            <th>{t("user.role")}</th>
          </>
        )
      case "projectLeader":
        return (
          <>
            <th>{t("user.role")}</th>
            <th>{t("user.position")}</th>
          </>
        )
      case "student":
        return (
          <>
            <th>{t("user.yearOfStudy")}</th>
            <th></th>
            <th></th>
            <th></th>
          </>
        )
      default:
        return null
    }
  }

  const userTypeRows = (userType, user) => {
    switch (userType) {
      case "admin":
        return (
          <>
            <td>{roles[user.role]}</td>
          </>
        )
      case "projectLeader":
        return (
          <>
            <td>{roles[user.role]}</td>
            <td>{user.position}</td>
          </>
        )
      case "student":
        return (
          <>
            <td>{yearsToText(user.yearOfStudy)}</td>

            <td>
              <FontAwesomeIcon
                icon="envelope"
                size="lg"
                className={!user.emailValidated ? "has-text-grey" : ""}
              />
            </td>

            <td>
              <FontAwesomeIcon
                icon="globe-europe"
                size="lg"
                className={
                  user?.projectID === nilObjectID ? "has-text-grey" : ""
                }
              />
            </td>

            <td>
              <FontAwesomeIcon
                icon="globe-europe"
                size="lg"
                className={
                  user?.oldProjectID === nilObjectID ? "has-text-grey" : ""
                }
              />
            </td>
          </>
        )
      default:
        return null
    }
  }

  const usersTable = users.length ? (
    <div className="table-container">
      <table className="table is-fullwidth">
        <thead>
          <tr>
            <th>{t("user.name")}</th>
            <th>Email</th>
            <th>{t("user.created")}</th>
            {userTypeHeader(userType)}
          </tr>
        </thead>
        <tbody>
          {users.map(user => (
            <tr key={user.id}>
              <th>
                <Link
                  to={`/r/common/profile?userID=${user.id}&userType=${userType}`}
                  target="_blank"
                >
                  {user.fullName}
                </Link>
              </th>
              <td>{user.email}</td>
              <td>{dayjs(user.created).format("DD/MM/YYYY")}</td>
              {userTypeRows(userType, user)}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  ) : (
    <p className="has-text-grey">{t("emptyList")}</p>
  )

  return (
    <>
      <div className="box has-text-centered">
        {userType === "student" ? (
          <div className="columns">
            <div className="column is-4 pt-4">
              Всего пользователей: {users?.length}
            </div>

            <div className="column is-4 pt-4">
              Без проектов:{" "}
              {users.filter(user => user.projectID === nilObjectID).length}
            </div>

            <div className="column is-4">
              <StudentInfoExport />
            </div>
          </div>
        ) : (
          "Всего пользователей: " + users?.length
        )}
      </div>
      <div className="box">
        {loading ? <LoadingSpinner text={t("loading.userList")} /> : usersTable}
      </div>
    </>
  )
}

export default UserList
