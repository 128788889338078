import axios from "axios"
import { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import { ProjectsContext } from "../../../context/ProjectsContext"
import useProjectChanges from "../../../context/useProjectChanges"
import { API_URL, messageToToasts } from "../../../utils"
import MaxStudents from "../../data/proposal/MaxStudents"
import FormEntry from "../../helpers/FormEntry"
import LoadingSpinner from "../../helpers/LoadingSpinner"

const ProjectChangePreview = ({ change, refetch }) => {
  const { t, i18n } = useTranslation(["data", "ws"])
  const { projects } = useContext(ProjectsContext)

  const [declineMessage, setDeclineMessage] = useState("")

  const project = projects.find(pr => pr.id === change.projectID)

  const spanFromStatus = status => {
    switch (status) {
      case "awaiting":
        return (
          <span className="has-text-warning">
            {t("application.awaitingStatus")}
          </span>
        )
      case "declined":
        return (
          <span className="has-text-danger">
            {t("application.declinedStatus")}
          </span>
        )
      case "accepted":
        return (
          <span className="has-text-success">
            {t("application.acceptedStatus")}
          </span>
        )
      default:
        break
    }
  }

  const declineRequest = async () => {
    try {
      const requestURL = `${API_URL}/project/declineProjectChangeReq`
      const data = {
        changeID: change.id,
        declineMessage: declineMessage,
      }

      const resp = await axios.post(requestURL, data)
      const { status, message } = resp.data

      if (status === "failed") return messageToToasts(message, i18n.language)

      refetch()
      setDeclineMessage("")
    } catch (error) {}
  }

  const acceptRequest = async () => {
    try {
      const requestURL = `${API_URL}/project/acceptProjectChangeReq`
      const data = {
        changeID: change.id,
      }

      const resp = await axios.post(requestURL, data)
      const { status, message } = resp.data

      if (status === "failed") return messageToToasts(message, i18n.language)

      refetch()
      setDeclineMessage("")
    } catch (error) {}
  }

  return (
    <div className="box">
      <p className="title is-5">
        {t("project.changeRequest") + " " + change.id}
      </p>

      <hr />

      <p>
        <strong>{t("proposal.titlePlaceholder")}: </strong>
        <Link
          to={"/r/admin/projects/viewProject/" + change.projectID}
          target="_blank"
        >
          {project?.title}
        </Link>
      </p>

      <p className="title is-6">{t("ws:projectsPage.availableSeats")}</p>

      <MaxStudents maxStudents={project?.maxStudents} />

      <hr />

      <p className="title is-6">{t("project.acceptedStudents")}</p>

      <MaxStudents maxStudents={project?.acceptedStudents} />

      <hr />

      <p className="title is-6">{t("project.newMaxStudents")}</p>
      <MaxStudents maxStudents={change.newMaxStudents} />

      {change.status === "declined" && change.declineMessage !== "" ? (
        <div className="message is-danger">
          <div className="message-body">{change.declineMessage}</div>
        </div>
      ) : null}

      <hr />

      {change.status === "awaiting" ? (
        <FormEntry>
          <textarea
            rows="2"
            className="textarea"
            value={declineMessage}
            onChange={e => setDeclineMessage(e.target.value)}
            placeholder="Если Вы хотите отклонить изменение, то здесь можно написать причину. Руководители ИПС увидят этот текст..."
          />
        </FormEntry>
      ) : null}

      <div className="columns mt-2">
        <div className="column is-5 has-text-centered">
          <strong>{spanFromStatus(change.status)}</strong>
        </div>
        <div className="column is-7 has-text-right">
          {change.status === "awaiting" ? (
            <div className="field is-grouped is-grouped-right">
              <button
                className="button is-success is-outlined is-small mr-3"
                onClick={acceptRequest}
              >
                {t("application.accept")}
              </button>
              <button
                className="button is-danger is-outlined is-small"
                onClick={declineRequest}
              >
                {t("application.decline")}
              </button>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  )
}

const ProjectChangeListView = () => {
  const { changes, loading, refetch } = useProjectChanges("all")
  const [mode, setMode] = useState("awaiting")
  const [viewedChanges, setViewedChanges] = useState([])

  useEffect(() => {
    if (mode === "awaiting")
      setViewedChanges(changes.filter(ch => ch.status === "awaiting"))
    else setViewedChanges(changes)
  }, [mode, changes])

  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <>
          <div className="box">
            <p className="is-size-5">
              Отображать заявки:{" "}
              <span
                className={
                  "pointer " + (mode === "awaiting" ? "has-text-link" : "")
                }
                onClick={() => setMode("awaiting")}
              >
                ожидающие решения
              </span>
              /
              <span
                className={"pointer " + (mode === "all" ? "has-text-link" : "")}
                onClick={() => setMode("all")}
              >
                все
              </span>
            </p>
          </div>
          {viewedChanges.map(change => (
            <ProjectChangePreview
              change={change}
              refetch={refetch}
              key={change.id}
            />
          ))}
        </>
      )}
    </>
  )
}

export default ProjectChangeListView
