import { useTranslation } from "react-i18next"
import InviteNewFile from "../../data/InviteNewFile"
import InviteNewForm from "../../data/InviteNewForm"

const InviteNewView = () => {
  const { t } = useTranslation("ws")

  return (
    <>
      {" "}
      <div className="box has-text-right title is-4">
        {t("usersPage.inviteNew.title")}
      </div>
      <InviteNewForm />
      <InviteNewFile />
    </>
  )
}

export default InviteNewView
