import { useContext } from "react"
import { ProjectsContext } from "../../../context/ProjectsContext"
import useGroupsForProject from "../../../context/useGroupsForProject"
import GroupNew from "../../data/groups/GroupNew"
import GroupPreview from "../../data/groups/GroupPreview"
import LoadingSpinner from "../../helpers/LoadingSpinner"

const ProjectGroupsView = () => {
  const { currentProject } = useContext(ProjectsContext)
  const { groups, refetch, loading } = useGroupsForProject(currentProject?.id)

  return (
    <>
      <GroupNew refetch={refetch} />

      {loading ? (
        <div className="box">
          <LoadingSpinner />
        </div>
      ) : (
        groups.map(group => <GroupPreview key={group.id} group={group} />)
      )}
    </>
  )
}

export default ProjectGroupsView
