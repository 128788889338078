import dayjs from "dayjs"
import { useContext } from "react"
import { useTranslation } from "react-i18next"
import { CommonDataContext } from "../../../context/CommonDataContext"
import { yearsToText } from "../../../utils"
import AdminInfoView from "./AdminInfoView"
import { SettingsContext } from "../../../context/SettingsContext"

const ProfileInfoView = ({ profile }) => {
  const { t, i18n } = useTranslation("ws")
  const {
    programmes: { dict: progDict },
    divisions: { dict: divDict },
  } = useContext(CommonDataContext)
  const { settings } = useContext(SettingsContext)

  return (
    <div className="box">
      <p className="title is-5">{t("userProfilePage.infoTitle")}</p>
      <div className="table-container">
        <table className="table is-fullwidth">
          <tbody>
            <tr>
              <th>Email</th>
              <td>{profile.email}</td>
            </tr>
            <tr>
              <th>{t("userProfilePage.signDate")}</th>
              <td>{dayjs(profile.created).format("DD/MM/YYYY")}</td>
            </tr>

            {/* Project Leader */}
            {profile.userType === "projectLeader" ? (
              <>
                <tr>
                  <th>{t("userProfilePage.workPlace")}</th>
                  <td>
                    {profile.workPlace === "hse"
                      ? t("userProfilePage.hse")
                      : profile.workPlace}
                  </td>
                </tr>

                {profile.workPlace === "hse" ? (
                  <tr>
                    <th>{t("userProfilePage.division")}</th>
                    <td>{divDict[profile?.division]?.title[i18n.language]}</td>
                  </tr>
                ) : null}

                <tr>
                  <th>{t("userProfilePage.position")}</th>
                  <td>{profile.position}</td>
                </tr>
              </>
            ) : null}

            {/* Division Manager */}
            {profile.userType === "divisionManager" ? (
              <tr>
                <th>{t("userProfilePage.division")}</th>
                <td>{divDict[profile?.division]?.title[i18n.language]}</td>
              </tr>
            ) : null}

            {/* Student */}
            {profile.userType === "student" ? (
              <>
                <tr>
                  <th>{t("userProfilePage.programme")}</th>
                  <td>
                    {progDict[profile?.programmeID]?.title[i18n.language]}
                  </td>
                </tr>

                <tr>
                  <th>{t("userProfilePage.yearOfStudy")}</th>
                  <td>
                    {yearsToText(profile?.yearOfStudy)} (в&nbsp;
                    {settings?.currentYear}/
                    {Number(settings?.currentYear) - 1999} учебном году)
                  </td>
                </tr>
              </>
            ) : null}
          </tbody>
        </table>

        {profile.userType !== "student" || <AdminInfoView profile={profile} />}
      </div>
    </div>
  )
}

export default ProfileInfoView
