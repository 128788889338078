import Select from "react-select"
import { useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import { CommonDataContext } from "../../../context/CommonDataContext"
import { UserContext } from "../../../context/UserContext"
import FormEntry from "../../helpers/FormEntry"
import { toast } from "react-toastify"
import { ProjectsContext } from "../../../context/ProjectsContext"
import axios from "axios"
import { API_URL, messageToToasts } from "../../../utils"
import { SettingsContext } from "../../../context/SettingsContext"
import { ApplicationsStudentContext } from "../../../context/ApplicationsStudentContext"

const priorityOptions = {
  ru: [
    { value: 1, label: "Приоритет 1 (самый высокий)" },
    { value: 2, label: "Приоритет 2" },
    { value: 3, label: "Приоритет 3" },
    { value: 4, label: "Приоритет 4" },
    { value: 5, label: "Приоритет 5" },
  ],
  en: [
    { value: 1, label: "Priority 1 (the highest)" },
    { value: 2, label: "Priority 2" },
    { value: 3, label: "Priority 3" },
    { value: 4, label: "Priority 4" },
    { value: 5, label: "Priority 5" },
  ],
}

const ApplicationNew = () => {
  const [submitting, setSubmitting] = useState(false)
  const { t, i18n } = useTranslation("data")
  const {
    user: { userID },
  } = useContext(UserContext)
  const {
    students: { dict: studDict },
  } = useContext(CommonDataContext)
  const {
    currentProject: { id: projectID, maxStudents, acceptedStudents },
  } = useContext(ProjectsContext)
  const { settings } = useContext(SettingsContext)
  const { availablePriorities, refetch } = useContext(
    ApplicationsStudentContext
  )

  const [selectedPriority, setSelectedPriority] = useState(null)
  const [letter, setLetter] = useState("")
  const [letterProgramme, setLetterProgramme] = useState("")

  const updateLetter = newVal => {
    if (newVal?.length < 5000) setLetter(newVal)
  }

  // const updateLetterProgramme = newVal => {
  //   if (newVal?.length < 5000) setLetterProgramme(newVal)
  // }

  const sendApplication = async () => {
    if (!selectedPriority?.value)
      return toast.error(t("application.priorityError"))

    setSubmitting(true)

    try {
      const data = {
        projectID,
        studentID: userID,
        letter,
        letterProgramme,
        priority: selectedPriority.value,
      }

      const resp = await axios.post(`${API_URL}/application/add`, data)
      setSubmitting(false)

      const { status, message } = resp.data
      if (status === "failed") return messageToToasts(message, i18n.language)

      toast.success(t("application.sendSuccess"))
      setSelectedPriority(null)
      setLetter("")
      refetch()
    } catch (error) {
      setSubmitting(false)
      toast.error("Server error!")
    }
  }

  const computedPriorities = availablePriorities.filter(
    prior => prior <= settings.applicationsPerStudent
  )

  if (computedPriorities?.length === 0)
    return (
      <div className="message is-warning">
        <div className="message-body">{t("application.noPrioritiesLeft")}</div>
      </div>
    )

  const studentYOS = studDict[userID]?.yearOfStudy
  const applicationOverflow =
    acceptedStudents[studentYOS] - maxStudents[studentYOS] >= 0

  if (settings.nonFreePlacesStudent === "notAllowed" && applicationOverflow)
    return (
      <div className="message is-warning">
        <div className="message-body">
          {t("application.strongApplicationOverflow")}
        </div>
      </div>
    )

  return (
    <div className="box">
      <p className="title is-5">{t("application.addTitle")}</p>
      <hr />

      {settings.leaderAcceptApplication === "allowed" ? (
        <>
          <p>{t("application.shortProcedure")}</p>
          <hr />
        </>
      ) : null}

      {applicationOverflow ? (
        <>
          <div className="message is-warning">
            <div className="message-body">
              {t("application.applicationOverflow")}
            </div>
          </div>

          <hr />
        </>
      ) : null}

      <div className="columns">
        <div className="column is-5">
          <FormEntry labelText={t("user.name")}>
            <input
              className="input"
              value={studDict[userID]?.fullName}
              disabled
            />
          </FormEntry>

          <FormEntry labelText={t("user.nameEnglish")}>
            <input
              className="input"
              value={studDict[userID]?.fullNameEn}
              disabled
            />
          </FormEntry>

          <FormEntry labelText={t("application.priority")} helpText={t("req")}>
            <Select
              placeholder={t("application.priorityPlaceholder")}
              options={priorityOptions[i18n.language].filter(opt =>
                computedPriorities.includes(opt.value)
              )}
              value={selectedPriority}
              onChange={setSelectedPriority}
            />
          </FormEntry>
        </div>

        <div className="column is-7">
          <FormEntry
            labelText={t("application.motivationLetter")}
            helpText={t("application.usedSymbols", { symbols: letter?.length })}
          >
            <textarea
              rows="8"
              className="textarea"
              placeholder={
                settings.requireMotivationLetter === "allowed"
                  ? t("application.motivationPlaceholderRequired")
                  : t("application.motivationPlaceholder")
              }
              value={letter}
              onChange={e => updateLetter(e.target.value)}
            />
          </FormEntry>

          {/* <FormEntry
            labelText={t("application.motivationLetterProgramme")}
            helpText={t("application.usedSymbols", {
              symbols: letterProgramme?.length,
            })}
          >
            <textarea
              rows="8"
              className="textarea"
              placeholder={
                settings.requireMotivationLetter === "allowed"
                  ? t("application.motivationProgrammePlaceholderRequired")
                  : t("application.motivationProgrammePlaceholder")
              }
              value={letterProgramme}
              onChange={e => updateLetterProgramme(e.target.value)}
            />
          </FormEntry> */}
        </div>
      </div>

      <div className="field is-grouped is-grouped-right">
        <button
          className="button is-link is-outlined"
          disabled={submitting}
          onClick={sendApplication}
        >
          {t("application.send")}
        </button>
      </div>
    </div>
  )
}

export default ApplicationNew
