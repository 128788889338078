import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import FormEntry from "../../helpers/FormEntry"
import Select from "react-select"
import useProgrammes from "../../../context/useProgrammes"
import { yearsOptions } from "../../../utils"

const extraFields = {
  programmeString: "",
  yearOfStudy: "",
  nameEn: "",
  surnameEn: "",
  secondNameEn: "",
}

const StudentSignUpForm = ({ fields, setFields, updateFields }) => {
  const { t, i18n } = useTranslation("auth")
  const [formNeedsEnrichment, setFormNeedsEnrichment] = useState(true)

  const [needRefetch, setNeedRefetch] = useState(true)
  const { programmeOptions, loading } = useProgrammes(
    needRefetch,
    setNeedRefetch
  )
  const [selectedYearOfStudy, setSelectedYearOfStudy] = useState(null)
  const [selectedProgramme, setSelectedProgramme] = useState(null)

  useEffect(() => {
    if (formNeedsEnrichment) {
      setFields({ ...fields, ...extraFields })
      setFormNeedsEnrichment(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(
    () => {
      if (selectedYearOfStudy?.value)
        setFields({ ...fields, yearOfStudy: selectedYearOfStudy.value })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedYearOfStudy]
  )

  useEffect(
    () => {
      if (selectedProgramme?.value)
        setFields({ ...fields, programmeString: selectedProgramme.value })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedProgramme]
  )

  return (
    <>
      <FormEntry labelText="Email" helpText={t("formLabels.req")}>
        <input
          className="input"
          name="email"
          value={fields.email}
          onChange={e => updateFields(e)}
          type="email"
          placeholder="some-mail@hse.ru"
          disabled
        />
      </FormEntry>

      <FormEntry labelText={t("formLabels.pwd")} helpText={t("formLabels.req")}>
        <input
          className="input"
          type="password"
          name="password"
          placeholder="********"
          value={fields.password}
          onChange={e => updateFields(e)}
        />
      </FormEntry>

      <FormEntry
        labelText={t("formLabels.pwdRep")}
        helpText={t("formLabels.req")}
      >
        <input
          className="input"
          type="password"
          name="passwordRep"
          placeholder="********"
          value={fields.passwordRep}
          onChange={e => updateFields(e)}
        />
      </FormEntry>

      <FormEntry
        labelText={t("formLabels.surname")}
        helpText={t("formLabels.req")}
      >
        <input
          className="input"
          name="surname"
          value={fields.surname}
          onChange={e => updateFields(e)}
          type="text"
          placeholder="Иванов"
        />
      </FormEntry>

      <FormEntry
        labelText={t("formLabels.name")}
        helpText={t("formLabels.req")}
      >
        <input
          className="input"
          name="name"
          value={fields.name}
          onChange={e => updateFields(e)}
          type="text"
          placeholder="Иван"
        />
      </FormEntry>

      <FormEntry labelText={t("formLabels.secondName")}>
        <input
          className="input"
          name="secondName"
          value={fields.secondName}
          onChange={e => updateFields(e)}
          type="text"
          placeholder="Иванович"
        />
      </FormEntry>

      {/* English name */}
      <FormEntry
        labelText={t("formLabels.surnameEn")}
        helpText={t("formLabels.req")}
      >
        <input
          className="input"
          name="surnameEn"
          value={fields.surnameEn}
          onChange={e => updateFields(e)}
          type="text"
          placeholder="Ivanov"
        />
      </FormEntry>

      <FormEntry
        labelText={t("formLabels.nameEn")}
        helpText={t("formLabels.req")}
      >
        <input
          className="input"
          name="nameEn"
          value={fields.nameEn}
          onChange={e => updateFields(e)}
          type="text"
          placeholder="Ivan"
        />
      </FormEntry>

      <FormEntry labelText={t("formLabels.secondNameEn")}>
        <input
          className="input"
          name="secondNameEn"
          value={fields.secondNameEn}
          onChange={e => updateFields(e)}
          type="text"
          placeholder="Ivanovich"
        />
      </FormEntry>

      <FormEntry
        labelText={t("formLabels.yearOfStudy")}
        helpText={t("formLabels.req")}
      >
        <Select
          options={yearsOptions[i18n.language]}
          value={selectedYearOfStudy}
          onChange={setSelectedYearOfStudy}
          placeholder={t("formLabels.yearOfStudyPlaceholder")}
          isLoading={loading}
        />
      </FormEntry>

      <FormEntry
        labelText={t("formLabels.programme")}
        helpText={t("formLabels.req")}
      >
        <Select
          options={programmeOptions[i18n.language]}
          value={selectedProgramme}
          onChange={setSelectedProgramme}
          placeholder={t("formLabels.programmePlaceholder")}
          isLoading={loading}
        />
      </FormEntry>
    </>
  )
}

export default StudentSignUpForm
