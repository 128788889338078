import axios from "axios"
import i18next from "i18next"
import { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { API_URL, messageToToasts } from "../utils"

const useTransfers = userType => {
  const [transfers, setTransfers] = useState([])
  const [loading, setLoading] = useState(true)
  const [needRefetch, setNeedRefetch] = useState(true)

  useEffect(() => {
    const fetchTransfers = async () => {
      try {
        let url = `${API_URL}/transfer/listFor`
        if (userType === "student") url += "Student"
        if (userType === "projectLeader") url += "ProjectLeader"
        if (userType === "admin") url += "Admin"

        const resp = await axios.get(url)
        const { status, message, transfers } = resp.data

        setLoading(false)
        if (status === "failed")
          return toast.error(messageToToasts(message, i18next.language))

        setTransfers(transfers)
      } catch (error) {
        setLoading(false)
        toast.error("Server error")
      }
    }

    fetchTransfers()
    setNeedRefetch(false)
  }, [needRefetch, userType])

  const refetch = (needsReload = false) => {
    if (needsReload) setLoading(true)

    setNeedRefetch(true)
  }

  return { transfers, loading, refetch }
}

export default useTransfers
