import dayjs from "dayjs"
import { useContext } from "react"
import { useTranslation } from "react-i18next"
import { useHistory, useRouteMatch } from "react-router"
import { useBlogPostContext } from "../../../context/BlogPostContext"
import { UserContext } from "../../../context/UserContext"
import LoadingSpinner from "../../helpers/LoadingSpinner"
import MathMarkdown from "../../helpers/MathMarkdown"
import Pagination from "../../helpers/Pagination"
import BlogVisibilitySwitch from "./BlogVisibilitySwitch"

const BlogPostList = () => {
  const { t } = useTranslation("data")
  const {
    user: { userType },
  } = useContext(UserContext)
  const history = useHistory()
  const { path } = useRouteMatch()
  const { posts, loading, currentPage, setCurrentPage, pageCount, refetch } =
    useBlogPostContext()

  if (loading)
    return (
      <div className="box">
        <LoadingSpinner />
      </div>
    )

  if (!posts?.length)
    return <div className="box has-text-grey">{t("emptyList")}...</div>

  return (
    <>
      {posts.map(post => (
        <div className="box" key={post?.id}>
          {["admin", "projectLeader"].includes(userType) ? (
            <div className="columns mb-0">
              <div className="column is-10 pb-0">
                <p className="title is-size-5">{post?.title}</p>
              </div>

              <div className="column is-2 pb-0 has-text-right">
                <BlogVisibilitySwitch post={post} refetch={refetch} />
              </div>
            </div>
          ) : (
            <p className="title is-size-5">{post?.title}</p>
          )}

          <hr />

          <MathMarkdown styleClass="content">{post.text}</MathMarkdown>

          <hr />

          <div className="columns">
            <div className="column is-half has-text-link">
              <span
                className="pointer"
                onClick={() => history.push(path + "/" + post?.id)}
              >
                {t("blogpost.showMore")}{" "}
                {post?.isCommentable
                  ? `(${post?.commentsCount} ${t("blogpost.commentsFooter")})`
                  : null}
              </span>
            </div>

            <div className="column is-half has-text-grey has-text-right">
              {dayjs(post?.creationDate).format("DD/MM/YYYY HH:mm")}
            </div>
          </div>
        </div>
      ))}

      <Pagination
        page={currentPage}
        pageCount={pageCount}
        setPage={setCurrentPage}
      />
    </>
  )
}

export default BlogPostList
