import { useContext } from "react"
import { useTranslation } from "react-i18next"
import { Link, Redirect, Route, Switch, useRouteMatch } from "react-router-dom"
import { UserContext } from "../../../context/UserContext"
import { userTypesOptions } from "../../../utils"
import ExportEmail from "../../data/ExportEmail"
// import AllowedEmailsView from "./AllowedEmailView"
import CourseView from "./CourseView"
import DivisionView from "./DivisionView"
import GreetingsView from "./GreetingsView"
import InviteListView from "./InviteListView"
import InviteNewView from "./InviteNewView"
import JobView from "./JobView"
import ProgrammeView from "./ProgrammeView"
import SettingsLoadView from "./SettingsLoadView"
import SettingsView from "./SettingsView"
import TopicView from "./TopicView"
import UsersListView from "./UserListView"
import InfoUpdatesList from "../../data/InfoUpdatesList"

const UsersPageView = () => {
  const { t } = useTranslation(["ws", "translation"])
  const {
    user: { role },
  } = useContext(UserContext)
  const { path, url } = useRouteMatch()

  const menu = (
    <div className="box">
      <aside className="menu">
        {role === "root" ? (
          <>
            <p className="menu-label">{t("usersPage.inviteCodes")}</p>
            <ul className="menu-list">
              <li>
                <Link to={url + "/newInvite"}>
                  {t("usersPage.inviteCodesNew")}
                </Link>
              </li>
              <li>
                <Link to={url + "/listInvites"}>
                  {t("usersPage.inviteCodesList")}
                </Link>
              </li>
            </ul>
          </>
        ) : null}

        <p className="menu-label">{t("usersPage.users")}</p>
        <ul className="menu-list">
          <li>
            <Link to={url + "/exportEmail"}>{t("usersPage.exportEmail")}</Link>
          </li>
          <li>
            <Link to={url + "/infoUpdates"}>
              Заявки изменения данных студентов
            </Link>
          </li>
          {userTypesOptions.map(uto => (
            <li key={uto.value}>
              <Link to={`${url}/${uto.value}`}>
                {t(`translation:userTypePlural.${uto.value}`)}
              </Link>
            </li>
          ))}
        </ul>

        <p className="menu-label">{t("usersPage.general")}</p>
        <ul className="menu-list">
          {/* <li>
            <Link to={url + "/allowedEmails"}>
              {t("usersPage.generalAllowedEmails")}
            </Link>
          </li> */}

          <li>
            <Link to={url + "/settings"}>{t("usersPage.generalSettings")}</Link>
          </li>

          <li>
            <Link to={url + "/greetings"}>{t("usersPage.greetings")}</Link>
          </li>

          <li>
            <Link to={url + "/loadSettings"}>
              {t("usersPage.loadSettings")}
            </Link>
          </li>

          <li>
            <Link to={url + "/programmes"}>
              {t("usersPage.generalProgrammes")}
            </Link>
          </li>

          <li>
            <Link to={url + "/divisions"}>
              {t("usersPage.generalDivisions")}
            </Link>
          </li>

          <li>
            <Link to={url + "/topics"}>{t("usersPage.generalTopics")}</Link>
          </li>

          <li>
            <Link to={url + "/courses"}>{t("usersPage.generalCourses")}</Link>
          </li>

          <li>
            <Link to={url + "/jobs"}>{t("usersPage.generalJobs")}</Link>
          </li>
        </ul>
      </aside>
    </div>
  )

  return (
    <div className="columns">
      <div className="column is-3">{menu}</div>
      <div className="column is-9">
        <Switch>
          <Route exact path={path}>
            <Redirect to={url + "/listInvites"} />
          </Route>

          <Route path={path + "/newInvite"}>
            <InviteNewView />
          </Route>

          <Route path={path + "/listInvites"}>
            <InviteListView />
          </Route>

          <Route path={path + "/exportEmail"}>
            <ExportEmail />
          </Route>

          <Route path={path + "/infoUpdates"}>
            <InfoUpdatesList />
          </Route>

          {/* <Route path={path + "/allowedEmails"}>
            <AllowedEmailsView />
          </Route> */}

          <Route path={path + "/settings"}>
            <SettingsView />
          </Route>

          <Route path={path + "/greetings"}>
            <GreetingsView />
          </Route>

          <Route path={path + "/loadSettings"}>
            <SettingsLoadView />
          </Route>

          <Route path={path + "/courses"}>
            <CourseView />
          </Route>

          <Route path={path + "/programmes"}>
            <ProgrammeView />
          </Route>

          <Route path={path + "/divisions"}>
            <DivisionView />
          </Route>

          <Route path={path + "/topics"}>
            <TopicView />
          </Route>

          <Route path={path + "/jobs"}>
            <JobView />
          </Route>

          {userTypesOptions.map(uto => (
            <Route path={`${path}/${uto.value}`} key={uto.value}>
              <UsersListView userType={uto.value} />
            </Route>
          ))}
        </Switch>
      </div>
    </div>
  )
}

export default UsersPageView
