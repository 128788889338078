import axios from "axios"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import { API_URL, messageToToasts } from "../utils"

const usePlaces = () => {
  const { i18n } = useTranslation()
  const [loading, setLoading] = useState(true)
  const [bachelors, setBachelors] = useState([])
  const [masters, setMasters] = useState([])
  const [needRefetch, setNeedRefetch] = useState(true)

  useEffect(
    () => {
      const fetchPlaces = async () => {
        try {
          const requestURL = `${API_URL}/courses/places`

          const resp = await axios.get(requestURL)
          const { status, message, bachelor, master } = resp.data

          if (status === "failed")
            return messageToToasts(message, i18n.language)

          setBachelors(bachelor)
          setMasters(master)
          setLoading(false)
        } catch (error) {
          toast.error(
            "Server error. Try to reload page or contact administrator"
          )
        }
      }

      setLoading(true)
      fetchPlaces()
      setNeedRefetch(false)
    },
    // eslint-disable-next-line
    [needRefetch]
  )

  const refetch = () => {
    setNeedRefetch(true)
  }

  return { loading, bachelors, masters, refetch }
}

export default usePlaces
