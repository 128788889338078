import { useContext, useEffect, useState } from "react"
import ReactMde from "react-mde"
import FormEntry from "../helpers/FormEntry"
import Select from "react-select"
import { useTranslation } from "react-i18next"
import ProposalNewStudents from "./proposal/ProposalNewStudents"
import ProposalNewRecomendedCourses from "./proposal/ProposalNewRecomendedCourses"
import { toast } from "react-toastify"
import axios from "axios"
import { API_URL, messageToToasts } from "../../utils"
import { useHistory } from "react-router-dom"
import ProposalNewTopics from "./proposal/ProposalNewTopics"
import ProposalNewCLE from "./proposal/ProposalNewCLE"
import { ProposalsContext } from "../../context/ProposalsContext"
import Submitting from "../helpers/Submitting"
import MathMarkdown from "../helpers/MathMarkdown"
import ReactTooltip from "react-tooltip"
import { CommonDataContext } from "../../context/CommonDataContext"
import FillFromProject from "./proposal/FillFromProject"
import i18next from "i18next"
import ProposalNewSeats from "./proposal/ProposalNewSeats"

const inlineMathCommand = {
  name: "inline-math",
  icon: () => (
    <span role="img" aria-label="inline-math">
      <strong>$</strong>
    </span>
  ),
  execute: opts => {
    let modifyText = "$" + opts.initialState.selectedText + "$"
    if (!opts.initialState.selectedText) {
      modifyText = "$ $"
    }

    opts.textApi.replaceSelection(modifyText)
  },
}

const blockMathCommand = {
  name: "block-math",
  icon: () => (
    <span role="img" aria-label="inline-math">
      <strong>$$</strong>
    </span>
  ),
  execute: opts => {
    let modifyText = "$$\n" + opts.initialState.selectedText + "\n$$"
    if (!opts.initialState.selectedText) {
      modifyText = "$$\n\n$$"
    }

    opts.textApi.replaceSelection(modifyText)
  },
}

const toolbar = [
  ["header", "bold", "italic"],
  ["link", "quote", "code", "image"],
  ["unordered-list", "ordered-list"],
  ["inline-math", "block-math"],
]

export const initialFormatOptions = {
  ru: [{ label: "Смешанные", value: "mixed" }],
  en: [{ label: "Mixed", value: "mixed" }],
}

export const langOptions = [
  { label: "Русский", value: "ru" },
  { label: "English", value: "en" },
  { label: "Mixed", value: "ru-en" },
]

const initailStudents = {
  firstBachelor: 0,
  secondBachelor: 0,
  thirdBachelor: 0,
  fourthBachelor: 0,
  firstMaster: 0,
  secondMaster: 0,
  studentsFEFU: 0,
}

const currentYear = new Date().getFullYear()
export const startDatesRaw = t =>
  [
    `9/${currentYear}`,
    `10/${currentYear}`,
    `11/${currentYear}`,
    `12/${currentYear}`,
    `1/${currentYear + 1}`,
  ].map(el => ({
    value: el,
    label: t(`month.${el.split("/")[0]}`) + " " + el.split("/")[1],
  }))

const ProposalNew = ({
  edit = false,
  editProposal = {},
  setPageMode = () => console.log("FOR EDIT ONLY"),
  setNeedRefetch = () => console.log("FOR EDIT ONLY"),
}) => {
  const { refetch } = useContext(ProposalsContext)

  const {
    projectLeaders: { dict: pls },
    divisions: { options: divisionOptions, loading: divisionsLoading },
    programmes: { options: programmeOptions, loading: programmesLoading },
  } = useContext(CommonDataContext)

  const { t, i18n } = useTranslation("data")
  const history = useHistory()
  const startDates = startDatesRaw(t)

  const [selectedLang, setSelectedLang] = useState({
    label: t("proposal.langPlaceholder"),
    value: "",
  })

  const [title, setTitle] = useState("")
  const [titleEN, setTitleEN] = useState("")

  const [selectedDivision, setSelectedDivision] = useState({
    label: t("proposal.divisionPlaceholder"),
    value: "",
  })

  const formatOptions = [
    { label: "Online", value: "online" },
    { label: "Offline", value: "offline" },
    ...initialFormatOptions[i18next.language],
  ]

  const [selectedFormat, setSelectedFormat] = useState({
    label: t("proposal.formatPlaceholder"),
    value: "",
  })
  const [isOpenToExternal, setIsOpenToExternal] = useState(true)
  const [isOpenToOtherSchools, setIsOpenToOtherSchool] = useState(true)
  const [isOpenToOnlineProgrammes, setIsOpenToOnlineProgrammes] = useState(true)

  const [summary, setSummary] = useState("")
  const [description, setDescription] = useState("")
  const [startDate, setStartDate] = useState(null)
  const [videoLink, setVideoLink] = useState("")
  const [externalPartner, setExternalPartner] = useState("")
  const [studentsMax, setStudentsMax] = useState(initailStudents)
  const [loadSeats, setLoadSeats] = useState([])
  const [externalSeats, setExternalSeats] = useState([])
  const [topicsStrings, setTopicStrings] = useState([])
  const [recomendedCoursesStrings, setRecomendedCoursesStrings] = useState([])
  const [coleadersExtras, setColeadersExtras] = useState([])
  const [existingColeaders, setExistingColeaders] = useState([])
  const [selectedProgrammes, setSelectedProgrammes] = useState([])

  const [selectedTab, setSelectedTab] = useState("write")
  const [submitting, setSubmitting] = useState(false)

  useEffect(
    () => {
      if (edit) {
        if (editProposal.lang) {
          setSelectedLang(
            langOptions.find(el => el.value === editProposal.lang)
          )
        }
        if (editProposal.division) {
          setSelectedDivision(
            divisionOptions[i18n.language].find(
              dio => dio.value === editProposal.division
            )
          )
        }

        if (editProposal?.startDate) {
          setStartDate(
            startDates.find(opt => opt.value === editProposal?.startDate)
          )
        }

        setTitle(editProposal.title)
        if (editProposal.lang === "ru") setTitleEN(editProposal.titleEN)

        if (editProposal.format) {
          setSelectedFormat(
            formatOptions.find(fo => fo.value === editProposal.format)
          )
        }
        setIsOpenToExternal(editProposal.isOpenToExternal)
        setIsOpenToOtherSchool(editProposal.isOpenToOtherSchools)
        setIsOpenToOnlineProgrammes(editProposal.isOpenToOnlineProgrammes)
        setSummary(editProposal.summary)
        setDescription(editProposal.description)
        setVideoLink(editProposal?.videoLink)
        setExternalPartner(editProposal?.externalPartner)
        setStudentsMax(editProposal.maxStudents)
        setLoadSeats(editProposal?.loadSeats)
        setExternalSeats(editProposal?.externalSeats)
        setTopicStrings(editProposal.topics)
        setRecomendedCoursesStrings(editProposal.recomendedCourses)
        setSelectedProgrammes(
          (programmeOptions[i18n.language] || []).filter(opt =>
            (editProposal?.requestedProgrammesID || []).includes(opt?.value)
          )
        )
        setExistingColeaders(
          editProposal.projectColeaders.map(cl =>
            pls[cl]?.email
              ? { email: pls[cl]?.email }
              : { email: "INVITE PENDING" }
          )
        )
      }
    },
    //eslint-disable-next-line
    [edit, editProposal, divisionOptions, programmeOptions]
  )

  const addAllProgrammes = () => {
    setSelectedProgrammes(
      (programmeOptions[i18n.language] || []).filter(
        opt => opt?.category === "main"
      )
    )
  }

  const createProposal = async (e, draft = false) => {
    e.preventDefault()
    setSubmitting(true)

    try {
      const data = {
        lang: selectedLang.value,
        title,
        titleEN,
        divisionString: selectedDivision?.value || "",
        format: selectedFormat?.value || "",
        isOpenToExternal,
        isOpenToOtherSchools,
        isOpenToOnlineProgrammes,
        requestedProgrammesID: (selectedProgrammes || []).map(
          opt => opt?.value
        ),
        summary,
        description,
        startDate: startDate?.value,
        videoLink,
        externalPartner,
        maxStudents: studentsMax,
        loadSeats,
        externalSeats,
        topicsStrings,
        recomendedCoursesStrings,
        coleadersExtras,
        status: draft ? "draft" : "awaiting",
      }

      const resp = await axios.post(`${API_URL}/project/proposal/add`, data)
      const { status, message } = resp.data

      if (status === "failed") {
        setSubmitting(false)
        return messageToToasts(message, i18n.language)
      }

      toast.success(t(`proposal.success${draft ? "Draft" : "Main"}Message`))
      setSubmitting(false)

      refetch(draft ? "draftProposals" : "mainProposals")
      history.push(`./list${draft ? "Draft" : "Main"}`)
    } catch (err) {
      toast.error("Server error. Try to reload page or contact administrator")
    }
  }

  const updateProposal = async e => {
    e.preventDefault()
    setSubmitting(true)

    try {
      const data = {
        updateID: editProposal.id,
        status: editProposal.status,
        isPublished: editProposal.isPublished,
        lang: selectedLang.value,
        title,
        titleEN,
        divisionString: selectedDivision?.value || "",
        format: selectedFormat?.value || "",
        isOpenToExternal,
        isOpenToOtherSchools,
        isOpenToOnlineProgrammes,
        requestedProgrammesID: (selectedProgrammes || []).map(
          opt => opt?.value
        ),
        summary,
        description,
        startDate: startDate?.value,
        videoLink,
        externalPartner,
        maxStudents: studentsMax,
        loadSeats,
        externalSeats,
        topicsStrings,
        recomendedCoursesStrings,
        coleadersExtras: coleadersExtras.filter(
          cle => cle.email !== "INVITE PENDING"
        ),
      }

      const resp = await axios.post(`${API_URL}/project/proposal/update`, data)
      const { status, message } = resp.data

      if (status === "failed") {
        setSubmitting(false)
        return messageToToasts(message, i18n.language)
      }

      toast.success(t("proposal.successUpdateMessage"))
      refetch(
        editProposal.status === "draft" ? "draftProposals" : "mainProposals"
      )
      setNeedRefetch(true)
      setSubmitting(false)
      setPageMode("view")
    } catch (err) {
      toast.error("Server error. Try to reload page or contact administrator")
    }
  }

  return (
    <>
      <div className="message is-warning">
        <div className="message-body">
          <span className="is-size-5">{t("proposal.saveWarning")}!</span>
        </div>
      </div>

      {edit || (
        <FillFromProject
          setTitle={setTitle}
          setTitleEN={setTitleEN}
          setSelectedLang={setSelectedLang}
          setDescription={setDescription}
          setIsOpenToExternal={setIsOpenToExternal}
          setSummary={setSummary}
          setSelectedDivision={setSelectedDivision}
          setStartDate={setStartDate}
          setStudentsMax={setStudentsMax}
          setVideoLink={setVideoLink}
          setSelectedFormat={setSelectedFormat}
          setTopicStrings={setTopicStrings}
          setRecomendedCoursesStrings={setRecomendedCoursesStrings}
          setExistingColeaders={setExistingColeaders}
        />
      )}

      <div className="box">
        <div className="columns">
          <div className="column is-half">
            <FormEntry labelText={t("proposal.lang")} helpText={t("req")}>
              <Select
                options={langOptions}
                value={selectedLang}
                onChange={setSelectedLang}
              />
            </FormEntry>
          </div>

          <div className="column is-half">
            <FormEntry labelText={t("proposal.division")} helpText={t("req")}>
              <Select
                options={divisionOptions[i18n.language]}
                value={selectedDivision}
                onChange={setSelectedDivision}
                isLoading={divisionsLoading}
              />
            </FormEntry>
          </div>
        </div>

        <FormEntry labelText={t("proposal.title")} helpText={t("req")}>
          <input
            type="text"
            className="input"
            value={title}
            onChange={e => setTitle(e.target.value)}
            placeholder={t("proposal.titlePlaceholder")}
          />
        </FormEntry>

        {["ru", "ru-en"].includes(selectedLang.value) ? (
          <FormEntry labelText={t("proposal.titleEN")} helpText={t("req")}>
            <input
              type="text"
              className="input"
              value={titleEN}
              onChange={e => setTitleEN(e.target.value)}
              placeholder={t("proposal.titleENPlaceholder")}
            />
          </FormEntry>
        ) : null}

        <FormEntry
          labelText={t("proposal.externalPartner")}
          helpText={t("proposal.externalPartnerHelp")}
        >
          <input
            type="text"
            className="input"
            value={externalPartner}
            onChange={e => setExternalPartner(e.target.value)}
            placeholder={t("proposal.externalPartnerPlaceholder")}
          />
        </FormEntry>

        <FormEntry labelText={t("proposal.format")} helpText={t("req")}>
          <Select
            options={formatOptions}
            value={selectedFormat}
            onChange={setSelectedFormat}
          />
        </FormEntry>

        {/* {["online", "mixed"].includes(selectedFormat.value) ? (
          <FormEntry>
            <label className="checkbox">
              <input
                type="checkbox"
                checked={!isOpenToExternal}
                onChange={e => setIsOpenToExternal(!e.target.checked)}
              />{" "}
              {t("proposal.externalStudents")}
            </label>
          </FormEntry>
        ) : null}

        {["online", "mixed"].includes(selectedFormat.value) ? (
          <FormEntry>
            <label className="checkbox">
              <input
                type="checkbox"
                checked={!isOpenToOnlineProgrammes}
                onChange={e => setIsOpenToOnlineProgrammes(!e.target.checked)}
              />{" "}
              {t("proposal.isOpenToOnlineProgrammes")}
            </label>
          </FormEntry>
        ) : null} */}

        {/* <FormEntry>
          <label className="checkbox">
            <input
              type="checkbox"
              checked={!isOpenToOtherSchools}
              onChange={e => setIsOpenToOtherSchool(!e.target.checked)}
            />{" "}
            {t("proposal.isOpenToExternal")}
          </label>
        </FormEntry> */}

        <FormEntry labelText={t("proposal.requestedProgrammes")}>
          <Select
            options={(programmeOptions[i18n.language] || []).filter(
              opt => opt?.category === "main"
            )}
            value={selectedProgrammes}
            onChange={setSelectedProgrammes}
            isLoading={programmesLoading}
            placeholder={t("proposal.requestedProgrammesPlh")}
            isMulti
            isClearable
          />
          <p className="help">
            <span className="pointer has-text-link" onClick={addAllProgrammes}>
              {t("addAll")}
            </span>
          </p>
        </FormEntry>

        <FormEntry labelText={t("proposal.startDate")} helpText={t("req")}>
          <Select
            value={startDate}
            onChange={setStartDate}
            options={startDates}
            placeholder={t("proposal.startDatePlh")}
            isClearable
          />
        </FormEntry>

        <hr />

        <FormEntry labelText={t("proposal.videoLink")}>
          <input
            className="input"
            value={videoLink}
            onChange={e => {
              if (e.target.value.length <= 500) setVideoLink(e.target.value)
              else setVideoLink(e.target.value.slice(0, 500))
            }}
            placeholder={t("proposal.videoLinkPlh")}
          />
        </FormEntry>

        <hr />

        <FormEntry
          labelText={t("proposal.summary")}
          helpText={
            t("req") +
            t("proposal.vlUnreq") +
            ", " +
            t("used") +
            ": " +
            summary.length +
            "/500"
          }
        >
          <textarea
            className="textarea"
            value={summary}
            onChange={e => {
              if (e.target.value.length <= 500) setSummary(e.target.value)
              else setSummary(e.target.value.slice(0, 500))
            }}
            placeholder={t("proposal.summaryPlaceholder")}
          />
        </FormEntry>

        <hr />

        <FormEntry labelText={t("proposal.description")}>
          <ReactTooltip multiline={true} />
          <ReactMde
            value={description}
            commands={{
              "inline-math": inlineMathCommand,
              "block-math": blockMathCommand,
            }}
            onChange={setDescription}
            selectedTab={selectedTab}
            onTabChange={setSelectedTab}
            toolbarCommands={toolbar}
            generateMarkdownPreview={markdown =>
              Promise.resolve(<MathMarkdown>{markdown}</MathMarkdown>)
            }
            childProps={{
              writeButton: {
                tabIndex: -1,
              },
            }}
          />
          <p className="help">
            {t("req") + t("proposal.vlUnreq") + ", "}
            <span
              className="has-text-link pointer"
              data-tip={t("proposal.latex")}
            >
              {t("proposal.latexHelp")}
            </span>
            {", "}
            <span
              className="has-text-link pointer"
              data-tip={t("proposal.img")}
            >
              {t("proposal.imgHelp")}
            </span>
          </p>
        </FormEntry>

        <hr />

        <ProposalNewStudents
          studentsMax={studentsMax}
          setStudentsMax={setStudentsMax}
        />

        <hr />

        <ProposalNewSeats
          seats={loadSeats}
          setSeats={setLoadSeats}
          category="main"
        />

        <hr />

        <ProposalNewSeats
          seats={externalSeats}
          setSeats={setExternalSeats}
          category="external"
        />

        <hr />

        <ProposalNewTopics
          topicsStrings={topicsStrings}
          setTopicsStrings={setTopicStrings}
        />

        <hr />

        <ProposalNewRecomendedCourses
          recomendedCoursesStrings={recomendedCoursesStrings}
          setRecomendedCoursesStrings={setRecomendedCoursesStrings}
        />

        <hr />

        <ProposalNewCLE
          coleadersExtras={coleadersExtras}
          setColeadersExtras={setColeadersExtras}
          existingColeaders={existingColeaders}
        />

        <hr />

        <Submitting submitting={submitting}>
          {edit ? (
            <div className="field is-grouped is-grouped-centered">
              <button
                className="button is-info is-outlined is-fullwidth mx-1"
                type="submit"
                onClick={() => setPageMode("view")}
              >
                {t("proposal.cancelChanges")}
              </button>

              <button
                className="button is-link is-outlined is-fullwidth mx-1"
                type="submit"
                onClick={updateProposal}
              >
                {t("proposal.updateProposal")}
              </button>
            </div>
          ) : (
            <div className="field is-grouped is-grouped-centered">
              <button
                className="button is-info is-outlined is-fullwidth mx-1"
                type="submit"
                onClick={e => createProposal(e, true)}
              >
                {t("proposal.saveDraftButton")}
              </button>

              <button
                className="button is-link is-outlined is-fullwidth mx-1"
                type="submit"
                onClick={e => createProposal(e, false)}
              >
                {t("proposal.addNewButton")}
              </button>
            </div>
          )}
        </Submitting>
      </div>
    </>
  )
}

export default ProposalNew
