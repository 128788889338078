import { useTranslation } from "react-i18next"
import { capitalize } from "../../../utils"
import LoadingSpinner from "../../helpers/LoadingSpinner"
import MathMarkdown from "../../helpers/MathMarkdown"
import AsgnChangeActions from "./AsgnChangeActions"

const classFromStatus = status => {
  switch (status) {
    case "awaiting":
      return "has-text-warning"
    case "success":
      return "has-text-success"
    default:
      return "has-text-danger"
  }
}

const AsgnChangeList = ({ asgnChanges, refetch, loading }) => {
  const { t } = useTranslation("data")

  if (loading)
    return (
      <div className="box">
        <LoadingSpinner />
      </div>
    )

  if (!asgnChanges?.length)
    return (
      <div className="box">
        <p className="has-text-grey">{t("emptyList")}</p>
      </div>
    )

  return (
    <>
      {(asgnChanges || []).map(asgnCh => (
        <div className="box" key={asgnCh?.id}>
          <p className="title is-5">
            {t("group.request")} {asgnCh?.id}
          </p>

          <div className="columns">
            <div className="column is-4">
              <span
                className={
                  asgnCh?.leaderApproved
                    ? "has-text-success"
                    : "has-text-warning"
                }
              >
                {t("group.leaderApproved")}
              </span>
            </div>

            <div className="column is-4 has-text-centered">
              <span
                className={
                  asgnCh?.studentApproved
                    ? "has-text-success"
                    : "has-text-warning"
                }
              >
                {t("group.studentApproved")}
              </span>
            </div>

            <div className="column is-4 has-text-right">
              <span className={classFromStatus(asgnCh?.status)}>
                {t(`group.${asgnCh?.status}`)}
              </span>
            </div>
          </div>
          <hr />

          <div className="columns">
            <div className="column is-half">
              <p className="title is-6">{t("group.oldValue")}</p>
            </div>

            <div className="column is-half">
              <p className="title is-6">{t("group.newValue")}</p>
            </div>
          </div>

          {(asgnCh?.changes || []).map(ch => (
            <div key={ch?.field}>
              <p className="has-text-centered mb-3">
                <span className="tag is-rounded">
                  {t(`group.asgn${capitalize(ch?.field)}`)}
                </span>
              </p>
              <div className="columns mb-1">
                <div className="column is-half">
                  {ch?.field !== "tasks" ? (
                    <textarea
                      className="textarea"
                      value={ch?.oldValue}
                      disabled
                    />
                  ) : (
                    <MathMarkdown styleClass="content has-background-light p-2 has-text-grey">
                      {ch?.oldValue}
                    </MathMarkdown>
                  )}
                </div>
                <div className="column is-half">
                  {ch?.field !== "tasks" ? (
                    <textarea
                      className="textarea"
                      value={ch?.newValue}
                      disabled
                    />
                  ) : (
                    <MathMarkdown styleClass="content has-background-light p-2 has-text-grey">
                      {ch?.newValue}
                    </MathMarkdown>
                  )}
                </div>
              </div>
            </div>
          ))}
          <AsgnChangeActions asgn={asgnCh} refetch={refetch} />
        </div>
      ))}
    </>
  )
}

export default AsgnChangeList
