import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import axios from "axios"
import i18next from "i18next"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import ReactMarkdown from "react-markdown"
import ReactMde from "react-mde"
import Select from "react-select"
import { toast } from "react-toastify"
import { API_URL, messageToToasts } from "../../../utils"
import { priorityOptions } from "./GreetingNew"

const GreetingAdminPreview = ({ greeting, refetch }) => {
  const { t } = useTranslation("data")
  const [trashMode, setTrashMode] = useState(false)
  const [editMode, setEditMode] = useState(false)

  const [selectedTab, setSelectedTab] = useState("write")
  const [selectedTabEN, setSelectedTabEN] = useState("write")
  const [selectedPriority, setSelectedPriority] = useState(
    priorityOptions.find(opt => opt.value === greeting.priority)
  )
  const [text, setText] = useState(greeting.text)
  const [textEN, setTextEN] = useState(greeting.textEN)

  const switchVisibility = async () => {
    try {
      const resp = await axios.post(`${API_URL}/greeting/switch`, {
        greetingID: greeting.id,
      })
      const { status, message } = resp.data

      if (status === "failed") return messageToToasts(message, i18next.language)

      refetch()
    } catch (error) {
      toast.error("Server error!")
    }
  }

  const updateGreeting = async () => {
    try {
      const data = {
        id: greeting.id,
        userType: greeting.userType,
        text,
        textEN,
        isVisible: greeting.isVisible,
        priority: selectedPriority?.value || "c",
      }

      const resp = await axios.post(`${API_URL}/greeting/update`, data)

      const { status, message } = resp.data
      if (status === "failed") return messageToToasts(message, i18next.language)

      refetch()
      setEditMode(false)
    } catch (error) {
      toast.error("Server error!")
    }
  }

  const removeGreeting = async () => {
    try {
      const resp = await axios.post(`${API_URL}/greeting/remove`, {
        greetingID: greeting.id,
      })
      const { status, message } = resp.data

      if (status === "failed") return messageToToasts(message, i18next.language)

      refetch()
    } catch (error) {
      toast.error("Server error!")
    }
  }

  if (editMode)
    return (
      <div className="box">
        <p className="title is-5">{t("greeting.addNew")}</p>

        <hr className="mb-3" />

        <div className="field">
          <label className="label">{t("greeting.text")}</label>
          <div className="control">
            <ReactMde
              value={text}
              onChange={setText}
              selectedTab={selectedTab}
              onTabChange={setSelectedTab}
              generateMarkdownPreview={markdown =>
                Promise.resolve(<ReactMarkdown>{markdown}</ReactMarkdown>)
              }
            />
          </div>
        </div>

        <div className="field">
          <label className="label">{t("greeting.textEN")}</label>
          <div className="control">
            <ReactMde
              value={textEN}
              onChange={setTextEN}
              selectedTab={selectedTabEN}
              onTabChange={setSelectedTabEN}
              generateMarkdownPreview={markdown =>
                Promise.resolve(<ReactMarkdown>{markdown}</ReactMarkdown>)
              }
            />
          </div>
        </div>

        <div className="columns">
          <div className="column is-half">
            <div className="field">
              <label className="label">{t("greeting.priority")}</label>
              <div className="control">
                <Select
                  options={priorityOptions}
                  value={selectedPriority}
                  onChange={setSelectedPriority}
                  placeholder={t("greeting.priorityPlaceholder")}
                />
              </div>
            </div>
          </div>

          <div className="column is-half"></div>
        </div>

        <div className="field is-grouped is-grouped-right">
          <div className="control">
            <button
              className="button is-light mr-4"
              onClick={() => setEditMode(false)}
            >
              {t("cancel")}
            </button>

            <button className="button is-link" onClick={updateGreeting}>
              {t("greeting.update")}
            </button>
          </div>
        </div>
      </div>
    )

  return (
    <div className="box">
      <div className="columns mb-0">
        <div className="column is-half">
          <p>
            <strong className="has-text-grey">{greeting.id}</strong>
          </p>
        </div>

        <div className="column is-half has-text-right">
          <div className="field">
            <label className="switch is-rounded">
              <input
                type="checkbox"
                value="false"
                checked={greeting.isVisible}
                onChange={switchVisibility}
              />
              <span className="check is-link"></span>
            </label>
          </div>
        </div>
      </div>

      <hr className="mt-0 pt-0" />

      <div className="content py-1 px-1">
        <ReactMarkdown>
          {i18next.language === "ru" ? greeting.text : greeting.textEN}
        </ReactMarkdown>
      </div>

      <hr />

      <p className="has-text-right">
        {trashMode ? (
          <>
            {t("remove")}?&nbsp;
            <span className="pointer" onClick={removeGreeting}>
              {t("yes")}
            </span>
            &nbsp;
            <span className="pointer" onClick={() => setTrashMode(false)}>
              {t("no")}
            </span>
          </>
        ) : (
          <>
            <span
              className="pointer has-text-grey mr-3"
              onClick={() => setEditMode(true)}
            >
              <FontAwesomeIcon icon="pen" />
            </span>

            <span
              className="pointer has-text-danger"
              onClick={() => setTrashMode(true)}
            >
              <FontAwesomeIcon icon="trash" />
            </span>
          </>
        )}
      </p>
    </div>
  )
}

export default GreetingAdminPreview
