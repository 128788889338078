import axios from "axios"
import i18next from "i18next"
import { createContext, useContext, useState } from "react"
import { toast } from "react-toastify"
import { API_URL, messageToToasts } from "../../../utils"

export const AsgnContext = createContext()

const AsgnContextProvider = ({ children, asgns, group, refetchGroup }) => {
  const asgnsMap = (asgns || []).reduce((acc, el) => {
    acc[el?.studentID] = el
    acc[el?.studentID].theme = el?.theme?.length ? el?.theme : group?.theme
    acc[el?.studentID].target = el?.target?.length ? el?.target : group?.target
    acc[el?.studentID].tasks = el?.tasks?.length ? el?.tasks : group?.tasks
    acc[el?.studentID].requirements = el?.requirements?.length
      ? el?.requirements
      : group?.requirements
    acc[el?.studentID].reportFormat = el?.reportFormat?.length
      ? el?.reportFormat
      : group?.reportFormat
    acc[el?.studentID].presentation = el?.presentation?.length
      ? el?.presentation
      : group?.presentation
    return acc
  }, {})
  const [asgnsData, setAsgnsData] = useState(asgnsMap)

  const updateAsgnData = (newData, studentID) => {
    setAsgnsData({
      ...asgnsData,
      [studentID]: { ...asgnsMap[studentID], ...newData },
    })
  }

  const updateEditedAssignments = async () => {
    for (const asgn of Object.values(asgnsData)) {
      try {
        const data = {
          studentID: asgn?.studentID,
          groupID: asgn?.groupID,
          theme: asgn?.theme,
          target: asgn?.target,
          tasks: asgn?.tasks,
          requirements: asgn?.requirements,
          reportFormat: asgn?.reportFormat,
          presentation: asgn?.presentation,
        }

        const resp = await axios.post(`${API_URL}/group/updateAssignment`, data)
        const { status } = resp.data

        if (status === "failed")
          toast.error(
            asgn?.student?.email + ": has approved/finished assignment"
          )
        else toast.success("Задание отправлено студенту")
      } catch (error) {
        toast.error("Server error: " + error)
      }
    }

    refetchGroup(false)
  }

  const value = {
    asgnsData,
    edittedLength: Object.values(asgnsData)?.length,
    updateAsgnData,
    updateEditedAssignments,
  }

  return <AsgnContext.Provider value={value}>{children}</AsgnContext.Provider>
}

export const useAsgnContext = () => {
  const context = useContext(AsgnContext)

  return context
}

export default AsgnContextProvider
