import axios from "axios"
import fileDownload from "js-file-download"
import Select from "react-select"
import { useContext, useEffect, useState } from "react"
import { toast } from "react-toastify"
import { API_URL, messageToToasts } from "../../../utils"
import i18next from "i18next"
import PracticeCard from "./PracticeCard"
import LoadingSpinner from "../../helpers/LoadingSpinner"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { CommonDataContext } from "../../../context/CommonDataContext"
import { useTranslation } from "react-i18next"
import { UserContext } from "../../../context/UserContext"

const PracticeList = () => {
  const { t } = useTranslation("data")
  const {
    user: { userType },
  } = useContext(UserContext)
  const {
    programmes: { options: programmeOptions },
  } = useContext(CommonDataContext)

  const [loading, setLoading] = useState(true)
  const [selectedProgramme, setSelectedProgramme] = useState(null)
  const [category, setCategory] = useState(
    userType === "admin" ? "yellow" : "blue"
  )
  const [practices, setPractices] = useState([])

  useEffect(() => {
    const fetchPractices = async () => {
      try {
        const resp = await axios.get(
          `${API_URL}/practice/list?category=${category}&programmeID=${
            selectedProgramme?.value || ""
          }`
        )
        const { status, practices, message } = resp.data
        if (status === "failed")
          return messageToToasts(message, i18next.language)

        setPractices(practices)
        setLoading(false)
      } catch (_) {
        toast.error("System error or missing internet connection")
      }
    }

    setLoading(true)
    fetchPractices()
  }, [category, selectedProgramme])

  const exportToXLSX = async () => {
    try {
      const resp = await axios.get(`${API_URL}/practice/export`, {
        headers: {
          "Content-Type":
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        },
        responseType: "arraybuffer",
      })

      fileDownload(resp.data, "practices.xlsx")
    } catch (error) {
      console.log(error)
      toast.error("Server error!")
    }
  }

  return (
    <>
      <div className="mb-5">
        <div className="columns">
          <div
            className={"column " + (userType === "admin" ? "is-10" : "is-12")}
          >
            <div className="field">
              <div className="control">
                <Select
                  options={programmeOptions[i18next.language]}
                  value={selectedProgramme}
                  onChange={setSelectedProgramme}
                  placeholder={t("practice.programmePlh")}
                  isClearable
                />
              </div>
            </div>
          </div>

          <div className="column is-2">
            <div className="field">
              <div className="control">
                <button
                  className="button is-success is-fullwidth"
                  onClick={exportToXLSX}
                >
                  <FontAwesomeIcon icon="file-excel" size="lg" />
                  &nbsp;Выгрузить
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="field has-addons">
          <div className="control is-expanded">
            <button
              onClick={() => setCategory("finished")}
              className={
                "button is-fullwidth " +
                (category === "finished" ? "is-dark" : "")
              }
            >
              <FontAwesomeIcon
                icon="ellipsis-v"
                size="lg"
                className="has-text-primary-dark"
              />
              &nbsp;{t("practice.finished")}
            </button>
          </div>

          <div className="control is-expanded">
            <button
              onClick={() => setCategory("green")}
              className={
                "button is-fullwidth " + (category === "green" ? "is-dark" : "")
              }
            >
              <FontAwesomeIcon
                icon="ellipsis-v"
                size="lg"
                className="has-text-success"
              />
              &nbsp;{t("practice.green")}
            </button>
          </div>

          <div className="control is-expanded">
            <button
              onClick={() => setCategory("blue")}
              className={
                "button is-fullwidth " + (category === "blue" ? "is-dark" : "")
              }
            >
              <FontAwesomeIcon
                icon="ellipsis-v"
                size="lg"
                className="has-text-link"
              />
              &nbsp;{t("practice.blue")}
            </button>
          </div>

          <div className="control is-expanded">
            <button
              onClick={() => setCategory("yellow")}
              className={
                "button is-fullwidth " +
                (category === "yellow" ? "is-dark" : "")
              }
            >
              <FontAwesomeIcon
                icon="ellipsis-v"
                size="lg"
                className="has-text-warning"
              />
              &nbsp;{t("practice.yellow")}
            </button>
          </div>

          <div className="control is-expanded">
            <button
              onClick={() => setCategory("red")}
              className={
                "button is-fullwidth " + (category === "red" ? "is-dark" : "")
              }
            >
              <FontAwesomeIcon
                icon="ellipsis-v"
                size="lg"
                className="has-text-danger"
              />
              &nbsp;{t("practice.red")}
            </button>
          </div>

          <div className="control is-expanded">
            <button
              onClick={() => setCategory("")}
              className={
                "button is-fullwidth " + (category === "" ? "is-dark" : "")
              }
            >
              <FontAwesomeIcon icon="ellipsis-v" size="lg" />
              &nbsp;{t("practice.all")}
            </button>
          </div>

          {/* <div className="control is-expanded">
            <button className="button is-fullwidth"></button>
          </div> */}
        </div>
      </div>

      {loading ? (
        <LoadingSpinner />
      ) : practices?.length === 0 ? (
        <div className="box">
          <p className="has-text-grey">Список пуст...</p>
        </div>
      ) : (
        practices.map(pr => <PracticeCard practice={pr} key={pr?.id} />)
      )}
    </>
  )
}

export default PracticeList
