import axios from "axios"
import { createContext } from "react"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import { API_URL, messageToToasts } from "../utils"
import useSettings from "./useSettings"

export const SettingsContext = createContext()

const SettingsContextProvider = ({ children }) => {
  const { i18n } = useTranslation("data")
  const { settings, setNeedRefetch } = useSettings()

  const refetch = () => {
    setNeedRefetch(true)
  }

  const updateSetting = async (settingKey, settingValue) => {
    try {
      const data = { settingKey, settingValue }

      const resp = await axios.post(`${API_URL}/setSetting`, data)

      const { status, message } = resp.data

      if (status === "failed") return messageToToasts(message, i18n.language)

      refetch()
    } catch (error) {
      toast.error("Server error!")
    }
  }

  const value = { settings, refetch, updateSetting }

  return (
    <SettingsContext.Provider value={value}>
      {children}
    </SettingsContext.Provider>
  )
}

export default SettingsContextProvider
