import { useTranslation } from "react-i18next"
import { Link, Route, Switch, useRouteMatch } from "react-router-dom"
import BulletinPage from "../data/bulettin/BulletinPage"
import NavBar from "../helpers/NavBar"
import HomeView from "./HomeView"

const ExternalClientWorkspace = () => {
  const { t } = useTranslation("ws")
  const { path, url } = useRouteMatch()

  return (
    <>
      <NavBar>
        <Link className="navbar-item" to={url + "/bulletin"}>
          {t("menu.bulletin")}
        </Link>
      </NavBar>

      <div className="section">
        <div className="container">
          <Switch>
            <Route exact path={path}>
              <HomeView />
            </Route>

            <Route path={path + "/bulletin"}>
              <BulletinPage />
            </Route>
          </Switch>
        </div>
      </div>
    </>
  )
}

export default ExternalClientWorkspace
