import axios from "axios"
import dayjs from "dayjs"
import i18next from "i18next"
import { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import { CommonDataContext } from "../../../context/CommonDataContext"
import { API_URL, messageToToasts } from "../../../utils"
import FormEntry from "../../helpers/FormEntry"
import Pagination from "../../helpers/Pagination"

const BlogCommentsList = ({ postID }) => {
  const { t } = useTranslation("data")
  const { allUsersDict } = useContext(CommonDataContext)

  const [needRefetch, setNeedRefetch] = useState(true)
  const [currentPage, setCurrentPage] = useState(1)
  const [pageCount, setPageCount] = useState(1)
  const [comments, setComments] = useState([])

  const [text, setText] = useState("")

  useEffect(() => {
    const fetchComments = async () => {
      try {
        const resp = await axios.get(
          `${API_URL}/blogPost/comment/list?postID=${postID}&page=${currentPage}`
        )

        const { status, message, comments, pageCount } = resp.data

        if (status === "failed")
          return messageToToasts(message, i18next.language)

        setComments(comments)
        setPageCount(pageCount)
        setNeedRefetch(false)
      } catch (error) {
        toast.error("Server error")
      }
    }

    if (needRefetch) fetchComments()
  }, [postID, needRefetch, currentPage])

  const addComment = async () => {
    try {
      const resp = await axios.post(`${API_URL}/blogPost/comment/add`, {
        postID,
        text,
      })
      const { status, message } = resp.data

      if (status === "failed") return messageToToasts(message, i18next.language)

      setText("")
      setNeedRefetch(true)
      setCurrentPage(1)
    } catch (error) {
      toast.error("Server error")
    }
  }

  const users = {
    ...allUsersDict.projectLeader,
    ...allUsersDict.admin,
    ...allUsersDict.students,
  }

  return (
    <>
      <div className="box">
        <p className="title is-size-6">{t("blogpost.comments")}</p>

        <FormEntry>
          <textarea
            rows={1}
            className="textarea is-small"
            value={text}
            onChange={e => setText(e.target.value)}
            placeholder={t("blogpost.commentPhl")}
          />
        </FormEntry>

        <div className="field is-grouped is-grouped-right">
          <p className="control">
            <button className="button is-small is-link" onClick={addComment}>
              {t("send")}
            </button>
          </p>
        </div>

        <hr />

        {comments?.length ? (
          comments.map(comm => (
            <div className="message is-link is-small" key={comm?.id}>
              <div className="message-header">
                <span>{users[comm?.authorID]?.fullName}</span>

                <span className="is-pulled-right">
                  {dayjs(comm?.creationDate).format("DD/MM/YYYY HH:mm:ss")}
                </span>
              </div>
              <div className="message-body" style={{ whiteSpace: "pre-wrap" }}>
                {comm?.text}
              </div>
            </div>
          ))
        ) : (
          <span className="has-text-grey">{t("blogpost.noComments")}</span>
        )}

        <Pagination
          page={currentPage}
          setPage={setCurrentPage}
          pageCount={pageCount}
        />
      </div>
    </>
  )
}

export default BlogCommentsList
