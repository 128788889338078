import axios from "axios"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import { API_URL, messageToToasts } from "../utils"

const useProjectWishes = mode => {
  const { i18n } = useTranslation()
  const [loading, setLoading] = useState(true)
  const [wishes, setWishes] = useState([])
  const [needRefetch, setNeedRefetch] = useState(true)

  useEffect(() => {
    setNeedRefetch(true)
  }, [mode])

  useEffect(() => {
    const fetchWishes = async () => {
      try {
        const requestURL = `${API_URL}/project/listWish?mode=${mode}`

        const resp = await axios.get(requestURL)
        const { status, message, wishes } = resp.data

        if (status === "failed") return messageToToasts(message, i18n.language)

        setWishes(wishes)
        setLoading(false)
      } catch (error) {
        toast.error("Server error. Try to reload page or contact administrator")
      }
    }

    setLoading(true)
    fetchWishes()
    setNeedRefetch(false)
  }, [needRefetch, mode, i18n])

  const refetch = () => {
    setNeedRefetch(true)
  }

  return { loading, wishes, refetch }
}

export default useProjectWishes
