import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import FormEntry from "../../helpers/FormEntry"
import Select from "react-select"
import useDivisions from "../../../context/useDivisions"

const projectLeaderFields = {
  workPlace: "",
  position: "",
  divisionString: "",
}

const ProjectLeaderSignUpForm = ({ fields, setFields, updateFields }) => {
  const { t, i18n } = useTranslation("auth")
  const [formNeedsEnrichment, setFormNeedsEnrichment] = useState(true)

  const workPlaceOptions = [
    { label: "НИУ ВШЭ", value: "hse" },
    { label: "Другое", value: "other" },
  ]
  const [selectedWorkPlace, setSelectedWorkPlace] = useState({
    label: t("placeholders.chooseWorkPlace"),
    value: "",
  })

  const [workPlaceName, setWorkPlaceName] = useState("")
  const [needRefetch, setNeedRefetch] = useState(true)
  const { divisionOptions, loading } = useDivisions(needRefetch, setNeedRefetch)
  const [selectedDivisionOption, setSelectedDivisionOption] = useState({
    label: t("placeholders.chooseDivision"),
    value: "",
  })

  useEffect(() => {
    if (formNeedsEnrichment) {
      setFields({ ...fields, ...projectLeaderFields })
      setFormNeedsEnrichment(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(
    () => {
      if (selectedWorkPlace.value === "hse")
        setFields({ ...fields, workPlace: "hse" })

      if (selectedWorkPlace.value === "other")
        setFields({ ...fields, workPlace: workPlaceName })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedWorkPlace, workPlaceName]
  )

  useEffect(
    () => {
      setSelectedDivisionOption({
        label: t("placeholders.chooseDivision"),
        value: "",
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedWorkPlace]
  )

  useEffect(
    () => {
      if (selectedWorkPlace.value === "hse")
        setFields({ ...fields, divisionString: selectedDivisionOption.value })
      if (selectedWorkPlace.value === "other")
        setFields({ ...fields, divisionString: "" })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedDivisionOption]
  )

  return (
    <>
      <FormEntry labelText="Email" helpText={t("formLabels.req")}>
        <input
          className="input"
          name="email"
          value={fields.email}
          onChange={e => updateFields(e)}
          type="email"
          placeholder="some-mail@hse.ru"
          disabled
        />
      </FormEntry>

      <FormEntry labelText={t("formLabels.pwd")} helpText={t("formLabels.req")}>
        <input
          className="input"
          type="password"
          name="password"
          placeholder="********"
          value={fields.password}
          onChange={e => updateFields(e)}
        />
      </FormEntry>

      <FormEntry
        labelText={t("formLabels.pwdRep")}
        helpText={t("formLabels.req")}
      >
        <input
          className="input"
          type="password"
          name="passwordRep"
          placeholder="********"
          value={fields.passwordRep}
          onChange={e => updateFields(e)}
        />
      </FormEntry>

      <FormEntry
        labelText={t("formLabels.surname")}
        helpText={t("formLabels.req")}
      >
        <input
          className="input"
          name="surname"
          value={fields.surname}
          onChange={e => updateFields(e)}
          type="text"
          placeholder="Иванов"
        />
      </FormEntry>

      <FormEntry
        labelText={t("formLabels.name")}
        helpText={t("formLabels.req")}
      >
        <input
          className="input"
          name="name"
          value={fields.name}
          onChange={e => updateFields(e)}
          type="text"
          placeholder="Иван"
        />
      </FormEntry>

      <FormEntry labelText={t("formLabels.secondName")}>
        <input
          className="input"
          name="secondName"
          value={fields.secondName}
          onChange={e => updateFields(e)}
          type="text"
          placeholder="Иванович"
        />
      </FormEntry>

      <div className="columns">
        <div
          className={
            "column" + (selectedWorkPlace.value === "" ? " is-12" : " is-6")
          }
        >
          <FormEntry
            labelText={t("formLabels.workPlace")}
            helpText={t("formLabels.req")}
          >
            <Select
              options={workPlaceOptions}
              value={selectedWorkPlace}
              onChange={setSelectedWorkPlace}
            />
          </FormEntry>
        </div>
        {selectedWorkPlace.value === "hse" ? (
          <div className="column is-6">
            <FormEntry
              labelText={t("formLabels.division")}
              helpText={t("formLabels.req")}
            >
              <Select
                options={divisionOptions[i18n.language]}
                value={selectedDivisionOption}
                onChange={setSelectedDivisionOption}
                isLoading={loading}
              />
            </FormEntry>
          </div>
        ) : null}
        {selectedWorkPlace.value === "other" ? (
          <div className="column is-6">
            <FormEntry
              labelText={t("formLabels.workPlaceName")}
              helpText={t("formLabels.req")}
            >
              <input
                className="input"
                type="text"
                value={workPlaceName}
                onChange={e => setWorkPlaceName(e.target.value)}
              />
            </FormEntry>
          </div>
        ) : null}
      </div>

      <FormEntry
        labelText={t("formLabels.position")}
        helpText={t("formLabels.req")}
      >
        <input
          className="input"
          name="position"
          value={fields.position || ""}
          onChange={e => updateFields(e)}
          type="text"
          placeholder="Доцент"
        />
      </FormEntry>
    </>
  )
}

export default ProjectLeaderSignUpForm
