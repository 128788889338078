import axios from "axios"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import { API_URL, messageToToasts } from "../utils"

const useDivisions = (needRefetch, setNeedRefetch) => {
  const { i18n } = useTranslation()
  const [loading, setLoading] = useState(true)
  const [divisions, setDivisions] = useState({})
  const [divisionOptions, setDivisionOptions] = useState([])

  const switchEditMode = divisionID => {
    const division = divisions[divisionID]

    setDivisions({
      ...divisions,
      [divisionID]: { ...division, editMode: !division.editMode },
    })
  }

  useEffect(
    () => {
      const fetchDivisions = async () => {
        try {
          const requestURL = `${API_URL}/divisions/list`
          const resp = await axios.get(requestURL)
          const { status, message, divisions } = resp.data

          if (status === "failed")
            return messageToToasts(message, i18n.language)

          const ruOptions = divisions.map(d => ({
            label: d.title.ru,
            value: d.id,
          }))
          const enOptions = divisions.map(d => ({
            label: d.title.en,
            value: d.id,
          }))

          setDivisions(
            divisions.reduce(
              (obj, division) =>
                Object.assign(obj, {
                  [division.id]: { ...division, editMode: false },
                }),
              {}
            )
          )
          setDivisionOptions({ ru: ruOptions, en: enOptions })
          setLoading(false)
          setNeedRefetch(false)
        } catch (error) {
          toast.error(
            "Server error. Try to reload page or contact administrator"
          )
        }
      }

      if (needRefetch) {
        setLoading(true)
        fetchDivisions()
      }
    },
    // eslint-disable-next-line
    [needRefetch, setNeedRefetch]
  )

  return { loading, divisions, divisionOptions, switchEditMode }
}

export default useDivisions
