import axios from "axios"
import { useContext } from "react"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import { UserContext } from "../../../context/UserContext"
import { API_URL, messageToToasts } from "../../../utils"

const GetGradeSheet = ({
  asgnID,
  gradeSheetFilename,
  refetch,
  showRemove = true,
}) => {
  const { t } = useTranslation("data")
  const {
    user: { userType },
  } = useContext(UserContext)

  const getGradeSheet = () => {
    window.open(API_URL + "/grade_sheets/" + gradeSheetFilename, "_blank")
  }

  const removeGradeSheet = async () => {
    try {
      const resp = await axios.post(`${API_URL}/group/removeGradeSheet`, {
        asgnID,
      })
      const { status, message } = resp.data

      if (status === "failed") return toast.error(messageToToasts(message))

      refetch(false)
    } catch (error) {
      toast.error("Server error!")
    }
  }

  return (
    <>
      <div className="field">
        <div className="control">
          <button
            className="button is-fullwidth is-link"
            onClick={getGradeSheet}
          >
            {t("group.getGradeSheet")}
          </button>
        </div>
      </div>

      {!(userType === "admin" && showRemove) || (
        <div className="field">
          <div className="control">
            <button
              className="button is-fullwidth is-danger"
              onClick={removeGradeSheet}
            >
              {t("group.removeGradeSheet")}
            </button>
          </div>
        </div>
      )}
    </>
  )
}

export default GetGradeSheet
