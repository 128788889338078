import { useContext } from "react"
import { useTranslation } from "react-i18next"
import { UserContext } from "../../../context/UserContext"
import AsgnChangeList from "./AsgnChangeList"
import AsgnChangeNew from "./AsgnChangeNew"
import useAsgnChanges from "../../../context/useAsgnChanges"

const AsgnChangePage = ({ groupID }) => {
  const {
    user: { userType },
  } = useContext(UserContext)
  const { t } = useTranslation("data")
  const { asgnChanges, loading, refetch } = useAsgnChanges(groupID)

  return (
    <>
      <div className="box">
        <p className="title is -4 has-text-right">{t("group.asgnChange")}</p>
      </div>

      {userType !== "student" || <AsgnChangeNew refetch={refetch} />}

      <AsgnChangeList
        asgnChanges={asgnChanges}
        loading={loading}
        refetch={refetch}
      />
    </>
  )
}

export default AsgnChangePage
