import axios from "axios"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import { API_URL, messageToToasts } from "../utils"

const useProposals = (userType, category, needRefetch, setNeedRefetch) => {
  const { i18n } = useTranslation()
  const [loading, setLoading] = useState(true)
  const [proposals, setProposals] = useState([])

  useEffect(
    () => {
      const fetchProposals = async () => {
        try {
          let requestURL = ""
          if (userType === "admin")
            requestURL = `${API_URL}/project/proposal/list?category=${category}`

          if (userType === "projectLeader")
            requestURL = `${API_URL}/project/proposal/listForLeader?category=${category}`

          if (userType === "divisionManager")
            requestURL = `${API_URL}/project/proposal/listForManager?category=${category}`

          const resp = await axios.get(requestURL)
          const { status, message, proposals } = resp.data

          if (status === "failed")
            return messageToToasts(message, i18n.language)

          setProposals(proposals)
          setLoading(false)
        } catch (error) {
          toast.error(
            "Server error. Try to reload page or contact administrator"
          )
        }
      }

      if (needRefetch) {
        setLoading(true)
        fetchProposals()
        setNeedRefetch(false)
      }
    },
    // eslint-disable-next-line
    [needRefetch, category, userType]
  )

  return { loading, proposals }
}

export default useProposals
