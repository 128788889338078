import axios from "axios"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import { API_URL, messageToToasts } from "../utils"

const optionsFromCourses = (coursesArray, lang) => {
  const others = lang === "ru" ? "Прочие" : "Others"

  return [...new Set(coursesArray.map(c => (c.group ? c.group : others)))].map(
    gr => ({
      label: gr,
      options: coursesArray
        .filter(c => (gr !== others ? c.group === gr : c.group === ""))
        .map(c => ({ label: c.title[lang], value: c.id })),
    })
  )
}
const useCourses = (needRefetch, setNeedRefetch, mode = "") => {
  const { i18n } = useTranslation()
  const [loading, setLoading] = useState(true)
  const [courses, setCourses] = useState({})
  const [courseOptions, setCourseOptions] = useState([])
  const [bachelorCourseOptions, setBachelorCourseOptions] = useState([])
  const [masterCourseOptions, setMasterCourseOptions] = useState([])

  const switchEditMode = courseID => {
    const course = courses[courseID]

    setCourses({
      ...courses,
      [courseID]: { ...course, editMode: !course.editMode },
    })
  }

  useEffect(
    () => {
      const fetchCourses = async () => {
        try {
          const requestURL = `${API_URL}/courses/list${
            mode ? "?mode=" + mode : ""
          }`
          const resp = await axios.get(requestURL)
          const { status, message, courses } = resp.data

          if (status === "failed")
            return messageToToasts(message, i18n.language)

          const ruOptions = courses.map(d => ({
            label: d.title.ru,
            value: d.id,
          }))
          const enOptions = courses.map(d => ({
            label: d.title.en,
            value: d.id,
          }))

          setCourses(
            courses.reduce(
              (obj, course) =>
                Object.assign(obj, {
                  [course.id]: { ...course, editMode: false },
                }),
              {}
            )
          )

          setCourseOptions({ ru: ruOptions, en: enOptions })

          const bachelorCourses = courses.filter(c => c.mode === "bachelor")
          const masterCourses = courses.filter(c => c.mode === "master")

          const bachelorOptionsRU = optionsFromCourses(bachelorCourses, "ru")
          const bachelorOptionsEN = optionsFromCourses(bachelorCourses, "en")

          const masterOptionsRU = optionsFromCourses(masterCourses, "ru")
          const masterOptionsEN = optionsFromCourses(masterCourses, "en")

          setBachelorCourseOptions({
            ru: bachelorOptionsRU,
            en: bachelorOptionsEN,
          })
          setMasterCourseOptions({ ru: masterOptionsRU, en: masterOptionsEN })

          setLoading(false)
          setNeedRefetch(false)
        } catch (error) {
          toast.error(
            "Server error. Try to reload page or contact administrator"
          )
        }
      }

      if (needRefetch || mode) {
        setLoading(true)
        fetchCourses()
      }
    },
    // eslint-disable-next-line
    [needRefetch, setNeedRefetch, mode]
  )

  return {
    loading,
    courses,
    switchEditMode,
    courseOptions,
    bachelorCourseOptions,
    masterCourseOptions,
  }
}

export default useCourses
