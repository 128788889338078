import dayjs from "dayjs"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import useInvites from "../../context/useInvites"
import { rolesOptions } from "../../utils"
import LoadingSpinner from "../helpers/LoadingSpinner"

const InviteList = ({ userType, used }) => {
  const { t } = useTranslation("data")
  const { loading, invites } = useInvites(userType, used)

  const roles = rolesOptions[userType].reduce((acc, role) => {
    acc[role.value] = role.label
    return acc
  }, {})

  const invitesTable = invites.length ? (
    <div className="table-container">
      <table className="table is-fullwidth">
        <thead>
          <tr>
            <th>{t("invite.inviteCode")}</th>
            <th>{t("invite.role")}</th>
            <th>{t("invite.created")}</th>
            <th>Email</th>
          </tr>
        </thead>
        <tbody>
          {invites.map(inv => (
            <tr key={inv.id}>
              <th>{inv.code}</th>
              <td>{roles[inv.role]}</td>
              <td>{dayjs(inv.created).format("DD/MM/YYYY HH:mm:ss")}</td>

              <td>
                {used ? (
                  <Link
                    to={`/r/common/profile?userID=${inv.newUserID}&userType=${userType}`}
                    target="_blank"
                  >
                    {inv.userEmail}
                  </Link>
                ) : (
                  inv.userEmail
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  ) : (
    <p className="has-text-grey">{t("emptyList")}</p>
  )

  return (
    <div className="box">
      {loading ? (
        <LoadingSpinner text={t("loading.inviteList")} />
      ) : (
        invitesTable
      )}
    </div>
  )
}

export default InviteList
