import { useContext, useEffect, useState } from "react"
import useInfoUpdates from "../../context/useInfoUpdates"
import dayjs from "dayjs"
import { CommonDataContext } from "../../context/CommonDataContext"
import { useTranslation } from "react-i18next"
import { yearsToText } from "../../utils"

const InfoUpdatesList = () => {
  const { t, i18n } = useTranslation("ws")
  const {
    programmes: { dict: progDict },
    students: { dict: studDict },
  } = useContext(CommonDataContext)
  const { infoUpdates, accept, decline } = useInfoUpdates()

  const [statusMode, setStatusMode] = useState("awaiting")
  const [updatesToShow, setUpdatesToShow] = useState([])

  useEffect(() => {
    setUpdatesToShow(
      (infoUpdates || []).filter(iu => iu?.status === statusMode)
    )
  }, [statusMode, infoUpdates])

  return (
    <>
      <div className="box has-text-right title is-4">
        Заявки изменения данных студентов
      </div>

      <div className="tabs is-centered">
        <ul>
          <li className={statusMode === "awaiting" ? " is-active" : ""}>
            <a href="#mode" onClick={() => setStatusMode("awaiting")}>
              Ожидают решения
            </a>
          </li>

          <li className={statusMode === "accepted" ? " is-active" : ""}>
            <a href="#mode" onClick={() => setStatusMode("accepted")}>
              Одобренные
            </a>
          </li>

          <li className={statusMode === "declined" ? " is-active" : ""}>
            <a href="#mode" onClick={() => setStatusMode("declined")}>
              Отклоненные
            </a>
          </li>
        </ul>
      </div>

      <div className="box">
        {updatesToShow.length > 0 || (
          <p className="has-text-grey">Список заявок пуст</p>
        )}

        {(updatesToShow || []).map(iu => (
          <div key={iu?.id}>
            <p className="has-text-grey is-underlined">
              {dayjs(iu?.creationDate).format("DD-MM-YYYY HH:mm")}
              &nbsp; (последнее обновление:{" "}
              {dayjs(iu?.lastUpdateDate).format("DD-MM-YYYY HH:mm")})
            </p>

            <p>
              <strong>Студент: </strong>
              {studDict[iu?.studentID]?.fullName}
              &nbsp; ({studDict[iu?.studentID]?.email})
            </p>

            <p>
              <strong>{t("userProfilePage.programme")}: </strong>
              {progDict[iu?.oldProgrammeID]?.title?.[i18n.language]}
              &nbsp;=&gt;&nbsp;
              {progDict[iu?.newProgrammeID]?.title?.[i18n.language]}
            </p>

            <p>
              <strong>{t("userProfilePage.yearOfStudy")}: </strong>
              {yearsToText(iu?.oldYos)}
              &nbsp;=&gt;&nbsp;
              {yearsToText(iu?.newYos)}
            </p>

            {iu?.status === "awaiting" ? (
              <div className="field is-grouped is-grouped-right mt-1">
                <div className="control">
                  <span
                    className="has-text-success is-underlined pointer"
                    onClick={() => accept(iu?.id)}
                  >
                    {t("userProfilePage.accept")}
                  </span>
                </div>

                <div className="control">
                  <span
                    className="has-text-danger is-underlined pointer"
                    onClick={() => decline(iu?.id)}
                  >
                    {t("userProfilePage.decline")}
                  </span>
                </div>
              </div>
            ) : null}

            <hr />
          </div>
        ))}
      </div>
    </>
  )
}

export default InfoUpdatesList
