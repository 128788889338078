import axios from "axios"
import { useContext } from "react"
import { useTranslation } from "react-i18next"
import { UserContext } from "../../../context/UserContext"
import { API_URL, messageToToasts } from "../../../utils"
import MaxStudents from "../proposal/MaxStudents"

const ChangePreview = ({ change, refetch }) => {
  const { t, i18n } = useTranslation("data")
  const {
    user: { userType },
  } = useContext(UserContext)

  const spanFromStatus = status => {
    switch (status) {
      case "awaiting":
        return (
          <span className="has-text-warning">
            {t("application.awaitingStatus")}
          </span>
        )
      case "declined":
        return (
          <span className="has-text-danger">
            {t("application.declinedStatus")}
          </span>
        )
      case "accepted":
        return (
          <span className="has-text-success">
            {t("application.acceptedStatus")}
          </span>
        )
      default:
        break
    }
  }

  const declineRequest = async () => {
    try {
      const requestURL = `${API_URL}/project/declineProjectChangeReq${
        userType === "projectLeader" ? "?mode=self" : ""
      }`
      const data = {
        changeID: change.id,
        declineMessage: "",
      }

      const resp = await axios.post(requestURL, data)
      const { status, message } = resp.data

      if (status === "failed") return messageToToasts(message, i18n.language)

      refetch()
    } catch (error) {}
  }

  return (
    <div className="box">
      <p className="title is-5">
        {t("project.changeRequest") + " " + change.id}
      </p>

      <hr />

      <MaxStudents maxStudents={change.newMaxStudents} />

      {change.status === "declined" && change.declineMessage !== "" ? (
        <div className="message is-danger">
          <div className="message-body">{change.declineMessage}</div>
        </div>
      ) : null}

      <hr />
      <div className="columns">
        <div className="column is-5 has-text-centered">
          <strong>{spanFromStatus(change.status)}</strong>
        </div>
        <div className="column is-7 has-text-right">
          {change.status === "awaiting" ? (
            <button
              className="button is-danger is-outlined is-small"
              onClick={declineRequest}
            >
              {t("application.decline")}
            </button>
          ) : null}
        </div>
      </div>
    </div>
  )
}

const ProjectChangeList = ({ changes, refetch }) =>
  changes.map(change => (
    <ChangePreview change={change} refetch={refetch} key={change.id} />
  ))

export default ProjectChangeList
