import { useContext } from "react"
import { useTranslation } from "react-i18next"
import Select from "react-select"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import FormEntry from "../../helpers/FormEntry"
import { toast } from "react-toastify"
import { CommonDataContext } from "../../../context/CommonDataContext"

const ProposalNewRecomendedCourses = ({
  recomendedCoursesStrings,
  setRecomendedCoursesStrings,
}) => {
  const { t, i18n } = useTranslation("data")
  const {
    courses: { loading, bachelorOptions, masterOptions, dict: courses },
  } = useContext(CommonDataContext)
  const selectedBacheloPlaceholder = {
    label: t("proposal.selectedBachelorCoursePlaceholder"),
    value: "",
  }
  const selectedMasterPlaceholder = {
    label: t("proposal.selectedMasterCoursePlaceholder"),
    value: "",
  }

  const addCourse = option => {
    if (recomendedCoursesStrings.includes(option.value)) return

    const selectedCourses = recomendedCoursesStrings.map(
      cID => courses[cID]?.mode
    )
    const bachelorCount = selectedCourses.filter(c => c === "bachelor").length
    const masterCount = selectedCourses.filter(c => c === "master").length
    const courseToAddMode = courses[option.value]?.mode

    if (courseToAddMode === "bachelor" && bachelorCount >= 4)
      return toast.error(t("proposal.courseBachelorLimitMessage") + 4)

    if (courseToAddMode === "master" && masterCount >= 4)
      return toast.error(t("proposal.courseMasterLimitMessage") + 4)

    setRecomendedCoursesStrings([...recomendedCoursesStrings, option.value])
  }

  const removeCourse = courseId => {
    setRecomendedCoursesStrings(
      recomendedCoursesStrings.filter(el => el !== courseId)
    )
  }

  return (
    <FormEntry labelText={t("proposal.recomendedCourses")}>
      <div className="columns">
        <div className="column is-half">
          <Select
            options={bachelorOptions[i18n.language]}
            value={selectedBacheloPlaceholder}
            onChange={addCourse}
            isSearchable={true}
            isLoading={loading}
          />

          <Select
            className="mt-5"
            options={masterOptions[i18n.language]}
            value={selectedMasterPlaceholder}
            onChange={addCourse}
            isSearchable={true}
            isLoading={loading}
          />
        </div>

        <div className="column is-half">
          {!Object.values(recomendedCoursesStrings).length ? (
            <p className="has-text-grey has-text-centered">
              {t("proposal.emptyRecomendedCourses")}
            </p>
          ) : (
            <>
              {recomendedCoursesStrings.map(rc => {
                const { title, mode } = courses[rc]
                return (
                  <div className="field has-addons" key={rc}>
                    <p className="control">
                      <button
                        className="button is-danger"
                        onClick={() => removeCourse(rc)}
                      >
                        <FontAwesomeIcon icon="trash" />
                      </button>
                    </p>
                    <p className="control is-expanded">
                      <button className="button is-fullwidth is-multiline is-static">
                        {title[i18n.language] +
                          " (" +
                          t(`proposal.${mode}`) +
                          ")"}
                      </button>
                    </p>
                  </div>
                )
              })}
            </>
          )}
        </div>
      </div>
    </FormEntry>
  )
}

export default ProposalNewRecomendedCourses
