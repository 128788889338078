import axios from "axios"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import { API_URL, messageToToasts, validateEmail } from "../../utils"
import FormEntry from "../helpers/FormEntry"

const CheckInvite = ({ userType, fields, setFields, setInviteChecked }) => {
  const { t, i18n } = useTranslation("auth")
  const [inviteCode, setInviteCode] = useState("")
  const [hseEmail, setHseEmail] = useState("")

  const checkInviteCode = async e => {
    e.preventDefault()

    try {
      const data = { inviteCode, userType }
      const resp = await axios.post(`${API_URL}/user/checkInvite`, data)
      const { status, message, invite } = resp.data

      if (status === "failed") return messageToToasts(message, i18n.language)

      const extraData = invite[userType + "Extra"]
      setFields({
        ...fields,
        ...extraData,
        inviteCode,
        email: invite.userEmail,
        newUserID: invite.newUserID,
      })
      setInviteChecked(true)
    } catch (err) {
      toast.error("Server error. Try to reload page or contact administrator")
    }
  }

  const checkMail = async e => {
    e.preventDefault()

    // TODO: add setting for the disable
    // if (userType === "projectLeader")
    //   return toast.error(t("prohibitedHseMailSignUp"))

    try {
      if (!validateEmail(hseEmail)) {
        return toast.error(t("emailFormatError"))
      }

      setFields({
        ...fields,
        email: hseEmail.toLowerCase(),
      })
      setInviteChecked(true)
    } catch (err) {
      toast.error("Server error. Try to reload page or contact administrator")
    }
  }

  const checkHseMail = async e => {
    e.preventDefault()

    // TODO: add setting for the disable
    // if (userType === "projectLeader")
    //   return toast.error(t("prohibitedHseMailSignUp"))

    try {
      if (
        !["hse.ru", "edu.hse.ru"].includes(hseEmail.toLowerCase().split("@")[1])
      )
        return toast.error(t("hseMailError"))

      setFields({
        ...fields,
        email: hseEmail.toLowerCase(),
      })
      setInviteChecked(true)
    } catch (err) {
      toast.error("Server error. Try to reload page or contact administrator")
    }
  }

  return (
    <>
      <form>
        <FormEntry
          labelText={t("formLabels.inviteCode")}
          helpText={t("formLabels.req")}
        >
          <input
            className="input"
            name="inviteCode"
            value={inviteCode}
            onChange={e => setInviteCode(e.target.value)}
            type="text"
            placeholder="xxxxxxxxxxxxxx"
          />
        </FormEntry>

        <FormEntry>
          <button
            className="button is-link is-outlined is-fullwidth"
            type="submit"
            onClick={checkInviteCode}
          >
            {t("checkInvite")}
          </button>
        </FormEntry>
      </form>

      {["projectLeader", "student"].includes(userType) ? (
        <>
          <div className="divider">{t("or")}</div>
          <form>
            <FormEntry
              labelText={t("hseMailSignUpLabel")}
              helpText={t("formLabels.req")}
            >
              <input
                className="input"
                name="hseEmail"
                type="email"
                value={hseEmail}
                onChange={e => setHseEmail(e.target.value)}
                placeholder={
                  userType === "projectLeader"
                    ? "lecturer@hse.ru"
                    : "student@edu.hse.ru"
                }
              />
            </FormEntry>

            <FormEntry>
              <button
                className="button is-link is-outlined is-fullwidth"
                type="submit"
                onClick={checkHseMail}
              >
                {t("signUpMail")}
              </button>
            </FormEntry>
          </form>
        </>
      ) : null}

      {userType === "externalClient" ? (
        <>
          <div className="divider">{t("or")}</div>
          <form>
            <FormEntry
              labelText={t("mailSignUpLabel")}
              helpText={t("formLabels.req")}
            >
              <input
                className="input"
                name="hseEmail"
                type="email"
                value={hseEmail}
                onChange={e => setHseEmail(e.target.value)}
                placeholder="user@mail.com"
              />
            </FormEntry>

            <FormEntry>
              <button
                className="button is-link is-outlined is-fullwidth"
                type="submit"
                onClick={checkMail}
              >
                {t("signUpMail")}
              </button>
            </FormEntry>
          </form>
        </>
      ) : null}
    </>
  )
}

export default CheckInvite
