import { useContext } from "react"
import { useTranslation } from "react-i18next"
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
} from "react-router"
import { Link } from "react-router-dom"
import { CommonDataContext } from "../../../context/CommonDataContext"
import { UserContext } from "../../../context/UserContext"
import ProgrammeDiscussion from "./ProgrammeDiscussion"

const ProgrammeDiscussionsList = ({ programmeDiscussions }) => {
  const history = useHistory()
  const { t, i18n } = useTranslation("data")
  const { url } = useRouteMatch()
  const {
    programmes: { dict: progDict },
  } = useContext(CommonDataContext)
  const {
    user: { userID },
  } = useContext(UserContext)

  const listDiscussions = discussions => {
    return discussions.map(disc => {
      const unreadMessages = (disc?.messages || []).filter(
        mes => !mes.viewedBy.includes(userID)
      ).length

      return (
        <div className="box is-size-5" key={disc.id}>
          <div className="columns">
            <div className="column is-8">
              {t("project.dialogForEP") + ": "}
              <Link to={url + "/viewEP/" + disc.programmeID}>
                {progDict[disc.programmeID]?.title[i18n.language]}
              </Link>
            </div>

            <div className="column is-4 has-text-right">
              {unreadMessages > 0 ? (
                <span className="tag is-link">
                  {t("project.unread") + ": " + unreadMessages}
                </span>
              ) : null}
            </div>
          </div>
        </div>
      )
    })
  }

  const toDialogList = (
    <div className="box has-text-centered">
      <span
        className="has-text-link pointer"
        onClick={() => history.push("../list")}
      >
        {t("project.backToList")}
      </span>
    </div>
  )

  return (
    <Switch>
      <Route exact path={url}>
        <Redirect to={url + "/list"} />
      </Route>

      <Route exact path={url + "/list"}>
        {/* <pre>{JSON.stringify(programmeDiscussions, "", 2)}</pre> */}
        {listDiscussions(programmeDiscussions)}
      </Route>

      <Route exact path={url + "/viewEP/:programmeID"}>
        {toDialogList}
        <ProgrammeDiscussion />
      </Route>
    </Switch>
  )
}

export default ProgrammeDiscussionsList
