import axios from "axios"
import Select from "react-select"
import { useContext, useEffect, useState } from "react"
import { API_URL, messageToToasts, yearsToText } from "../../../utils"
import i18next from "i18next"
import { toast } from "react-toastify"
import { CommonDataContext } from "../../../context/CommonDataContext"
import { useTranslation } from "react-i18next"
import LoadingSpinner from "../../helpers/LoadingSpinner"
import PracticeStudentEmails from "./PracticeStudentEmails"

const PracticeStudentList = () => {
  const { t } = useTranslation("data")
  const {
    programmes: { options: programmeOptions, dict: programmesDict },
  } = useContext(CommonDataContext)

  const [loading, setLoading] = useState(true)
  const [selectedProgramme, setSelectedProgramme] = useState(null)
  const [students, setStudents] = useState([])

  useEffect(() => {
    const fetchPractices = async () => {
      try {
        const resp = await axios.get(
          `${API_URL}/practice/listUnapplied?programmeID=${
            selectedProgramme?.value || ""
          }`
        )
        const { status, students, message } = resp.data
        if (status === "failed")
          return messageToToasts(message, i18next.language)

        setStudents(
          students.sort((a, b) => a?.fullName.localeCompare(b?.fullName))
        )
        setLoading(false)
      } catch (_) {
        toast.error("System error or missing internet connection")
      }
    }

    setLoading(true)
    fetchPractices()
  }, [selectedProgramme])

  const setProgrammeFromTag = progID => {
    if (progID === selectedProgramme?.value) setSelectedProgramme(null)
    else
      setSelectedProgramme(
        programmeOptions[i18next.language].find(opt => opt.value === progID)
      )
  }

  return (
    <>
      <div className="mb-5">
        <div className="field">
          <div className="control">
            <Select
              options={programmeOptions[i18next.language]}
              value={selectedProgramme}
              onChange={setSelectedProgramme}
              placeholder={t("practice.programmePlh")}
              isClearable
            />
          </div>
        </div>
      </div>

      {loading ? (
        <LoadingSpinner />
      ) : (
        <>
          <div className="box has-text-centered">
            Всего студентов без заявки: {students?.length}
          </div>

          <PracticeStudentEmails students={students} />

          <div className="box table-container">
            <table className="table is-fullwidth">
              <tbody>
                {students.map(st => {
                  const progTitle =
                    programmesDict?.[st?.programmeID]?.title?.[i18next.language]
                  return (
                    <tr key={st?.id}>
                      <th>{yearsToText(st?.yearOfStudy)}</th>
                      <td>{st?.fullName}</td>
                      <td>
                        <a href={`mailto:${st?.email}`}>{st?.email}</a>
                      </td>
                      <td>
                        <span
                          className="tag pointer is-link"
                          onClick={() =>
                            setProgrammeFromTag(
                              programmesDict?.[st?.programmeID]?.id
                            )
                          }
                        >
                          {progTitle?.length <= 51
                            ? progTitle
                            : `${progTitle.slice(0, 51)}...`}
                        </span>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </>
      )}
    </>
  )
}

export default PracticeStudentList
