import { useTranslation } from "react-i18next"
import AuthLayout from "./AuthLayout"

const Page404 = () => {
  const { t } = useTranslation()

  return (
    <AuthLayout>
      <div className="has-text-centered has-text-grey py-2">
        <p className="title has-text-grey is-3 mt-4">{t("page404")}</p>
        <p className="title has-text-grey is-5 mt-4">{t("page404text")}</p>
      </div>
    </AuthLayout>
  )
}

export default Page404
