import axios from "axios"
import { useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router"
import { toast } from "react-toastify"
import { CommonDataContext } from "../../../context/CommonDataContext"
import { ProposalsContext } from "../../../context/ProposalsContext"
import { API_URL, messageToToasts } from "../../../utils"
import FormEntry from "../../helpers/FormEntry"
import Submitting from "../../helpers/Submitting"

const ProposalApproval = ({ proposal }) => {
  const history = useHistory()
  const {
    projectLeaders: { dict: pls },
  } = useContext(CommonDataContext)
  const { refetch } = useContext(ProposalsContext)
  const { t, i18n } = useTranslation("data")
  const [mode, setMode] = useState("view")
  const [comment, setComment] = useState("")

  const approveProposal = async () => {
    setMode("submitting")

    try {
      const data = {
        proposalID: proposal.id,
        proposalTitle: proposal.title,
        emails: [proposal.projectLeaderID, ...proposal.projectColeaders].map(
          obID => pls[obID]?.email
        ),
      }
      const requestURL = `${API_URL}/project/proposal/approve`
      const resp = await axios.post(requestURL, data)

      const { status, message } = resp.data
      if (status === "failed") {
        setMode("view")
        messageToToasts(message, i18n.language)
      }

      toast.success(t("proposal.approveSuccess"))
      refetch("archiveProposals")
      refetch("publishedProposals")
      history.push(`../listPublished`)
    } catch (error) {
      setMode("view")
      toast.error("Server error!")
    }
  }

  const sendToImproveProposal = async () => {
    setMode("submitting")

    try {
      const data = {
        proposalID: proposal.id,
        proposalTitle: proposal.title,
        emails: [proposal.projectLeaderID, ...proposal.projectColeaders].map(
          obID => pls[obID]?.email
        ),
        comment,
      }
      const requestURL = `${API_URL}/project/proposal/sendToImprove`
      const resp = await axios.post(requestURL, data)

      const { status, message } = resp.data
      if (status === "failed") {
        setMode("view")
        messageToToasts(message, i18n.language)
      }

      toast.success(t("proposal.sendToImproveSuccess"))
      refetch("reviewedProposals")
      refetch("publishedProposals")
      history.push(`../listPublished`)
    } catch (error) {
      setMode("view")
      toast.error("Server error!")
    }
  }

  return (
    <>
      {mode === "view" ? (
        <>
          <hr />
          <p>{t("proposal.approvalText")}</p>
          <FormEntry>
            <div className="columns has-text-centered mt-3">
              <div className="column is-half">
                <button
                  className="button is-link is-fullwidth"
                  onClick={() => setMode("approval")}
                >
                  {t("proposal.approveProposal")}
                </button>
              </div>
              <div className="column is-half">
                <button
                  className="button is-warning is-fullwidth"
                  onClick={() => setMode("review")}
                >
                  {t("proposal.sendToImprove")}
                </button>
              </div>
            </div>
          </FormEntry>
        </>
      ) : null}

      {mode === "approval" ? (
        <>
          <hr />
          <p>{t("proposal.approvalConfirmText")}</p>
          <FormEntry>
            <div className="columns has-text-centered mt-3">
              <div className="column is-half">
                <button
                  className="button is-link is-fullwidth"
                  onClick={approveProposal}
                >
                  {t("proposal.approveProposal")}
                </button>
              </div>
              <div className="column is-half">
                <button
                  className="button is-light is-fullwidth"
                  onClick={() => setMode("view")}
                >
                  {t("proposal.cancelAction")}
                </button>
              </div>
            </div>
          </FormEntry>
        </>
      ) : null}

      {mode === "review" ? (
        <>
          <hr />
          <FormEntry labelText={t("proposal.reviewText")}>
            <textarea
              className="textarea"
              value={comment}
              onChange={e => setComment(e.target.value)}
              placeholder={t("proposal.reviewTextPlaceholder")}
            />
          </FormEntry>
          <FormEntry>
            <div className="columns has-text-centered mt-3">
              <div className="column is-half">
                <button
                  className="button is-warning is-fullwidth"
                  onClick={sendToImproveProposal}
                >
                  {t("proposal.sendToImprove")}
                </button>
              </div>
              <div className="column is-half">
                <button
                  className="button is-light is-fullwidth"
                  onClick={() => setMode("view")}
                >
                  {t("proposal.cancelAction")}
                </button>
              </div>
            </div>
          </FormEntry>
        </>
      ) : null}

      {mode === "submitting" ? <Submitting submitting={true} /> : null}
    </>
  )
}

export default ProposalApproval
