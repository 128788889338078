import axios from "axios"
import i18next from "i18next"
import { toast } from "react-toastify"
import { API_URL, messageToToasts } from "../../../utils"

const BlogVisibilitySwitch = ({ post, refetch }) => {
  const switchVisibility = async post => {
    try {
      const resp = await axios.post(`${API_URL}/blogPost/switchVisibility`, {
        postID: post?.id,
      })
      const { status, message } = resp.data

      if (status === "failed") return messageToToasts(message, i18next.language)

      refetch(false)
    } catch (error) {
      toast.error("Server error!")
    }
  }

  return (
    <div className="field">
      <label className="switch is-rounded">
        <input
          type="checkbox"
          value="false"
          checked={post?.isVisible}
          onChange={() => switchVisibility(post)}
        />
        <span className="check is-link"></span>
      </label>
    </div>
  )
}

export default BlogVisibilitySwitch
