import axios from "axios"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import Select from "react-select"
import { toast } from "react-toastify"
import useDivisions from "../../context/useDivisions"
import useProgrammes from "../../context/useProgrammes"
import {
  API_URL,
  messageToToasts,
  rolesOptions,
  userTypesOptions,
} from "../../utils"
import FormEntry from "../helpers/FormEntry"
import Submitting from "../helpers/Submitting"

const emptyUserTypeOption = {
  label: "Выберите тип пользователя...",
  value: "",
}
const emptyRoleOption = [
  { label: "Выберите сначала тип пользователя...", value: "" },
  { label: "Выберите роль...", value: "" },
]

const InviteNewForm = () => {
  const { t, i18n } = useTranslation("data")
  const [selectedUserType, setSelectedUserType] = useState(emptyUserTypeOption)
  const [roleOptions, setRoleOptions] = useState({})
  const [selectedRole, setSelectedRole] = useState(emptyRoleOption[0])
  const [userEmail, setUserEmail] = useState("")
  const [submitting, setSubmitting] = useState(false)

  const [dnr, setDNR] = useState(true)
  const { divisionOptions, loading: dLoading } = useDivisions(dnr, setDNR)
  const [selectedDivision, setSelectedDivision] = useState({
    label: t("proposal.divisionPlaceholder"),
    value: "",
  })

  const [pnr, setPNR] = useState(true)
  const { programmeOptions, loading: pLoading } = useProgrammes(pnr, setPNR)
  const [selectedProgramme, setSelectedProgramme] = useState(null)

  useEffect(() => {
    if (selectedUserType.value) {
      setSelectedRole(emptyRoleOption[1])
      setRoleOptions(rolesOptions[selectedUserType.value])
    }
  }, [selectedUserType])

  const createInvite = async e => {
    e.preventDefault()
    setSubmitting(true)

    const data = {
      userType: selectedUserType.value,
      userEmail,
      role: selectedRole.value,
    }

    if (data.userType === "divisionManager") {
      if (!selectedDivision.value) {
        setSubmitting(false)
        return toast.error(
          "Для менеджера подразделения необходимо указать подразделения"
        )
      }

      data["divisionManagerExtra"] = {
        division: selectedDivision.value,
      }
    }

    if (data.userType === "academicManager") {
      if (!selectedProgramme?.length) {
        setSubmitting(false)
        return toast.error(
          "Для руководителя ОП необходимо указать хотя бы одну ОП"
        )
      }

      data["academicManagerExtra"] = {
        programmes: selectedProgramme.map(sp => sp.value),
      }
    }

    const resp = await axios.post(`${API_URL}/user/createInvite`, data)

    const { status, message } = resp.data

    if (status === "failed") {
      setSubmitting(false)
      return messageToToasts(message, i18n.language)
    }

    toast.success(t("invite.createSuccessMessage"))
    setSelectedUserType(emptyUserTypeOption)
    setSelectedRole(emptyRoleOption[0])
    setUserEmail("")
    setSubmitting(false)
  }

  return (
    <div className="box">
      <p className="title is-5">{t("invite.newTitle")}</p>
      <form>
        <div className="columns">
          <div className="column is-4">
            <FormEntry labelText={t("invite.userType")}>
              <Select
                options={userTypesOptions}
                value={selectedUserType}
                onChange={selected => setSelectedUserType(selected)}
              />
            </FormEntry>
          </div>

          <div className="column is-4">
            <FormEntry labelText={t("invite.role")}>
              <Select
                options={roleOptions}
                value={selectedRole}
                onChange={selected => setSelectedRole(selected)}
                isDisabled={!selectedUserType.value}
              />
            </FormEntry>
          </div>

          <div className="column is-4">
            <FormEntry labelText="Email">
              <input
                className="input"
                type="email"
                value={userEmail}
                onChange={e => setUserEmail(e.target.value)}
                placeholder="ivanovaa@mail.com"
              />
            </FormEntry>
          </div>
        </div>

        {selectedUserType.value === "divisionManager" ? (
          <FormEntry>
            <Select
              value={selectedDivision}
              options={divisionOptions[i18n.language]}
              onChange={setSelectedDivision}
              isLoading={dLoading}
            />
          </FormEntry>
        ) : null}

        {selectedUserType.value === "academicManager" ? (
          <FormEntry>
            <Select
              placeholder={t("invite.programmePlaceholder")}
              value={selectedProgramme}
              options={programmeOptions[i18n.language]}
              onChange={setSelectedProgramme}
              isLoading={pLoading}
              isMulti
              isClearable
            />
          </FormEntry>
        ) : null}

        <div className="field has-text-centered mt-4">
          <Submitting submitting={submitting}>
            <p className="control">
              <button
                className="button is-link is-outlined is-fullwidth"
                type="submit"
                onClick={e => createInvite(e)}
              >
                {t("invite.newButton")}
              </button>
            </p>
          </Submitting>
        </div>
      </form>
    </div>
  )
}

export default InviteNewForm
