import { createContext, useContext, useEffect, useState } from "react"
import { AcademicProgrammeContext } from "./AcademicProgrammeContext"
import usePaginatedApplications from "./usePaginatedApplications"

export const PaginatedApplicationsContext = createContext()

const PaginatedApplicationsContextProvider = ({ children }) => {
  const [currentPage, setCurrentPage] = useState(1)
  const [queryStatus, setQueryStatus] = useState("")
  const [queryStudentID, setQueryStudentID] = useState("")
  const [queryProjectID, setQueryProjectID] = useState("")
  const { currentProgramme } = useContext(AcademicProgrammeContext)
  const { applications, loading, refetch, pageCount } =
    usePaginatedApplications(
      currentPage,
      queryStatus,
      currentProgramme,
      queryStudentID,
      queryProjectID
    )

  useEffect(() => {
    if (currentPage > pageCount) setCurrentPage(1)
  }, [currentPage, pageCount])

  const switchStatus = status => {
    setCurrentPage(1)
    setQueryStatus(status)
  }

  const switchStudentID = studentID => {
    setCurrentPage(1)
    setQueryStudentID(studentID)
  }

  const switchProjectID = projectID => {
    setCurrentPage(1)
    setQueryProjectID(projectID)
  }

  const value = {
    applications,
    loading,
    refetch,
    currentPage,
    setCurrentPage,
    pageCount,
    switchStatus,
    switchStudentID,
    switchProjectID,
    queryStatus,
  }

  return (
    <PaginatedApplicationsContext.Provider value={value}>
      {children}
    </PaginatedApplicationsContext.Provider>
  )
}

export const usePaginatedApplicationsContext = () => {
  const context = useContext(PaginatedApplicationsContext)

  return context
}

export default PaginatedApplicationsContextProvider
